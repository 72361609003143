export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  Date: any;
  /** A field whose value is an existing 2 or 3 letter ISO 639 language code https://iso639-3.sil.org/code_tables/639/data */
  Iso639: any;
  /**
   * A date-time string at UTC, such as 2007-12-03T10:15:30Z,
   *     compliant with the 'date-time' format outlined in section 5.6 of
   *     the RFC 3339 profile of the ISO 8601 standard for representation
   *     of dates and times using the Gregorian calendar.
   */
  ContentfulDateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  ContentfulJSON: any;
  /** The 'HexColor' type represents color in `rgb:ffffff` string format. */
  ContentfulHexColor: any;
  /** The 'Dimension' type represents dimensions as whole numeric values between `1` and `4000`. */
  ContentfulDimension: any;
  /** The 'Quality' type represents quality as whole numeric values between `1` and `100`. */
  ContentfulQuality: any;
  JSON: any;
  /** A field whose value conforms to the standard URL format as specified in RFC3986: https://www.ietf.org/rfc/rfc3986.txt. */
  Url: any;
};

export type Query = {
  __typename?: "Query";
  activity: Activity;
  activityBySectionUidIfLatestMeetingNotReviewed: Maybe<Activity>;
  activitySection: Maybe<Section>;
  adminEnrollmentPaymentRefundActions: Maybe<
    Array<AdminEnrollmentPaymentRefundAction>
  >;
  agreementTemplate: SellerOrgAgreementTemplate;
  approvedExternalResources: Array<Maybe<ExternalResource>>;
  appState: AppState;
  assignmentClassPost: Maybe<AssignmentClassPost>;
  assignmentClassPosts: Maybe<Array<AssignmentClassPost>>;
  assignmentLessonPost: AssignmentLessonPost;
  attachment: Attachment;
  bulkPricingBreakdown: BulkPricingBreakdown;
  bundle: Bundle;
  bundleElement: BundleElement;
  categoryPage: Maybe<CategoryPage>;
  categoryPages: Maybe<Array<CategoryPage>>;
  categoryPagesByPage: Maybe<PaginatedCategoryPages>;
  /** Check for meetings that occur within at least one of the range. */
  checkMeetingConflicts: Array<MeetingConflictResult>;
  classRequest: Maybe<ClassRequest>;
  classroom: Classroom;
  club: Club;
  clubCategories: Array<ClubCategory>;
  clubPostAndCommentBackgroundColors: Array<Scalars["String"]>;
  contentful_node: Maybe<Contentful_Node>;
  contentful_nodes: Array<Maybe<Contentful_Node>>;
  contentfulAccordionPage: Maybe<ContentfulAccordionPage>;
  contentfulAccordionPageCollection: Maybe<ContentfulAccordionPageCollection>;
  contentfulAccordionSection: Maybe<ContentfulAccordionSection>;
  contentfulAccordionSectionCollection: Maybe<ContentfulAccordionSectionCollection>;
  contentfulAnnouncementBanner: Maybe<ContentfulAnnouncementBanner>;
  contentfulAnnouncementBannerCollection: Maybe<ContentfulAnnouncementBannerCollection>;
  contentfulAsset: Maybe<ContentfulAsset>;
  contentfulAssetCollection: Maybe<ContentfulAssetCollection>;
  contentfulAuthor: Maybe<ContentfulAuthor>;
  contentfulAuthorCollection: Maybe<ContentfulAuthorCollection>;
  contentfulBanner: Maybe<ContentfulBanner>;
  contentfulBannerCollection: Maybe<ContentfulBannerCollection>;
  contentfulBannerPlacement: Maybe<ContentfulBannerPlacement>;
  contentfulBannerPlacementCollection: Maybe<ContentfulBannerPlacementCollection>;
  contentfulBasicPage: Maybe<ContentfulBasicPage>;
  contentfulBasicPageCollection: Maybe<ContentfulBasicPageCollection>;
  contentfulCampaign: Maybe<ContentfulCampaign>;
  contentfulCampaignCollection: Maybe<ContentfulCampaignCollection>;
  contentfulCampaignCountry: Maybe<ContentfulCampaignCountry>;
  contentfulCampaignCountryCollection: Maybe<ContentfulCampaignCountryCollection>;
  contentfulCampaignStateOrProvince: Maybe<ContentfulCampaignStateOrProvince>;
  contentfulCampaignStateOrProvinceCollection: Maybe<ContentfulCampaignStateOrProvinceCollection>;
  contentfulCategoryLabel: Maybe<ContentfulCategoryLabel>;
  contentfulCategoryLabelCollection: Maybe<ContentfulCategoryLabelCollection>;
  contentfulCategoryPage: Maybe<ContentfulCategoryPage>;
  contentfulCategoryPageCollection: Maybe<ContentfulCategoryPageCollection>;
  contentfulClpEmailCapture: Maybe<ContentfulClpEmailCapture>;
  contentfulClpEmailCaptureCollection: Maybe<ContentfulClpEmailCaptureCollection>;
  contentfulEntryCollection: Maybe<ContentfulEntryCollection>;
  contentfulFeaturedPages: Maybe<ContentfulFeaturedPages>;
  contentfulFeaturedPagesCollection: Maybe<ContentfulFeaturedPagesCollection>;
  contentfulHeroSlide: Maybe<ContentfulHeroSlide>;
  contentfulHeroSlideCollection: Maybe<ContentfulHeroSlideCollection>;
  contentfulHomePage2: Maybe<ContentfulHomePage2>;
  contentfulHomePage2Collection: Maybe<ContentfulHomePage2Collection>;
  contentfulJumpPills: Maybe<ContentfulJumpPills>;
  contentfulJumpPillsCollection: Maybe<ContentfulJumpPillsCollection>;
  contentfulLandingPage: Maybe<ContentfulLandingPage>;
  contentfulLandingPageClassCarousel: Maybe<ContentfulLandingPageClassCarousel>;
  contentfulLandingPageClassCarouselCollection: Maybe<ContentfulLandingPageClassCarouselCollection>;
  contentfulLandingPageCollection: Maybe<ContentfulLandingPageCollection>;
  contentfulLandingPageHeroSection: Maybe<ContentfulLandingPageHeroSection>;
  contentfulLandingPageHeroSectionCollection: Maybe<ContentfulLandingPageHeroSectionCollection>;
  contentfulLandingPageIconInfo: Maybe<ContentfulLandingPageIconInfo>;
  contentfulLandingPageIconInfoCollection: Maybe<ContentfulLandingPageIconInfoCollection>;
  contentfulLandingPageIconInfoSection: Maybe<ContentfulLandingPageIconInfoSection>;
  contentfulLandingPageIconInfoSectionCollection: Maybe<ContentfulLandingPageIconInfoSectionCollection>;
  contentfulLandingPageImageTextCtaSection: Maybe<ContentfulLandingPageImageTextCtaSection>;
  contentfulLandingPageImageTextCtaSectionCollection: Maybe<ContentfulLandingPageImageTextCtaSectionCollection>;
  contentfulLandingPageJourneyItem: Maybe<ContentfulLandingPageJourneyItem>;
  contentfulLandingPageJourneyItemCollection: Maybe<ContentfulLandingPageJourneyItemCollection>;
  contentfulLandingPageJourneySection: Maybe<ContentfulLandingPageJourneySection>;
  contentfulLandingPageJourneySectionCollection: Maybe<ContentfulLandingPageJourneySectionCollection>;
  contentfulLandingPageLessonPreviewSection: Maybe<ContentfulLandingPageLessonPreviewSection>;
  contentfulLandingPageLessonPreviewSectionCollection: Maybe<ContentfulLandingPageLessonPreviewSectionCollection>;
  contentfulLandingPageSingleReviewSection: Maybe<ContentfulLandingPageSingleReviewSection>;
  contentfulLandingPageSingleReviewSectionCollection: Maybe<ContentfulLandingPageSingleReviewSectionCollection>;
  contentfulLandingPageStatBox: Maybe<ContentfulLandingPageStatBox>;
  contentfulLandingPageStatBoxCollection: Maybe<ContentfulLandingPageStatBoxCollection>;
  contentfulLandingPageStatBoxSection: Maybe<ContentfulLandingPageStatBoxSection>;
  contentfulLandingPageStatBoxSectionCollection: Maybe<ContentfulLandingPageStatBoxSectionCollection>;
  contentfulLandingPageTextOnlyStackedCtaSection: Maybe<ContentfulLandingPageTextOnlyStackedCtaSection>;
  contentfulLandingPageTextOnlyStackedCtaSectionCollection: Maybe<ContentfulLandingPageTextOnlyStackedCtaSectionCollection>;
  contentfulLearnerBanner: Maybe<ContentfulLearnerBanner>;
  contentfulLearnerBannerCollection: Maybe<ContentfulLearnerBannerCollection>;
  contentfulListingsPromoBanner: Maybe<ContentfulListingsPromoBanner>;
  contentfulListingsPromoBannerButton: Maybe<ContentfulListingsPromoBannerButton>;
  contentfulListingsPromoBannerButtonCollection: Maybe<ContentfulListingsPromoBannerButtonCollection>;
  contentfulListingsPromoBannerCollection: Maybe<ContentfulListingsPromoBannerCollection>;
  contentfulListingsPromoBannerHeaderTitleFontDefinition: Maybe<ContentfulListingsPromoBannerHeaderTitleFontDefinition>;
  contentfulListingsPromoBannerHeaderTitleFontDefinitionCollection: Maybe<ContentfulListingsPromoBannerHeaderTitleFontDefinitionCollection>;
  contentfulMagnetPage: Maybe<ContentfulMagnetPage>;
  contentfulMagnetPageCollection: Maybe<ContentfulMagnetPageCollection>;
  contentfulSearchFilters: Maybe<ContentfulSearchFilters>;
  contentfulSearchFiltersCollection: Maybe<ContentfulSearchFiltersCollection>;
  contentfulTopic: Maybe<ContentfulTopic>;
  contentfulTopicCollection: Maybe<ContentfulTopicCollection>;
  contentfulTutoringPage: Maybe<ContentfulTutoringPage>;
  contentfulTutoringPageCollection: Maybe<ContentfulTutoringPageCollection>;
  contentfulUpsellClassCard: Maybe<ContentfulUpsellClassCard>;
  contentfulUpsellClassCardCollection: Maybe<ContentfulUpsellClassCardCollection>;
  contentfulValueProp: Maybe<ContentfulValueProp>;
  contentfulValuePropCollection: Maybe<ContentfulValuePropCollection>;
  contentfulVisualLink: Maybe<ContentfulVisualLink>;
  contentfulVisualLinkCollection: Maybe<ContentfulVisualLinkCollection>;
  contentfulVisualLinkGrid: Maybe<ContentfulVisualLinkGrid>;
  contentfulVisualLinkGridCollection: Maybe<ContentfulVisualLinkGridCollection>;
  countListings: Scalars["Int"];
  countPricingOffersOnOrgForEmail: Scalars["Int"];
  coupon: Coupon;
  couponCodeExists: Scalars["Boolean"];
  couponCodeList: CouponCodeList;
  couponCodeRedemptionDetailsList: Maybe<CouponCodeRedemptionDetailsList>;
  /** @deprecated Use couponCodeRedemptionDetailsList */
  couponCodeRedemptionList: Maybe<CouponCodeRedemptionList>;
  couponList: CouponList;
  /** @deprecated Use coupon.couponMaxUsesReached instead */
  couponMaxUsesReached: Maybe<Scalars["Boolean"]>;
  creditBalance: Maybe<CreditAccount>;
  curatedPages: Maybe<Array<Maybe<CategoryPage>>>;
  currentLearner: CurrentLearner;
  currentLearnerClubs: Array<Club>;
  currentLearnerPastActivities: PaginatedPastActivities;
  currentLearnerPastClasses: PastClasses;
  currentLearnerRecommendedTeachers: RecommendedTeachers;
  currentLearnerRecommendedTopics: Array<Topic>;
  currentLearnerScheduleEvents: ScheduleEvents;
  currentUser: Maybe<User>;
  donation: Donation;
  emailPreferences: Array<EmailPreference>;
  enrollmentForm: Maybe<EnrollmentForm>;
  enrollmentMeetingPurchaseForm: Maybe<EnrollmentMeetingPurchaseForm>;
  enrollmentPaymentRefund: EnrollmentPaymentRefund;
  entityAvailabilitiesByLearnerPlanUid: EntityAvailabilitySchedule;
  entityAvailabilitySchedule: EntityAvailabilitySchedule;
  exchangeRate: Maybe<ExchangeRate>;
  experimentOverrides: Array<ExperimentOverride>;
  featured: Featured;
  featuredClubs: Array<Activity>;
  featureFlags: Array<Scalars["String"]>;
  feedbackForCategorySearchFilters: Maybe<FeedbackForCategoryPageResult>;
  financialAidPricingOffer: PublicPricingOffer;
  getClassInquiriesByUser: Array<Maybe<ClassInquiry>>;
  getFundingProgramInvoice: Maybe<FundingProgramInvoice>;
  getPayoutsCsvUrl: Scalars["String"];
  getTeacherClassInquiries: TeacherClassInquiries;
  getTnsIncidentsSummary: TnsIncidentsSummary;
  giftCard: GiftCard;
  giftEnrollmentsForAcceptSlug: Array<GiftEnrollment>;
  hasPinSet: Maybe<Scalars["Boolean"]>;
  /** Get learner interests topics for signup */
  interestTopics: TopicsConnection;
  isMessageRead: Scalars["Boolean"];
  joinMeeting: JoinMeeting;
  joinNextMeeting: NextMeeting;
  lastUnrefundedEnrollmentPayment: Maybe<EnrollmentPayment>;
  learner: Maybe<Learner>;
  /** Returns whether or not the learner has any unread notifications between now and 'after' */
  learnerHasUnreadNotifications: Scalars["Boolean"];
  /**
   * A paginated list of notifications for a learner (based on learnerAuthToken).
   * You can use INotification.createdAt as a cursor for pagination
   */
  learnerNotifications: LearnerNotificationConnection;
  learnerPlanActivitiesExist: Scalars["Boolean"];
  learnerPlanForGoal: Maybe<LearnerPlan>;
  learnerPlanGoals: Array<LearnerPlanGoal>;
  learnerPlanRejectedSuggestionsForLearner: Array<LearnerPlanRejectedSuggestion>;
  learnerPlans: Array<LearnerPlan>;
  learnerProfile: LearnerProfile;
  learnerScheduleEvents: ScheduleEvents;
  learnerSearchActivityByTopic: LearnerActivityByTopicSearch;
  learnerSearchKeyword: LearnerKeywordSearch;
  learnerSearchSuggestions: LearnerSearchSuggestions;
  learnerSearchTeacherActivities: LearnerSearchTeacherActivities;
  learnersForParentInSection: Array<Maybe<Learner>>;
  learnersToPick: Array<LearnerToPick>;
  learnerSupportTopics: Array<LearnerSupportTopic>;
  learningStatement: LearningStatement;
  lessonLiveMeeting: LessonLiveMeeting;
  lessonPost: LessonPost;
  listing: Maybe<Listing>;
  listings: Array<Listing>;
  listingsByActivityUids: Array<Listing>;
  listingsByTeacherUids: Array<ListingsByTeacher>;
  meetingAiSummary: Maybe<MeetingAiSummary>;
  messageThread: MessageThread;
  nextApprovalActivityUid: Maybe<Scalars["ID"]>;
  /** @deprecated Moved to JoinMeeting service */
  nextMeeting: Maybe<Meeting>;
  nextMeetingReminder: Maybe<NextMeetingReminder>;
  nextTeacherToReview: Maybe<User>;
  /** @deprecated Use coupon instead */
  nonBatchedCoupon: Maybe<Coupon>;
  order: Order;
  organization: Maybe<Organization>;
  organizations: Array<Organization>;
  parent: Maybe<Parent>;
  participants: Array<Participant>;
  pastEnrollments: Array<Maybe<Listing>>;
  pastEnrollmentsForParentProfile: Array<Maybe<Listing>>;
  /** @deprecated use pastTeachersForParentProfile */
  pastReviewedTeachers: Array<Leader>;
  /** @deprecated use pastTeachersForParentProfile */
  pastTeachers: Array<Leader>;
  pastTeachersForParentProfile: Array<Leader>;
  photoFeedbackByUser: Array<PublicReview>;
  policyForExternalResource: Maybe<ExternalResourcePolicy>;
  popularClubs: Array<Activity>;
  pricingBreakdown: PricingBreakdown;
  pricingOffer: PricingOffer;
  pricingOfferAttendanceLog: Array<Maybe<PricingOfferAttendanceLog>>;
  pricingOfferAttendancesByWeek: Array<PricingOfferAttendancesByWeek>;
  pricingOfferEnrollmentsSummary: PricingOfferEnrollmentsSummary;
  pricingOfferPaymentsByWeek: Array<Maybe<PricingOffersPaymentsByWeek>>;
  pricingOffers: Array<PricingOffer>;
  pricingOfferTransactions: Array<Maybe<PricingOffersTransaction>>;
  privateClassMessages: Array<PrivateClassMessage>;
  privateMessageThread: PrivateClassThread;
  /** @deprecated use pastEnrollmentsForParentProfile */
  publiclyReviewedPastEnrollments: Array<Maybe<Listing>>;
  /** @deprecated Use publicProfileNew */
  publicProfile: Maybe<PublicProfile>;
  publicProfileNew: Maybe<PublicProfile>;
  raceEthnicityOptions: Array<RaceEthnicityOption>;
  randomCategoriesByFacet: Maybe<Array<Maybe<CategoryPage>>>;
  refundPolicies: Array<RefundPolicy>;
  relatedTermSuggestions: RelatedTermSuggestions;
  savedClassList: Maybe<SavedClassList>;
  savedClassListsForParentProfile: Array<SavedClassList>;
  savedClassListsForUser: Array<SavedClassList>;
  savedLearnerClassList: Maybe<SavedClassList>;
  schoolPage: Maybe<SchoolPage>;
  searchConversationFilters: Array<ConversationFilter>;
  searchCounselorSuggestion: SearchCounselorSuggestion;
  searchListings: SearchListingsConnection;
  searchSuggestions: SearchSuggestions;
  section: Maybe<Section>;
  selfPacedEnrollmentForm: SelfPacedEnrollmentForm;
  similarListingsUnpublished: SimilarListings;
  /** Experimental */
  spellingSuggestion: SpellingSuggestion;
  stripePublishableKey: Scalars["String"];
  subscriptionCreditBalance: Maybe<SubscriptionCreditBalance>;
  switchUserLink: Scalars["String"];
  syllabus: Maybe<Syllabus>;
  teacherCredentialFilePreviewURL: Scalars["String"];
  teacherCredentials: Maybe<Array<Maybe<TeacherCredential>>>;
  teacherHasInquiriesButHasNotRepliedToday: Scalars["Boolean"];
  teacherMarketingSettings: Maybe<TeacherMarketingSettings>;
  teacherSearchSuggestions: TeacherSearchSuggestions;
  teacherTags: TeacherTagsConnection;
  teachingWithOutschoolActivity: Activity;
  textFeedbackByUser: Array<PublicReview>;
  topLevelCategoryPages: Maybe<Array<Maybe<CategoryPage>>>;
  transactionListings: TransactionListings;
  trendingSearchTopics: Array<TrendingSearchTopic>;
  tutorMatchListings: TutorMatchListingsConnection;
  unsentMeetingSummariesSections: Array<Scalars["ID"]>;
  unverifiedClubEnrollments: Array<Enrollment>;
  user: Maybe<User>;
  validateBulkEnrollments: ValidatedBulkEnrollment;
  validateEmail: Scalars["Boolean"];
  validateUsername: Scalars["Boolean"];
  waitlistSeat: WaitlistSeat;
  waitlistSeatsForLearner: Array<Maybe<WaitlistSeat>>;
  waitlistSeatsForParent: Array<Maybe<WaitlistSeat>>;
};

export type QueryActivityArgs = {
  esaState?: InputMaybe<Scalars["String"]>;
  sectionUid?: InputMaybe<Scalars["ID"]>;
  slugId?: InputMaybe<Scalars["String"]>;
  uid?: InputMaybe<Scalars["ID"]>;
};

export type QueryActivityBySectionUidIfLatestMeetingNotReviewedArgs = {
  sectionUid: Scalars["ID"];
};

export type QueryActivitySectionArgs = {
  activityUid: Scalars["ID"];
  sectionUid: Scalars["ID"];
};

export type QueryAdminEnrollmentPaymentRefundActionsArgs = {
  enrollmentPaymentRefundUid?: InputMaybe<Scalars["ID"]>;
};

export type QueryAgreementTemplateArgs = {
  name?: InputMaybe<Scalars["String"]>;
  uid?: InputMaybe<Scalars["ID"]>;
};

export type QueryAssignmentClassPostArgs = {
  uid: Scalars["ID"];
};

export type QueryAssignmentClassPostsArgs = {
  lessonUid: Scalars["ID"];
  sectionUid: Scalars["ID"];
};

export type QueryAssignmentLessonPostArgs = {
  uid: Scalars["ID"];
};

export type QueryAttachmentArgs = {
  attachmentUid: Scalars["ID"];
};

export type QueryBulkPricingBreakdownArgs = {
  outschoolCouponCode?: InputMaybe<Scalars["String"]>;
  pricingBreakdownInputs: Array<PricingBreakdownInput>;
};

export type QueryBundleArgs = {
  uid: Scalars["ID"];
};

export type QueryBundleElementArgs = {
  uid: Scalars["ID"];
};

export type QueryCategoryPageArgs = {
  slug: Scalars["String"];
};

export type QueryCategoryPagesArgs = {
  slugs?: InputMaybe<Array<Scalars["String"]>>;
};

export type QueryCategoryPagesByPageArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type QueryCheckMeetingConflictsArgs = {
  orgTeacherUid?: InputMaybe<Scalars["String"]>;
  query: Array<MeetingConflictsQueryInput>;
};

export type QueryClassRequestArgs = {
  uid: Scalars["ID"];
};

export type QueryClassroomArgs = {
  sectionUid: Scalars["ID"];
};

export type QueryClubArgs = {
  sectionUid: Scalars["ID"];
};

export type QueryContentful_NodeArgs = {
  id: Scalars["ID"];
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryContentful_NodesArgs = {
  ids: Array<Scalars["ID"]>;
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryContentfulAccordionPageArgs = {
  id: Scalars["String"];
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryContentfulAccordionPageCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Array<InputMaybe<ContentfulAccordionPageOrder>>>;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ContentfulAccordionPageFilter>;
};

export type QueryContentfulAccordionSectionArgs = {
  id: Scalars["String"];
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryContentfulAccordionSectionCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Array<InputMaybe<ContentfulAccordionSectionOrder>>>;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ContentfulAccordionSectionFilter>;
};

export type QueryContentfulAnnouncementBannerArgs = {
  id: Scalars["String"];
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryContentfulAnnouncementBannerCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Array<InputMaybe<ContentfulAnnouncementBannerOrder>>>;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ContentfulAnnouncementBannerFilter>;
};

export type QueryContentfulAssetArgs = {
  id: Scalars["String"];
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryContentfulAssetCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Array<InputMaybe<ContentfulAssetOrder>>>;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ContentfulAssetFilter>;
};

export type QueryContentfulAuthorArgs = {
  id: Scalars["String"];
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryContentfulAuthorCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Array<InputMaybe<ContentfulAuthorOrder>>>;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ContentfulAuthorFilter>;
};

export type QueryContentfulBannerArgs = {
  id: Scalars["String"];
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryContentfulBannerCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Array<InputMaybe<ContentfulBannerOrder>>>;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ContentfulBannerFilter>;
};

export type QueryContentfulBannerPlacementArgs = {
  id: Scalars["String"];
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryContentfulBannerPlacementCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Array<InputMaybe<ContentfulBannerPlacementOrder>>>;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ContentfulBannerPlacementFilter>;
};

export type QueryContentfulBasicPageArgs = {
  id: Scalars["String"];
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryContentfulBasicPageCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Array<InputMaybe<ContentfulBasicPageOrder>>>;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ContentfulBasicPageFilter>;
};

export type QueryContentfulCampaignArgs = {
  id: Scalars["String"];
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryContentfulCampaignCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Array<InputMaybe<ContentfulCampaignOrder>>>;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ContentfulCampaignFilter>;
};

export type QueryContentfulCampaignCountryArgs = {
  id: Scalars["String"];
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryContentfulCampaignCountryCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Array<InputMaybe<ContentfulCampaignCountryOrder>>>;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ContentfulCampaignCountryFilter>;
};

export type QueryContentfulCampaignStateOrProvinceArgs = {
  id: Scalars["String"];
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryContentfulCampaignStateOrProvinceCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Array<InputMaybe<ContentfulCampaignStateOrProvinceOrder>>>;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ContentfulCampaignStateOrProvinceFilter>;
};

export type QueryContentfulCategoryLabelArgs = {
  id: Scalars["String"];
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryContentfulCategoryLabelCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Array<InputMaybe<ContentfulCategoryLabelOrder>>>;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ContentfulCategoryLabelFilter>;
};

export type QueryContentfulCategoryPageArgs = {
  id: Scalars["String"];
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryContentfulCategoryPageCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Array<InputMaybe<ContentfulCategoryPageOrder>>>;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ContentfulCategoryPageFilter>;
};

export type QueryContentfulClpEmailCaptureArgs = {
  id: Scalars["String"];
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryContentfulClpEmailCaptureCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Array<InputMaybe<ContentfulClpEmailCaptureOrder>>>;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ContentfulClpEmailCaptureFilter>;
};

export type QueryContentfulEntryCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Array<InputMaybe<ContentfulEntryOrder>>>;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ContentfulEntryFilter>;
};

export type QueryContentfulFeaturedPagesArgs = {
  id: Scalars["String"];
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryContentfulFeaturedPagesCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Array<InputMaybe<ContentfulFeaturedPagesOrder>>>;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ContentfulFeaturedPagesFilter>;
};

export type QueryContentfulHeroSlideArgs = {
  id: Scalars["String"];
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryContentfulHeroSlideCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Array<InputMaybe<ContentfulHeroSlideOrder>>>;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ContentfulHeroSlideFilter>;
};

export type QueryContentfulHomePage2Args = {
  id: Scalars["String"];
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryContentfulHomePage2CollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Array<InputMaybe<ContentfulHomePage2Order>>>;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ContentfulHomePage2Filter>;
};

export type QueryContentfulJumpPillsArgs = {
  id: Scalars["String"];
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryContentfulJumpPillsCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Array<InputMaybe<ContentfulJumpPillsOrder>>>;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ContentfulJumpPillsFilter>;
};

export type QueryContentfulLandingPageArgs = {
  id: Scalars["String"];
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryContentfulLandingPageClassCarouselArgs = {
  id: Scalars["String"];
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryContentfulLandingPageClassCarouselCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<
    Array<InputMaybe<ContentfulLandingPageClassCarouselOrder>>
  >;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ContentfulLandingPageClassCarouselFilter>;
};

export type QueryContentfulLandingPageCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Array<InputMaybe<ContentfulLandingPageOrder>>>;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ContentfulLandingPageFilter>;
};

export type QueryContentfulLandingPageHeroSectionArgs = {
  id: Scalars["String"];
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryContentfulLandingPageHeroSectionCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Array<InputMaybe<ContentfulLandingPageHeroSectionOrder>>>;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ContentfulLandingPageHeroSectionFilter>;
};

export type QueryContentfulLandingPageIconInfoArgs = {
  id: Scalars["String"];
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryContentfulLandingPageIconInfoCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Array<InputMaybe<ContentfulLandingPageIconInfoOrder>>>;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ContentfulLandingPageIconInfoFilter>;
};

export type QueryContentfulLandingPageIconInfoSectionArgs = {
  id: Scalars["String"];
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryContentfulLandingPageIconInfoSectionCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<
    Array<InputMaybe<ContentfulLandingPageIconInfoSectionOrder>>
  >;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ContentfulLandingPageIconInfoSectionFilter>;
};

export type QueryContentfulLandingPageImageTextCtaSectionArgs = {
  id: Scalars["String"];
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryContentfulLandingPageImageTextCtaSectionCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<
    Array<InputMaybe<ContentfulLandingPageImageTextCtaSectionOrder>>
  >;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ContentfulLandingPageImageTextCtaSectionFilter>;
};

export type QueryContentfulLandingPageJourneyItemArgs = {
  id: Scalars["String"];
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryContentfulLandingPageJourneyItemCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Array<InputMaybe<ContentfulLandingPageJourneyItemOrder>>>;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ContentfulLandingPageJourneyItemFilter>;
};

export type QueryContentfulLandingPageJourneySectionArgs = {
  id: Scalars["String"];
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryContentfulLandingPageJourneySectionCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<
    Array<InputMaybe<ContentfulLandingPageJourneySectionOrder>>
  >;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ContentfulLandingPageJourneySectionFilter>;
};

export type QueryContentfulLandingPageLessonPreviewSectionArgs = {
  id: Scalars["String"];
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryContentfulLandingPageLessonPreviewSectionCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<
    Array<InputMaybe<ContentfulLandingPageLessonPreviewSectionOrder>>
  >;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ContentfulLandingPageLessonPreviewSectionFilter>;
};

export type QueryContentfulLandingPageSingleReviewSectionArgs = {
  id: Scalars["String"];
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryContentfulLandingPageSingleReviewSectionCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<
    Array<InputMaybe<ContentfulLandingPageSingleReviewSectionOrder>>
  >;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ContentfulLandingPageSingleReviewSectionFilter>;
};

export type QueryContentfulLandingPageStatBoxArgs = {
  id: Scalars["String"];
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryContentfulLandingPageStatBoxCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Array<InputMaybe<ContentfulLandingPageStatBoxOrder>>>;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ContentfulLandingPageStatBoxFilter>;
};

export type QueryContentfulLandingPageStatBoxSectionArgs = {
  id: Scalars["String"];
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryContentfulLandingPageStatBoxSectionCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<
    Array<InputMaybe<ContentfulLandingPageStatBoxSectionOrder>>
  >;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ContentfulLandingPageStatBoxSectionFilter>;
};

export type QueryContentfulLandingPageTextOnlyStackedCtaSectionArgs = {
  id: Scalars["String"];
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryContentfulLandingPageTextOnlyStackedCtaSectionCollectionArgs =
  {
    limit?: InputMaybe<Scalars["Int"]>;
    locale?: InputMaybe<Scalars["String"]>;
    order?: InputMaybe<
      Array<InputMaybe<ContentfulLandingPageTextOnlyStackedCtaSectionOrder>>
    >;
    preview?: InputMaybe<Scalars["Boolean"]>;
    skip?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<ContentfulLandingPageTextOnlyStackedCtaSectionFilter>;
  };

export type QueryContentfulLearnerBannerArgs = {
  id: Scalars["String"];
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryContentfulLearnerBannerCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Array<InputMaybe<ContentfulLearnerBannerOrder>>>;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ContentfulLearnerBannerFilter>;
};

export type QueryContentfulListingsPromoBannerArgs = {
  id: Scalars["String"];
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryContentfulListingsPromoBannerButtonArgs = {
  id: Scalars["String"];
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryContentfulListingsPromoBannerButtonCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<
    Array<InputMaybe<ContentfulListingsPromoBannerButtonOrder>>
  >;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ContentfulListingsPromoBannerButtonFilter>;
};

export type QueryContentfulListingsPromoBannerCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Array<InputMaybe<ContentfulListingsPromoBannerOrder>>>;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ContentfulListingsPromoBannerFilter>;
};

export type QueryContentfulListingsPromoBannerHeaderTitleFontDefinitionArgs = {
  id: Scalars["String"];
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryContentfulListingsPromoBannerHeaderTitleFontDefinitionCollectionArgs =
  {
    limit?: InputMaybe<Scalars["Int"]>;
    locale?: InputMaybe<Scalars["String"]>;
    order?: InputMaybe<
      Array<
        InputMaybe<ContentfulListingsPromoBannerHeaderTitleFontDefinitionOrder>
      >
    >;
    preview?: InputMaybe<Scalars["Boolean"]>;
    skip?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<ContentfulListingsPromoBannerHeaderTitleFontDefinitionFilter>;
  };

export type QueryContentfulMagnetPageArgs = {
  id: Scalars["String"];
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryContentfulMagnetPageCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Array<InputMaybe<ContentfulMagnetPageOrder>>>;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ContentfulMagnetPageFilter>;
};

export type QueryContentfulSearchFiltersArgs = {
  id: Scalars["String"];
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryContentfulSearchFiltersCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Array<InputMaybe<ContentfulSearchFiltersOrder>>>;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ContentfulSearchFiltersFilter>;
};

export type QueryContentfulTopicArgs = {
  id: Scalars["String"];
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryContentfulTopicCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Array<InputMaybe<ContentfulTopicOrder>>>;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ContentfulTopicFilter>;
};

export type QueryContentfulTutoringPageArgs = {
  id: Scalars["String"];
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryContentfulTutoringPageCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Array<InputMaybe<ContentfulTutoringPageOrder>>>;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ContentfulTutoringPageFilter>;
};

export type QueryContentfulUpsellClassCardArgs = {
  id: Scalars["String"];
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryContentfulUpsellClassCardCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Array<InputMaybe<ContentfulUpsellClassCardOrder>>>;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ContentfulUpsellClassCardFilter>;
};

export type QueryContentfulValuePropArgs = {
  id: Scalars["String"];
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryContentfulValuePropCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Array<InputMaybe<ContentfulValuePropOrder>>>;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ContentfulValuePropFilter>;
};

export type QueryContentfulVisualLinkArgs = {
  id: Scalars["String"];
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryContentfulVisualLinkCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Array<InputMaybe<ContentfulVisualLinkOrder>>>;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ContentfulVisualLinkFilter>;
};

export type QueryContentfulVisualLinkGridArgs = {
  id: Scalars["String"];
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryContentfulVisualLinkGridCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Array<InputMaybe<ContentfulVisualLinkGridOrder>>>;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ContentfulVisualLinkGridFilter>;
};

export type QueryCountListingsArgs = {
  searchFilters?: InputMaybe<SearchFilters>;
};

export type QueryCountPricingOffersOnOrgForEmailArgs = {
  emailAddress: Scalars["String"];
  organizationUid: Scalars["ID"];
};

export type QueryCouponArgs = {
  uid: Scalars["String"];
};

export type QueryCouponCodeExistsArgs = {
  code: Scalars["String"];
};

export type QueryCouponCodeListArgs = {
  filter: CouponCodeListFilter;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type QueryCouponCodeRedemptionDetailsListArgs = {
  couponCodeUid: Scalars["String"];
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type QueryCouponCodeRedemptionListArgs = {
  couponCodeUid: Scalars["String"];
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type QueryCouponListArgs = {
  filter: CouponListFilter;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type QueryCouponMaxUsesReachedArgs = {
  uid: Scalars["String"];
};

export type QueryCurrentLearnerClubsArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type QueryCurrentLearnerPastActivitiesArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type QueryCurrentLearnerRecommendedTeachersArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
};

export type QueryDonationArgs = {
  uid: Scalars["ID"];
};

export type QueryEmailPreferencesArgs = {
  token: Scalars["String"];
};

export type QueryEnrollmentFormArgs = {
  sectionUid: Scalars["ID"];
};

export type QueryEnrollmentMeetingPurchaseFormArgs = {
  activityUid: Scalars["ID"];
  enrollmentUid?: InputMaybe<Scalars["ID"]>;
  meetingRequestUids?: InputMaybe<Array<Scalars["ID"]>>;
  orgTeacherUid?: InputMaybe<Scalars["ID"]>;
  startTime: Scalars["DateTime"];
};

export type QueryEnrollmentPaymentRefundArgs = {
  enrollmentPaymentRefundUid: Scalars["ID"];
};

export type QueryEntityAvailabilitiesByLearnerPlanUidArgs = {
  learnerPlanUid: Scalars["ID"];
};

export type QueryEntityAvailabilityScheduleArgs = {
  uid: Scalars["ID"];
};

export type QueryExchangeRateArgs = {
  currencyCode: CurrencyCode;
};

export type QueryFeedbackForCategorySearchFiltersArgs = {
  findKeywords?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  limit?: InputMaybe<Scalars["Int"]>;
  maxFeedbackLength?: InputMaybe<Scalars["Int"]>;
  minFeedbackLength?: InputMaybe<Scalars["Int"]>;
  minHelpfulCount?: InputMaybe<Scalars["Int"]>;
  minStarRating?: InputMaybe<Scalars["Int"]>;
  pageLocation?: InputMaybe<Scalars["String"]>;
  searchFilters: SearchFilters;
};

export type QueryGetClassInquiriesByUserArgs = {
  userUid: Scalars["ID"];
};

export type QueryGetFundingProgramInvoiceArgs = {
  orderSlugId: Scalars["ID"];
};

export type QueryGetPayoutsCsvUrlArgs = {
  input: GetPayoutsCsvUrlInput;
};

export type QueryGetTeacherClassInquiriesArgs = {
  isActive?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  teacherUid: Scalars["ID"];
};

export type QueryGetTnsIncidentsSummaryArgs = {
  userUid: Scalars["ID"];
};

export type QueryGiftCardArgs = {
  uid: Scalars["ID"];
};

export type QueryGiftEnrollmentsForAcceptSlugArgs = {
  acceptGiftSlugId: Scalars["ID"];
};

export type QueryInterestTopicsArgs = {
  cursor?: InputMaybe<TopicsConnectionCursorInput>;
};

export type QueryIsMessageReadArgs = {
  audience: Audience;
  messageUid: Scalars["ID"];
};

export type QueryJoinMeetingArgs = {
  learnerUid?: InputMaybe<Scalars["ID"]>;
  lessonUid?: InputMaybe<Scalars["ID"]>;
  sectionUid: Scalars["ID"];
};

export type QueryJoinNextMeetingArgs = {
  enrollmentUid?: InputMaybe<Scalars["ID"]>;
  sectionUid?: InputMaybe<Scalars["ID"]>;
};

export type QueryLastUnrefundedEnrollmentPaymentArgs = {
  learnerUid: Scalars["ID"];
  sectionUid: Scalars["ID"];
};

export type QueryLearnerArgs = {
  learnerUid: Scalars["ID"];
};

export type QueryLearnerHasUnreadNotificationsArgs = {
  after: Scalars["DateTime"];
};

export type QueryLearnerNotificationsArgs = {
  after?: InputMaybe<Scalars["DateTime"]>;
  cursor?: InputMaybe<Scalars["DateTime"]>;
  direction: CursorDirection;
  limit?: InputMaybe<Scalars["Int"]>;
};

export type QueryLearnerPlanForGoalArgs = {
  learnerPlanGoalUid: Scalars["ID"];
};

export type QueryLearnerPlanGoalsArgs = {
  learnerPlanUid: Scalars["ID"];
};

export type QueryLearnerPlanRejectedSuggestionsForLearnerArgs = {
  learnerUid: Scalars["ID"];
};

export type QueryLearnerPlansArgs = {
  learnerUid: Scalars["ID"];
};

export type QueryLearnerProfileArgs = {
  learnerUid: Scalars["ID"];
};

export type QueryLearnerScheduleEventsArgs = {
  learnerUid: Scalars["ID"];
};

export type QueryLearnerSearchActivityByTopicArgs = {
  cursor?: InputMaybe<Scalars["String"]>;
  search: LearnerActivityByTopicSearchInput;
};

export type QueryLearnerSearchKeywordArgs = {
  cursor?: InputMaybe<Scalars["String"]>;
  search: LearnerKeywordSearchInput;
};

export type QueryLearnerSearchSuggestionsArgs = {
  search: LearnerSearchSuggestionsInput;
};

export type QueryLearnerSearchTeacherActivitiesArgs = {
  cursor?: InputMaybe<Scalars["String"]>;
  teacherUid: Scalars["ID"];
};

export type QueryLearnersForParentInSectionArgs = {
  sectionUid: Scalars["ID"];
};

export type QueryLearnersToPickArgs = {
  sectionUid: Scalars["ID"];
};

export type QueryLearningStatementArgs = {
  uid: Scalars["ID"];
};

export type QueryLessonLiveMeetingArgs = {
  learnerUid: Scalars["ID"];
  lessonUid: Scalars["ID"];
  sectionUid: Scalars["ID"];
};

export type QueryLessonPostArgs = {
  uid: Scalars["ID"];
};

export type QueryListingArgs = {
  activityUid: Scalars["ID"];
};

export type QueryListingsArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  personalized?: InputMaybe<Scalars["Boolean"]>;
  searchFilters?: InputMaybe<SearchFilters>;
};

export type QueryListingsByActivityUidsArgs = {
  activityUids: Array<Scalars["ID"]>;
};

export type QueryListingsByTeacherUidsArgs = {
  learnerAges?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  teacherUids: Array<Scalars["ID"]>;
  userTimeZone?: InputMaybe<Scalars["String"]>;
};

export type QueryMeetingAiSummaryArgs = {
  meetingUid: Scalars["ID"];
};

export type QueryMessageThreadArgs = {
  threadUid: Scalars["ID"];
};

export type QueryNextApprovalActivityUidArgs = {
  includeFlex?: InputMaybe<Scalars["Boolean"]>;
  mode?: InputMaybe<ActivityReviewMode>;
  previousActivityUid?: InputMaybe<Scalars["ID"]>;
  subject?: InputMaybe<Scalars["String"]>;
};

export type QueryNonBatchedCouponArgs = {
  uid: Scalars["String"];
};

export type QueryOrderArgs = {
  orderSlugId: Scalars["ID"];
};

export type QueryOrganizationArgs = {
  includeDeleted?: InputMaybe<Scalars["Boolean"]>;
  uidOrLink: Scalars["String"];
};

export type QueryOrganizationsArgs = {
  filter?: InputMaybe<OrganizationAdminFilter>;
  types?: InputMaybe<Array<InputMaybe<OrganizationType>>>;
};

export type QueryParentArgs = {
  uid: Scalars["ID"];
};

export type QueryParticipantsArgs = {
  audience?: InputMaybe<Audience>;
  uids: Array<Scalars["ID"]>;
};

export type QueryPastEnrollmentsArgs = {
  offset?: InputMaybe<Scalars["Int"]>;
  profileLinkUid?: InputMaybe<Scalars["String"]>;
  userUid: Scalars["ID"];
};

export type QueryPastEnrollmentsForParentProfileArgs = {
  offset?: InputMaybe<Scalars["Int"]>;
  profileLinkUid?: InputMaybe<Scalars["String"]>;
  publicView?: InputMaybe<Scalars["Boolean"]>;
  userUid: Scalars["ID"];
};

export type QueryPastReviewedTeachersArgs = {
  userUid: Scalars["ID"];
};

export type QueryPastTeachersArgs = {
  userUid: Scalars["ID"];
};

export type QueryPastTeachersForParentProfileArgs = {
  profileLinkUid?: InputMaybe<Scalars["String"]>;
  publicView?: InputMaybe<Scalars["Boolean"]>;
  userUid: Scalars["ID"];
};

export type QueryPhotoFeedbackByUserArgs = {
  userUid: Scalars["ID"];
};

export type QueryPolicyForExternalResourceArgs = {
  resourceUrl?: InputMaybe<Scalars["String"]>;
};

export type QueryPricingBreakdownArgs = {
  pricingBreakdownInput: PricingBreakdownInput;
};

export type QueryPricingOfferArgs = {
  name?: InputMaybe<Scalars["String"]>;
  uid?: InputMaybe<Scalars["String"]>;
};

export type QueryPricingOfferAttendanceLogArgs = {
  organizationUid?: InputMaybe<Scalars["String"]>;
  uid?: InputMaybe<Scalars["String"]>;
};

export type QueryPricingOfferAttendancesByWeekArgs = {
  organizationUid?: InputMaybe<Scalars["String"]>;
  uid?: InputMaybe<Scalars["String"]>;
};

export type QueryPricingOfferEnrollmentsSummaryArgs = {
  dateFilter?: InputMaybe<DateFilterInput>;
  organizationUid?: InputMaybe<Scalars["String"]>;
  uid?: InputMaybe<Scalars["String"]>;
};

export type QueryPricingOfferPaymentsByWeekArgs = {
  organizationUid?: InputMaybe<Scalars["String"]>;
  uid?: InputMaybe<Scalars["String"]>;
};

export type QueryPricingOffersArgs = {
  filter?: InputMaybe<PricingOfferAdminFilter>;
};

export type QueryPricingOfferTransactionsArgs = {
  dateFilter?: InputMaybe<DateFilterInput>;
  organizationUid?: InputMaybe<Scalars["String"]>;
  uid?: InputMaybe<Scalars["String"]>;
};

export type QueryPrivateClassMessagesArgs = {
  learnerUid: Scalars["ID"];
  sectionUid: Scalars["ID"];
};

export type QueryPrivateMessageThreadArgs = {
  threadUid: Scalars["ID"];
};

export type QueryPubliclyReviewedPastEnrollmentsArgs = {
  offset?: InputMaybe<Scalars["Int"]>;
  userUid: Scalars["ID"];
};

export type QueryPublicProfileArgs = {
  uidOrLink: Scalars["String"];
};

export type QueryPublicProfileNewArgs = {
  leader_link?: InputMaybe<Scalars["String"]>;
  slug_id?: InputMaybe<Scalars["String"]>;
  uid?: InputMaybe<Scalars["ID"]>;
};

export type QueryRandomCategoriesByFacetArgs = {
  facet?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
};

export type QueryRelatedTermSuggestionsArgs = {
  search?: InputMaybe<SearchSuggestionsInput>;
};

export type QuerySavedClassListArgs = {
  listUid?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Scalars["String"]>;
  skipSavedActivities?: InputMaybe<Scalars["Boolean"]>;
};

export type QuerySavedClassListsForParentProfileArgs = {
  profileLinkUid?: InputMaybe<Scalars["String"]>;
  publicView?: InputMaybe<Scalars["Boolean"]>;
  userUid: Scalars["ID"];
};

export type QuerySavedClassListsForUserArgs = {
  userUid: Scalars["ID"];
};

export type QuerySavedLearnerClassListArgs = {
  listUid?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Scalars["String"]>;
  skipSavedActivities?: InputMaybe<Scalars["Boolean"]>;
};

export type QuerySchoolPageArgs = {
  slug: Scalars["String"];
};

export type QuerySearchConversationFiltersArgs = {
  audience: Audience;
  existingFilters?: InputMaybe<ConversationFiltersInput>;
  searchQuery: Scalars["String"];
};

export type QuerySearchCounselorSuggestionArgs = {
  activityUids: Array<Scalars["ID"]>;
  searchFilters?: InputMaybe<SearchFilters>;
  userPreferencesPrompt: Scalars["String"];
};

export type QuerySearchListingsArgs = {
  anonymousId?: InputMaybe<Scalars["String"]>;
  cursor?: InputMaybe<Scalars["String"]>;
  customSearchUseCase?: InputMaybe<CustomSearchUseCase>;
  disableAutoApplyFilterTypes?: InputMaybe<
    Array<InputMaybe<AutoAppliedFilterType>>
  >;
  includeMatchingSections?: InputMaybe<Scalars["Boolean"]>;
  isFuzzySearchEnabled?: InputMaybe<Scalars["Boolean"]>;
  learnerPlanGoalUid?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  matchNames?: InputMaybe<Scalars["Boolean"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  optionalSearchFilters?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  pageImpressionId?: InputMaybe<Scalars["String"]>;
  pageLocation?: InputMaybe<Scalars["String"]>;
  promotedExperimentGroup?: InputMaybe<PromotedExperimentGroup>;
  promotedRankingsEnabled?: InputMaybe<Scalars["Boolean"]>;
  referrer?: InputMaybe<Scalars["String"]>;
  searchFilters: SearchFilters;
  searchObjective?: InputMaybe<SearchObjective>;
  searchUid?: InputMaybe<Scalars["String"]>;
  source?: InputMaybe<Scalars["Boolean"]>;
  useCase?: InputMaybe<SearchUseCase>;
};

export type QuerySearchSuggestionsArgs = {
  ignore?: InputMaybe<Array<Scalars["String"]>>;
  search?: InputMaybe<SearchSuggestionsInput>;
  size?: InputMaybe<Scalars["Int"]>;
};

export type QuerySectionArgs = {
  uid: Scalars["ID"];
};

export type QuerySelfPacedEnrollmentFormArgs = {
  activityUid: Scalars["ID"];
};

export type QuerySimilarListingsUnpublishedArgs = {
  activitySlugId: Scalars["String"];
};

export type QuerySpellingSuggestionArgs = {
  query: Scalars["String"];
  spellingSuggestionType?: InputMaybe<SpellingSuggestionType>;
};

export type QuerySwitchUserLinkArgs = {
  uid: Scalars["ID"];
};

export type QuerySyllabusArgs = {
  uid: Scalars["ID"];
};

export type QueryTeacherCredentialFilePreviewUrlArgs = {
  uid: Scalars["String"];
};

export type QueryTeacherHasInquiriesButHasNotRepliedTodayArgs = {
  teacherUid: Scalars["ID"];
};

export type QueryTeacherSearchSuggestionsArgs = {
  search?: InputMaybe<SearchSuggestionsInput>;
  size?: InputMaybe<Scalars["Int"]>;
};

export type QueryTextFeedbackByUserArgs = {
  offset?: InputMaybe<Scalars["Int"]>;
  userUid: Scalars["ID"];
};

export type QueryTopLevelCategoryPagesArgs = {
  taxonomy?: InputMaybe<Scalars["String"]>;
};

export type QueryTransactionListingsArgs = {
  limit: Scalars["Int"];
  offset: Scalars["Int"];
};

export type QueryTrendingSearchTopicsArgs = {
  subject?: InputMaybe<Scalars["String"]>;
};

export type QueryTutorMatchListingsArgs = {
  anonymousId?: InputMaybe<Scalars["String"]>;
  disableAutoApplyFilterTypes?: InputMaybe<
    Array<InputMaybe<AutoAppliedFilterType>>
  >;
  includeMatchingSections?: InputMaybe<Scalars["Boolean"]>;
  isFuzzySearchEnabled?: InputMaybe<Scalars["Boolean"]>;
  matchNames?: InputMaybe<Scalars["Boolean"]>;
  pageImpressionId?: InputMaybe<Scalars["String"]>;
  promotedExperimentGroup?: InputMaybe<PromotedExperimentGroup>;
  promotedRankingsEnabled?: InputMaybe<Scalars["Boolean"]>;
  referrer?: InputMaybe<Scalars["String"]>;
  searchFilters: SearchFilters;
  searchObjective?: InputMaybe<SearchObjective>;
  searchUid?: InputMaybe<Scalars["String"]>;
  source?: InputMaybe<Scalars["Boolean"]>;
  useCase?: InputMaybe<SearchUseCase>;
};

export type QueryUnverifiedClubEnrollmentsArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type QueryUserArgs = {
  uidOrEmail: Scalars["String"];
};

export type QueryValidateBulkEnrollmentsArgs = {
  bulkOrderInput: BulkOrderInput;
  esaState?: InputMaybe<Scalars["String"]>;
};

export type QueryValidateEmailArgs = {
  email: Scalars["String"];
};

export type QueryValidateUsernameArgs = {
  username: Scalars["String"];
};

export type QueryWaitlistSeatArgs = {
  uid: Scalars["ID"];
};

export type QueryWaitlistSeatsForLearnerArgs = {
  learnerUid: Scalars["ID"];
};

export type QueryWaitlistSeatsForParentArgs = {
  sectionUid?: InputMaybe<Scalars["ID"]>;
  userUid: Scalars["ID"];
};

export type Activity = {
  __typename?: "Activity";
  actions: Array<ActivityAction>;
  activeSections: Scalars["Int"];
  adminChecklist: ActivityAdminChecklist;
  age_max: Maybe<Scalars["Int"]>;
  age_min: Maybe<Scalars["Int"]>;
  /** @deprecated Deprecated in favor of allows_late_enrollments_for_fixed_length_classes */
  allows_late_enrollment: Maybe<Scalars["Boolean"]>;
  allows_late_enrollments_for_fixed_length_classes: Scalars["Boolean"];
  allows_recurring_payment: Maybe<Scalars["Boolean"]>;
  allowWaitlist: Maybe<Scalars["Boolean"]>;
  approvalCriteria: Array<ApprovalCriterion>;
  approvalStatus: Maybe<ActivityApprovalStatus>;
  archived_at: Maybe<Scalars["DateTime"]>;
  assessment: Maybe<Scalars["String"]>;
  attributes: Array<EntityAttribute>;
  /** The maximum number of days in advance that an activity can be booked at. */
  autoSchedulingDaysNoticeMax: Maybe<Scalars["Int"]>;
  /** The minimum number of days in advance that an activity can be booked at. */
  autoSchedulingDaysNoticeMin: Maybe<Scalars["Int"]>;
  averageStarRating: Maybe<Scalars["Float"]>;
  bundles: Array<Bundle>;
  canEnroll: Scalars["Boolean"];
  /** @deprecated No longer used */
  canMigrateToOneOnOne: OneOnOneMigrationInfo;
  canRequestListing: Scalars["Boolean"];
  canScheduleContinuously: Scalars["Boolean"];
  class_experience: Maybe<Scalars["String"]>;
  classFormat: EducatorSelectedFormat;
  classroomType: ClassroomType;
  classScheduleRequests: Array<ClassScheduleRequest>;
  clubBannerImage: Maybe<Attachment>;
  clubGuidelines: Maybe<WelcomeMessage>;
  clubSection: Maybe<Section>;
  clubWelcomeVideo: Maybe<Attachment>;
  completedEnrollmentCount: Scalars["Int"];
  created_at: Scalars["DateTime"];
  created_by_uid: Maybe<Scalars["String"]>;
  currentAndFutureSections: Array<Section>;
  currentUserEnrollments: Array<Enrollment>;
  deleted_at: Maybe<Scalars["DateTime"]>;
  deliveryTypes: Maybe<Array<DeliveryType>>;
  /** @deprecated Renamed to class_experience */
  description: Maybe<Scalars["String"]>;
  details: ActivityDetails;
  disclaimer: Maybe<Scalars["String"]>;
  duration_minutes: Maybe<Scalars["Int"]>;
  duration_weeks: Maybe<Scalars["Int"]>;
  english_proficiency_level: Maybe<EnglishProficiencyLevel>;
  enrollableTimes: Array<EnrollableTime>;
  enrollmentCount: Scalars["Int"];
  enrollments: Array<Enrollment>;
  esaStatus: EsaStatus;
  externalResources: Array<ExternalResource>;
  feedbackSummary: Maybe<TeacherFeedbackSummary>;
  /** @deprecated Use paginatedFilteredSections */
  filteredSections: Array<Section>;
  first_listed_at: Maybe<Scalars["DateTime"]>;
  format: ClassFormat;
  formatString: Scalars["String"];
  futureEnrollmentMeetingAvailability: Array<EnrollmentMeetingPurchaseMeetingAvailability>;
  grade_level_max: Maybe<Scalars["String"]>;
  grade_level_min: Maybe<Scalars["String"]>;
  grading_policy: Maybe<Scalars["String"]>;
  has_building_content: Maybe<Scalars["Boolean"]>;
  has_external_resources: Maybe<Scalars["Boolean"]>;
  hasSections: Scalars["Boolean"];
  hasTeacherSchedule: Scalars["Boolean"];
  homework: Maybe<Scalars["String"]>;
  inProgressSections: Array<Section>;
  is_ongoing_weekly: Scalars["Boolean"];
  is_online: Scalars["Boolean"];
  /**
   * Whether auto-scheduling is allowed for this activity.
   * This allows buyers to book a new section at a time of their choosing based
   * on their schedule and the teacher's schedule.
   */
  isAutoSchedulingEnabled: Maybe<Scalars["Boolean"]>;
  isClub: Scalars["Boolean"];
  isFlexSchedule: Scalars["Boolean"];
  isOneTime: Scalars["Boolean"];
  isPublished: Scalars["Boolean"];
  isRestricted: Scalars["Boolean"];
  isSelfPaced: Scalars["Boolean"];
  isSelfPacedEnrollmentEnabled: Scalars["Boolean"];
  isSoldOut: Scalars["Boolean"];
  languageOfInstruction: Maybe<Scalars["Iso639"]>;
  lastWeekEnrollmentCount: Scalars["Int"];
  lastWeekSavedCount: Scalars["Int"];
  leader: Leader;
  leaderDetails: ActivityLeaderDetails;
  /** @deprecated Use learningGoals */
  learning_goals: Maybe<Scalars["String"]>;
  learningGoals: Array<Scalars["String"]>;
  location: Location;
  lockedClassFormats: Array<ClassFormat>;
  materials: Maybe<Scalars["String"]>;
  meetingCount: Maybe<Scalars["Int"]>;
  nextAvailableMeetingTime: Maybe<Scalars["DateTime"]>;
  paginatedFilteredSections: SectionList;
  parental_guidance: Maybe<Scalars["String"]>;
  path: Scalars["String"];
  pendingOneOnOneEsaEnrollments: Array<Enrollment>;
  perWeeklyCourseCredits: Maybe<Scalars["Int"]>;
  prerequisites: Maybe<Scalars["String"]>;
  price_cents: Maybe<Scalars["Int"]>;
  priceCredits: Maybe<Scalars["Float"]>;
  priceCreditsPerMeeting: Maybe<Scalars["Int"]>;
  pricingAdjustments: PricingAdjustment;
  pricingInfo: Maybe<PricingInfo>;
  /**
   * Return all private meeting requests for an activity.
   * These are different from Section.meetingRequests in that the MeetingRequests
   * for Section.meetingRequests are associated with a section already whereas
   * Activity.privateMeetingRequests are *NOT* associated with a section and anyone
   * with the link can accept the request whether or not they are already enrolled.
   */
  privateMeetingRequests: Array<MeetingRequest>;
  proficiency_level: Maybe<ProficiencyLevel>;
  promotableSections: Array<Section>;
  published_at: Maybe<Scalars["DateTime"]>;
  recentReviews: Array<PublicReview>;
  refundPolicy: RefundPolicy;
  relatedCategories: Maybe<Array<Maybe<CategoryPage>>>;
  requested_listing_at: Maybe<Scalars["DateTime"]>;
  reviewCount: Scalars["Int"];
  reviewsWithAttachments: Array<PublicReview>;
  reviewsWithTextCount: Scalars["Int"];
  /** @deprecated Will use sampleClubPosts and the SampleClubPost type */
  sampleClubPost: Maybe<WelcomeMessage>;
  sampleClubPosts: Maybe<Array<SampleClubPost>>;
  saveCount: Maybe<Scalars["Int"]>;
  savedClassLists: Array<SavedClassList>;
  scheduleSettings: Array<ScheduleSettings>;
  scheduleSuggestions: Maybe<Array<Array<MeetingSuggestion>>>;
  sections: Array<Section>;
  selfPacedMigration: Maybe<SelfPacedMigrationStatus>;
  size_max: Maybe<Scalars["Int"]>;
  size_min: Maybe<Scalars["Int"]>;
  slug_id: Scalars["String"];
  sources: Maybe<Scalars["String"]>;
  strictAgeRange: Maybe<AgeRangeType>;
  subject: Maybe<Scalars["String"]>;
  suggestedWeeklyMeetings: Maybe<Scalars["Int"]>;
  summary: Maybe<Scalars["String"]>;
  summaryForLearners: Maybe<Scalars["String"]>;
  summaryTranslated: Maybe<Scalars["String"]>;
  syllabus: Maybe<Syllabus>;
  tags: Maybe<Scalars["String"]>;
  teacherScheduleAvailability: Array<TeacherScheduleAvailability>;
  title: Scalars["String"];
  titleTranslated: Maybe<Scalars["String"]>;
  uid: Scalars["ID"];
  upcomingAvailableSectionCount: Scalars["Int"];
  upcomingSectionCount: Scalars["Int"];
  updated_at: Scalars["DateTime"];
  upsell_activity_uid: Maybe<Scalars["ID"]>;
  url: Scalars["String"];
  user_uid: Scalars["String"];
  video: Maybe<Attachment>;
  videoForLearners: Maybe<Attachment>;
  waitlistSeatsCount: Maybe<Scalars["Int"]>;
  wasPublishedInThePast: Scalars["Boolean"];
  weekly_meetings: Maybe<Scalars["Int"]>;
  welcomeAttachmentCount: Maybe<Scalars["Int"]>;
  why_me: Maybe<Scalars["String"]>;
};

export type ActivityCanScheduleContinuouslyArgs = {
  endTime?: InputMaybe<Scalars["DateTime"]>;
  orgTeacherUid?: InputMaybe<Scalars["String"]>;
  startTime?: InputMaybe<Scalars["DateTime"]>;
};

export type ActivityEnrollableTimesArgs = {
  endBy?: InputMaybe<Scalars["DateTime"]>;
  filterByDow?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orgTeacherUid?: InputMaybe<Scalars["String"]>;
  recurringOnly?: InputMaybe<Scalars["Boolean"]>;
  sectionUid?: InputMaybe<Scalars["ID"]>;
  startAfter?: InputMaybe<Scalars["DateTime"]>;
};

export type ActivityEnrollmentCountArgs = {
  enrollmentParams?: InputMaybe<EnrollmentParamsInput>;
};

export type ActivityEnrollmentsArgs = {
  enrollmentParams?: InputMaybe<EnrollmentParamsInput>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type ActivityEsaStatusArgs = {
  esaState?: InputMaybe<Scalars["String"]>;
};

export type ActivityFeedbackSummaryArgs = {
  variant?: InputMaybe<Scalars["String"]>;
};

export type ActivityFilteredSectionsArgs = {
  dow?: InputMaybe<Scalars["String"]>;
  endAtDate?: InputMaybe<Scalars["DateTime"]>;
  endByTime?: InputMaybe<Scalars["Float"]>;
  filter?: InputMaybe<SectionFilter>;
  forcedOrderBy?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<SectionSearchInput>;
  skipFiltersForClub?: InputMaybe<Scalars["Boolean"]>;
  startAfter?: InputMaybe<Scalars["DateTime"]>;
  startAfterTime?: InputMaybe<Scalars["Float"]>;
  userTimeZone?: InputMaybe<Scalars["String"]>;
};

export type ActivityFutureEnrollmentMeetingAvailabilityArgs = {
  endTime?: InputMaybe<Scalars["DateTime"]>;
  includeFirstMeeting?: InputMaybe<Scalars["Boolean"]>;
  orgTeacherUid?: InputMaybe<Scalars["String"]>;
  startTime?: InputMaybe<Scalars["DateTime"]>;
};

export type ActivityPaginatedFilteredSectionsArgs = {
  dow?: InputMaybe<Scalars["String"]>;
  endAtDate?: InputMaybe<Scalars["DateTime"]>;
  endByTime?: InputMaybe<Scalars["Float"]>;
  esaBlackoutLengthDays?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<SectionFilter>;
  forcedOrderBy?: InputMaybe<Scalars["String"]>;
  ignoreSelfPaced?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  onlySelfPaced?: InputMaybe<Scalars["Boolean"]>;
  search?: InputMaybe<SectionSearchInput>;
  sellerOrgTeacherUid?: InputMaybe<Scalars["String"]>;
  skipFiltersForClub?: InputMaybe<Scalars["Boolean"]>;
  startAfter?: InputMaybe<Scalars["DateTime"]>;
  startAfterTime?: InputMaybe<Scalars["Float"]>;
  userTimeZone?: InputMaybe<Scalars["String"]>;
};

export type ActivityRecentReviewsArgs = {
  limit: Scalars["Int"];
  offset: Scalars["Int"];
};

export type ActivityScheduleSettingsArgs = {
  orgTeachersOnly?: InputMaybe<Scalars["Boolean"]>;
  orgTeacherUid?: InputMaybe<Scalars["String"]>;
};

export type ActivityScheduleSuggestionsArgs = {
  dow?: InputMaybe<Scalars["String"]>;
  endAtDate?: InputMaybe<Scalars["DateTime"]>;
  endByTime?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  startAfter?: InputMaybe<Scalars["DateTime"]>;
  startAfterTime?: InputMaybe<Scalars["Int"]>;
  timeZone?: InputMaybe<Scalars["String"]>;
};

export type ActivitySectionsArgs = {
  uid?: InputMaybe<Scalars["ID"]>;
};

export type ActivitySyllabusArgs = {
  preferDraft?: InputMaybe<Scalars["Boolean"]>;
};

export type ActivityTeacherScheduleAvailabilityArgs = {
  orgTeacherUid?: InputMaybe<Scalars["String"]>;
};

export type ActivityAction = {
  __typename?: "ActivityAction";
  action: Scalars["String"];
  activity: Activity;
  created_at: Scalars["DateTime"];
  message_to_teacher: Maybe<Scalars["String"]>;
  notes: Maybe<Scalars["String"]>;
  uid: Scalars["ID"];
  user: User;
};

export type User = {
  __typename?: "User";
  activeEnrollments: Array<Maybe<Enrollment>>;
  activeOngoingEnrollmentsPausedDueToPaymentFailure: Array<Enrollment>;
  admin_for_organization_uid: Maybe<Scalars["ID"]>;
  adminActions: Array<AdminUserAction>;
  adminIncidents: Array<AdminUserIncident>;
  anonymousId: Maybe<Scalars["String"]>;
  approvalCriteria: Array<ApprovalCriterion>;
  background_check_started_at: Maybe<Scalars["DateTime"]>;
  background_checked_at: Maybe<Scalars["DateTime"]>;
  backgroundChecked: Scalars["Boolean"];
  calendar_uid: Scalars["ID"];
  canPayWithSubscriptionCredits: Scalars["Boolean"];
  canTeacherStartBackgroundCheck: Scalars["Boolean"];
  children: Array<Child>;
  cityState: Maybe<Scalars["String"]>;
  classroom_trained_at: Maybe<Scalars["DateTime"]>;
  classroom_training_scheduled_at: Maybe<Scalars["DateTime"]>;
  completed_trainings: Maybe<Array<Scalars["String"]>>;
  confirmed_at: Maybe<Scalars["DateTime"]>;
  confirmedEnrollmentCount: Scalars["Int"];
  /** @deprecated dead feature, to be removed */
  consent_to_share_class_history: Maybe<Scalars["Boolean"]>;
  /** @deprecated dead feature, to be removed */
  consent_to_share_favourites: Maybe<Scalars["Boolean"]>;
  consented_to_coppa_notice_at: Maybe<Scalars["DateTime"]>;
  conversations: Array<IMessageThread>;
  conversationsCount: Scalars["Int"];
  couponMaxUsesReachedPerUser: Scalars["Boolean"];
  created_at: Scalars["DateTime"];
  creditAccount: CreditAccount;
  default_currency: Maybe<Scalars["String"]>;
  deleted_at: Maybe<Scalars["DateTime"]>;
  details: UserDetails;
  do_not_sell_my_personal_info: Scalars["Boolean"];
  donations: Array<Donation>;
  email: Maybe<Scalars["String"]>;
  email_bounced_at: Maybe<Scalars["DateTime"]>;
  emailPreferences: Array<EmailPreference>;
  enrolledSectionsThatNeedReview: Array<Maybe<ReviewableSection>>;
  enrolledSectionThatNeedsReview: Maybe<Section>;
  enrollment: Enrollment;
  enrollments: Array<Maybe<Enrollment>>;
  enrollmentsForTranscript: Array<Maybe<Enrollment>>;
  firstPaidEnrollment: Maybe<Enrollment>;
  followedUsers: Array<FollowedUser>;
  followerCount: Scalars["Int"];
  futureEndingActivities: Array<Activity>;
  giftCardsPurchased: Array<GiftCard>;
  giftEnrollments: Array<GiftEnrollment>;
  hasBeenAssignedToSection: Scalars["Boolean"];
  /** @deprecated Use completed_trainings instead */
  hasCompletedClassContentPoliciesOrgAdminTraining: Scalars["Boolean"];
  /** @deprecated Use completed_trainings instead */
  hasCompletedClassContentPoliciesTraining: Scalars["Boolean"];
  /** @deprecated Use completed_trainings instead */
  hasCompletedSafetyAndPrivacyOrgAdminTraining: Scalars["Boolean"];
  /** @deprecated Use completed_trainings instead */
  hasCompletedSafetyAndPrivacyTraining: Scalars["Boolean"];
  hasEnrolled: Scalars["Boolean"];
  hasMadeOrder: Scalars["Boolean"];
  hasSubscription: Scalars["Boolean"];
  hasVerifiedIdentity: Maybe<Scalars["Boolean"]>;
  idVerificationStatus: IdvStatus;
  intercomUserHash: Maybe<Scalars["String"]>;
  interfacePreferences: InterfacePreferences;
  interview_scheduled_at: Maybe<Scalars["DateTime"]>;
  interviewed_at: Maybe<Scalars["DateTime"]>;
  introduction: Maybe<Scalars["String"]>;
  is_admin: Maybe<Scalars["Boolean"]>;
  is_crosslister: Maybe<Scalars["Boolean"]>;
  isEducatorBetaTester: Maybe<Scalars["Boolean"]>;
  isLearnerModeEnabled: Maybe<Scalars["Boolean"]>;
  isRecordingAutopostEnabled: Maybe<Scalars["Boolean"]>;
  isTeacherUnlisted: Scalars["Boolean"];
  lastClassScheduleRequest: Maybe<ClassScheduleRequest>;
  lastSubmittedEnrollments: Array<Enrollment>;
  latestLegalName: Maybe<Scalars["String"]>;
  latestSellerOrgAgreement: Maybe<SellerOrgAgreement>;
  latestSellerOrgApplication: Maybe<SellerOrgApplication>;
  latestTeacherApplication: Maybe<TeacherApplication>;
  leader: Maybe<Leader>;
  leader_link: Maybe<Scalars["String"]>;
  leader_subscribed_at: Maybe<Scalars["DateTime"]>;
  leaderActivities: Array<Activity>;
  /** @deprecated Moved to Leader type */
  leaderClassLinks: Array<Bundle>;
  learner_uses_parent_mode: Scalars["Boolean"];
  learnerModeSwitchAuthRequirement: Maybe<LearnerModeSwitchAuthRequirement>;
  learnerPlans: Array<LearnerPlan>;
  learners: Array<Learner>;
  locale: UserLocale;
  location: Maybe<Location>;
  mostRecentFavouriteClass: Maybe<Activity>;
  name: Maybe<Scalars["String"]>;
  nextMeeting: Maybe<Meeting>;
  number_of_merges: Scalars["Int"];
  organizationAdmin: Maybe<OrganizationAdmin>;
  otherEmailDetails: Maybe<Array<Maybe<OtherEmailDetails>>>;
  owner_of_seller_org_uid: Maybe<Scalars["ID"]>;
  parentDashboardFeed: ParentDashboardFeed;
  paymentMethods: Array<PaymentMethod>;
  payout_email: Maybe<Scalars["String"]>;
  phone: Maybe<Scalars["String"]>;
  photo: Maybe<Scalars["String"]>;
  pricingOffer: Maybe<PricingOffer>;
  pricingOfferAdmin: Maybe<OrganizationAdmin>;
  pricingOffers: Maybe<Array<PricingOffer>>;
  privacy_state: Maybe<Scalars["String"]>;
  profile_link_uid: Maybe<Scalars["ID"]>;
  publicName: Maybe<Scalars["String"]>;
  publicSectionCount: Scalars["Int"];
  raceEthnicity: Array<RaceEthnicityType>;
  referralCredits: Scalars["Int"];
  referredParents: Array<ParentReferral>;
  referredTeachers: Array<TeacherReferral>;
  referrerNameOrEmail: Maybe<Scalars["String"]>;
  referrerSocialOptOut: Scalars["Boolean"];
  roles: Maybe<Array<Scalars["String"]>>;
  savedActivities: Array<SavedActivity>;
  savedClassLists: Array<SavedClassList>;
  savedSearches: Array<SavedSearch>;
  schoolClosure: Maybe<SchoolClosure>;
  sections: Array<Section>;
  seller_org_admin_teacher_account_uid: Maybe<Scalars["ID"]>;
  sellerOrg: Maybe<SellerOrg>;
  shouldShowEnrollmentPausedDueToPaymentFailureAlert: Maybe<Scalars["Boolean"]>;
  /** @deprecated dead feature, to be removed */
  signUpCreditExpiry: Maybe<Scalars["String"]>;
  /** @deprecated dead feature, to be removed */
  signUpCreditSummary: Maybe<SignUpCreditSummary>;
  slug_id: Scalars["String"];
  subscribed_at: Maybe<Scalars["DateTime"]>;
  suspended_at: Maybe<Scalars["DateTime"]>;
  teacher_approved_at: Maybe<Scalars["DateTime"]>;
  teacher_featured_at: Maybe<Scalars["DateTime"]>;
  teacherAvailability: Maybe<Availability>;
  teacherFlexSections: Array<Section>;
  teacherMeetings: Array<Meeting>;
  teacherScheduleSettings: Array<ScheduleSettings>;
  teacherUnavailableBlocks: Array<UnavailableBlock>;
  timeZone: Maybe<Scalars["String"]>;
  totalReferralEarnings: Scalars["Int"];
  uid: Scalars["ID"];
  unreadMessageThreadsCount: Scalars["Int"];
  unreadPrivateClassMessageThreadsCount: Scalars["Int"];
  updated_at: Scalars["DateTime"];
  upgradablePackages: Maybe<Array<Maybe<Scalars["Int"]>>>;
  useOtherFundsForMemberships: Scalars["Boolean"];
  video: Maybe<Attachment>;
  walletSummary: UserWalletSummary;
  wasReferred: Scalars["Boolean"];
};

export type UserActiveEnrollmentsArgs = {
  end: Scalars["DateTime"];
  start: Scalars["DateTime"];
};

export type UserConversationsArgs = {
  audience?: InputMaybe<Audience>;
  filters?: InputMaybe<ConversationFiltersInput>;
  limit: Scalars["Int"];
  messageSelection?: InputMaybe<MessageSelection>;
  offset: Scalars["Int"];
  queryText?: InputMaybe<Scalars["String"]>;
  uid?: InputMaybe<Scalars["ID"]>;
  viewAsOrgTeacherUid?: InputMaybe<Scalars["String"]>;
};

export type UserConversationsCountArgs = {
  audience?: InputMaybe<Audience>;
  filters?: InputMaybe<ConversationFiltersInput>;
  messageSelection?: InputMaybe<MessageSelection>;
  queryText?: InputMaybe<Scalars["String"]>;
  viewAsOrgTeacherUid?: InputMaybe<Scalars["String"]>;
};

export type UserCouponMaxUsesReachedPerUserArgs = {
  couponName: Scalars["String"];
};

export type UserEnrollmentArgs = {
  enrollmentUid: Scalars["String"];
};

export type UserEnrollmentsArgs = {
  filter?: InputMaybe<UserEnrollmentsFilters>;
};

export type UserEnrollmentsForTranscriptArgs = {
  end?: InputMaybe<Scalars["DateTime"]>;
  learnerUid?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
};

export type UserLeaderActivitiesArgs = {
  activityType?: InputMaybe<CreateActivityType>;
  filter?: InputMaybe<Scalars["String"]>;
};

export type UserParentDashboardFeedArgs = {
  seed?: InputMaybe<Scalars["Int"]>;
};

export type UserPublicSectionCountArgs = {
  includeUnpublished?: InputMaybe<Scalars["Boolean"]>;
};

export type UserSavedActivitiesArgs = {
  order?: InputMaybe<Scalars["String"]>;
};

export type UserSectionsArgs = {
  activityType?: InputMaybe<CreateActivityType>;
  excludeOrgTeachers?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Scalars["String"]>;
  filterByTeacher?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  maxDate?: InputMaybe<Scalars["DateTime"]>;
  minDate?: InputMaybe<Scalars["DateTime"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTeacherFlexSectionsArgs = {
  end: Scalars["DateTime"];
  excludeOrgTeachers?: InputMaybe<Scalars["Boolean"]>;
  filterByTeacher?: InputMaybe<Scalars["ID"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  start: Scalars["DateTime"];
};

export type UserTeacherMeetingsArgs = {
  end: Scalars["DateTime"];
  excludeOrgTeachers?: InputMaybe<Scalars["Boolean"]>;
  filterByTeacher?: InputMaybe<Scalars["ID"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  start: Scalars["DateTime"];
};

export type UserTeacherUnavailableBlocksArgs = {
  end: Scalars["DateTime"];
  start: Scalars["DateTime"];
};

export type Enrollment = BaseEnrollment & {
  __typename?: "Enrollment";
  amount_in_local_currency: Scalars["Int"];
  attendances: Array<Attendance>;
  buyer: Maybe<Parent>;
  canBePaused: Scalars["Boolean"];
  canBeStopped: Scalars["Boolean"];
  canBeWithdrawn: Scalars["Boolean"];
  clubVerificationVideo: Maybe<Attachment>;
  completedEnrolledWeeks: Scalars["Int"];
  /** @deprecated use confirmedAt instead */
  confirmed_at: Maybe<Scalars["DateTime"]>;
  confirmedAt: Maybe<Scalars["DateTime"]>;
  /** @deprecated use createdAt instead */
  created_at: Maybe<Scalars["DateTime"]>;
  createdAt: Scalars["DateTime"];
  currency_code: CurrencyCode;
  details: EnrollmentDetails;
  exchange_rate: Scalars["Float"];
  fundingProgramInvoice: Maybe<FundingProgramInvoice>;
  hasAttended: Scalars["Boolean"];
  hasOverlappingPendingEnrollmentMeetingPurchase: Scalars["Boolean"];
  hasPaymentSchedule: Scalars["Boolean"];
  isActiveOrUpcoming: Scalars["Boolean"];
  isEnded: Scalars["Boolean"];
  isEnrolled: Scalars["Boolean"];
  /** @deprecated use isActiveOrUpcoming instead */
  isEnrollmentActive: Scalars["Boolean"];
  isFirstOutschoolClass: Scalars["Boolean"];
  /** @deprecated no longer used */
  isFirstPurchaseByUser: Scalars["Boolean"];
  isPaused: Scalars["Boolean"];
  isPendingEsaOrder: Scalars["Boolean"];
  isPrepay: Scalars["Boolean"];
  isRepeatLearnerOfTeacher: Scalars["Boolean"];
  isWithdrawn: Scalars["Boolean"];
  lastSectionTransfer: Maybe<SectionTransfer>;
  learner: Maybe<Learner>;
  nextMeeting: Maybe<Meeting>;
  /** @deprecated use ongoingEndTime instead */
  ongoing_end_time: Maybe<Scalars["DateTime"]>;
  /** @deprecated use ongoingStartTime instead */
  ongoing_start_time: Maybe<Scalars["DateTime"]>;
  /** @deprecated use ongoingStoppedAt instead */
  ongoing_stopped_at: Maybe<Scalars["DateTime"]>;
  ongoingEndTime: Maybe<Scalars["DateTime"]>;
  ongoingSectionTransferredFrom: Maybe<SectionTransfer>;
  ongoingStartTime: Maybe<Scalars["DateTime"]>;
  ongoingStoppedAt: Maybe<Scalars["DateTime"]>;
  orderSlugId: Maybe<Scalars["String"]>;
  otherSections: Array<Section>;
  parent: Parent;
  pauseWindow: Maybe<EnrollmentPauseWindow>;
  payments: Array<EnrollmentPayment>;
  paymentSchedule: Maybe<PaymentSchedule>;
  /** @deprecated use priceCents instead */
  price_cents: Maybe<Scalars["Int"]>;
  priceCents: Maybe<Scalars["Int"]>;
  priceCredits: Maybe<Scalars["Float"]>;
  pricingOffer: Maybe<PricingOffer>;
  purchasedAsGift: Scalars["Boolean"];
  recordingForClubsVerificationFlaggedAt: Maybe<Scalars["DateTime"]>;
  refundableAmountCents: Scalars["Int"];
  refundPolicy: Maybe<RefundPolicy>;
  section: Maybe<Section>;
  section_uid: Maybe<Scalars["ID"]>;
  service_fee_percentage: Scalars["Float"];
  siblings: Array<Sibling>;
  thisWeekPauseDeadline: Maybe<Scalars["DateTime"]>;
  uid: Scalars["ID"];
  upcomingCharges: Array<UpcomingCharge>;
  usersCanCommunicate: Scalars["Boolean"];
  usersCanSendPrivateMessage: Scalars["Boolean"];
  /** @deprecated use withdrawnAt instead */
  withdrawn_at: Maybe<Scalars["DateTime"]>;
  withdrawnAt: Maybe<Scalars["DateTime"]>;
};

export type BaseEnrollment = {
  amount_in_local_currency: Scalars["Int"];
  buyer: Maybe<Parent>;
  canBePaused: Scalars["Boolean"];
  canBeStopped: Scalars["Boolean"];
  canBeWithdrawn: Scalars["Boolean"];
  completedEnrolledWeeks: Scalars["Int"];
  confirmedAt: Maybe<Scalars["DateTime"]>;
  createdAt: Scalars["DateTime"];
  currency_code: CurrencyCode;
  details: EnrollmentDetails;
  exchange_rate: Scalars["Float"];
  hasPaymentSchedule: Scalars["Boolean"];
  isEnded: Scalars["Boolean"];
  isEnrolled: Scalars["Boolean"];
  isPaused: Scalars["Boolean"];
  isWithdrawn: Scalars["Boolean"];
  lastSectionTransfer: Maybe<SectionTransfer>;
  learner: Maybe<Learner>;
  ongoingEndTime: Maybe<Scalars["DateTime"]>;
  ongoingSectionTransferredFrom: Maybe<SectionTransfer>;
  ongoingStartTime: Maybe<Scalars["DateTime"]>;
  ongoingStoppedAt: Maybe<Scalars["DateTime"]>;
  orderSlugId: Maybe<Scalars["String"]>;
  parent: Maybe<Parent>;
  pauseWindow: Maybe<EnrollmentPauseWindow>;
  payments: Array<EnrollmentPayment>;
  priceCents: Maybe<Scalars["Int"]>;
  pricingOffer: Maybe<PricingOffer>;
  purchasedAsGift: Scalars["Boolean"];
  refundableAmountCents: Scalars["Int"];
  refundPolicy: Maybe<RefundPolicy>;
  section: Maybe<Section>;
  service_fee_percentage: Scalars["Float"];
  thisWeekPauseDeadline: Maybe<Scalars["DateTime"]>;
  uid: Scalars["ID"];
  withdrawnAt: Maybe<Scalars["DateTime"]>;
};

export type Parent = {
  __typename?: "Parent";
  canPayWithSubscriptionCredits: Scalars["Boolean"];
  consent_to_share_class_history: Maybe<Scalars["Boolean"]>;
  consent_to_share_favourites: Maybe<Scalars["Boolean"]>;
  created_at: Maybe<Scalars["DateTime"]>;
  deleted_at: Maybe<Scalars["DateTime"]>;
  email: Maybe<Scalars["String"]>;
  enrollmentsWithTeacher: Array<Enrollment>;
  hasEnrollment: Scalars["Boolean"];
  hasSubscription: Scalars["Boolean"];
  introduction: Maybe<Scalars["String"]>;
  /** @deprecated No longer in use */
  is_seller_org: Scalars["Boolean"];
  learnerModeSwitchAuthRequirement: Maybe<LearnerModeSwitchAuthRequirement>;
  learners_verified_at: Maybe<Scalars["DateTime"]>;
  name: Maybe<Scalars["String"]>;
  photo: Maybe<Scalars["String"]>;
  publicName: Maybe<Scalars["String"]>;
  reviewCount: Scalars["Int"];
  sectionsWithCurrentUser: Array<Section>;
  slug_id: Maybe<Scalars["String"]>;
  socialOptOut: Scalars["Boolean"];
  timeZone: Maybe<Scalars["String"]>;
  uid: Scalars["ID"];
  uniqueActivityCount: Scalars["Int"];
  upgradablePackages: Maybe<Array<Maybe<Scalars["Int"]>>>;
};

export type ParentEnrollmentsWithTeacherArgs = {
  teacherUid: Scalars["ID"];
};

export enum LearnerModeSwitchAuthRequirement {
  Password = "password",
  Pin = "pin",
}

export type Section = Node & {
  __typename?: "Section";
  activity: Activity;
  activity_uid: Scalars["String"];
  allowsLateEnrollment: Scalars["Boolean"];
  allowsTeacherTransfer: Scalars["Boolean"];
  assignmentClassPosts: Array<AssignmentClassPost>;
  availableSpaces: Scalars["Int"];
  canBeCanceled: Scalars["Boolean"];
  canceled_at: Maybe<Scalars["DateTime"]>;
  checklist: SectionChecklist;
  classRecording: Recording;
  classRecordings: Array<Recording>;
  clubPostCount: Scalars["Int"];
  created_at: Scalars["DateTime"];
  currentLearnerEnrollment: Enrollment;
  currentSectionLesson: Maybe<SectionLesson>;
  currentUserCanManage: Scalars["Boolean"];
  currentUserConflictingEnrollments: Array<Enrollment>;
  currentUserEnrollments: Array<Enrollment>;
  currentUserFeedback: Maybe<Feedback>;
  currentUserGiftEnrollments: Array<GiftEnrollment>;
  currentUserGiftSlugId: Maybe<Scalars["String"]>;
  currentUserHasAttendances: Scalars["Boolean"];
  currentUserIsOwner: Scalars["Boolean"];
  currentUserPublicReview: Maybe<PublicReview>;
  deleted_at: Maybe<Scalars["DateTime"]>;
  deletedMeetings: Array<Meeting>;
  details: SectionDetails;
  duration_minutes: Maybe<Scalars["Int"]>;
  end_time: Maybe<Scalars["DateTime"]>;
  enrollments: Array<Enrollment>;
  enrollmentsForAdmin: EnrollmentsForAdmin;
  feedbacksForLeader: Array<FeedbackForLeader>;
  filledSpaceCount: Scalars["Int"];
  /**
   * Returns true if the section has a frozen syllabus.
   * A frozen syllabus cannot have its structure change.
   * Freezing currently happens when the first enrollment is confirmed.
   * Before freezing, the number of lessons/units/etc can still change.
   */
  hasFrozenSyllabus: Scalars["Boolean"];
  is_cross_listed: Scalars["Boolean"];
  is_self_paced: Scalars["Boolean"];
  isAutoCanceled: Scalars["Boolean"];
  isBuyable: Scalars["Boolean"];
  isInProgress: Scalars["Boolean"];
  isPublished: Maybe<Scalars["Boolean"]>;
  isSelfPacedReadOnly: Scalars["Boolean"];
  latestProgressReportDate: Maybe<Scalars["DateTime"]>;
  leader: Leader;
  learnerEnrollmentPayments: Array<EnrollmentPayment>;
  location: Maybe<Location>;
  meetingConflictInfo: Maybe<Array<SectionMeetingConflictInfo>>;
  meetingCount: Scalars["Int"];
  meetingDays: Maybe<Scalars["String"]>;
  /**
   * Returns meeting requests NOT associated with a meeting
   * (i.e., requests to add a meeting NOT reschedule requests).
   * To query reschedule requests query the rescheduleRequest field on the Meeting object.
   */
  meetingRequests: Array<MeetingRequest>;
  meetings: Array<Meeting>;
  meetingTimes: Array<MeetingTime>;
  messages: Array<Message>;
  moderators: Array<Leader>;
  nextOngoingMeeting: Maybe<Meeting>;
  notes: Maybe<Scalars["String"]>;
  ongoing_stopped_at: Maybe<Scalars["DateTime"]>;
  paidAmount: Maybe<Scalars["Float"]>;
  pausedLearnerCount: Scalars["Int"];
  payouts: Array<Payout>;
  pendingEnrollments: Array<Maybe<Enrollment>>;
  pendingOneOnOneMeetingOrdersForAdmin: Array<EnrollmentMeetingPurchaseInfoForOrderSlug>;
  pendingOneOnOneMeetings: Maybe<Array<EnrollmentMeetingPurchase>>;
  posts: Array<ClassPost>;
  prepaidOngoingPeriodOptions: Maybe<Array<Period>>;
  price_cents: Maybe<Scalars["Int"]>;
  priceCredits: Maybe<Scalars["Float"]>;
  progress: Maybe<CourseProgress>;
  published_at: Maybe<Scalars["DateTime"]>;
  sectionLessons: Maybe<Array<SectionLesson>>;
  selectedEnrollment: Maybe<BaseEnrollment>;
  size_max: Maybe<Scalars["Int"]>;
  size_min: Maybe<Scalars["Int"]>;
  standardPayoutCents: Scalars["Int"];
  start_time: Maybe<Scalars["DateTime"]>;
  startsAtNight: Maybe<Scalars["Boolean"]>;
  syllabus: Maybe<Syllabus>;
  uid: Scalars["ID"];
  unclaimedGiftEnrollments: Array<GiftEnrollment>;
  unclaimedGiftEnrollmentsForAdmin: Array<GiftEnrollment>;
  unpublishedPosts: Array<ClassPost>;
  updated_at: Scalars["DateTime"];
  user_uid: Maybe<Scalars["ID"]>;
  usersCanSendPrivateMessage: Scalars["Boolean"];
  usesOutschoolVideoChat: Scalars["Boolean"];
  validEnrollmentCount: Scalars["Int"];
  waitlistSeats: Maybe<Array<WaitlistSeat>>;
  waitlistSeatsCount: Maybe<Scalars["Int"]>;
  zoomMeeting: Maybe<ZoomMeeting>;
};

export type SectionClassRecordingArgs = {
  recordingUid: Scalars["ID"];
};

export type SectionEnrollmentsArgs = {
  filter?: InputMaybe<EnrollmentFilter>;
  status?: InputMaybe<EnrollmentStatus>;
  statusList?: InputMaybe<Array<EnrollmentStatus>>;
};

export type SectionEnrollmentsForAdminArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type SectionFilledSpaceCountArgs = {
  exact?: InputMaybe<Scalars["Boolean"]>;
};

export type SectionLearnerEnrollmentPaymentsArgs = {
  learnerUid: Scalars["String"];
};

export type SectionMeetingRequestsArgs = {
  filters?: InputMaybe<MeetingRequestFilters>;
};

export type SectionMeetingsArgs = {
  filter?: InputMaybe<MeetingFilter>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  sortOrder?: InputMaybe<SortOrder>;
  startAfter?: InputMaybe<Scalars["DateTime"]>;
  startBefore?: InputMaybe<Scalars["DateTime"]>;
};

export type SectionSelectedEnrollmentArgs = {
  learnerOrEnrollmentUid: Scalars["String"];
};

export type SectionUnclaimedGiftEnrollmentsArgs = {
  status?: InputMaybe<EnrollmentStatus>;
  statusList?: InputMaybe<Array<EnrollmentStatus>>;
};

export type Node = {
  uid: Scalars["ID"];
};

export type AssignmentClassPost = {
  __typename?: "AssignmentClassPost";
  assignmentStatus: Maybe<LearningStatementsVerb>;
  /**
   * The high-level comments on an assignment class post, making up the threads (which are split by learner).
   * Each comment can have replies, representing those threads.
   * Learners can only see their own comments and replies.
   */
  comments: Array<AssignmentClassPostComment>;
  created_at: Scalars["DateTime"];
  deleted_at: Maybe<Scalars["DateTime"]>;
  /** Assignments don't need due dates, but due dates should be after publish date */
  dueDate: Maybe<Scalars["DateTime"]>;
  /** Whether the assignment is completed by the learner in the section */
  isCompleted: Scalars["Boolean"];
  /** Whether the assignment is submitted by the learner in the section */
  isSubmitted: Scalars["Boolean"];
  lesson: Maybe<Lesson>;
  /** Template lesson post that this class post was made from */
  lessonPost: Maybe<AssignmentLessonPost>;
  messageContent: MessageContent;
  /** The date a post is published, assignment class posts will not be editable after the publish at date */
  publishAt: Scalars["DateTime"];
  section: Section;
  /** The requirement for students to submit something for this assignment. */
  submissionRequirement: SubmissionRequirementType;
  title: Scalars["String"];
  uid: Scalars["ID"];
  updated_at: Scalars["DateTime"];
  /** The teacher who created the post */
  user: User;
};

export type AssignmentClassPostAssignmentStatusArgs = {
  learnerUid: Scalars["ID"];
};

export type AssignmentClassPostIsCompletedArgs = {
  learnerUid: Scalars["ID"];
};

export type AssignmentClassPostIsSubmittedArgs = {
  learnerUid: Scalars["ID"];
};

export enum LearningStatementsVerb {
  Completed = "Completed",
  NotStarted = "NotStarted",
  Started = "Started",
  Submitted = "Submitted",
  Viewed = "Viewed",
  Voided = "Voided",
}

export type AssignmentClassPostComment = {
  __typename?: "AssignmentClassPostComment";
  /**
   * Whether this comment is considered an assignment submission.
   * Currently this is true if it's the learner's first comment on the assignment.
   */
  isSubmission: Maybe<Scalars["Boolean"]>;
  messageContent: MessageContent;
  /** Replies to this comment, making up a thread. */
  replies: Maybe<Array<AssignmentClassPostComment>>;
  sender: MessageSender;
  sentAt: Scalars["DateTime"];
  /** All comments on an assignment class post are grouped into threads based on learner. */
  threadForLearnerUid: Scalars["ID"];
  uid: Scalars["ID"];
};

export type MessageContent = {
  __typename?: "MessageContent";
  attachments: Array<Attachment>;
  editedAt: Maybe<Scalars["DateTime"]>;
  originalMimeType: TextMimeType;
  text: Maybe<Scalars["String"]>;
  uid: Scalars["ID"];
  video: Maybe<Attachment>;
};

export type MessageContentTextArgs = {
  mimeType?: InputMaybe<TextMimeType>;
  plainText?: InputMaybe<Scalars["Boolean"]>;
};

export type Attachment = {
  __typename?: "Attachment";
  conversionStatus: Maybe<Scalars["String"]>;
  created_at: Scalars["DateTime"];
  file: File;
  uid: Scalars["ID"];
};

export type File = {
  __typename?: "File";
  filename: Scalars["String"];
  /** The size of the file in bytes, uses float because js integers are not large enough to hold the size of large files */
  fileSize: Maybe<Scalars["Float"]>;
  mimetype: Maybe<Scalars["String"]>;
  /** @deprecated Use 'fileSize' instead because js integers are not large enough to hold the size of large files */
  size: Scalars["Int"];
  thumbUrl: Maybe<Scalars["String"]>;
  url: Scalars["String"];
};

export enum TextMimeType {
  TextHtml = "TEXT_HTML",
  TextMarkdown = "TEXT_MARKDOWN",
  TextPlain = "TEXT_PLAIN",
  TextPlainWithMentions = "TEXT_PLAIN_WITH_MENTIONS",
}

export type MessageSender = {
  __typename?: "MessageSender";
  avatar: Maybe<Scalars["String"]>;
  isLearner: Scalars["Boolean"];
  name: Maybe<Scalars["String"]>;
  photo: Maybe<Scalars["String"]>;
  uid: Scalars["ID"];
};

export type Lesson = {
  __typename?: "Lesson";
  /**
   * The assignment class posts for the given lesson. Assignment posts are similar
   * to class posts in that they are a section level concern. that can optionally
   * be created from a assignment lesson post.
   */
  assignmentClassPosts: Array<AssignmentClassPost>;
  /** Template assignment posts for a lesson that are converted to assignment class posts at section creation */
  assignmentLessonPosts: Array<AssignmentLessonPost>;
  /** Number of assignments in a lesson */
  assignmentsCount: Scalars["Int"];
  /** The class posts for the given lesson. Different than lessonsPosts, which are more like templates. */
  classPosts: Maybe<Array<ClassPost>>;
  description: Maybe<Scalars["String"]>;
  /**
   * Whether the lesson is completed by the learner in the section
   * (equivalent to "lessonStatus === LearningStatementsVerb.Completed")
   */
  isCompleted: Scalars["Boolean"];
  /** Whether the lesson is locked for the learner in the section */
  isLocked: Scalars["Boolean"];
  /** Whether the lesson is missed for the learner in the section */
  isMissed: Scalars["Boolean"];
  lessonNumber: Maybe<Scalars["Int"]>;
  /** Template posts for a lesson that are converted to class posts at section creation */
  lessonPosts: Array<LessonPost>;
  lessonStatus: Maybe<LearningStatementsVerb>;
  /**
   * All meetings associated with this lesson.
   * We currently only support 1 meeting per lesson.
   */
  meetings: Maybe<Array<Meeting>>;
  order_within_week: Scalars["Int"];
  title: Maybe<Scalars["String"]>;
  uid: Scalars["ID"];
  unit: Maybe<Unit>;
  /** Estimation for when this will unlock based on the meeting time and other information */
  unlocksAt: Maybe<Scalars["DateTime"]>;
  /** Total duration of the videos in a lesson */
  videoDurationSeconds: Scalars["Int"];
  week_number: Scalars["Int"];
};

export type LessonAssignmentClassPostsArgs = {
  sectionUid: Scalars["ID"];
};

export type LessonClassPostsArgs = {
  sectionUid?: InputMaybe<Scalars["ID"]>;
};

export type LessonIsCompletedArgs = {
  learnerUid: Scalars["ID"];
};

export type LessonIsLockedArgs = {
  learnerUid: Scalars["ID"];
  sectionUid: Scalars["ID"];
};

export type LessonIsMissedArgs = {
  learnerUid: Scalars["ID"];
  sectionUid: Scalars["ID"];
};

export type LessonLessonStatusArgs = {
  learnerUid?: InputMaybe<Scalars["ID"]>;
};

export type LessonMeetingsArgs = {
  sectionUid: Scalars["ID"];
};

export type LessonUnlocksAtArgs = {
  sectionUid: Scalars["ID"];
};

export type AssignmentLessonPost = {
  __typename?: "AssignmentLessonPost";
  created_at: Scalars["DateTime"];
  deleted_at: Maybe<Scalars["DateTime"]>;
  lessonUid: Scalars["ID"];
  messageContent: MessageContent;
  orderInLesson: Scalars["Int"];
  submissionRequirement: SubmissionRequirementType;
  title: Maybe<Scalars["String"]>;
  uid: Scalars["ID"];
};

export enum SubmissionRequirementType {
  Optional = "optional",
  Recommended = "recommended",
  Required = "required",
}

export type ClassPost = {
  __typename?: "ClassPost";
  backgroundColor: Maybe<Scalars["String"]>;
  comments: Array<ClassPostComment>;
  commentsPreview: CommentsPreview;
  isWelcomePost: Scalars["Boolean"];
  lesson: Maybe<Lesson>;
  lessonPost: Maybe<LessonPost>;
  mentionedEnrollmentUids: Array<Scalars["String"]>;
  messageContent: MessageContent;
  pinnedAt: Maybe<Scalars["DateTime"]>;
  promotedAt: Maybe<Scalars["DateTime"]>;
  publishAt: Scalars["DateTime"];
  /**
   * A score that ranks how popular the post is.
   * Larger is more popular/engaged.
   * Scores are calculated relative to a DateTime
   */
  score: Maybe<Scalars["Float"]>;
  sender: MessageSender;
  title: Maybe<Scalars["String"]>;
  uid: Scalars["ID"];
};

export type ClassPostCommentsArgs = {
  filters?: InputMaybe<CommentsFilterInput>;
};

export type CommentsFilterInput = {
  creationDateSortOrder?: InputMaybe<SortOrder>;
};

export enum SortOrder {
  Ascending = "ascending",
  Descending = "descending",
}

export type ClassPostComment = {
  __typename?: "ClassPostComment";
  backgroundColor: Maybe<Scalars["String"]>;
  mentionedEnrollmentUids: Array<Scalars["String"]>;
  mentionedTeacherUid: Maybe<Scalars["String"]>;
  messageContent: MessageContent;
  replies: Array<ClassPostComment>;
  sendAt: Scalars["DateTime"];
  sender: MessageSender;
  uid: Scalars["ID"];
};

export type ClassPostCommentRepliesArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
};

export type CommentsPreview = {
  __typename?: "CommentsPreview";
  comments: Array<ClassPostComment>;
  remainingCommentsCount: Scalars["Int"];
};

export type LessonPost = {
  __typename?: "LessonPost";
  created_at: Scalars["DateTime"];
  deleted_at: Maybe<Scalars["DateTime"]>;
  is_posted_for_enrollments: Scalars["Boolean"];
  is_posted_for_subscriptions: Scalars["Boolean"];
  lessonUid: Scalars["ID"];
  messageContent: MessageContent;
  orderInLesson: Scalars["Int"];
  title: Maybe<Scalars["String"]>;
  uid: Scalars["ID"];
};

export type Meeting = Node & {
  __typename?: "Meeting";
  attendances: Array<Attendance>;
  /**
   * When payment for the meeting is due.
   * For ongoing, this is the start of meeting's ISO week.
   * For 1:1, this is either the payment time (for 1-time and first enrollments) or start of ISO week for meeting
   * For others, this is the enrollment confirmation time.
   */
  billedOn: Scalars["DateTime"];
  canParentUpdate: Maybe<Scalars["Boolean"]>;
  classRecordings: Array<Recording>;
  /** @deprecated Moved to Section as meetingConflictInfo */
  conflictInfo: Maybe<MeetingConflictInfo>;
  created_at: Maybe<Scalars["DateTime"]>;
  deleted_at: Maybe<Scalars["DateTime"]>;
  end_time: Scalars["DateTime"];
  holiday: Maybe<Holiday>;
  lesson: Maybe<Lesson>;
  oneOnOneTenderType: Maybe<Array<TenderType>>;
  /**
   * When the meeting was paid for.
   * For 1:1, this is when the EnrollmentMeetingPurchase was confirmed or null if not paid yet.
   * For non-1:1 classes are not paid for by meeting it will always be null.
   */
  paidAt: Maybe<Scalars["DateTime"]>;
  recordingAvailability: RecordingAvailability;
  recordingRequests: Array<RecordingRequest>;
  /**
   * Boolean value if the meeting is part of a recurring set.
   * For 1:1, this can be true or false depending on if the meeting is connected to a row in section_recurring_schedules
   * For non-1:1, will always be false
   */
  recurring: Maybe<Scalars["Boolean"]>;
  recurringSchedule: Maybe<RecurringSchedule>;
  refundableAmountCents: Scalars["Int"];
  refundableAmountCredits: Maybe<Scalars["Int"]>;
  /** Returns all non-cancelled reschedule requests for a meeting (including expired/declined) */
  rescheduleRequests: Array<MeetingRequest>;
  section: Section;
  sectionSequenceNumber: Scalars["Int"];
  start_time: Scalars["DateTime"];
  startStatus: MeetingStartStatus;
  teacherAttendance: Maybe<TeacherAttendance>;
  uid: Scalars["ID"];
};

export type Attendance = {
  __typename?: "Attendance";
  created_at: Scalars["DateTime"];
  learner: Learner;
  meeting: Meeting;
  uid: Scalars["ID"];
};

export type Learner = {
  __typename?: "Learner";
  about_me: Maybe<Scalars["String"]>;
  age: Maybe<Scalars["Int"]>;
  avatar: Maybe<Scalars["String"]>;
  blockedAt: Maybe<Scalars["DateTime"]>;
  blockedByUserUid: Maybe<Scalars["ID"]>;
  created_at: Scalars["DateTime"];
  currentProgress: Maybe<Array<LearningStatement>>;
  deleted_at: Maybe<Scalars["DateTime"]>;
  enrollmentCount: Scalars["Int"];
  intro: Maybe<Scalars["String"]>;
  isBlocked: Maybe<Scalars["Boolean"]>;
  isVerified: Scalars["Boolean"];
  latestPaidEnrollment: Maybe<Enrollment>;
  name: Maybe<Scalars["String"]>;
  newOnOutschool: Maybe<Scalars["Boolean"]>;
  parent: Parent;
  privateNote: Maybe<Scalars["String"]>;
  pronoun: Maybe<Scalars["String"]>;
  recentAttendances: Array<Maybe<Attendance>>;
  savedClassList: Maybe<SavedClassList>;
  siblings: Array<Child>;
  timeZone: Scalars["String"];
  uid: Scalars["ID"];
};

export type LearnerCurrentProgressArgs = {
  activityUid: Scalars["ID"];
};

export type LearnerRecentAttendancesArgs = {
  start?: InputMaybe<Scalars["DateTime"]>;
};

export type LearningStatement = {
  __typename?: "LearningStatement";
  actor: LearningStatementsActor;
  object: LearningStatementsResource;
  uid: Scalars["ID"];
  verb: LearningStatementsVerb;
};

export type LearningStatementsActor = {
  uid: Scalars["ID"];
};

export type LearningStatementsResource =
  | AssignmentClassPost
  | LearningStatement
  | Lesson;

export type SavedClassList = {
  __typename?: "SavedClassList";
  activityCount: Scalars["Int"];
  createdAt: Scalars["DateTime"];
  lastAddedToAt: Maybe<Scalars["DateTime"]>;
  listings: Array<Maybe<Listing>>;
  name: Scalars["String"];
  owner: User;
  ownerUid: Scalars["String"];
  privacyStatus: SavedClassListStatus;
  uid: Scalars["ID"];
};

export type SavedClassListListingsArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  searchFilters: SearchFilters;
};

export type SearchFilters = {
  activityRatingMin?: InputMaybe<Scalars["Float"]>;
  /**
   * A list of strings that are matched without analysis to normalized values
   * from the activities.tags column
   */
  adminTags?: InputMaybe<Array<Scalars["String"]>>;
  age?: InputMaybe<Scalars["String"]>;
  availableTimes?: InputMaybe<Array<SearchFilterExcludeTimeWindow>>;
  capacityMax?: InputMaybe<Scalars["Int"]>;
  capacityMin?: InputMaybe<Scalars["Int"]>;
  curriculums?: InputMaybe<Array<Scalars["String"]>>;
  delivery?: InputMaybe<Scalars["String"]>;
  dow?: InputMaybe<Scalars["String"]>;
  durationSessionMin?: InputMaybe<Scalars["Int"]>;
  durationWeeksMax?: InputMaybe<Scalars["Int"]>;
  durationWeeksMin?: InputMaybe<Scalars["Int"]>;
  enabledBooleanFilters?: InputMaybe<Scalars["String"]>;
  endBy?: InputMaybe<Scalars["String"]>;
  endByTime?: InputMaybe<Scalars["Float"]>;
  englishProficiencyLevel?: InputMaybe<EnglishProficiencyLevel>;
  /**
   * Boolean to exclude activities with the new 1-1 format in search results
   * Default: false
   */
  excludeNewOneOnOneFormat?: InputMaybe<Scalars["Boolean"]>;
  /** only returns recurring 1 on 1 classes */
  excludeNonOngoingOneOnOne?: InputMaybe<Scalars["Boolean"]>;
  excludeTimeWindows?: InputMaybe<Array<SearchFilterExcludeTimeWindow>>;
  format?: InputMaybe<Scalars["String"]>;
  fundingPrograms?: InputMaybe<Array<Scalars["String"]>>;
  gradeLevel?: InputMaybe<Scalars["String"]>;
  hasAssessment?: InputMaybe<Scalars["Boolean"]>;
  hasFilledOutUniqueLearningNeeds?: InputMaybe<Scalars["Boolean"]>;
  hasGrades?: InputMaybe<Scalars["Boolean"]>;
  hasHomework?: InputMaybe<Scalars["Boolean"]>;
  includeInProgressFixedLengthLiveFormat?: InputMaybe<Scalars["Boolean"]>;
  includeSelfPaced?: InputMaybe<Scalars["Boolean"]>;
  isAvailable?: InputMaybe<Scalars["Boolean"]>;
  languageOfInstruction?: InputMaybe<Scalars["String"]>;
  listUid?: InputMaybe<Scalars["String"]>;
  /**
   * A list of strings, to be matched against the activity title.
   *
   * Example: to search for winter holiday classes, a multiTermQuery could be:
   * ["Hanukkah", "Christmas", "Winter Solstice"]
   */
  multiTermQuery?: InputMaybe<Array<Scalars["String"]>>;
  order?: InputMaybe<Scalars["String"]>;
  priceCreditsPerCourseMax?: InputMaybe<Scalars["Int"]>;
  priceCreditsPerCourseMin?: InputMaybe<Scalars["Int"]>;
  priceCreditsPerMeetingMax?: InputMaybe<Scalars["Int"]>;
  priceCreditsPerMeetingMin?: InputMaybe<Scalars["Int"]>;
  pricePerCourseMax?: InputMaybe<Scalars["Int"]>;
  pricePerCourseMin?: InputMaybe<Scalars["Int"]>;
  pricePerMeetingMax?: InputMaybe<Scalars["Int"]>;
  pricePerMeetingMin?: InputMaybe<Scalars["Int"]>;
  /** A string query, matched against various fields in the activites ES index */
  q?: InputMaybe<Scalars["String"]>;
  /**
   * A number used to be able to reproduce randomized search results on
   * no query searches
   */
  seed?: InputMaybe<Scalars["Int"]>;
  showListings?: InputMaybe<Scalars["Int"]>;
  standards?: InputMaybe<Array<Scalars["String"]>>;
  starred?: InputMaybe<Scalars["Boolean"]>;
  startAfter?: InputMaybe<Scalars["String"]>;
  startAfterTime?: InputMaybe<Scalars["Float"]>;
  startBefore?: InputMaybe<Scalars["String"]>;
  teacherRatingMin?: InputMaybe<Scalars["Float"]>;
  theme?: InputMaybe<Scalars["String"]>;
  time?: InputMaybe<Scalars["String"]>;
  timeOfDay?: InputMaybe<Scalars["String"]>;
  timeZone?: InputMaybe<Scalars["String"]>;
  topics?: InputMaybe<Array<Scalars["String"]>>;
  uniqueLearningNeeds?: InputMaybe<Scalars["String"]>;
  userName?: InputMaybe<Scalars["String"]>;
  userUid?: InputMaybe<Scalars["String"]>;
  weeklyMeetingsMax?: InputMaybe<Scalars["Int"]>;
  weeklyMeetingsMin?: InputMaybe<Scalars["Int"]>;
};

export type SearchFilterExcludeTimeWindow = {
  dow: Scalars["Int"];
  endHour: Scalars["Int"];
  endMinute: Scalars["Int"];
  startHour: Scalars["Int"];
  startMinute: Scalars["Int"];
};

export enum EnglishProficiencyLevel {
  A0 = "A0",
  A05 = "A05",
  A1 = "A1",
  A2 = "A2",
  B1 = "B1",
  B2 = "B2",
  PreA1 = "PRE_A1",
}

export type Listing = {
  __typename?: "Listing";
  activity: Activity;
  /** How many sections don't match user's timezone preferences */
  inconvenientSectionCount: Scalars["Int"];
  /** InsertionId to be used for Promoted Search Ranking */
  insertionId: Maybe<Scalars["String"]>;
  /** Are any section available for enrollment?  Might be null or false if query expansion. */
  isAvailable: Maybe<Scalars["Boolean"]>;
  leader: Leader;
  /** The section which is starting next chronologically */
  nextSection: Maybe<Section>;
  /** How many sections are available for enrollment */
  openSectionCount: Scalars["Int"];
  /** The ES score of the activity */
  score: Maybe<Scalars["Float"]>;
  /** Total sections for this listing */
  sectionCount: Scalars["Int"];
  sections: Array<Section>;
  /** Listings similar to this Listing */
  similarListings: SimilarListings;
  /** The strategy determined for scoring */
  strategy: Maybe<Scalars["String"]>;
  /** The activity custom scores */
  strategyScore: Maybe<Scalars["Float"]>;
  /** How many sections start or have a meeting from now until Date (default to 2 weeks from now) */
  upcomingSectionCount: Scalars["Int"];
};

export type ListingInconvenientSectionCountArgs = {
  userTimeZone?: InputMaybe<Scalars["String"]>;
};

export type ListingSectionsArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
};

export type ListingUpcomingSectionCountArgs = {
  until?: InputMaybe<Scalars["DateTime"]>;
};

export type Leader = {
  __typename?: "Leader";
  about: Maybe<Scalars["String"]>;
  adminMetrics: AdminLeaderMetrics;
  approved: Scalars["Boolean"];
  autoSchedulingBufferMinutes: Maybe<Scalars["Int"]>;
  averageActivityStarRating: Maybe<Scalars["Float"]>;
  /** @deprecated Moved to User type to support seller orgs */
  backgroundChecked: Maybe<Scalars["Boolean"]>;
  cityState: Maybe<Scalars["String"]>;
  classOfferingTimeRange: Maybe<ClassOfferingTimeRange>;
  created_at: Maybe<Scalars["DateTime"]>;
  credentials: Array<TeacherCredential>;
  deleted_at: Maybe<Scalars["DateTime"]>;
  details: LeaderDetails;
  educatorTiers: Array<EducatorTier>;
  /** @deprecated ESA eligibility is determined at activity level: activity -> esaStatus */
  esaEligability: Array<Maybe<EsaEligibility>>;
  feedbackSummary: Maybe<TeacherFeedbackSummary>;
  flexSections: SectionConnection;
  hasAvailabilitySet: Scalars["Boolean"];
  hasBackgroundCheckExpired: Maybe<Scalars["Boolean"]>;
  hasListings: Scalars["Boolean"];
  is_crosslister: Maybe<Scalars["Boolean"]>;
  /** @deprecated Use ownerOfSellerOrg instead */
  is_seller_org: Scalars["Boolean"];
  isAutoSchedulingEnabled: Maybe<Scalars["Boolean"]>;
  isRecordingAutopostEnabled: Scalars["Boolean"];
  lastWeekFollowedCount: Scalars["Int"];
  leader_link: Maybe<Scalars["String"]>;
  leaderClassLinks: Array<Bundle>;
  listings: Array<Listing>;
  mainToolbarPinnedTabs: Maybe<Array<MainToolbarPinnedTabs>>;
  meetings: MeetingConnection;
  metrics: LeaderMetrics;
  name: Maybe<Scalars["String"]>;
  orgTeacherMetrics: OrgTeacherMetrics;
  ownerOfSellerOrg: Maybe<SellerOrg>;
  photo: Maybe<Scalars["String"]>;
  publishedClassCount: Scalars["Int"];
  recentReviews: Array<PublicReview>;
  reviewCount: Scalars["Int"];
  reviewedSectionCount: Scalars["Int"];
  reviews: Array<Feedback>;
  reviewsWithTextCount: Scalars["Int"];
  showCalendar: Maybe<Scalars["Boolean"]>;
  socialOptOut: Scalars["Boolean"];
  successfulClassCount: Scalars["Int"];
  successfulPrivateClassCount: Scalars["Int"];
  suspended_at: Maybe<Scalars["DateTime"]>;
  tags: TeacherTagsConnection;
  teacher_approved_at: Maybe<Scalars["DateTime"]>;
  teacherForSellerOrg: Maybe<SellerOrg>;
  timeZone: Maybe<Scalars["String"]>;
  uid: Scalars["ID"];
  video: Maybe<Attachment>;
};

export type LeaderClassOfferingTimeRangeArgs = {
  userTimeZone: Scalars["String"];
};

export type LeaderFlexSectionsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  startAfter?: InputMaybe<Scalars["DateTime"]>;
  startBefore?: InputMaybe<Scalars["DateTime"]>;
};

export type LeaderListingsArgs = {
  all?: InputMaybe<Scalars["Boolean"]>;
  excludeActivityUid?: InputMaybe<Scalars["ID"]>;
};

export type LeaderMeetingsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  startAfter?: InputMaybe<Scalars["DateTime"]>;
  startBefore?: InputMaybe<Scalars["DateTime"]>;
};

export type LeaderOrgTeacherMetricsArgs = {
  startAfter?: InputMaybe<Scalars["DateTime"]>;
};

export type LeaderRecentReviewsArgs = {
  limit: Scalars["Int"];
  offset: Scalars["Int"];
};

export type LeaderReviewsArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type AdminLeaderMetrics = {
  __typename?: "AdminLeaderMetrics";
  lastMinuteCancelledEnrollments: Scalars["Int"];
  lastMinuteCancelledSections: Scalars["Int"];
  lateAttendances: Scalars["Int"];
  missedAttendances: Scalars["Int"];
  numIncidents: Scalars["Int"];
  totalAttendances: Scalars["Int"];
};

export type ClassOfferingTimeRange = {
  __typename?: "ClassOfferingTimeRange";
  end: Scalars["String"];
  start: Scalars["String"];
};

export type TeacherCredential = {
  __typename?: "TeacherCredential";
  certificateDiscipline: Maybe<TeacherCertificateDiscipline>;
  certificateDoesNotExpire: Maybe<Scalars["Boolean"]>;
  certificateExpireDate: Maybe<Scalars["DateTime"]>;
  certificateIssueDate: Maybe<Scalars["DateTime"]>;
  certificateNumber: Maybe<Scalars["String"]>;
  certificateSecondaryDiscipline: Maybe<TeacherCertificateDiscipline>;
  certificateState: Maybe<TeacherCertificateState>;
  createdAt: Maybe<Scalars["DateTime"]>;
  credentialType: CredentialType;
  degreeAdditionalInfo: Maybe<Scalars["String"]>;
  degreeInstitution: Maybe<Scalars["String"]>;
  degreeIssueDate: Maybe<Scalars["DateTime"]>;
  degreeSubject: Maybe<DegreeSubject>;
  degreeType: Maybe<DegreeType>;
  name: Maybe<Scalars["String"]>;
  otherDetails: Maybe<Scalars["String"]>;
  otherType: Maybe<Scalars["String"]>;
  uid: Scalars["ID"];
};

export enum TeacherCertificateDiscipline {
  ElementaryEducation = "ElementaryEducation",
  EnglishOrLanguageArts = "EnglishOrLanguageArts",
  EnglishToSpeakersOfOtherLanguages = "EnglishToSpeakersOfOtherLanguages",
  ForeignLanguage = "ForeignLanguage",
  HealthOrPe = "HealthOrPE",
  Mathematics = "Mathematics",
  MusicOrTheaterOrArts = "MusicOrTheaterOrArts",
  Other = "Other",
  Science = "Science",
  SecondaryEducation = "SecondaryEducation",
  SocialStudiesOrHistory = "SocialStudiesOrHistory",
  SpecialEducation = "SpecialEducation",
}

export enum TeacherCertificateState {
  Alabama = "Alabama",
  Alaska = "Alaska",
  Arizona = "Arizona",
  Arkansas = "Arkansas",
  California = "California",
  Colorado = "Colorado",
  Connecticut = "Connecticut",
  Delaware = "Delaware",
  DistrictOfColumbia = "DistrictOfColumbia",
  Florida = "Florida",
  Georgia = "Georgia",
  Hawaii = "Hawaii",
  Idaho = "Idaho",
  Illinois = "Illinois",
  Indiana = "Indiana",
  Iowa = "Iowa",
  Kansas = "Kansas",
  Kentucky = "Kentucky",
  Louisiana = "Louisiana",
  Maine = "Maine",
  Maryland = "Maryland",
  Massachusetts = "Massachusetts",
  Michigan = "Michigan",
  Minnesota = "Minnesota",
  Mississippi = "Mississippi",
  Missouri = "Missouri",
  Montana = "Montana",
  Nebraska = "Nebraska",
  Nevada = "Nevada",
  NewHampshire = "NewHampshire",
  NewJersey = "NewJersey",
  NewMexico = "NewMexico",
  NewYork = "NewYork",
  NorthCarolina = "NorthCarolina",
  NorthDakota = "NorthDakota",
  NotInUs = "NotInUS",
  Ohio = "Ohio",
  Oklahoma = "Oklahoma",
  Oregon = "Oregon",
  Pennsylvania = "Pennsylvania",
  RhodeIsland = "RhodeIsland",
  SouthCarolina = "SouthCarolina",
  SouthDakota = "SouthDakota",
  Tennessee = "Tennessee",
  Texas = "Texas",
  Utah = "Utah",
  Vermont = "Vermont",
  Virginia = "Virginia",
  Washington = "Washington",
  WestVirginia = "WestVirginia",
  Wisconsin = "Wisconsin",
  Wyoming = "Wyoming",
}

export enum CredentialType {
  CollegeDegree = "CollegeDegree",
  Other = "Other",
  TeachingCertificate = "TeachingCertificate",
}

export enum DegreeSubject {
  Accounting = "Accounting",
  ActuarialScience = "ActuarialScience",
  Advertising = "Advertising",
  AerospaceEngineering = "AerospaceEngineering",
  Agriculture = "Agriculture",
  AmericanStudies = "AmericanStudies",
  AnimalScience = "AnimalScience",
  Anthropology = "Anthropology",
  Architecture = "Architecture",
  ArtAndDesign = "ArtAndDesign",
  ArtHistoryCriticismAndConservation = "ArtHistoryCriticismAndConservation",
  AsianStudiesCivilization = "AsianStudiesCivilization",
  BehavioralScience = "BehavioralScience",
  Biochemistry = "Biochemistry",
  BiologyBiologicalSciences = "BiologyBiologicalSciences",
  BiomedicalSciences = "BiomedicalSciences",
  BotanyPlantBiology = "BotanyPlantBiology",
  BusinessAdministrationAndManagement = "BusinessAdministrationAndManagement",
  BusinessCommerce = "BusinessCommerce",
  ChemicalEngineering = "ChemicalEngineering",
  Chemistry = "Chemistry",
  CivilEngineering = "CivilEngineering",
  Communications = "Communications",
  ComputerEngineering = "ComputerEngineering",
  ComputerScience = "ComputerScience",
  ConstructionManagement = "ConstructionManagement",
  CriminalJustice = "CriminalJustice",
  Dance = "Dance",
  EarlyChildhoodEducation = "EarlyChildhoodEducation",
  Economics = "Economics",
  Education = "Education",
  ElectricalEngineering = "ElectricalEngineering",
  ElementaryEducation = "ElementaryEducation",
  EngineeringGeneral = "EngineeringGeneral",
  English = "English",
  EnglishLanguageAndLiterature = "EnglishLanguageAndLiterature",
  EnvironmentalScience = "EnvironmentalScience",
  EthnicStudies = "EthnicStudies",
  ExerciseScience = "ExerciseScience",
  FamilyAndConsumerSciencesHumanSciences = "FamilyAndConsumerSciencesHumanSciences",
  FashionMerchandising = "FashionMerchandising",
  FilmCinemaVideoStudies = "FilmCinemaVideoStudies",
  Finance = "Finance",
  FineStudioArts = "FineStudioArts",
  ForeignLanguage = "ForeignLanguage",
  ForensicScienceAndTechnology = "ForensicScienceAndTechnology",
  Forestry = "Forestry",
  Genetics = "Genetics",
  GeologyEarthScience = "GeologyEarthScience",
  GraphicDesign = "GraphicDesign",
  HealthAndPhysicalEducationFitness = "HealthAndPhysicalEducationFitness",
  HealthOrPe = "HealthOrPE",
  HealthProfessionsAndRelatedPrograms = "HealthProfessionsAndRelatedPrograms",
  HealthServicesAlliedHealthHealthSciences = "HealthServicesAlliedHealthHealthSciences",
  History = "History",
  Horticulture = "Horticulture",
  HospitalAndHealthCareFacilitiesAdministrationManagement = "HospitalAndHealthCareFacilitiesAdministrationManagement",
  HospitalityManagement = "HospitalityManagement",
  HumanDevelopmentFamilyStudies = "HumanDevelopmentFamilyStudies",
  HumanResourcesManagement = "HumanResourcesManagement",
  InformationTechnology = "InformationTechnology",
  InteriorDesign = "InteriorDesign",
  InternationalBusiness = "InternationalBusiness",
  InternationalRelations = "InternationalRelations",
  Journalism = "Journalism",
  Kinesiology = "Kinesiology",
  LiberalArtsAndSciences = "LiberalArtsAndSciences",
  Linguistics = "Linguistics",
  ManagementInformationSystems = "ManagementInformationSystems",
  MarineBiology = "MarineBiology",
  Marketing = "Marketing",
  MaterialsScience = "MaterialsScience",
  Math = "Math",
  MechanicalEngineering = "MechanicalEngineering",
  MolecularBiology = "MolecularBiology",
  Music = "Music",
  MusicOrTheaterOrArts = "MusicOrTheaterOrArts",
  Nursing = "Nursing",
  NutritionSciences = "NutritionSciences",
  OccupationalTherapy = "OccupationalTherapy",
  OrganizationalLeadership = "OrganizationalLeadership",
  Other = "Other",
  Philosophy = "Philosophy",
  Physics = "Physics",
  PoliticalScienceAndGovernment = "PoliticalScienceAndGovernment",
  PreMedicinePreMedicalStudies = "PreMedicinePreMedicalStudies",
  Psychology = "Psychology",
  PublicAdministration = "PublicAdministration",
  PublicHealth = "PublicHealth",
  PublicRelations = "PublicRelations",
  RealEstate = "RealEstate",
  ReligiousStudies = "ReligiousStudies",
  Science = "Science",
  SecondaryEducation = "SecondaryEducation",
  SecondarySchoolAdministrationPrincipalship = "SecondarySchoolAdministrationPrincipalship",
  SocialSciences = "SocialSciences",
  SocialWork = "SocialWork",
  Sociology = "Sociology",
  SpanishLanguageAndLiterature = "SpanishLanguageAndLiterature",
  SpecialEducation = "SpecialEducation",
  SpeechCommunicationAndRhetoric = "SpeechCommunicationAndRhetoric",
  SpeechLanguagePathologyPathologist = "SpeechLanguagePathologyPathologist",
  SportsManagement = "SportsManagement",
  SupplyChainManagementLogistics = "SupplyChainManagementLogistics",
  TheatreArts = "TheatreArts",
  UrbanAndRegionalPlanning = "UrbanAndRegionalPlanning",
  UrbanStudies = "UrbanStudies",
  VeterinaryTechnology = "VeterinaryTechnology",
  ZoologyAnimalBiology = "ZoologyAnimalBiology",
}

export enum DegreeType {
  AssociatesDegree = "AssociatesDegree",
  BachelorsDegree = "BachelorsDegree",
  DoctoralDegree = "DoctoralDegree",
  DoctorOfMedicine = "DoctorOfMedicine",
  JurisDoctor = "JurisDoctor",
  MastersDegree = "MastersDegree",
}

export type LeaderDetails = {
  __typename?: "LeaderDetails";
  about: Maybe<Scalars["String"]>;
  countryOfResidence: Maybe<Scalars["String"]>;
  headline: Maybe<Scalars["String"]>;
  headlineTranslated: Maybe<Scalars["String"]>;
  photo: Maybe<Scalars["String"]>;
};

export enum EducatorTier {
  NewOnOutschool = "new_on_outschool",
  Popular = "popular",
  RisingStar = "rising_star",
  Star = "star",
}

export type EsaEligibility = {
  __typename?: "EsaEligibility";
  approved: Array<Maybe<Scalars["String"]>>;
  stateAbbreviation: Scalars["String"];
  subjectFilters: Array<Maybe<EsaSubjectFilters>>;
};

export type EsaSubjectFilters = {
  __typename?: "EsaSubjectFilters";
  filters: Maybe<Array<Maybe<Scalars["String"]>>>;
  minAge: Maybe<Scalars["Int"]>;
  subject: Scalars["String"];
};

export type TeacherFeedbackSummary = {
  __typename?: "TeacherFeedbackSummary";
  activity_uid: Maybe<Scalars["ID"]>;
  cons: Maybe<Scalars["String"]>;
  created_at: Maybe<Scalars["DateTime"]>;
  pros: Maybe<Scalars["String"]>;
  teacher_attributes: Maybe<Array<Maybe<Scalars["String"]>>>;
  teacher_uid: Maybe<Scalars["ID"]>;
  uid: Maybe<Scalars["ID"]>;
  updated_at: Maybe<Scalars["DateTime"]>;
};

export type SectionConnection = Connection & {
  __typename?: "SectionConnection";
  edges: Array<SectionEdge>;
  pageInfo: PageInfo;
};

export type Connection = {
  edges: Array<Edge>;
  pageInfo: PageInfo;
};

export type Edge = {
  cursor: Scalars["String"];
  node: Node;
};

export type PageInfo = {
  __typename?: "PageInfo";
  hasNextPage: Scalars["Boolean"];
  lastCursor: Scalars["String"];
};

export type SectionEdge = Edge & {
  __typename?: "SectionEdge";
  cursor: Scalars["String"];
  node: Section;
};

export type Bundle = {
  __typename?: "Bundle";
  bundleElements: Array<BundleElement>;
  created_at: Scalars["DateTime"];
  createdByUid: Scalars["ID"];
  deleted_at: Maybe<Scalars["DateTime"]>;
  description: Maybe<Scalars["String"]>;
  discount: Maybe<Scalars["Float"]>;
  isOrdered: Scalars["Boolean"];
  leader: Leader;
  publishedAt: Maybe<Scalars["DateTime"]>;
  thumbnail: Maybe<Scalars["String"]>;
  title: Scalars["String"];
  uid: Scalars["ID"];
};

export type BundleElement = {
  __typename?: "BundleElement";
  activity: Activity;
  bundleUid: Scalars["ID"];
  created_at: Scalars["DateTime"];
  deleted_at: Maybe<Scalars["DateTime"]>;
  orderInBundle: Scalars["Int"];
  uid: Scalars["ID"];
};

export enum MainToolbarPinnedTabs {
  Availability = "availability",
  Classes = "classes",
  Conversations = "conversations",
  Coupons = "coupons",
  Credentials = "credentials",
  Groups = "groups",
  Insights = "insights",
  Marketing = "marketing",
  Profile = "profile",
  Schedule = "schedule",
  Sections = "sections",
}

export type MeetingConnection = Connection & {
  __typename?: "MeetingConnection";
  edges: Array<MeetingEdge>;
  pageInfo: PageInfo;
};

export type MeetingEdge = Edge & {
  __typename?: "MeetingEdge";
  cursor: Scalars["String"];
  node: Meeting;
};

export type LeaderMetrics = {
  __typename?: "LeaderMetrics";
  activeSections: Scalars["Int"];
  /** @deprecated Query leader.listings instead */
  availableClasses: Scalars["Int"];
  averageActivityStarRating: Maybe<Scalars["Float"]>;
  classBreakdown: Array<Maybe<ActivityMetric>>;
  followers: Scalars["Int"];
  hoursTaught: Scalars["Float"];
  learnersTaught: Scalars["Int"];
  lifetimeEarningsCents: Scalars["Int"];
  monthlySummary: Array<Maybe<MonthlyLeaderMetric>>;
  repurchaseRate: Maybe<Scalars["Float"]>;
};

export type ActivityMetric = {
  __typename?: "ActivityMetric";
  activityTitle: Scalars["String"];
  activityUid: Maybe<Scalars["ID"]>;
  averageStarRating: Scalars["Float"];
  classFormat: ClassFormat;
  hoursTaught: Scalars["Float"];
  numEnrollments90: Maybe<Scalars["Int"]>;
  numEnrollmentsUnwithdrawn90: Maybe<Scalars["Int"]>;
  numReviews: Scalars["Int"];
  numSaves: Scalars["Int"];
  numViewers90: Maybe<Scalars["Int"]>;
  numViews90: Maybe<Scalars["Int"]>;
  paidOutCents: Scalars["Int"];
  reenrollmentPercentage: Scalars["Float"];
  sectionsDuringTimeFrame: Scalars["Int"];
  uniqueStudents: Scalars["Int"];
};

export enum ClassFormat {
  Club = "CLUB",
  Flex = "FLEX",
  MultiDay = "MULTI_DAY",
  OneOnOne = "ONE_ON_ONE",
  OneTime = "ONE_TIME",
  Ongoing = "ONGOING",
  SelfPaced = "SELF_PACED",
}

export type MonthlyLeaderMetric = {
  __typename?: "MonthlyLeaderMetric";
  hours: Scalars["Float"];
  month: Scalars["String"];
  payCents: Scalars["Int"];
  students: Scalars["Int"];
};

export type OrgTeacherMetrics = {
  __typename?: "OrgTeacherMetrics";
  attendedMeetings: Scalars["Int"];
  averageRating: Maybe<Scalars["Float"]>;
  firstSectionStart: Maybe<Scalars["DateTime"]>;
  futureSections: Scalars["Int"];
  lateMeetings: Scalars["Int"];
  latestSectionStart: Maybe<Scalars["DateTime"]>;
  pastSections: Scalars["Int"];
  totalEnrollments: Scalars["Int"];
  totalMeetings: Scalars["Int"];
  totalReviews: Scalars["Int"];
  totalSections: Scalars["Int"];
  unattendedMeetings: Scalars["Int"];
};

export type SellerOrg = {
  __typename?: "SellerOrg";
  currentUserIsOwner: Maybe<Scalars["Boolean"]>;
  currentUserIsTeacher: Maybe<Scalars["Boolean"]>;
  hasApprovedTeacherAccountForOwner: Maybe<Scalars["Boolean"]>;
  leader_link: Maybe<Scalars["String"]>;
  meetings: MeetingConnection;
  name: Scalars["String"];
  ownsBackgroundChecks: Scalars["Boolean"];
  status: Scalars["String"];
  teacherAccountForOwner: Maybe<User>;
  teachers: Array<User>;
  uid: Scalars["ID"];
  userIsOwner: Maybe<Scalars["Boolean"]>;
};

export type SellerOrgMeetingsArgs = {
  endAfter?: InputMaybe<Scalars["DateTime"]>;
  endBefore?: InputMaybe<Scalars["DateTime"]>;
  startAfter?: InputMaybe<Scalars["DateTime"]>;
  startBefore?: InputMaybe<Scalars["DateTime"]>;
};

export type PublicReview = {
  __typename?: "PublicReview";
  activity: Maybe<Activity>;
  activity_star_rating: Maybe<Scalars["Int"]>;
  attachments: Array<Attachment>;
  created_at: Scalars["DateTime"];
  currentUserCanEdit: Scalars["Boolean"];
  currentUserCanRespond: Scalars["Boolean"];
  currentUserMarkedHelpful: Maybe<Scalars["Boolean"]>;
  details: Maybe<PublicReviewDetails>;
  markedHelpfulCount: Scalars["Int"];
  parent: Parent;
  section_uid: Scalars["ID"];
  teacher_responded_at: Maybe<Scalars["DateTime"]>;
  teacher_response: Maybe<Scalars["String"]>;
  uid: Scalars["ID"];
};

export type PublicReviewDetails = {
  __typename?: "PublicReviewDetails";
  publicReview: Maybe<Scalars["String"]>;
};

export type Feedback = {
  __typename?: "Feedback";
  activity_star_rating: Maybe<Scalars["Int"]>;
  attachments: Array<Attachment>;
  createdAt: Scalars["DateTime"];
  currentUserCanEdit: Scalars["Boolean"];
  leaderFeedback: Maybe<Scalars["String"]>;
  learners: Array<Learner>;
  outschool_rating: Maybe<Scalars["Int"]>;
  outschoolFeedback: Maybe<Scalars["String"]>;
  parent: Parent;
  publicReview: Maybe<Scalars["String"]>;
  section: Section;
  /** @deprecated replaced with section */
  section_uid: Scalars["ID"];
  uid: Scalars["ID"];
};

export type TeacherTagsConnection = Connection & {
  __typename?: "TeacherTagsConnection";
  edges: Array<TeacherTagEdge>;
  pageInfo: PageInfo;
};

export type TeacherTagEdge = Edge & {
  __typename?: "TeacherTagEdge";
  cursor: Scalars["String"];
  node: Tag;
};

export type Tag = Node & {
  __typename?: "Tag";
  key: Scalars["String"];
  uid: Scalars["ID"];
  value: Scalars["String"];
};

export type SimilarListings = {
  __typename?: "SimilarListings";
  /** @deprecated Use popularElasticsearch */
  popular: Array<Listing>;
  /** Searches for popular classes via Elasticsearch */
  popularElasticsearch: Array<Listing>;
  popularMBA: Array<Listing>;
  sameTeacher: Array<Listing>;
  tfidf: Array<Listing>;
  upsellClubs: Array<Listing>;
};

export type SimilarListingsTfidfArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
};

export type SimilarListingsUpsellClubsArgs = {
  limit: Scalars["Int"];
};

export enum SavedClassListStatus {
  LearnerProfile = "learnerProfile",
  Private = "private",
  Unlisted = "unlisted",
}

export type Child = {
  __typename?: "Child";
  age: Maybe<Scalars["Int"]>;
  attributes: Array<EntityAttribute>;
  avatar: Maybe<Scalars["String"]>;
  birthMonth: Maybe<Scalars["Int"]>;
  birthYear: Maybe<Scalars["Int"]>;
  /** @deprecated Use birthMonth & birthYear */
  date_of_birth: Maybe<Scalars["DateTime"]>;
  deleted_at: Maybe<Scalars["DateTime"]>;
  email: Maybe<Scalars["String"]>;
  email_confirmed_at: Maybe<Scalars["DateTime"]>;
  entityAvailabilitySchedule: Maybe<EntityAvailabilitySchedule>;
  hasPassword: Scalars["Boolean"];
  /** @deprecated Use recommendedCategories instead */
  interestTopicCategories: Array<CategoryPage>;
  interestTopics: TopicsConnection;
  intro: Maybe<Scalars["String"]>;
  isLoginEnabled: Scalars["Boolean"];
  name: Maybe<Scalars["String"]>;
  optedOutOfPersonalizedRecommendationsAt: Maybe<Scalars["DateTime"]>;
  pronoun: Maybe<Scalars["String"]>;
  raceEthnicity: Array<RaceEthnicityType>;
  recommendedCategories: Array<CategoryPage>;
  uid: Scalars["ID"];
  unlockedAvatars: Array<Scalars["String"]>;
  username: Maybe<Scalars["String"]>;
};

export type ChildInterestTopicsArgs = {
  cursor?: InputMaybe<TopicsConnectionCursorInput>;
};

export type EntityAttribute = {
  __typename?: "EntityAttribute";
  entity_type: Scalars["String"];
  entity_uid: Scalars["ID"];
  key: Scalars["String"];
  source: Maybe<Scalars["String"]>;
  uid: Scalars["ID"];
  value: Scalars["String"];
};

export type EntityAvailabilitySchedule = {
  __typename?: "EntityAvailabilitySchedule";
  entityAvailabilities: Array<EntityAvailability>;
  entityAvailability: Array<EntityAvailability>;
  entityType: EntityAvailabilityEntityType;
  entityUid: Scalars["ID"];
  uid: Scalars["ID"];
};

export type EntityAvailability = {
  __typename?: "EntityAvailability";
  day: Scalars["Int"];
  endHour: Scalars["Int"];
  endMinute: Scalars["Int"];
  entityAvailabilityScheduleUid: Scalars["ID"];
  startHour: Scalars["Int"];
  startMinute: Scalars["Int"];
  uid: Scalars["ID"];
};

export enum EntityAvailabilityEntityType {
  Learner = "learner",
}

export type CategoryPage = {
  __typename?: "CategoryPage";
  ancestors: Maybe<Array<Maybe<CategoryPage>>>;
  basePath: Scalars["String"];
  carouselSubcategories: Maybe<Array<Maybe<CategoryPage>>>;
  children: Maybe<Array<Maybe<CategoryPage>>>;
  description: Maybe<Scalars["String"]>;
  grandparent: Maybe<CategoryPage>;
  name: Scalars["String"];
  nameSentenceCased: Scalars["String"];
  nameTitleCased: Scalars["String"];
  parent: Maybe<CategoryPage>;
  parentalSiblings: Maybe<Array<Maybe<CategoryPage>>>;
  related: Maybe<Array<Maybe<CategoryPage>>>;
  reviewTitle: Scalars["String"];
  reviewTitleWithPhotos: Scalars["String"];
  searchFilters: Maybe<SavedSearchFilters>;
  siblings: Maybe<Array<Maybe<CategoryPage>>>;
  slug: Scalars["String"];
  title: Maybe<Scalars["String"]>;
  uid: Scalars["ID"];
};

export type SavedSearchFilters = {
  __typename?: "SavedSearchFilters";
  adminTags: Maybe<Array<Scalars["String"]>>;
  age: Maybe<Scalars["String"]>;
  capacityMax: Maybe<Scalars["Int"]>;
  capacityMin: Maybe<Scalars["Int"]>;
  curriculums: Maybe<Array<Scalars["String"]>>;
  delivery: Maybe<Scalars["String"]>;
  dow: Maybe<Scalars["String"]>;
  enabledBooleanFilters: Maybe<Scalars["String"]>;
  endBy: Maybe<Scalars["String"]>;
  endByTime: Maybe<Scalars["Float"]>;
  englishProficiencyLevel: Maybe<EnglishProficiencyLevel>;
  format: Maybe<Scalars["String"]>;
  fundingPrograms: Maybe<Array<Scalars["String"]>>;
  gradeLevel: Maybe<Scalars["String"]>;
  hasFilledOutUniqueLearningNeeds: Maybe<Scalars["Boolean"]>;
  includeInProgressFixedLengthLiveFormat: Maybe<Scalars["Boolean"]>;
  languageOfInstruction: Maybe<Scalars["String"]>;
  multiTermQuery: Maybe<Array<Scalars["String"]>>;
  order: Maybe<Scalars["String"]>;
  pricePerMeetingMax: Maybe<Scalars["Float"]>;
  pricePerMeetingMin: Maybe<Scalars["Float"]>;
  q: Maybe<Scalars["String"]>;
  standards: Maybe<Array<Scalars["String"]>>;
  startAfter: Maybe<Scalars["String"]>;
  startAfterTime: Maybe<Scalars["Float"]>;
  startBefore: Maybe<Scalars["String"]>;
  theme: Maybe<Scalars["String"]>;
  time: Maybe<Scalars["String"]>;
  timeOfDay: Maybe<Scalars["String"]>;
  timeZone: Maybe<Scalars["String"]>;
  uniqueLearningNeeds: Maybe<Scalars["String"]>;
  userName: Maybe<Scalars["String"]>;
  userUid: Maybe<Scalars["String"]>;
};

export type TopicsConnectionCursorInput = {
  created_at: Scalars["DateTime"];
  popularity: Scalars["Int"];
  uid: Scalars["ID"];
};

export type TopicsConnection = {
  __typename?: "TopicsConnection";
  /** A string if there are more records, null otherwise */
  cursor: Maybe<TopicsConnectionCursor>;
  /** Topics for this page */
  topics: Array<Topic>;
};

export type TopicsConnectionCursor = {
  __typename?: "TopicsConnectionCursor";
  created_at: Scalars["DateTime"];
  popularity: Scalars["Int"];
  uid: Scalars["ID"];
};

export type Topic = {
  __typename?: "Topic";
  /** topic taxonomy main category */
  category: Maybe<Scalars["String"]>;
  /** URL of image for Topic */
  image_url: Scalars["String"];
  /** Short name of Topic */
  label: Scalars["String"];
  /** Unique ID for Topic */
  uid: Scalars["ID"];
};

export enum RaceEthnicityType {
  African = "AFRICAN",
  AfricanAmericanOrCaribbean = "AFRICAN_AMERICAN_OR_CARIBBEAN",
  ApacHawaiian = "APAC_HAWAIIAN",
  EastAsian = "EAST_ASIAN",
  European = "EUROPEAN",
  HispanicLatin = "HISPANIC_LATIN",
  IndigenousAmerican = "INDIGENOUS_AMERICAN",
  MiddleEastern = "MIDDLE_EASTERN",
  Other = "OTHER",
  PreferNotToAnswer = "PREFER_NOT_TO_ANSWER",
  SouthAsian = "SOUTH_ASIAN",
  SoutheastAsian = "SOUTHEAST_ASIAN",
  White = "WHITE",
}

export type Recording = {
  endTime: Scalars["DateTime"];
  meeting: Maybe<Meeting>;
  playUrl: Maybe<Scalars["String"]>;
  recordingId: Scalars["ID"];
  startTime: Scalars["DateTime"];
  uid: Scalars["ID"];
};

export type MeetingConflictInfo = {
  __typename?: "MeetingConflictInfo";
  conflictingSectionUid: Scalars["String"];
  message: Scalars["String"];
};

export type Holiday = {
  __typename?: "Holiday";
  name: Scalars["String"];
};

/** Type of enrollment/order payment. Keep this in sync with knex migration constraint. */
export enum TenderType {
  AppleIap = "apple_iap",
  ClassWallet = "class_wallet",
  Coupon = "coupon",
  FundingProgramInvoice = "funding_program_invoice",
  PricingOffer = "pricing_offer",
  PurchaseOrder = "purchase_order",
  StoredCredit = "stored_credit",
  Stripe = "stripe",
  StripeCoupon = "stripe_coupon",
  SubscriptionCredit = "subscription_credit",
}

export enum RecordingAvailability {
  Hidden = "hidden",
  Processing = "processing",
  Requestable = "requestable",
  Requested = "requested",
  Shown = "shown",
  Unavailable = "unavailable",
}

export type RecordingRequest = {
  __typename?: "RecordingRequest";
  message: Scalars["String"];
  requestedAt: Scalars["DateTime"];
  requestedBy: Learner;
};

export type RecurringSchedule = {
  __typename?: "RecurringSchedule";
  end_at: Maybe<Scalars["DateTime"]>;
  uid: Scalars["ID"];
};

export type MeetingRequest = Node & {
  __typename?: "MeetingRequest";
  /**
   * When payment for the meeting request is due.
   * This is the start of the ISO week for the meeting request.
   * If billedAt is before now, then payment is due immediately
   */
  billedOn: Scalars["DateTime"];
  /** When the meeting request was created */
  created_at: Scalars["DateTime"];
  /** When the meeting ends */
  end_time: Scalars["DateTime"];
  /** When the request expires */
  expires_at: Scalars["DateTime"];
  /**
   * If true, the reschedule request reschedules all future recurring meetings
   * in the same schedule.  If false, only reschedules the current meeting.
   * Always false for non-reschedule requests right now.
   */
  is_recurring: Scalars["Boolean"];
  /** The message the teacher put in */
  message: Maybe<Scalars["String"]>;
  /**
   * When the meeting request is from a seller org,
   * the meeting request will have an org teacher assigned
   */
  orgTeacher: Maybe<Leader>;
  /** When the meeting starts */
  start_time: Scalars["DateTime"];
  /** The status of the meeting request. */
  status: MeetingRequestStatus;
  uid: Scalars["ID"];
};

export enum MeetingRequestStatus {
  /** The request has been approved by the parent */
  Confirmed = "Confirmed",
  /** The request has been declined by the parent */
  Declined = "Declined",
  /** The request expired before parent approved or declined */
  Expired = "Expired",
  /** The request must be approved by the parent */
  Pending = "Pending",
}

export enum MeetingStartStatus {
  LateNoShow = "LateNoShow",
  NotStarted = "NotStarted",
  StartedLate = "StartedLate",
  StartedOnTime = "StartedOnTime",
  Unknown = "Unknown",
}

export type TeacherAttendance = {
  __typename?: "TeacherAttendance";
  attendedAt: Scalars["DateTime"];
  /** @deprecated Use attendedAt */
  created_at: Scalars["DateTime"];
  meeting: Meeting;
  uid: Scalars["ID"];
};

export type Unit = {
  __typename?: "Unit";
  learning_syllabus_uid: Scalars["ID"];
  title: Maybe<Scalars["String"]>;
  uid: Scalars["ID"];
  unitNumber: Maybe<Scalars["Int"]>;
};

export type SectionChecklist = {
  __typename?: "SectionChecklist";
  autoCanceledAt: Maybe<Scalars["DateTime"]>;
  grandfatheredAt2017Rate: Maybe<Scalars["Boolean"]>;
};

/**
 * this entity represents a section level entity that is built
 * from the idea of a lesson. in practice, this entity can either
 * have a lesson or a meeting associated with it at all times
 * beyond that, it tells us the ordering within sections.
 *
 * this entity was created because from the learner's perspective there
 * are 3 things that can happen in a section:
 * 1. a lesson is scheduled but there are no meetings
 * 2. a lesson is scheduled and there are meetings
 * 3. a lesson is not scheduled but there are meetings
 */
export type SectionLesson = {
  __typename?: "SectionLesson";
  assignmentClassPosts: Array<AssignmentClassPost>;
  classPosts: Array<ClassPost>;
  /** Lesson.description if it exists */
  description: Maybe<Scalars["String"]>;
  /** Whether the meeting.deleted_at is set */
  isCancelled: Scalars["Boolean"];
  /** Whether a lesson is completed or not, for Meeting Types this is based on the meeting being attended */
  isCompleted: Scalars["Boolean"];
  /**
   * Meetings take precedence in figuring out locking order - is the meeting in the current iso week or before?
   * if there's no meeting, then we fall back to syllabus order
   */
  isLocked: Scalars["Boolean"];
  lesson: Maybe<Lesson>;
  /** Lesson.lessonStatus if it exists or "Completed" if meeting attended for meeting types */
  lessonStatus: LearningStatementsVerb;
  /** equivalent of join live meeting details used for creating the join meeting button */
  liveMeetingDetails: Maybe<LessonLiveMeeting>;
  meeting: Maybe<Meeting>;
  /** Lesson.order_within_week if it exists */
  order_within_week: Maybe<Scalars["Int"]>;
  /**
   * the ordering within a lesson.
   * for self paced it's always based on syllabus order
   * 1. order based on meeting scheduling
   * 2. emergency cancellations keep their original time
   * 3. when there are no meetings, order is based on syllabus order
   */
  orderInSection: Scalars["Int"];
  /** Meeting.start_time if it exists */
  start_time: Maybe<Scalars["DateTime"]>;
  /** Lesson.title if it exists or "Bonus Meeting" for meetings that do not have a lesson */
  title: Scalars["String"];
  /**
   * refers to the type mentioned above
   * meeting without lesson, lesson without meeting and meeting with lesson
   */
  type: SectionLessonType;
  /** composite key - {lessonUid || "make-up"}_{meetingUid || "no-meeting"} */
  uid: Scalars["ID"];
  unit: Maybe<Unit>;
  /** Lesson.week_number if it exists */
  week_number: Maybe<Scalars["Int"]>;
};

/**
 * this entity represents a section level entity that is built
 * from the idea of a lesson. in practice, this entity can either
 * have a lesson or a meeting associated with it at all times
 * beyond that, it tells us the ordering within sections.
 *
 * this entity was created because from the learner's perspective there
 * are 3 things that can happen in a section:
 * 1. a lesson is scheduled but there are no meetings
 * 2. a lesson is scheduled and there are meetings
 * 3. a lesson is not scheduled but there are meetings
 */
export type SectionLessonIsCompletedArgs = {
  learnerUid?: InputMaybe<Scalars["ID"]>;
};

/**
 * this entity represents a section level entity that is built
 * from the idea of a lesson. in practice, this entity can either
 * have a lesson or a meeting associated with it at all times
 * beyond that, it tells us the ordering within sections.
 *
 * this entity was created because from the learner's perspective there
 * are 3 things that can happen in a section:
 * 1. a lesson is scheduled but there are no meetings
 * 2. a lesson is scheduled and there are meetings
 * 3. a lesson is not scheduled but there are meetings
 */
export type SectionLessonIsLockedArgs = {
  learnerUid?: InputMaybe<Scalars["ID"]>;
};

/**
 * this entity represents a section level entity that is built
 * from the idea of a lesson. in practice, this entity can either
 * have a lesson or a meeting associated with it at all times
 * beyond that, it tells us the ordering within sections.
 *
 * this entity was created because from the learner's perspective there
 * are 3 things that can happen in a section:
 * 1. a lesson is scheduled but there are no meetings
 * 2. a lesson is scheduled and there are meetings
 * 3. a lesson is not scheduled but there are meetings
 */
export type SectionLessonLessonStatusArgs = {
  learnerUid?: InputMaybe<Scalars["ID"]>;
};

export type LessonLiveMeeting = {
  __typename?: "LessonLiveMeeting";
  classRecordingUids: Array<Maybe<Scalars["String"]>>;
  enrollmentUid: Maybe<Scalars["String"]>;
  meetingEndTime: Maybe<Scalars["DateTime"]>;
  meetingsCount: Scalars["Int"];
  meetingStartTime: Maybe<Scalars["DateTime"]>;
  sectionStartTime: Maybe<Scalars["DateTime"]>;
  uid: Scalars["ID"];
  usersCanHaveLiveMeeting: Maybe<Scalars["Boolean"]>;
};

export enum SectionLessonType {
  Lesson = "Lesson",
  Meeting = "Meeting",
  MeetingWithLesson = "MeetingWithLesson",
}

export type GiftEnrollment = BaseEnrollment & {
  __typename?: "GiftEnrollment";
  acceptGiftSlugId: Scalars["String"];
  amount_in_local_currency: Scalars["Int"];
  buyer: Parent;
  canBePaused: Scalars["Boolean"];
  canBeStopped: Scalars["Boolean"];
  canBeWithdrawn: Scalars["Boolean"];
  completedEnrolledWeeks: Scalars["Int"];
  confirmedAt: Maybe<Scalars["DateTime"]>;
  createdAt: Scalars["DateTime"];
  currency_code: CurrencyCode;
  currentUserIsPurchaser: Scalars["Boolean"];
  details: EnrollmentDetails;
  exchange_rate: Scalars["Float"];
  giftClaimedAt: Maybe<Scalars["DateTime"]>;
  hasPaymentSchedule: Scalars["Boolean"];
  isEnded: Scalars["Boolean"];
  isEnrolled: Scalars["Boolean"];
  isPaused: Scalars["Boolean"];
  isWithdrawn: Scalars["Boolean"];
  lastSectionTransfer: Maybe<SectionTransfer>;
  learner: Maybe<Learner>;
  /** @deprecated use ongoingEndTime instead */
  ongoing_end_time: Maybe<Scalars["DateTime"]>;
  /** @deprecated use ongoingStartTime instead */
  ongoing_start_time: Maybe<Scalars["DateTime"]>;
  /** @deprecated use ongoingStoppedAt instead */
  ongoing_stopped_at: Maybe<Scalars["DateTime"]>;
  ongoingEndTime: Maybe<Scalars["DateTime"]>;
  ongoingSectionTransferredFrom: Maybe<SectionTransfer>;
  ongoingStartTime: Maybe<Scalars["DateTime"]>;
  ongoingStoppedAt: Maybe<Scalars["DateTime"]>;
  orderSlugId: Maybe<Scalars["String"]>;
  parent: Maybe<Parent>;
  pauseWindow: Maybe<EnrollmentPauseWindow>;
  payments: Array<EnrollmentPayment>;
  paymentSchedule: Maybe<PaymentSchedule>;
  /** @deprecated use priceCents instead */
  price_cents: Maybe<Scalars["Int"]>;
  priceCents: Maybe<Scalars["Int"]>;
  pricingOffer: Maybe<PricingOffer>;
  purchasedAsGift: Scalars["Boolean"];
  refundableAmountCents: Scalars["Int"];
  refundPolicy: Maybe<RefundPolicy>;
  section: Section;
  service_fee_percentage: Scalars["Float"];
  thisWeekPauseDeadline: Maybe<Scalars["DateTime"]>;
  uid: Scalars["ID"];
  usersCanCommunicate: Scalars["Boolean"];
  /** @deprecated use withdrawnAt instead */
  withdrawn_at: Maybe<Scalars["DateTime"]>;
  withdrawnAt: Maybe<Scalars["DateTime"]>;
};

export enum CurrencyCode {
  Aud = "AUD",
  Cad = "CAD",
  Eur = "EUR",
  Gbp = "GBP",
  Hkd = "HKD",
  Inr = "INR",
  Jpy = "JPY",
  Krw = "KRW",
  Mxn = "MXN",
  Nzd = "NZD",
  Thb = "THB",
  Twd = "TWD",
  Usd = "USD",
}

export type EnrollmentDetails = {
  __typename?: "EnrollmentDetails";
  age: Maybe<Scalars["String"]>;
  attendedAt: Maybe<Scalars["DateTime"]>;
  chaperone: Maybe<Scalars["String"]>;
  esaState: Maybe<Scalars["String"]>;
  message: Maybe<Scalars["String"]>;
  name: Maybe<Scalars["String"]>;
};

export type SectionTransfer = {
  __typename?: "SectionTransfer";
  fromSection: Maybe<Section>;
  timestamp: Scalars["DateTime"];
};

export type EnrollmentPauseWindow = {
  __typename?: "EnrollmentPauseWindow";
  canBeCanceled: Scalars["Boolean"];
  endDate: Scalars["DateTime"];
  enrollment: Enrollment;
  isActive: Scalars["Boolean"];
  pauseReason: Scalars["String"];
  startDate: Scalars["DateTime"];
  uid: Scalars["ID"];
};

export type EnrollmentPayment = {
  __typename?: "EnrollmentPayment";
  amount_cents: Maybe<Scalars["Int"]>;
  amount_credits: Maybe<Scalars["Int"]>;
  coupon_code_uid: Maybe<Scalars["String"]>;
  couponCode: Maybe<CouponCode>;
  created_at: Maybe<Scalars["DateTime"]>;
  credit_account_type: Maybe<Scalars["String"]>;
  credit_transaction_uid: Maybe<Scalars["ID"]>;
  currency_code: Maybe<CurrencyCode>;
  disbursed_at: Maybe<Scalars["DateTime"]>;
  end_time: Maybe<Scalars["DateTime"]>;
  esa_order_id: Maybe<Scalars["String"]>;
  exchange_rate: Maybe<Scalars["Float"]>;
  funding_program_invoice_uid: Maybe<Scalars["String"]>;
  intercom_url: Maybe<Scalars["String"]>;
  invoice_uid: Maybe<Scalars["String"]>;
  isOngoingRefundable: Scalars["Boolean"];
  last4: Maybe<Scalars["String"]>;
  oneOnOneMeeting: Maybe<Meeting>;
  order_slug_id: Maybe<Scalars["String"]>;
  organization: Maybe<Organization>;
  paid_at: Maybe<Scalars["DateTime"]>;
  payout_uid: Maybe<Scalars["ID"]>;
  pricing_offer_uid: Maybe<Scalars["ID"]>;
  pricingOffer: Maybe<PricingOffer>;
  purchase_order_id: Maybe<Scalars["String"]>;
  refunds: Array<EnrollmentPaymentRefund>;
  service_fee_percentage: Maybe<Scalars["Float"]>;
  start_time: Maybe<Scalars["DateTime"]>;
  stripe_charge_id: Maybe<Scalars["String"]>;
  stripePaymentMethod: Maybe<Scalars["String"]>;
  stripeReceiptUrl: Maybe<Scalars["String"]>;
  uid: Scalars["ID"];
  unrefundedCents: Scalars["Int"];
  unrefundedCredits: Maybe<Scalars["Int"]>;
  voucher_name: Maybe<Scalars["String"]>;
};

export type CouponCode = {
  __typename?: "CouponCode";
  code: Scalars["String"];
  coupon: Maybe<Coupon>;
  couponUid: Maybe<Scalars["ID"]>;
  createdBy: Maybe<Scalars["ID"]>;
  deletedAt: Maybe<Scalars["DateTime"]>;
  expiresAt: Maybe<Scalars["DateTime"]>;
  totalRedemption: Maybe<Scalars["Int"]>;
  uid: Scalars["ID"];
};

export type Coupon = {
  __typename?: "Coupon";
  activities: Maybe<Array<Maybe<Activity>>>;
  classFormatsAllowed: Maybe<Array<Maybe<CouponClassFormats>>>;
  couponMaxUsesReached: Maybe<Scalars["Boolean"]>;
  couponType: CouponType;
  createdBy: Maybe<Scalars["ID"]>;
  deletedAt: Maybe<Scalars["DateTime"]>;
  description: Maybe<Scalars["String"]>;
  expiresAt: Maybe<Scalars["DateTime"]>;
  isBuyerFirstPurchaseOfClassFormatOnly: Maybe<Scalars["Boolean"]>;
  isFirstPurchaseOnly: Scalars["Boolean"];
  maxAmountCentsPerUse: Maybe<Scalars["Int"]>;
  maxUses: Maybe<Scalars["Int"]>;
  maxUsesPerUser: Scalars["Int"];
  minPurchaseAmountCents: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  percentOff: Maybe<Scalars["Int"]>;
  startsAt: Maybe<Scalars["DateTime"]>;
  subjectsAllowed: Maybe<Array<Scalars["String"]>>;
  uid: Scalars["ID"];
  userIsEligibleToApplyCoupon: UserIsEligibleToApplyCoupon;
};

export type CouponUserIsEligibleToApplyCouponArgs = {
  userUid?: InputMaybe<Scalars["String"]>;
};

export enum CouponClassFormats {
  Course = "Course",
  EnrichmentOrClub = "EnrichmentOrClub",
  Group = "Group",
  MultiDay = "MultiDay",
  OneOnOne = "OneOnOne",
  OneTime = "OneTime",
  Ongoing = "Ongoing",
  SelfPaced = "SelfPaced",
  Semester = "Semester",
}

export enum CouponType {
  Outschool = "outschool",
  Teacher = "teacher",
}

export type UserIsEligibleToApplyCoupon = {
  __typename?: "UserIsEligibleToApplyCoupon";
  isEligible: Scalars["Boolean"];
  reason: Scalars["String"];
};

export type Organization = {
  __typename?: "Organization";
  address: Maybe<Scalars["String"]>;
  deletedAt: Maybe<Scalars["DateTime"]>;
  invoiceEmail: Maybe<Scalars["String"]>;
  isEsaOrMicrogrant: Maybe<Scalars["Boolean"]>;
  isOrgCommunityPartner: Maybe<Scalars["Boolean"]>;
  logoUrl: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  notes: Maybe<Scalars["String"]>;
  pricingOffers: Array<PricingOffer>;
  state: Maybe<Scalars["String"]>;
  techSupportEmail: Maybe<Scalars["String"]>;
  totalCapCents: Maybe<Scalars["Int"]>;
  trustSafetyEmail: Maybe<Scalars["String"]>;
  type: OrganizationType;
  uid: Scalars["ID"];
  urlName: Maybe<Scalars["String"]>;
};

export type OrganizationPricingOffersArgs = {
  filter?: InputMaybe<PricingOfferAdminFilter>;
};

export enum PricingOfferAdminFilter {
  Active = "Active",
  All = "All",
  Deleted = "Deleted",
}

export type PricingOffer = {
  __typename?: "PricingOffer";
  buyerOrgName: Maybe<Scalars["String"]>;
  deletedAt: Maybe<Scalars["DateTime"]>;
  discount: Maybe<Scalars["Float"]>;
  eligibilityLastUpdatedAt: Maybe<Scalars["DateTime"]>;
  eligibleEmailDomains: Array<Scalars["String"]>;
  endBy: Scalars["DateTime"];
  excludedEmailAddresses: Array<Scalars["String"]>;
  includedEmailAddresses: Array<Scalars["String"]>;
  isFinancialAid: Scalars["Boolean"];
  isOfferLimitReached: Scalars["Boolean"];
  isTotalCapReached: Scalars["Boolean"];
  name: Scalars["String"];
  offerRemainingCapCents: Maybe<Scalars["Int"]>;
  organization: Maybe<Organization>;
  organizationUid: Maybe<Scalars["ID"]>;
  /** @deprecated Partial payments */
  purchaseLimitCents: Maybe<Scalars["Int"]>;
  remainingCapCents: Maybe<Scalars["Int"]>;
  requirePaymentMethod: Scalars["Boolean"];
  startAfter: Scalars["DateTime"];
  totalCapCents: Scalars["Int"];
  uid: Scalars["ID"];
  userLimitCents: Scalars["Int"];
  userOfferEmail: Maybe<Scalars["String"]>;
};

export enum OrganizationType {
  CharterSchool = "charter_school",
  CommunityPartner = "community_partner",
  Company = "company",
  CreativeSchoolingOption = "creative_schooling_option",
  EsaOrMicrogrant = "esa_or_microgrant",
  OrgCommunityPartner = "org_community_partner",
  School = "school",
}

export type EnrollmentPaymentRefund = {
  __typename?: "EnrollmentPaymentRefund";
  addOutschoolCreditTransaction: Maybe<CreditTransaction>;
  amount_cents: Scalars["Int"];
  amount_credits: Maybe<Scalars["Int"]>;
  amount_in_local_currency: Maybe<Scalars["Int"]>;
  currency_code: Maybe<CurrencyCode>;
  exchange_rate: Maybe<Scalars["Float"]>;
  note: Maybe<Scalars["String"]>;
  reason: Maybe<Scalars["String"]>;
  reduces_teacher_payout: Scalars["Boolean"];
  refunded_at: Maybe<Scalars["DateTime"]>;
  uid: Scalars["ID"];
  user_role: Maybe<Scalars["String"]>;
  user_uid: Scalars["ID"];
};

export type CreditTransaction = {
  __typename?: "CreditTransaction";
  admin_note: Maybe<Scalars["String"]>;
  amount_cents: Scalars["Int"];
  amount_credits: Scalars["Int"];
  canceled_at: Maybe<Scalars["DateTime"]>;
  captured_at: Maybe<Scalars["DateTime"]>;
  created_at: Scalars["DateTime"];
  credit_account_uid: Scalars["ID"];
  expiresAt: Maybe<Scalars["DateTime"]>;
  order_slug_id: Maybe<Scalars["String"]>;
  redeemedGiftCard: Maybe<GiftCard>;
  referralTransactionType: Maybe<CreditReferralTransactionType>;
  referredUser: Maybe<User>;
  referringUser: Maybe<User>;
  transaction_type: CreditTransactionType;
  uid: Scalars["ID"];
  unspentCents: Maybe<Scalars["Int"]>;
  updated_at: Scalars["DateTime"];
};

export type GiftCard = {
  __typename?: "GiftCard";
  amountCents: Scalars["Int"];
  confirmedAt: Maybe<Scalars["DateTime"]>;
  createdAt: Scalars["DateTime"];
  deletedAt: Maybe<Scalars["DateTime"]>;
  deliverAfter: Scalars["DateTime"];
  deliveredAt: Maybe<Scalars["DateTime"]>;
  deliveryTimezone: Scalars["String"];
  isCancelable: Scalars["Boolean"];
  isEditable: Scalars["Boolean"];
  note: Maybe<Scalars["String"]>;
  orderSlugId: Scalars["String"];
  payments: Array<OrderPayment>;
  /** @deprecated use purchaserName/Email */
  purchaser: GiftCardPurchaser;
  purchaserEmail: Scalars["String"];
  purchaserName: Scalars["String"];
  recipientEmail: Scalars["String"];
  recipientName: Scalars["String"];
  redeemedAt: Maybe<Scalars["DateTime"]>;
  redeemerUid: Maybe<Scalars["String"]>;
  redemptionCode: Maybe<Scalars["String"]>;
  status: GiftCardStatus;
  uid: Scalars["ID"];
  updatedAt: Scalars["DateTime"];
  userUid: Scalars["ID"];
};

export type OrderPayment = {
  __typename?: "OrderPayment";
  amount_cents: Scalars["Int"];
  amount_credits: Maybe<Scalars["Int"]>;
  last4: Maybe<Scalars["String"]>;
  order_slug_id: Maybe<Scalars["ID"]>;
  tender_type: Maybe<Scalars["String"]>;
  uid: Scalars["ID"];
};

export type GiftCardPurchaser = {
  __typename?: "GiftCardPurchaser";
  email: Scalars["String"];
  name: Scalars["String"];
};

export enum GiftCardStatus {
  Canceled = "Canceled",
  Incomplete = "Incomplete",
  Purchased = "Purchased",
  Redeemed = "Redeemed",
  Sent = "Sent",
}

export enum CreditReferralTransactionType {
  UserWasReferred = "UserWasReferred",
  UserWasReferrer = "UserWasReferrer",
}

export enum CreditTransactionType {
  Adjustment = "adjustment",
  Admin = "admin",
  AllowanceWithdrawal = "allowance_withdrawal",
  Purchase = "purchase",
  Redemption = "redemption",
  Referral = "referral",
  Refund = "refund",
  Subscription = "subscription",
  SubscriptionDiscardCancelation = "subscription_discard_cancelation",
  SubscriptionDiscardRollover = "subscription_discard_rollover",
  Urbansitter = "urbansitter",
  Voucher = "voucher",
}

export type PaymentSchedule = {
  __typename?: "PaymentSchedule";
  amountCents: Maybe<Scalars["Int"]>;
  createdAt: Scalars["DateTime"];
  endTime: Maybe<Scalars["DateTime"]>;
  nextBillingDate: Scalars["DateTime"];
  payments: Array<PaymentSchedulePayment>;
  scheduleType: PaymentScheduleTypes;
  startTime: Scalars["DateTime"];
  status: PaymentScheduleStatus;
  stoppedAt: Maybe<Scalars["DateTime"]>;
  stoppedReason: Maybe<Scalars["String"]>;
  uid: Scalars["ID"];
  updatedAt: Scalars["DateTime"];
};

export type PaymentSchedulePayment = {
  __typename?: "PaymentSchedulePayment";
  amountCents: Scalars["Int"];
  canceledAt: Scalars["DateTime"];
  confirmedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  orderSlugId: Scalars["String"];
  periodEndTime: Maybe<Scalars["DateTime"]>;
  periodStartTime: Scalars["DateTime"];
  schedule: PaymentSchedule;
  uid: Scalars["ID"];
  updatedAt: Scalars["DateTime"];
};

export enum PaymentScheduleTypes {
  MonthlyFirst = "MonthlyFirst",
  WeeklySunday = "WeeklySunday",
}

export enum PaymentScheduleStatus {
  AlreadyPaid = "AlreadyPaid",
  AwaitingPayment = "AwaitingPayment",
  Ended = "Ended",
  NotYetStarted = "NotYetStarted",
}

export type RefundPolicy = {
  __typename?: "RefundPolicy";
  creditDescription: Maybe<Scalars["String"]>;
  description: Scalars["String"];
  name: Scalars["String"];
  type: RefundPolicyType;
};

export enum RefundPolicyType {
  Flexible = "flexible",
  Limited = "limited",
  Moderate = "moderate",
  Variable = "variable",
}

export type SectionDetails = {
  __typename?: "SectionDetails";
  autoScheduledByUserUid: Maybe<Scalars["ID"]>;
  autoScheduledDraft: Maybe<Scalars["Boolean"]>;
  onlineClassroom: Maybe<OnlineClassroom>;
  originalEndTime: Maybe<Scalars["DateTime"]>;
};

export type OnlineClassroom = {
  __typename?: "OnlineClassroom";
  type: Maybe<Scalars["String"]>;
  url: Maybe<Scalars["String"]>;
};

export enum EnrollmentFilter {
  OneOnOne = "OneOnOne",
}

export enum EnrollmentStatus {
  Absent = "Absent",
  Attended = "Attended",
  Confirmed = "Confirmed",
  Ended = "Ended",
  Enrolled = "Enrolled",
  Paused = "Paused",
  Waitlisted = "Waitlisted",
  Withdrawn = "Withdrawn",
}

export type EnrollmentsForAdmin = {
  __typename?: "EnrollmentsForAdmin";
  data: Array<Enrollment>;
  total: Scalars["Int"];
};

export type FeedbackForLeader = {
  __typename?: "FeedbackForLeader";
  activity_star_rating: Maybe<Scalars["Int"]>;
  buyerNew: Parent;
  created_at: Scalars["DateTime"];
  details: FeedbackForLeaderDetails;
  outschool_rating: Maybe<Scalars["Int"]>;
  uid: Scalars["ID"];
};

export type FeedbackForLeaderDetails = {
  __typename?: "FeedbackForLeaderDetails";
  leaderFeedback: Maybe<Scalars["String"]>;
};

export type Location = {
  __typename?: "Location";
  address: Maybe<Scalars["String"]>;
  city: Maybe<Scalars["String"]>;
  lat: Maybe<Scalars["Float"]>;
  lng: Maybe<Scalars["Float"]>;
  placeDescription: Maybe<Scalars["String"]>;
  placeName: Maybe<Scalars["String"]>;
  state: Maybe<Scalars["String"]>;
  timeZone: Maybe<Scalars["String"]>;
};

export type SectionMeetingConflictInfo = {
  __typename?: "SectionMeetingConflictInfo";
  conflictingSectionUid: Scalars["String"];
  meetingUid: Scalars["String"];
  message: Scalars["String"];
};

export type MeetingRequestFilters = {
  /**
   * Only return meeting requests whose start_time is after Date.
   * Defaults to "now()"
   */
  startAfter?: InputMaybe<Scalars["DateTime"]>;
  /**
   * Only return meeting requests whose start_time before Date
   * Defaults to no filter
   */
  startBefore?: InputMaybe<Scalars["DateTime"]>;
  /**
   * Only return meetings whose status matches this.
   * N.B., Meetings are always "confirmed" so other statuses will exclude all Meetings
   */
  status?: InputMaybe<MeetingRequestStatus>;
};

export enum MeetingFilter {
  All = "All",
  Enrolled = "Enrolled",
}

export type MeetingTime = {
  __typename?: "MeetingTime";
  /** 0 (Sunday) to 6 (Saturday) */
  dayOfWeek: Scalars["Int"];
  /** 0 (midnight) to 23 (11PM) */
  hour: Scalars["Int"];
  /** 0 to 59 */
  minute: Scalars["Int"];
};

export type Message = IMessage & {
  __typename?: "Message";
  action: Maybe<Scalars["String"]>;
  activity_uid: Maybe<Scalars["ID"]>;
  content: Maybe<Scalars["String"]>;
  created_at: Scalars["DateTime"];
  messageContent: Maybe<MessageContent>;
  participants: Array<Participant>;
  section_uid: Maybe<Scalars["ID"]>;
  sender: Sender;
  subject: Maybe<Scalars["String"]>;
  thread_uid: Scalars["ID"];
  uid: Scalars["ID"];
};

export type IMessage = {
  activity_uid: Maybe<Scalars["ID"]>;
  content: Maybe<Scalars["String"]>;
  participants: Array<Participant>;
  section_uid: Maybe<Scalars["ID"]>;
  subject: Maybe<Scalars["String"]>;
  thread_uid: Scalars["ID"];
  uid: Scalars["ID"];
};

export type Participant = {
  __typename?: "Participant";
  activitiesWithCurrentUser: Maybe<Array<Activity>>;
  avatar: Maybe<Scalars["String"]>;
  headline: Maybe<Scalars["String"]>;
  isLearner: Scalars["Boolean"];
  leader_link: Maybe<Scalars["String"]>;
  name: Maybe<Scalars["String"]>;
  ownerOfSellerOrg: Maybe<SellerOrg>;
  parent: Maybe<Parent>;
  photo: Maybe<Scalars["String"]>;
  /** This participant's role in this thread; currently only calculated for PrivateClassMessages */
  role: Maybe<ParticipantRole>;
  teacherForSellerOrg: Maybe<SellerOrg>;
  timeZone: Maybe<Scalars["String"]>;
  uid: Scalars["ID"];
};

export enum ParticipantRole {
  /** The learner */
  Learner = "Learner",
  /** None of the above; possibly an org Group moderator */
  Other = "Other",
  /** The parent */
  Parent = "Parent",
  /** The owner of the seller org this message's section belongs to, if any */
  SellerOrgOwner = "SellerOrgOwner",
  /** The teacher of this message's section */
  Teacher = "Teacher",
}

export type Sender = {
  __typename?: "Sender";
  headline: Maybe<Scalars["String"]>;
  leader_link: Maybe<Scalars["String"]>;
  name: Maybe<Scalars["String"]>;
  photo: Maybe<Scalars["String"]>;
  timeZone: Maybe<Scalars["String"]>;
  uid: Scalars["ID"];
};

export type Payout = {
  __typename?: "Payout";
  amount_cents: Maybe<Scalars["Int"]>;
  created_at: Maybe<Scalars["DateTime"]>;
  numberOfEnrollments: Maybe<Scalars["Int"]>;
  startTime: Maybe<Scalars["DateTime"]>;
  uid: Maybe<Scalars["String"]>;
};

export type EnrollmentMeetingPurchaseInfoForOrderSlug = {
  __typename?: "EnrollmentMeetingPurchaseInfoForOrderSlug";
  confirmedAt: Maybe<Scalars["DateTime"]>;
  createdAt: Scalars["DateTime"];
  endTime: Scalars["DateTime"];
  orderSlugId: Scalars["String"];
  startTime: Scalars["DateTime"];
  weeks: Scalars["Int"];
};

export type EnrollmentMeetingPurchase = {
  __typename?: "EnrollmentMeetingPurchase";
  activity: Maybe<Activity>;
  activityUid: Maybe<Scalars["ID"]>;
  confirmedAt: Maybe<Scalars["DateTime"]>;
  createdAt: Scalars["DateTime"];
  deletedAt: Scalars["DateTime"];
  endTime: Scalars["DateTime"];
  enrollmentUid: Maybe<Scalars["ID"]>;
  meetingUid: Maybe<Scalars["ID"]>;
  orderSlugId: Scalars["String"];
  startTime: Scalars["DateTime"];
  uid: Scalars["ID"];
  updatedAt: Scalars["DateTime"];
};

export type Period = {
  __typename?: "Period";
  endOfIsoWeek: Scalars["DateTime"];
  startOfIsoWeek: Scalars["DateTime"];
};

export type CourseProgress = {
  __typename?: "CourseProgress";
  currentWeek: Scalars["Int"];
  nextMeeting: Maybe<Meeting>;
  pastMeetingCount: Scalars["Int"];
  remainingMeetingCount: Scalars["Int"];
  totalMeetingCount: Scalars["Int"];
};

export type Syllabus = {
  __typename?: "Syllabus";
  /**
   * uid of the current lesson for the given learner in the given section.
   * This will be the most recent lesson that is not completed.
   */
  currentLesson: Maybe<Lesson>;
  deleted_at: Maybe<Scalars["String"]>;
  isFrozen: Scalars["Boolean"];
  lessons: Array<Lesson>;
  /** How many versions of this syllabus exist.  Always >= 1 */
  numVersions: Scalars["Int"];
  published_at: Maybe<Scalars["String"]>;
  uid: Scalars["ID"];
  units: Array<Unit>;
};

export type SyllabusCurrentLessonArgs = {
  learnerUid?: InputMaybe<Scalars["ID"]>;
  sectionUid: Scalars["ID"];
};

export type WaitlistSeat = {
  __typename?: "WaitlistSeat";
  attribution: Maybe<Scalars["String"]>;
  createdAt: Maybe<Scalars["Date"]>;
  deletedAt: Maybe<Scalars["Date"]>;
  enrollment: Maybe<Enrollment>;
  formerLearner: Maybe<Scalars["Boolean"]>;
  learner: Learner;
  parent: Parent;
  previouslyEnrolled: Maybe<Scalars["Boolean"]>;
  section: Section;
  uid: Scalars["ID"];
  waitlistSource: Maybe<WaitlistSource>;
};

export enum WaitlistSource {
  UnpauseFailure = "unpause_failure",
  UserInitiated = "user_initiated",
}

export type ZoomMeeting = {
  __typename?: "ZoomMeeting";
  zoom_id: Maybe<Scalars["String"]>;
};

export type FundingProgramInvoice = {
  __typename?: "FundingProgramInvoice";
  awardId: Scalars["String"];
  childName: Scalars["String"];
  createdAt: Scalars["String"];
  /** @deprecated Use uid instead */
  id: Scalars["ID"];
  orderSlugId: Scalars["String"];
  parentName: Scalars["String"];
  uid: Scalars["ID"];
  updatedAt: Scalars["String"];
};

export type Sibling = {
  __typename?: "Sibling";
  currentOrLastSection: Section;
  learner: Learner;
};

export type UpcomingCharge = {
  __typename?: "UpcomingCharge";
  date: Scalars["DateTime"];
  priceCents: Scalars["Int"];
  priceCredits: Scalars["Int"];
};

export type AdminUserAction = {
  __typename?: "AdminUserAction";
  action: Scalars["String"];
  admin: User;
  created_at: Scalars["DateTime"];
  customValue: Maybe<Scalars["String"]>;
  notes: Maybe<Scalars["String"]>;
  uid: Scalars["ID"];
  user: User;
};

export type AdminUserIncident = {
  __typename?: "AdminUserIncident";
  admin: User;
  category: Scalars["String"];
  created_at: Scalars["DateTime"];
  notes: Maybe<Scalars["String"]>;
  uid: Scalars["ID"];
  updated_at: Scalars["DateTime"];
  user: User;
};

export type ApprovalCriterion = {
  __typename?: "ApprovalCriterion";
  description: Scalars["String"];
  value: ApprovalCriterionValue;
};

export enum ApprovalCriterionValue {
  Negative = "negative",
  Neutral = "neutral",
  Positive = "positive",
}

export enum Audience {
  Learners = "learners",
  Parents = "parents",
}

export type ConversationFiltersInput = {
  activityUid?: InputMaybe<Scalars["ID"]>;
  participantUids?: InputMaybe<Array<Scalars["ID"]>>;
  searchTerms?: InputMaybe<Array<Scalars["String"]>>;
};

export enum MessageSelection {
  All = "all",
  Spam = "spam",
  Unread = "unread",
}

export type IMessageThread = {
  activity: Maybe<Activity>;
  isRead: Scalars["Boolean"];
  matchedSearchResults: Maybe<Array<ConversationSearchResult>>;
  messages: Array<IMessage>;
  participants: Array<Participant>;
  section: Maybe<Section>;
  uid: Scalars["ID"];
};

export type ConversationSearchResult = {
  __typename?: "ConversationSearchResult";
  highlightedMatch: Scalars["String"];
  threadUid: Scalars["ID"];
};

export type CreditAccount = {
  __typename?: "CreditAccount";
  accountType: Maybe<CreditAccountType>;
  balance: Scalars["Int"];
  openToBuy: Scalars["Int"];
  status: Maybe<CreditAccountStatus>;
  uid: Scalars["ID"];
  updatedAt: Maybe<Scalars["DateTime"]>;
};

export enum CreditAccountType {
  OsCash = "OSCash",
  Subscription = "Subscription",
}

export enum CreditAccountStatus {
  Available = "Available",
  InvalidSharedPaymentMethod = "InvalidSharedPaymentMethod",
  RequiresConfirmedPrimaryEmail = "RequiresConfirmedPrimaryEmail",
}

export type UserDetails = {
  __typename?: "UserDetails";
  about: Maybe<Scalars["String"]>;
  adminDashboardUrl: Maybe<Scalars["String"]>;
  browserTimeZone: Maybe<Scalars["String"]>;
  countryOfResidence: Maybe<Scalars["String"]>;
  educationApproach: Maybe<Scalars["String"]>;
  experiments: Maybe<Array<Maybe<Scalars["String"]>>>;
  headline: Maybe<Scalars["String"]>;
  holdMessages: Maybe<Scalars["Boolean"]>;
  howFoundOutschool: Maybe<Scalars["String"]>;
  howFoundOutschoolOther: Maybe<Scalars["String"]>;
  introduction: Maybe<Scalars["String"]>;
  socialOptOut: Maybe<Scalars["Boolean"]>;
  timeRangeAfterSchool: Maybe<Scalars["Boolean"]>;
  timeRangeSchool: Maybe<Scalars["Boolean"]>;
  timeRangeWeekend: Maybe<Scalars["Boolean"]>;
};

export type Donation = {
  __typename?: "Donation";
  amountCents: Scalars["Int"];
  confirmedAt: Maybe<Scalars["DateTime"]>;
  orderSlugId: Scalars["String"];
  payments: Maybe<Array<OrderPayment>>;
  uid: Scalars["ID"];
  userUid: Scalars["String"];
};

export type EmailPreference = {
  __typename?: "EmailPreference";
  childPreferences: Array<EmailPreference>;
  description: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  isEnabled: Scalars["Boolean"];
  label: Scalars["String"];
  name: Scalars["String"];
};

export type ReviewableSection = {
  __typename?: "ReviewableSection";
  activity_uid: Scalars["ID"];
  can_review: Scalars["Boolean"];
  feedback_uid: Maybe<Scalars["ID"]>;
  section_uid: Scalars["ID"];
};

export enum UserEnrollmentsFilters {
  All = "All",
  Confirmed = "Confirmed",
}

export type FollowedUser = {
  __typename?: "FollowedUser";
  created_at: Scalars["DateTime"];
  followed_uid: Scalars["String"];
  follower_uid: Scalars["String"];
  leader: Leader;
  uid: Scalars["ID"];
};

export enum IdvStatus {
  Accept = "ACCEPT",
  NotStarted = "NOT_STARTED",
  Reject = "REJECT",
  RejectMany = "REJECT_MANY",
  Review = "REVIEW",
  Started = "STARTED",
}

export type InterfacePreferences = {
  __typename?: "InterfacePreferences";
  activityFormatDescriptionFlexHidden: Scalars["Boolean"];
  activityFormatDescriptionMultiDayHidden: Scalars["Boolean"];
  activityFormatDescriptionOneTimeHidden: Scalars["Boolean"];
  activityFormatDescriptionOngoingHidden: Scalars["Boolean"];
  defaultScheduleFilters: UserScheduleFilters;
};

export type UserScheduleFilters = {
  __typename?: "UserScheduleFilters";
  dow: Maybe<Scalars["String"]>;
  endBy: Maybe<Scalars["String"]>;
  endByTime: Maybe<Scalars["Float"]>;
  startAfter: Maybe<Scalars["String"]>;
  startAfterTime: Maybe<Scalars["Float"]>;
};

export type ClassScheduleRequest = {
  __typename?: "ClassScheduleRequest";
  created_at: Scalars["DateTime"];
  message: Maybe<Scalars["String"]>;
  parent: Parent;
  parentEnrolledAt: Maybe<Scalars["DateTime"]>;
  suggestedMeetings: Maybe<Array<MeetingSuggestion>>;
  timetable: Timetable;
  timeZone: Scalars["String"];
  uid: Scalars["ID"];
};

export type MeetingSuggestion = {
  __typename?: "MeetingSuggestion";
  endTime: Maybe<Scalars["DateTime"]>;
  startTime: Maybe<Scalars["DateTime"]>;
};

export type Timetable = {
  __typename?: "Timetable";
  fri: TimetableTimes;
  mon: TimetableTimes;
  sat: TimetableTimes;
  sun: TimetableTimes;
  thu: TimetableTimes;
  tue: TimetableTimes;
  wed: TimetableTimes;
};

export type TimetableTimes = {
  __typename?: "TimetableTimes";
  f12_4: Scalars["Boolean"];
  f4_8: Scalars["Boolean"];
  f8_12: Scalars["Boolean"];
};

export type SellerOrgAgreement = {
  __typename?: "SellerOrgAgreement";
  agreement: Scalars["String"];
  contractSignedBy: Maybe<Scalars["String"]>;
  contractSignerTitle: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  legalAddress: Maybe<Scalars["String"]>;
  legalEntityType: Maybe<Scalars["String"]>;
  legalName: Maybe<Scalars["String"]>;
  legalState: Maybe<Scalars["String"]>;
  ownsBackgroundChecks: Maybe<Scalars["Boolean"]>;
  signedAt: Maybe<Scalars["DateTime"]>;
  uid: Scalars["ID"];
  updatedAt: Scalars["DateTime"];
};

export type SellerOrgApplication = {
  __typename?: "SellerOrgApplication";
  additionalInfo: Maybe<Scalars["String"]>;
  adminResponse: Maybe<Scalars["String"]>;
  /** @deprecated No longer requested */
  alreadyApprovedTeacher: Maybe<Scalars["Boolean"]>;
  /** @deprecated No longer requested */
  alreadyApprovedTeacherEmail: Maybe<Scalars["String"]>;
  appliedAt: Maybe<Scalars["DateTime"]>;
  approvedAt: Maybe<Scalars["DateTime"]>;
  classesInfo: Maybe<Scalars["String"]>;
  contractSignedAt: Maybe<Scalars["DateTime"]>;
  countries: Maybe<Scalars["String"]>;
  createdAt: Maybe<Scalars["DateTime"]>;
  /** @deprecated No longer requested */
  description: Maybe<Scalars["String"]>;
  firstClassDescription: Maybe<Scalars["String"]>;
  learnersInfo: Maybe<Scalars["String"]>;
  /** @deprecated No longer requested */
  learnerTypes: Maybe<Scalars["String"]>;
  name: Maybe<Scalars["String"]>;
  /** @deprecated No longer requested */
  numberOfClassesPerWeek: Maybe<Scalars["String"]>;
  /** @deprecated No longer requested */
  numberOfStudentsOnAverage: Maybe<Scalars["String"]>;
  numberOfTeachers: Maybe<Scalars["String"]>;
  organizationPhone: Maybe<Scalars["String"]>;
  primaryContactName: Maybe<Scalars["String"]>;
  primaryContactTitle: Maybe<Scalars["String"]>;
  publicReviews: Maybe<Scalars["String"]>;
  rejectedAt: Maybe<Scalars["DateTime"]>;
  sourcedFrom: Maybe<Scalars["String"]>;
  status: SellerOrgApplicationStatus;
  teachersInfo: Maybe<Scalars["String"]>;
  /** @deprecated No longer requested */
  timeInBusiness: Maybe<Scalars["String"]>;
  /** @deprecated No longer requested */
  type: Maybe<Scalars["String"]>;
  /** @deprecated No longer requested */
  typeDetail: Maybe<Scalars["String"]>;
  uid: Scalars["ID"];
  updatedAt: Maybe<Scalars["DateTime"]>;
  website: Maybe<Scalars["String"]>;
  whenFounded: Maybe<Scalars["String"]>;
};

export enum SellerOrgApplicationStatus {
  Applied = "applied",
  Approved = "approved",
  Interested = "interested",
  Rejected = "rejected",
}

export type TeacherApplication = {
  __typename?: "TeacherApplication";
  age_ranges_taught: Maybe<Array<Maybe<Scalars["String"]>>>;
  class_subjects: Maybe<Array<Maybe<Scalars["String"]>>>;
  class_types: Maybe<Scalars["String"]>;
  experience_types: Maybe<Array<Maybe<Scalars["String"]>>>;
  how_did_you_hear_about_us: Maybe<Scalars["String"]>;
  legal_name: Maybe<Scalars["String"]>;
  online_profiles: Maybe<Scalars["String"]>;
  reviewed_at: Maybe<Scalars["DateTime"]>;
  sample_classes: Maybe<Scalars["String"]>;
  status: TeacherApplicationStatus;
  submitted_at: Maybe<Scalars["DateTime"]>;
  teaching_credentials: Maybe<Scalars["String"]>;
  teaching_experience: Maybe<Scalars["String"]>;
  teaching_motivation: Maybe<Scalars["String"]>;
  uid: Scalars["ID"];
  video: Maybe<Attachment>;
  years_of_experience: Maybe<Scalars["String"]>;
};

export enum TeacherApplicationStatus {
  Approved = "approved",
  Created = "created",
  Declined = "declined",
  Submitted = "submitted",
}

export enum CreateActivityType {
  Class = "Class",
  Club = "Club",
}

export type LearnerPlan = {
  __typename?: "LearnerPlan";
  created_from: Maybe<Scalars["String"]>;
  end_date: Maybe<Scalars["DateTime"]>;
  entityAvailabilitySchedule: Maybe<EntityAvailabilitySchedule>;
  learner: Maybe<Learner>;
  learnerPlanGoals: Array<LearnerPlanGoal>;
  name: Maybe<Scalars["String"]>;
  start_date: Maybe<Scalars["DateTime"]>;
  uid: Scalars["ID"];
};

export type LearnerPlanGoal = {
  __typename?: "LearnerPlanGoal";
  created_at: Maybe<Scalars["DateTime"]>;
  learner_plan_uid: Scalars["ID"];
  learnerPlanActivity: Maybe<LearnerPlanActivity>;
  learnerPlanGoalFilters: Array<LearnerPlanGoalFilter>;
  uid: Scalars["ID"];
};

export type LearnerPlanActivity = {
  __typename?: "LearnerPlanActivity";
  activity: Maybe<Activity>;
  canScheduleContinuously: Scalars["Boolean"];
  created_at: Maybe<Scalars["DateTime"]>;
  enrollableMeetings: Maybe<
    Array<Maybe<EnrollmentMeetingPurchaseMeetingAvailability>>
  >;
  enrollment: Maybe<Enrollment>;
  is_pending: Maybe<Scalars["Boolean"]>;
  /** @deprecated should be is_suggested, but unused */
  isSuggested: Maybe<Scalars["Boolean"]>;
  learner_plan_uid: Scalars["ID"];
  meeting_duration_weeks: Maybe<Scalars["Int"]>;
  meeting_end_time: Maybe<Scalars["DateTime"]>;
  meeting_start_time: Maybe<Scalars["DateTime"]>;
  section: Maybe<Section>;
  subject: Maybe<Scalars["String"]>;
  uid: Scalars["ID"];
};

export type EnrollmentMeetingPurchaseMeetingAvailability = {
  __typename?: "EnrollmentMeetingPurchaseMeetingAvailability";
  endTime: Scalars["DateTime"];
  isAvailable: Scalars["Boolean"];
  startTime: Scalars["DateTime"];
};

export type LearnerPlanGoalFilter = {
  __typename?: "LearnerPlanGoalFilter";
  type: Maybe<Scalars["String"]>;
  uid: Scalars["ID"];
  value: Maybe<Scalars["String"]>;
};

export enum UserLocale {
  En = "en",
  Es = "es",
  /** @deprecated Use es instead */
  Es_419 = "es_419",
  Ja = "ja",
  Ko = "ko",
  ZhTw = "zh_TW",
}

export type OrganizationAdmin = {
  __typename?: "OrganizationAdmin";
  organization: Organization;
};

export type OtherEmailDetails = {
  __typename?: "OtherEmailDetails";
  confirmed_at: Maybe<Scalars["DateTime"]>;
  created_at: Scalars["DateTime"];
  email: Scalars["String"];
  uid: Scalars["ID"];
  updated_at: Scalars["DateTime"];
  user_uid: Scalars["ID"];
};

export type ParentDashboardFeed = {
  __typename?: "ParentDashboardFeed";
  cards: Array<ParentDashboardCard>;
  seed: Scalars["Int"];
};

export type ParentDashboardCard = {
  __typename?: "ParentDashboardCard";
  display: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  listings: Maybe<Array<Listing>>;
  log: Maybe<Scalars["String"]>;
  rawWeight: Maybe<Scalars["Float"]>;
  type: Scalars["String"];
  weight: Maybe<Scalars["Float"]>;
};

export type PaymentMethod = {
  __typename?: "PaymentMethod";
  brand: Scalars["String"];
  expirationMonth: Scalars["Int"];
  expirationYear: Scalars["Int"];
  last4: Scalars["String"];
  type: PaymentMethodType;
  uid: Scalars["ID"];
};

export enum PaymentMethodType {
  Card = "Card",
}

export type ParentReferral = {
  __typename?: "ParentReferral";
  firstName: Scalars["String"];
  status: ParentReferralStatus;
};

export enum ParentReferralStatus {
  CreditClaimed = "credit_claimed",
  CreditEarned = "credit_earned",
  SignedUp = "signed_up",
}

export type TeacherReferral = {
  __typename?: "TeacherReferral";
  firstName: Scalars["String"];
  status: TeacherReferralStatus;
};

export enum TeacherReferralStatus {
  ApplicationDeclined = "application_declined",
  EligibleForPayOut = "eligible_for_pay_out",
  PaidOut = "paid_out",
  TeacherApplied = "teacher_applied",
  TeacherApproved = "teacher_approved",
}

export type SavedActivity = {
  __typename?: "SavedActivity";
  activity_uid: Scalars["String"];
  created_at: Scalars["DateTime"];
  listing: Listing;
  uid: Scalars["ID"];
};

export type SavedSearch = {
  __typename?: "SavedSearch";
  createdAt: Scalars["DateTime"];
  filters: SavedSearchFilters;
  uid: Scalars["ID"];
};

export type SchoolClosure = {
  __typename?: "SchoolClosure";
  application_status: Maybe<FfapApplicationStatus>;
  created_at: Scalars["DateTime"];
  hispanic_latino: Maybe<Scalars["Boolean"]>;
  household_size: Maybe<Scalars["Int"]>;
  household_size_3_to_18: Maybe<Scalars["Int"]>;
  how_did_you_hear: Maybe<Scalars["String"]>;
  income: Maybe<Scalars["String"]>;
  interested_in_research: Maybe<Scalars["Boolean"]>;
  interested_in_sharing: Maybe<Scalars["Boolean"]>;
  learning_environments: Maybe<Array<Maybe<Scalars["String"]>>>;
  needs_assistance: Maybe<Scalars["Boolean"]>;
  primary_language: Maybe<Scalars["String"]>;
  race_ethnicity: Maybe<Array<Maybe<Scalars["String"]>>>;
  reliable_internet: Maybe<Scalars["Boolean"]>;
  uid: Scalars["String"];
  updated_at: Scalars["DateTime"];
  user: User;
  user_uid: Scalars["String"];
  zip_code: Maybe<Scalars["String"]>;
};

export enum FfapApplicationStatus {
  Approved = "approved",
  Declined = "declined",
  Pending = "pending",
}

export type SignUpCreditSummary = {
  __typename?: "SignUpCreditSummary";
  expiresAt: Maybe<Scalars["DateTime"]>;
  referrer: Maybe<Referrer>;
};

export type Referrer = {
  __typename?: "Referrer";
  leader: Maybe<Leader>;
  parent: Maybe<Parent>;
};

export type Availability = {
  __typename?: "Availability";
  hourlyTimetable: Maybe<HourlyTimetable>;
  updated_at: Maybe<Scalars["DateTime"]>;
};

export type HourlyTimetable = {
  __typename?: "HourlyTimetable";
  fri: Array<Scalars["Int"]>;
  mon: Array<Scalars["Int"]>;
  sat: Array<Scalars["Int"]>;
  sun: Array<Scalars["Int"]>;
  thu: Array<Scalars["Int"]>;
  tue: Array<Scalars["Int"]>;
  wed: Array<Scalars["Int"]>;
};

export type ScheduleSettings = {
  __typename?: "ScheduleSettings";
  activity: Activity;
  availabilities: Array<TeacherScheduleAvailability>;
  bufferTimeMinutes: Scalars["Int"];
  maximumNoticeHours: Scalars["Int"];
  meetingsStartTimeIncrementsInMinutes: Scalars["Int"];
  mininumNoticeHours: Scalars["Int"];
  teacher: Leader;
  uid: Scalars["ID"];
};

export type TeacherScheduleAvailability = {
  __typename?: "TeacherScheduleAvailability";
  endTime: Maybe<Scalars["DateTime"]>;
  isRecurring: Maybe<Scalars["Boolean"]>;
  isUnavailable: Maybe<Scalars["Boolean"]>;
  recurringEndTime: Maybe<Scalars["DateTime"]>;
  startTime: Maybe<Scalars["DateTime"]>;
  uid: Maybe<Scalars["ID"]>;
};

export type UnavailableBlock = {
  __typename?: "UnavailableBlock";
  endTime: Scalars["DateTime"];
  startTime: Scalars["DateTime"];
  uid: Scalars["ID"];
};

export type UserWalletSummary = {
  __typename?: "UserWalletSummary";
  creditAccountBalanceCents: Scalars["Int"];
  pricingOffers: Array<PricingOfferSummary>;
  pricingOffersTotalCents: Scalars["Int"];
  totalBalanceCents: Scalars["Int"];
};

export type PricingOfferSummary = {
  __typename?: "PricingOfferSummary";
  buyerOrgName: Maybe<Scalars["String"]>;
  isFinancialAid: Scalars["Boolean"];
  organizationUid: Maybe<Scalars["ID"]>;
  remainingCapCents: Scalars["Int"];
  uid: Scalars["ID"];
};

export type ActivityAdminChecklist = {
  __typename?: "ActivityAdminChecklist";
  notes: Maybe<Scalars["String"]>;
};

export type ActivityApprovalStatus = {
  __typename?: "ActivityApprovalStatus";
  action: Scalars["String"];
  message_to_teacher: Maybe<Scalars["String"]>;
};

export type OneOnOneMigrationInfo = {
  __typename?: "OneOnOneMigrationInfo";
  canMigrate: Scalars["Boolean"];
  reason: Maybe<Scalars["String"]>;
};

export enum EducatorSelectedFormat {
  Course = "Course",
  EnrichmentOrClub = "EnrichmentOrClub",
  OneTime = "OneTime",
  Tutoring = "Tutoring",
}

/** Defines how the classroom appears */
export enum ClassroomType {
  Group = "Group",
  OneOnOne = "OneOnOne",
  SelfPaced = "SelfPaced",
}

export type WelcomeMessage = {
  __typename?: "WelcomeMessage";
  attachments: Maybe<Array<Attachment>>;
  text: Maybe<Scalars["String"]>;
  video: Maybe<Attachment>;
};

export enum DeliveryType {
  Camp = "CAMP",
  Club = "CLUB",
  OneOnOneClass = "ONE_ON_ONE_CLASS",
  OneTimeClass = "ONE_TIME_CLASS",
  OngoingClass = "ONGOING_CLASS",
  SemesterCourse = "SEMESTER_COURSE",
  ShortCourse = "SHORT_COURSE",
}

export type ActivityDetails = {
  __typename?: "ActivityDetails";
  asyncTime: Maybe<Scalars["String"]>;
  photo: Maybe<Scalars["String"]>;
  photoSquare: Maybe<Scalars["String"]>;
};

export type EnrollableTime = {
  __typename?: "EnrollableTime";
  endTime: Scalars["DateTime"];
  startTime: Scalars["DateTime"];
  teacher: Leader;
};

export type EnrollmentParamsInput = {
  isActive?: InputMaybe<Scalars["Boolean"]>;
  orderByNextMeeting?: InputMaybe<Scalars["Boolean"]>;
};

export enum EsaStatus {
  Approved = "Approved",
  NotApproved = "NotApproved",
}

export type ExternalResource = {
  __typename?: "ExternalResource";
  category: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  policy_type: Maybe<ExternalResourcePolicy>;
  uid: Scalars["ID"];
  url: Maybe<Scalars["String"]>;
};

export enum ExternalResourcePolicy {
  /** Allowed for all ages without parental consent */
  Allowed = "ALLOWED",
  /** Allowed for all ages, parental consent required under age 13 */
  AllowedWithConsent = "ALLOWED_WITH_CONSENT",
  /** Allowed for all ages, parental consent required under age 16 */
  AllowedWithConsentUnder_16 = "ALLOWED_WITH_CONSENT_UNDER_16",
  /** Disallowed for all ages */
  Disallowed = "DISALLOWED",
  /** Allowed for ages 13+, disallowed under age 13 */
  DisallowedUnder_13 = "DISALLOWED_UNDER_13",
  /** Allowed for ages 16+, disallowed under age 16 */
  DisallowedUnder_16 = "DISALLOWED_UNDER_16",
  /** This resource hasn't been reviewed */
  Unvetted = "UNVETTED",
}

export enum SectionFilter {
  Bookable = "Bookable",
  BookableOrSoldOut = "BookableOrSoldOut",
  Cancelled = "Cancelled",
  Past = "Past",
  Promotable = "Promotable",
  Upcoming = "Upcoming",
}

export type SectionSearchInput = {
  daysOfWeek?: InputMaybe<Array<DaysOfWeek>>;
  endBy?: InputMaybe<Scalars["DateTime"]>;
  endByTime?: InputMaybe<Scalars["Float"]>;
  startAfter?: InputMaybe<Scalars["DateTime"]>;
  startAfterTime?: InputMaybe<Scalars["Float"]>;
  startBefore?: InputMaybe<Scalars["DateTime"]>;
  userTimeZone?: InputMaybe<Scalars["String"]>;
};

export enum DaysOfWeek {
  Fri = "Fri",
  Mon = "Mon",
  Sat = "Sat",
  Sun = "Sun",
  Thu = "Thu",
  Tue = "Tue",
  Wed = "Wed",
}

export type ActivityLeaderDetails = {
  __typename?: "ActivityLeaderDetails";
  activityUid: Scalars["ID"];
  classRequest: Maybe<ClassRequest>;
  requestedListingAt: Maybe<Scalars["DateTime"]>;
  uid: Scalars["ID"];
  welcomeMessage: Maybe<WelcomeMessage>;
};

export type ClassRequest = {
  __typename?: "ClassRequest";
  age: Scalars["Int"];
  content: Scalars["String"];
  created_at: Scalars["DateTime"];
  uid: Scalars["ID"];
  updated_at: Scalars["DateTime"];
  user_uid: Scalars["ID"];
};

export type SectionList = {
  __typename?: "SectionList";
  data: Array<Section>;
  totalCount: Scalars["Int"];
};

export type PricingAdjustment = {
  __typename?: "PricingAdjustment";
  weeklySurchargePercentage: Maybe<Scalars["Float"]>;
};

export type PricingInfo = {
  __typename?: "PricingInfo";
  /** The total price of the enrollment when paying upfront */
  totalPriceCentsForUpfrontPayment: Scalars["Int"];
  /** The total price of the enrollment when paying weekly */
  totalPriceCentsForWeeklyPayments: Scalars["Int"];
  /** The total price of the enrollment when paying upfront in credits */
  totalPriceCreditsForUpfrontPayment: Scalars["Int"];
  /** The total price of the enrollment when paying weekly in credits */
  totalPriceCreditsForWeeklyPayments: Scalars["Int"];
  upfrontDiscountPercentage: Scalars["Int"];
  /** The price divided by the number of weeks in the class */
  weeklyPriceCentsForUpfrontPayment: Scalars["Int"];
  /** The price with applied pricing adjustments divided by the number of weeks in the class */
  weeklyPriceCentsForWeeklyPayments: Scalars["Int"];
  /** The price divided by the number of weeks in the class */
  weeklyPriceCreditsForUpfrontPayment: Scalars["Int"];
  /** The price with applied pricing adjustments divided by the number of weeks in the class */
  weeklyPriceCreditsForWeeklyPayments: Scalars["Int"];
};

export type ProficiencyLevel = {
  __typename?: "ProficiencyLevel";
  level: Maybe<Array<Maybe<Scalars["String"]>>>;
  text: Maybe<Scalars["String"]>;
};

export type SampleClubPost = {
  __typename?: "SampleClubPost";
  attachments: Maybe<Array<Attachment>>;
  comments: Array<SampleClubPostComment>;
  text: Maybe<Scalars["String"]>;
  video: Maybe<Attachment>;
};

export type SampleClubPostComment = {
  __typename?: "SampleClubPostComment";
  text: Maybe<Scalars["String"]>;
};

export type SelfPacedMigrationStatus = {
  __typename?: "SelfPacedMigrationStatus";
  consentedAt: Maybe<Scalars["DateTime"]>;
  hasConsented: Scalars["Boolean"];
};

export type AgeRangeType = {
  __typename?: "AgeRangeType";
  max: Scalars["Int"];
  min: Scalars["Int"];
};

export type AdminEnrollmentPaymentRefundAction = {
  __typename?: "AdminEnrollmentPaymentRefundAction";
  action: Scalars["String"];
  notes: Scalars["String"];
  uid: Scalars["ID"];
};

export type SellerOrgAgreementTemplate = {
  __typename?: "SellerOrgAgreementTemplate";
  createdAt: Scalars["DateTime"];
  name: Scalars["String"];
  template: Scalars["String"];
  uid: Scalars["ID"];
  updatedAt: Scalars["DateTime"];
};

export type AppState = {
  __typename?: "AppState";
  authTrigger: Maybe<Scalars["String"]>;
  openModal: Maybe<ModalType>;
  welcomeFlashMessage: Maybe<Scalars["String"]>;
};

export enum ModalType {
  Welcome = "WELCOME",
}

export type PricingBreakdownInput = {
  activityUid?: InputMaybe<Scalars["String"]>;
  bulkMeetingDurationWeeks?: InputMaybe<Scalars["Int"]>;
  buyingLearnerUids?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  buyingUserUid?: InputMaybe<Scalars["String"]>;
  canScheduleContinuously?: InputMaybe<Scalars["Boolean"]>;
  charterAdminEnrollmentInput?: InputMaybe<CharterAdminEnrollmentInput>;
  couponCode?: InputMaybe<Scalars["String"]>;
  donationCents?: InputMaybe<Scalars["Int"]>;
  enrollableMeetings?: InputMaybe<Array<BulkFutureMeetingInput>>;
  enrollmentCount?: InputMaybe<Scalars["Int"]>;
  isAcceptingGiftEnrollment?: InputMaybe<Scalars["Boolean"]>;
  isUsingPurchaseOrder?: InputMaybe<Scalars["Boolean"]>;
  isWeeklyPayment?: InputMaybe<Scalars["Boolean"]>;
  numCredits?: InputMaybe<Scalars["Int"]>;
  ongoingEndTime?: InputMaybe<Scalars["DateTime"]>;
  ongoingStartTime?: InputMaybe<Scalars["DateTime"]>;
  paymentOption?: InputMaybe<PaymentOption>;
  prepayDurationWeeks?: InputMaybe<Scalars["Int"]>;
  purchaseOrderCoverage?: InputMaybe<Scalars["DateTime"]>;
  sectionUid?: InputMaybe<Scalars["String"]>;
  teacherUid?: InputMaybe<Scalars["String"]>;
  uid?: InputMaybe<Scalars["String"]>;
};

export type CharterAdminEnrollmentInput = {
  email: Scalars["String"];
  learnerName: Scalars["String"];
  name: Scalars["String"];
};

export type BulkFutureMeetingInput = {
  endTime: Scalars["DateTime"];
  isAvailable: Scalars["Boolean"];
  startTime: Scalars["DateTime"];
};

export type PaymentOption = {
  pricingOfferName?: InputMaybe<Scalars["String"]>;
  type: TenderType;
};

export type BulkPricingBreakdown = {
  __typename?: "BulkPricingBreakdown";
  couponCents: Scalars["Int"];
  couponState: CouponState;
  itemizedPricingBreakdown: Array<PricingBreakdown>;
  originalTotalCents: Scalars["Int"];
  /** @deprecated replaced with itemizedPricingBreakdown */
  pricingBreakdowns: Array<BulkPricingBreakdownItem>;
  pricingOffer: Maybe<PricingOffer>;
  pricingOfferCents: Scalars["Int"];
  storedCreditCents: Scalars["Int"];
  stripeCents: Scalars["Int"];
  subscriptionAccountState: Maybe<CreditAccountState>;
  subscriptionCredits: Maybe<Scalars["Int"]>;
  subscriptionOutstandingBalanceCents: Maybe<Scalars["Int"]>;
  subscriptionOutstandingBalanceCredits: Maybe<Scalars["Int"]>;
  totalCents: Scalars["Int"];
  totalPayUpfrontCents: Scalars["Int"];
  totalWeeklyFirstPaymentCents: Scalars["Int"];
  weeklyPaymentsCharges: Array<BulkWeeklyPricingBreakdown>;
};

export enum CouponState {
  InvalidActivityFormatNotAllowed = "InvalidActivityFormatNotAllowed",
  InvalidActivityNotEligible = "InvalidActivityNotEligible",
  InvalidCode = "InvalidCode",
  InvalidExpired = "InvalidExpired",
  InvalidMinimumPurchaseNotMet = "InvalidMinimumPurchaseNotMet",
  InvalidNoDiscount = "InvalidNoDiscount",
  InvalidNotStarted = "InvalidNotStarted",
  InvalidTooManyCouponCodeUses = "InvalidTooManyCouponCodeUses",
  InvalidTooManyUsesByUser = "InvalidTooManyUsesByUser",
  InvalidTooManyUsesTotal = "InvalidTooManyUsesTotal",
  InvalidUserNotConfirmed = "InvalidUserNotConfirmed",
  InvalidUserNotEligible = "InvalidUserNotEligible",
  InvalidUserNotEligibleClassFormat = "InvalidUserNotEligibleClassFormat",
  NoCoupon = "NoCoupon",
  Valid = "Valid",
}

export type PricingBreakdown = {
  __typename?: "PricingBreakdown";
  couponCents: Scalars["Int"];
  couponState: CouponState;
  creditAccountState: CreditAccountState;
  enrollmentMeetingTotalCents: Maybe<Scalars["Int"]>;
  hasProratedEnrollments: Maybe<Scalars["Boolean"]>;
  originalTotalCents: Scalars["Int"];
  outstandingStoredCreditsBalance: Maybe<Scalars["Float"]>;
  perEnrollmentCurrentWeekCents: Maybe<Scalars["Int"]>;
  perEnrollmentCurrentWeekCredits: Maybe<Scalars["Float"]>;
  /**
   * The total amount you would pay in USD if you paid weekly.
   * This is not the same as perEnrollmentUpfrontCents if an upfront discount
   * is set by the teacher
   */
  perEnrollmentTotalWeeklyCents: Maybe<Scalars["Int"]>;
  /**
   * The total amount you would pay in Credits if you paid weekly.
   * This is not the same as perEnrollmentUpfrontCents if an upfront discount
   * is set by the teacher
   */
  perEnrollmentTotalWeeklyCredits: Maybe<Scalars["Int"]>;
  perEnrollmentUpfrontCents: Maybe<Scalars["Int"]>;
  perEnrollmentUpfrontCredits: Maybe<Scalars["Float"]>;
  perEnrollmentWeeklyCents: Maybe<Scalars["Int"]>;
  perEnrollmentWeeklyCredits: Maybe<Scalars["Float"]>;
  pricingOffer: Maybe<PricingOffer>;
  pricingOfferCents: Scalars["Int"];
  pricingOfferState: PricingOfferState;
  serviceFeePercentage: Maybe<Scalars["Int"]>;
  storedCreditCents: Scalars["Int"];
  storedCredits: Maybe<Scalars["Float"]>;
  stripeCents: Scalars["Int"];
  stripeCentsForRemainingCredits: Maybe<Scalars["Int"]>;
  subscriptionAccountState: Maybe<CreditAccountState>;
  subscriptionCredits: Maybe<Scalars["Int"]>;
  subscriptionOutstandingBalanceCents: Maybe<Scalars["Int"]>;
  subscriptionOutstandingBalanceCredits: Maybe<Scalars["Int"]>;
  totalCents: Scalars["Int"];
  totalCredits: Maybe<Scalars["Float"]>;
  uid: Maybe<Scalars["String"]>;
};

export enum CreditAccountState {
  InvalidNoCreditAccount = "InvalidNoCreditAccount",
  InvalidNoDiscount = "InvalidNoDiscount",
  InvalidNoOpenBalance = "InvalidNoOpenBalance",
  InvalidSharedPaymentMethod = "InvalidSharedPaymentMethod",
  InvalidSubscriptionNotActive = "InvalidSubscriptionNotActive",
  InvalidUnconfirmedEmail = "InvalidUnconfirmedEmail",
  InvalidWhenAcceptingGiftEnrollment = "InvalidWhenAcceptingGiftEnrollment",
  Valid = "Valid",
}

export enum PricingOfferState {
  InvalidBuyoutSection = "InvalidBuyoutSection",
  InvalidDate = "InvalidDate",
  InvalidEducator = "InvalidEducator",
  InvalidNoDiscount = "InvalidNoDiscount",
  InvalidOverTotalCap = "InvalidOverTotalCap",
  InvalidOverUserCap = "InvalidOverUserCap",
  InvalidUnconfirmedEmail = "InvalidUnconfirmedEmail",
  InvalidWhenAcceptingGiftEnrollment = "InvalidWhenAcceptingGiftEnrollment",
  InvalidWithDonationPurchase = "InvalidWithDonationPurchase",
  NoPricingOffer = "NoPricingOffer",
  ValidBuyerOrg = "ValidBuyerOrg",
  ValidFinancialAid = "ValidFinancialAid",
}

export type BulkPricingBreakdownItem = {
  __typename?: "BulkPricingBreakdownItem";
  activityUid: Scalars["ID"];
  pricingBreakdown: Maybe<PricingBreakdown>;
  status: BulkPricingBreakdownItemStatus;
};

export enum BulkPricingBreakdownItemStatus {
  ErrorInvalidActivityType = "ERROR_INVALID_ACTIVITY_TYPE",
  ErrorNotAvailable = "ERROR_NOT_AVAILABLE",
  ErrorUnknown = "ERROR_UNKNOWN",
  Success = "SUCCESS",
}

export type BulkWeeklyPricingBreakdown = {
  __typename?: "BulkWeeklyPricingBreakdown";
  priceCents: Scalars["Int"];
  startOfWeek: Scalars["DateTime"];
};

export type PaginatedCategoryPages = {
  __typename?: "PaginatedCategoryPages";
  categoryPages: Maybe<Array<Maybe<CategoryPage>>>;
  count: Maybe<Scalars["Int"]>;
  limit: Maybe<Scalars["Int"]>;
  offset: Maybe<Scalars["Int"]>;
  total: Maybe<Scalars["Int"]>;
};

export type MeetingConflictsQueryInput = {
  ignoreMeetingUids?: InputMaybe<Array<Scalars["ID"]>>;
  range: StartEndTime;
  /** A unique ID that the client can use to identify which range matched this */
  uid: Scalars["ID"];
};

export type StartEndTime = {
  end_time: Scalars["DateTime"];
  start_time: Scalars["DateTime"];
};

export type MeetingConflictResult = {
  __typename?: "MeetingConflictResult";
  /** The range uid that was passed in the MeetingConflictsQueryInput */
  rangeUid: Scalars["ID"];
  /** The section which conflicts with the range */
  section: Section;
};

export type Classroom = {
  __typename?: "Classroom";
  activity: Activity;
  /** @deprecated Use currentLearnerEnrollments. Learner may have multiple enrollments per section. */
  currentLearnerEnrollment: Enrollment;
  currentLearnerEnrollments: Array<Enrollment>;
  currentUserEnrollments: Array<Enrollment>;
  learner: Learner;
  learners: Array<LearnerProfile>;
  mentionNames: Array<MentionName>;
  postByUid: Maybe<ClassPost>;
  posts: Array<ClassPost>;
  section: Section;
  uid: Scalars["ID"];
  unpublishedPosts: Array<ClassPost>;
  usersCanComment: Scalars["Boolean"];
  usersCanHaveLiveMeeting: Scalars["Boolean"];
  usersCanPost: Scalars["Boolean"];
};

export type ClassroomLearnerArgs = {
  learnerUid?: InputMaybe<Scalars["ID"]>;
};

export type ClassroomPostByUidArgs = {
  classPostUid: Scalars["ID"];
};

export type ClassroomPostsArgs = {
  excludeForLessons?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<ClassroomPostFilter>;
  lessonUid?: InputMaybe<Scalars["ID"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  order?: InputMaybe<ClassroomPostOrder>;
  publishedAfter?: InputMaybe<Scalars["DateTime"]>;
  publishedBefore?: InputMaybe<Scalars["DateTime"]>;
};

export type LearnerProfile = {
  __typename?: "LearnerProfile";
  aboutMe: Maybe<Scalars["String"]>;
  age: Maybe<Scalars["Int"]>;
  avatar: Maybe<Scalars["String"]>;
  favoritedClassList: Maybe<SavedClassList>;
  isProfileAgeHidden: Scalars["Boolean"];
  isProfileLocationHidden: Scalars["Boolean"];
  location: Maybe<ApproximateLocation>;
  name: Maybe<Scalars["String"]>;
  pronoun: Maybe<Scalars["String"]>;
  uid: Scalars["ID"];
  unlockedAvatars: Array<Scalars["String"]>;
};

export type ApproximateLocation = {
  __typename?: "ApproximateLocation";
  country: Maybe<Scalars["String"]>;
  state: Maybe<Scalars["String"]>;
};

export type MentionName = {
  __typename?: "MentionName";
  avatar: Maybe<Scalars["String"]>;
  display: Scalars["String"];
  id: Scalars["ID"];
  startTime: Maybe<Scalars["DateTime"]>;
};

export enum ClassroomPostFilter {
  /** Both posts associated with lessons and not */
  All = "All",
  /** Only posts not associated with a lesson */
  Announcements = "Announcements",
  /** Only posts associated with a lesson */
  OnlyForLessons = "OnlyForLessons",
}

export enum ClassroomPostOrder {
  Chronological = "Chronological",
  ReverseChronological = "ReverseChronological",
}

export type Club = {
  __typename?: "Club";
  activity: Activity;
  currentLearnerHasSubmittedIntroductionForClub: Scalars["Boolean"];
  currentLearnerIsVerified: Scalars["Boolean"];
  currentLearnerMustAcceptGuidelines: Scalars["Boolean"];
  currentLearnerPostCount: Scalars["Int"];
  currentLearnerPostReplyCount: Scalars["Int"];
  currentUserCanParticipate: Scalars["Boolean"];
  currentUserEnrollments: Array<Enrollment>;
  mentionNames: Array<MentionName>;
  pinnedPosts: Array<ClassPost>;
  postByUid: Maybe<ClassPost>;
  posts: Array<ClassPost>;
  promotedPostCount: Scalars["Int"];
  section: Section;
  uid: Scalars["ID"];
};

export type ClubCurrentLearnerPostCountArgs = {
  publishedAfter?: InputMaybe<Scalars["DateTime"]>;
  publishedBefore?: InputMaybe<Scalars["DateTime"]>;
};

export type ClubCurrentLearnerPostReplyCountArgs = {
  publishedAfter?: InputMaybe<Scalars["DateTime"]>;
  publishedBefore?: InputMaybe<Scalars["DateTime"]>;
};

export type ClubPostByUidArgs = {
  classPostUid: Scalars["ID"];
};

export type ClubPostsArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  publishedAfter?: InputMaybe<Scalars["DateTime"]>;
  publishedBefore?: InputMaybe<Scalars["DateTime"]>;
  score?: InputMaybe<Scalars["Float"]>;
  scoreFrom?: InputMaybe<Scalars["DateTime"]>;
};

export type ClubPromotedPostCountArgs = {
  publishedAfter?: InputMaybe<Scalars["DateTime"]>;
  publishedBefore?: InputMaybe<Scalars["DateTime"]>;
};

export type ClubCategory = {
  __typename?: "ClubCategory";
  activities: Array<Activity>;
  label: Scalars["String"];
  subject: Scalars["String"];
};

export type Contentful_Node = {
  _id: Scalars["ID"];
};

/**
 * A simple page layout with sections logically separated so they can be collapsed
 * as an accordion page. [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/accordionPage)
 */
export type ContentfulAccordionPage = Contentful_Node &
  ContentfulEntry & {
    __typename?: "ContentfulAccordionPage";
    _id: Scalars["ID"];
    contentfulMetadata: ContentfulContentfulMetadata;
    headerImage: Maybe<ContentfulAsset>;
    linkedFrom: Maybe<ContentfulAccordionPageLinkingCollections>;
    preamble: Maybe<ContentfulAccordionPagePreamble>;
    publishedAt: Maybe<Scalars["ContentfulDateTime"]>;
    sectionsCollection: Maybe<ContentfulAccordionPageSectionsCollection>;
    showToc: Maybe<Scalars["Boolean"]>;
    slug: Maybe<Scalars["String"]>;
    sys: ContentfulSys;
    title: Maybe<Scalars["String"]>;
  };

/**
 * A simple page layout with sections logically separated so they can be collapsed
 * as an accordion page. [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/accordionPage)
 */
export type ContentfulAccordionPageHeaderImageArgs = {
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
};

/**
 * A simple page layout with sections logically separated so they can be collapsed
 * as an accordion page. [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/accordionPage)
 */
export type ContentfulAccordionPageLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

/**
 * A simple page layout with sections logically separated so they can be collapsed
 * as an accordion page. [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/accordionPage)
 */
export type ContentfulAccordionPagePreambleArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/**
 * A simple page layout with sections logically separated so they can be collapsed
 * as an accordion page. [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/accordionPage)
 */
export type ContentfulAccordionPagePublishedAtArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/**
 * A simple page layout with sections logically separated so they can be collapsed
 * as an accordion page. [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/accordionPage)
 */
export type ContentfulAccordionPageSectionsCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<
    Array<InputMaybe<ContentfulAccordionPageSectionsCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ContentfulAccordionSectionFilter>;
};

/**
 * A simple page layout with sections logically separated so they can be collapsed
 * as an accordion page. [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/accordionPage)
 */
export type ContentfulAccordionPageShowTocArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/**
 * A simple page layout with sections logically separated so they can be collapsed
 * as an accordion page. [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/accordionPage)
 */
export type ContentfulAccordionPageSlugArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/**
 * A simple page layout with sections logically separated so they can be collapsed
 * as an accordion page. [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/accordionPage)
 */
export type ContentfulAccordionPageTitleArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

export type ContentfulEntry = {
  contentfulMetadata: ContentfulContentfulMetadata;
  sys: ContentfulSys;
};

export type ContentfulContentfulMetadata = {
  __typename?: "ContentfulContentfulMetadata";
  concepts: Array<Maybe<ContentfulTaxonomyConcept>>;
  tags: Array<Maybe<ContentfulContentfulTag>>;
};

/**
 * Represents a taxonomy concept entity for finding and organizing content easily.
 *         Find out more here: https://www.contentful.com/developers/docs/references/content-delivery-api/#/reference/content-concepts
 */
export type ContentfulTaxonomyConcept = {
  __typename?: "ContentfulTaxonomyConcept";
  id: Maybe<Scalars["String"]>;
};

/**
 * Represents a tag entity for finding and organizing content easily.
 *       Find out more here: https://www.contentful.com/developers/docs/references/content-delivery-api/#/reference/content-tags
 */
export type ContentfulContentfulTag = {
  __typename?: "ContentfulContentfulTag";
  id: Maybe<Scalars["String"]>;
  name: Maybe<Scalars["String"]>;
};

export type ContentfulSys = {
  __typename?: "ContentfulSys";
  environmentId: Scalars["String"];
  firstPublishedAt: Maybe<Scalars["ContentfulDateTime"]>;
  id: Scalars["String"];
  /** The locale that was requested. */
  locale: Maybe<Scalars["String"]>;
  publishedAt: Maybe<Scalars["ContentfulDateTime"]>;
  publishedVersion: Maybe<Scalars["Int"]>;
  spaceId: Scalars["String"];
};

/** Represents a binary file in a space. An asset can be any file type. */
export type ContentfulAsset = {
  __typename?: "ContentfulAsset";
  contentfulMetadata: ContentfulContentfulMetadata;
  contentType: Maybe<Scalars["String"]>;
  description: Maybe<Scalars["String"]>;
  fileName: Maybe<Scalars["String"]>;
  height: Maybe<Scalars["Int"]>;
  linkedFrom: Maybe<ContentfulAssetLinkingCollections>;
  size: Maybe<Scalars["Int"]>;
  sys: ContentfulSys;
  title: Maybe<Scalars["String"]>;
  url: Maybe<Scalars["String"]>;
  width: Maybe<Scalars["Int"]>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type ContentfulAssetContentTypeArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type ContentfulAssetDescriptionArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type ContentfulAssetFileNameArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type ContentfulAssetHeightArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type ContentfulAssetLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type ContentfulAssetSizeArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type ContentfulAssetTitleArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type ContentfulAssetUrlArgs = {
  locale?: InputMaybe<Scalars["String"]>;
  transform?: InputMaybe<ContentfulImageTransformOptions>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type ContentfulAssetWidthArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

export type ContentfulAssetLinkingCollections = {
  __typename?: "ContentfulAssetLinkingCollections";
  accordionPageCollection: Maybe<ContentfulAccordionPageCollection>;
  authorCollection: Maybe<ContentfulAuthorCollection>;
  bannerCollection: Maybe<ContentfulBannerCollection>;
  basicPageCollection: Maybe<ContentfulBasicPageCollection>;
  categoryPageCollection: Maybe<ContentfulCategoryPageCollection>;
  entryCollection: Maybe<ContentfulEntryCollection>;
  heroSlideCollection: Maybe<ContentfulHeroSlideCollection>;
  landingPageCollection: Maybe<ContentfulLandingPageCollection>;
  landingPageHeroSectionCollection: Maybe<ContentfulLandingPageHeroSectionCollection>;
  landingPageIconInfoCollection: Maybe<ContentfulLandingPageIconInfoCollection>;
  landingPageImageTextCtaSectionCollection: Maybe<ContentfulLandingPageImageTextCtaSectionCollection>;
  landingPageJourneyItemCollection: Maybe<ContentfulLandingPageJourneyItemCollection>;
  landingPageLessonPreviewSectionCollection: Maybe<ContentfulLandingPageLessonPreviewSectionCollection>;
  landingPageSingleReviewSectionCollection: Maybe<ContentfulLandingPageSingleReviewSectionCollection>;
  landingPageStatBoxCollection: Maybe<ContentfulLandingPageStatBoxCollection>;
  learnerBannerCollection: Maybe<ContentfulLearnerBannerCollection>;
  listingsPromoBannerCollection: Maybe<ContentfulListingsPromoBannerCollection>;
  magnetPageCollection: Maybe<ContentfulMagnetPageCollection>;
  valuePropCollection: Maybe<ContentfulValuePropCollection>;
  visualLinkCollection: Maybe<ContentfulVisualLinkCollection>;
};

export type ContentfulAssetLinkingCollectionsAccordionPageCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type ContentfulAssetLinkingCollectionsAuthorCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type ContentfulAssetLinkingCollectionsBannerCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type ContentfulAssetLinkingCollectionsBasicPageCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type ContentfulAssetLinkingCollectionsCategoryPageCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type ContentfulAssetLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type ContentfulAssetLinkingCollectionsHeroSlideCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type ContentfulAssetLinkingCollectionsLandingPageCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type ContentfulAssetLinkingCollectionsLandingPageHeroSectionCollectionArgs =
  {
    limit?: InputMaybe<Scalars["Int"]>;
    locale?: InputMaybe<Scalars["String"]>;
    preview?: InputMaybe<Scalars["Boolean"]>;
    skip?: InputMaybe<Scalars["Int"]>;
  };

export type ContentfulAssetLinkingCollectionsLandingPageIconInfoCollectionArgs =
  {
    limit?: InputMaybe<Scalars["Int"]>;
    locale?: InputMaybe<Scalars["String"]>;
    preview?: InputMaybe<Scalars["Boolean"]>;
    skip?: InputMaybe<Scalars["Int"]>;
  };

export type ContentfulAssetLinkingCollectionsLandingPageImageTextCtaSectionCollectionArgs =
  {
    limit?: InputMaybe<Scalars["Int"]>;
    locale?: InputMaybe<Scalars["String"]>;
    preview?: InputMaybe<Scalars["Boolean"]>;
    skip?: InputMaybe<Scalars["Int"]>;
  };

export type ContentfulAssetLinkingCollectionsLandingPageJourneyItemCollectionArgs =
  {
    limit?: InputMaybe<Scalars["Int"]>;
    locale?: InputMaybe<Scalars["String"]>;
    preview?: InputMaybe<Scalars["Boolean"]>;
    skip?: InputMaybe<Scalars["Int"]>;
  };

export type ContentfulAssetLinkingCollectionsLandingPageLessonPreviewSectionCollectionArgs =
  {
    limit?: InputMaybe<Scalars["Int"]>;
    locale?: InputMaybe<Scalars["String"]>;
    preview?: InputMaybe<Scalars["Boolean"]>;
    skip?: InputMaybe<Scalars["Int"]>;
  };

export type ContentfulAssetLinkingCollectionsLandingPageSingleReviewSectionCollectionArgs =
  {
    limit?: InputMaybe<Scalars["Int"]>;
    locale?: InputMaybe<Scalars["String"]>;
    preview?: InputMaybe<Scalars["Boolean"]>;
    skip?: InputMaybe<Scalars["Int"]>;
  };

export type ContentfulAssetLinkingCollectionsLandingPageStatBoxCollectionArgs =
  {
    limit?: InputMaybe<Scalars["Int"]>;
    locale?: InputMaybe<Scalars["String"]>;
    preview?: InputMaybe<Scalars["Boolean"]>;
    skip?: InputMaybe<Scalars["Int"]>;
  };

export type ContentfulAssetLinkingCollectionsLearnerBannerCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type ContentfulAssetLinkingCollectionsListingsPromoBannerCollectionArgs =
  {
    limit?: InputMaybe<Scalars["Int"]>;
    locale?: InputMaybe<Scalars["String"]>;
    preview?: InputMaybe<Scalars["Boolean"]>;
    skip?: InputMaybe<Scalars["Int"]>;
  };

export type ContentfulAssetLinkingCollectionsMagnetPageCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type ContentfulAssetLinkingCollectionsValuePropCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type ContentfulAssetLinkingCollectionsVisualLinkCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type ContentfulAccordionPageCollection = {
  __typename?: "ContentfulAccordionPageCollection";
  items: Array<Maybe<ContentfulAccordionPage>>;
  limit: Scalars["Int"];
  skip: Scalars["Int"];
  total: Scalars["Int"];
};

export type ContentfulAuthorCollection = {
  __typename?: "ContentfulAuthorCollection";
  items: Array<Maybe<ContentfulAuthor>>;
  limit: Scalars["Int"];
  skip: Scalars["Int"];
  total: Scalars["Int"];
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/author) */
export type ContentfulAuthor = Contentful_Node &
  ContentfulEntry & {
    __typename?: "ContentfulAuthor";
    _id: Scalars["ID"];
    contentfulMetadata: ContentfulContentfulMetadata;
    description: Maybe<Scalars["String"]>;
    isTeacher: Maybe<Scalars["Boolean"]>;
    linkedFrom: Maybe<ContentfulAuthorLinkingCollections>;
    name: Maybe<Scalars["String"]>;
    profileImage: Maybe<ContentfulAsset>;
    publicProfile: Maybe<PublicProfile>;
    sys: ContentfulSys;
    uid: Maybe<Scalars["String"]>;
  };

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/author) */
export type ContentfulAuthorDescriptionArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/author) */
export type ContentfulAuthorIsTeacherArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/author) */
export type ContentfulAuthorLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/author) */
export type ContentfulAuthorNameArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/author) */
export type ContentfulAuthorProfileImageArgs = {
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/author) */
export type ContentfulAuthorUidArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

export type ContentfulAuthorLinkingCollections = {
  __typename?: "ContentfulAuthorLinkingCollections";
  entryCollection: Maybe<ContentfulEntryCollection>;
  magnetPageCollection: Maybe<ContentfulMagnetPageCollection>;
};

export type ContentfulAuthorLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type ContentfulAuthorLinkingCollectionsMagnetPageCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<
    Array<
      InputMaybe<ContentfulAuthorLinkingCollectionsMagnetPageCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type ContentfulEntryCollection = {
  __typename?: "ContentfulEntryCollection";
  items: Array<Maybe<ContentfulEntry>>;
  limit: Scalars["Int"];
  skip: Scalars["Int"];
  total: Scalars["Int"];
};

export enum ContentfulAuthorLinkingCollectionsMagnetPageCollectionOrder {
  MagnetPageSlugAsc = "magnetPageSlug_ASC",
  MagnetPageSlugDesc = "magnetPageSlug_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export type ContentfulMagnetPageCollection = {
  __typename?: "ContentfulMagnetPageCollection";
  items: Array<Maybe<ContentfulMagnetPage>>;
  limit: Scalars["Int"];
  skip: Scalars["Int"];
  total: Scalars["Int"];
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/magnetPage) */
export type ContentfulMagnetPage = Contentful_Node &
  ContentfulEntry & {
    __typename?: "ContentfulMagnetPage";
    _id: Scalars["ID"];
    author: Maybe<ContentfulAuthor>;
    body: Maybe<ContentfulMagnetPageBody>;
    category: CategoryPage;
    categoryLabel: Maybe<ContentfulCategoryLabel>;
    contentfulMetadata: ContentfulContentfulMetadata;
    headerImage: Maybe<ContentfulAsset>;
    linkedFrom: Maybe<ContentfulMagnetPageLinkingCollections>;
    magnetPageSlug: Maybe<Scalars["String"]>;
    relatedMagnetPages: Maybe<ContentfulMagnetPageCollection>;
    summary: Maybe<ContentfulMagnetPageSummary>;
    sys: ContentfulSys;
    title: Maybe<Scalars["String"]>;
    upsellClassCarousel: Maybe<ContentfulUpsellClassCard>;
  };

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/magnetPage) */
export type ContentfulMagnetPageAuthorArgs = {
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
  where?: InputMaybe<ContentfulAuthorFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/magnetPage) */
export type ContentfulMagnetPageBodyArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/magnetPage) */
export type ContentfulMagnetPageCategoryLabelArgs = {
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
  where?: InputMaybe<ContentfulCategoryLabelFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/magnetPage) */
export type ContentfulMagnetPageHeaderImageArgs = {
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/magnetPage) */
export type ContentfulMagnetPageLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/magnetPage) */
export type ContentfulMagnetPageMagnetPageSlugArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/magnetPage) */
export type ContentfulMagnetPageRelatedMagnetPagesArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Array<InputMaybe<ContentfulMagnetPageOrder>>>;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/magnetPage) */
export type ContentfulMagnetPageSummaryArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/magnetPage) */
export type ContentfulMagnetPageTitleArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/magnetPage) */
export type ContentfulMagnetPageUpsellClassCarouselArgs = {
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
  where?: InputMaybe<ContentfulUpsellClassCardFilter>;
};

export type ContentfulAuthorFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulAuthorFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  description?: InputMaybe<Scalars["String"]>;
  description_contains?: InputMaybe<Scalars["String"]>;
  description_exists?: InputMaybe<Scalars["Boolean"]>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  description_not?: InputMaybe<Scalars["String"]>;
  description_not_contains?: InputMaybe<Scalars["String"]>;
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  isTeacher?: InputMaybe<Scalars["Boolean"]>;
  isTeacher_exists?: InputMaybe<Scalars["Boolean"]>;
  isTeacher_not?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  name_contains?: InputMaybe<Scalars["String"]>;
  name_exists?: InputMaybe<Scalars["Boolean"]>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  name_not?: InputMaybe<Scalars["String"]>;
  name_not_contains?: InputMaybe<Scalars["String"]>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulAuthorFilter>>>;
  profileImage_exists?: InputMaybe<Scalars["Boolean"]>;
  sys?: InputMaybe<ContentfulSysFilter>;
  uid?: InputMaybe<Scalars["String"]>;
  uid_contains?: InputMaybe<Scalars["String"]>;
  uid_exists?: InputMaybe<Scalars["Boolean"]>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  uid_not?: InputMaybe<Scalars["String"]>;
  uid_not_contains?: InputMaybe<Scalars["String"]>;
  uid_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type ContentfulContentfulMetadataFilter = {
  concepts?: InputMaybe<ContentfulContentfulMetadataConceptsFilter>;
  concepts_exists?: InputMaybe<Scalars["Boolean"]>;
  tags?: InputMaybe<ContentfulContentfulMetadataTagsFilter>;
  tags_exists?: InputMaybe<Scalars["Boolean"]>;
};

export type ContentfulContentfulMetadataConceptsFilter = {
  descendants?: InputMaybe<ContentfulContentfulMetadataConceptsDescendantsFilter>;
  id_contains_all?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  id_contains_none?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  id_contains_some?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type ContentfulContentfulMetadataConceptsDescendantsFilter = {
  id_contains_all?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  id_contains_none?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  id_contains_some?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type ContentfulContentfulMetadataTagsFilter = {
  id_contains_all?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  id_contains_none?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  id_contains_some?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type ContentfulSysFilter = {
  firstPublishedAt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  firstPublishedAt_exists?: InputMaybe<Scalars["Boolean"]>;
  firstPublishedAt_gt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  firstPublishedAt_gte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  firstPublishedAt_in?: InputMaybe<
    Array<InputMaybe<Scalars["ContentfulDateTime"]>>
  >;
  firstPublishedAt_lt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  firstPublishedAt_lte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  firstPublishedAt_not?: InputMaybe<Scalars["ContentfulDateTime"]>;
  firstPublishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["ContentfulDateTime"]>>
  >;
  id?: InputMaybe<Scalars["String"]>;
  id_contains?: InputMaybe<Scalars["String"]>;
  id_exists?: InputMaybe<Scalars["Boolean"]>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  id_not?: InputMaybe<Scalars["String"]>;
  id_not_contains?: InputMaybe<Scalars["String"]>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  publishedAt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  publishedAt_exists?: InputMaybe<Scalars["Boolean"]>;
  publishedAt_gt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  publishedAt_gte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["ContentfulDateTime"]>>>;
  publishedAt_lt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  publishedAt_lte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  publishedAt_not?: InputMaybe<Scalars["ContentfulDateTime"]>;
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["ContentfulDateTime"]>>
  >;
  publishedVersion?: InputMaybe<Scalars["Float"]>;
  publishedVersion_exists?: InputMaybe<Scalars["Boolean"]>;
  publishedVersion_gt?: InputMaybe<Scalars["Float"]>;
  publishedVersion_gte?: InputMaybe<Scalars["Float"]>;
  publishedVersion_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]>>>;
  publishedVersion_lt?: InputMaybe<Scalars["Float"]>;
  publishedVersion_lte?: InputMaybe<Scalars["Float"]>;
  publishedVersion_not?: InputMaybe<Scalars["Float"]>;
  publishedVersion_not_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]>>>;
};

export type ContentfulMagnetPageBody = {
  __typename?: "ContentfulMagnetPageBody";
  json: Scalars["ContentfulJSON"];
  links: ContentfulMagnetPageBodyLinks;
};

export type ContentfulMagnetPageBodyLinks = {
  __typename?: "ContentfulMagnetPageBodyLinks";
  assets: ContentfulMagnetPageBodyAssets;
  entries: ContentfulMagnetPageBodyEntries;
  resources: ContentfulMagnetPageBodyResources;
};

export type ContentfulMagnetPageBodyAssets = {
  __typename?: "ContentfulMagnetPageBodyAssets";
  block: Array<Maybe<ContentfulAsset>>;
  hyperlink: Array<Maybe<ContentfulAsset>>;
};

export type ContentfulMagnetPageBodyEntries = {
  __typename?: "ContentfulMagnetPageBodyEntries";
  block: Array<Maybe<ContentfulEntry>>;
  hyperlink: Array<Maybe<ContentfulEntry>>;
  inline: Array<Maybe<ContentfulEntry>>;
};

export type ContentfulMagnetPageBodyResources = {
  __typename?: "ContentfulMagnetPageBodyResources";
  block: Array<ContentfulMagnetPageBodyResourcesBlock>;
  hyperlink: Array<ContentfulMagnetPageBodyResourcesHyperlink>;
  inline: Array<ContentfulMagnetPageBodyResourcesInline>;
};

export type ContentfulMagnetPageBodyResourcesBlock = ContentfulResourceLink & {
  __typename?: "ContentfulMagnetPageBodyResourcesBlock";
  sys: ContentfulResourceSys;
};

export type ContentfulResourceLink = {
  sys: ContentfulResourceSys;
};

export type ContentfulResourceSys = {
  __typename?: "ContentfulResourceSys";
  linkType: Scalars["String"];
  urn: Scalars["String"];
};

export type ContentfulMagnetPageBodyResourcesHyperlink =
  ContentfulResourceLink & {
    __typename?: "ContentfulMagnetPageBodyResourcesHyperlink";
    sys: ContentfulResourceSys;
  };

export type ContentfulMagnetPageBodyResourcesInline = ContentfulResourceLink & {
  __typename?: "ContentfulMagnetPageBodyResourcesInline";
  sys: ContentfulResourceSys;
};

export type ContentfulCategoryLabelFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulCategoryLabelFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulCategoryLabelFilter>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
  value?: InputMaybe<Scalars["String"]>;
  value_contains?: InputMaybe<Scalars["String"]>;
  value_exists?: InputMaybe<Scalars["Boolean"]>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  value_not?: InputMaybe<Scalars["String"]>;
  value_not_contains?: InputMaybe<Scalars["String"]>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

/** used to define related topic pills that link to /online-classes pages [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/categoryLabel) */
export type ContentfulCategoryLabel = Contentful_Node &
  ContentfulEntry & {
    __typename?: "ContentfulCategoryLabel";
    _id: Scalars["ID"];
    contentfulMetadata: ContentfulContentfulMetadata;
    linkedFrom: Maybe<ContentfulCategoryLabelLinkingCollections>;
    sys: ContentfulSys;
    value: Maybe<Scalars["String"]>;
  };

/** used to define related topic pills that link to /online-classes pages [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/categoryLabel) */
export type ContentfulCategoryLabelLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

/** used to define related topic pills that link to /online-classes pages [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/categoryLabel) */
export type ContentfulCategoryLabelValueArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

export type ContentfulCategoryLabelLinkingCollections = {
  __typename?: "ContentfulCategoryLabelLinkingCollections";
  clpEmailCaptureCollection: Maybe<ContentfulClpEmailCaptureCollection>;
  entryCollection: Maybe<ContentfulEntryCollection>;
  magnetPageCollection: Maybe<ContentfulMagnetPageCollection>;
};

export type ContentfulCategoryLabelLinkingCollectionsClpEmailCaptureCollectionArgs =
  {
    limit?: InputMaybe<Scalars["Int"]>;
    locale?: InputMaybe<Scalars["String"]>;
    order?: InputMaybe<
      Array<
        InputMaybe<ContentfulCategoryLabelLinkingCollectionsClpEmailCaptureCollectionOrder>
      >
    >;
    preview?: InputMaybe<Scalars["Boolean"]>;
    skip?: InputMaybe<Scalars["Int"]>;
  };

export type ContentfulCategoryLabelLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type ContentfulCategoryLabelLinkingCollectionsMagnetPageCollectionArgs =
  {
    limit?: InputMaybe<Scalars["Int"]>;
    locale?: InputMaybe<Scalars["String"]>;
    order?: InputMaybe<
      Array<
        InputMaybe<ContentfulCategoryLabelLinkingCollectionsMagnetPageCollectionOrder>
      >
    >;
    preview?: InputMaybe<Scalars["Boolean"]>;
    skip?: InputMaybe<Scalars["Int"]>;
  };

export enum ContentfulCategoryLabelLinkingCollectionsClpEmailCaptureCollectionOrder {
  CallToActionTextAsc = "callToActionText_ASC",
  CallToActionTextDesc = "callToActionText_DESC",
  ErrorMessageAsc = "errorMessage_ASC",
  ErrorMessageDesc = "errorMessage_DESC",
  IterableLinkAsc = "iterableLink_ASC",
  IterableLinkDesc = "iterableLink_DESC",
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  ProdIterableAudienceIdAsc = "prodIterableAudienceId_ASC",
  ProdIterableAudienceIdDesc = "prodIterableAudienceId_DESC",
  StagingIterableAudienceIdAsc = "stagingIterableAudienceId_ASC",
  StagingIterableAudienceIdDesc = "stagingIterableAudienceId_DESC",
  SuccessMessageAsc = "successMessage_ASC",
  SuccessMessageDesc = "successMessage_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export type ContentfulClpEmailCaptureCollection = {
  __typename?: "ContentfulClpEmailCaptureCollection";
  items: Array<Maybe<ContentfulClpEmailCapture>>;
  limit: Scalars["Int"];
  skip: Scalars["Int"];
  total: Scalars["Int"];
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/clpEmailCapture) */
export type ContentfulClpEmailCapture = Contentful_Node &
  ContentfulEntry & {
    __typename?: "ContentfulClpEmailCapture";
    _id: Scalars["ID"];
    bodyContent: Maybe<ContentfulClpEmailCaptureBodyContent>;
    callToActionText: Maybe<Scalars["String"]>;
    clpPagesCollection: Maybe<ContentfulClpEmailCaptureClpPagesCollection>;
    contentfulMetadata: ContentfulContentfulMetadata;
    errorMessage: Maybe<Scalars["String"]>;
    iterableLink: Maybe<Scalars["String"]>;
    linkedFrom: Maybe<ContentfulClpEmailCaptureLinkingCollections>;
    name: Maybe<Scalars["String"]>;
    prodIterableAudienceId: Maybe<Scalars["String"]>;
    stagingIterableAudienceId: Maybe<Scalars["String"]>;
    successMessage: Maybe<Scalars["String"]>;
    sys: ContentfulSys;
    title: Maybe<Scalars["String"]>;
  };

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/clpEmailCapture) */
export type ContentfulClpEmailCaptureBodyContentArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/clpEmailCapture) */
export type ContentfulClpEmailCaptureCallToActionTextArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/clpEmailCapture) */
export type ContentfulClpEmailCaptureClpPagesCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<
    Array<InputMaybe<ContentfulClpEmailCaptureClpPagesCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ContentfulCategoryLabelFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/clpEmailCapture) */
export type ContentfulClpEmailCaptureErrorMessageArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/clpEmailCapture) */
export type ContentfulClpEmailCaptureIterableLinkArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/clpEmailCapture) */
export type ContentfulClpEmailCaptureLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/clpEmailCapture) */
export type ContentfulClpEmailCaptureNameArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/clpEmailCapture) */
export type ContentfulClpEmailCaptureProdIterableAudienceIdArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/clpEmailCapture) */
export type ContentfulClpEmailCaptureStagingIterableAudienceIdArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/clpEmailCapture) */
export type ContentfulClpEmailCaptureSuccessMessageArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/clpEmailCapture) */
export type ContentfulClpEmailCaptureTitleArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

export type ContentfulClpEmailCaptureBodyContent = {
  __typename?: "ContentfulClpEmailCaptureBodyContent";
  json: Scalars["ContentfulJSON"];
  links: ContentfulClpEmailCaptureBodyContentLinks;
};

export type ContentfulClpEmailCaptureBodyContentLinks = {
  __typename?: "ContentfulClpEmailCaptureBodyContentLinks";
  assets: ContentfulClpEmailCaptureBodyContentAssets;
  entries: ContentfulClpEmailCaptureBodyContentEntries;
  resources: ContentfulClpEmailCaptureBodyContentResources;
};

export type ContentfulClpEmailCaptureBodyContentAssets = {
  __typename?: "ContentfulClpEmailCaptureBodyContentAssets";
  block: Array<Maybe<ContentfulAsset>>;
  hyperlink: Array<Maybe<ContentfulAsset>>;
};

export type ContentfulClpEmailCaptureBodyContentEntries = {
  __typename?: "ContentfulClpEmailCaptureBodyContentEntries";
  block: Array<Maybe<ContentfulEntry>>;
  hyperlink: Array<Maybe<ContentfulEntry>>;
  inline: Array<Maybe<ContentfulEntry>>;
};

export type ContentfulClpEmailCaptureBodyContentResources = {
  __typename?: "ContentfulClpEmailCaptureBodyContentResources";
  block: Array<ContentfulClpEmailCaptureBodyContentResourcesBlock>;
  hyperlink: Array<ContentfulClpEmailCaptureBodyContentResourcesHyperlink>;
  inline: Array<ContentfulClpEmailCaptureBodyContentResourcesInline>;
};

export type ContentfulClpEmailCaptureBodyContentResourcesBlock =
  ContentfulResourceLink & {
    __typename?: "ContentfulClpEmailCaptureBodyContentResourcesBlock";
    sys: ContentfulResourceSys;
  };

export type ContentfulClpEmailCaptureBodyContentResourcesHyperlink =
  ContentfulResourceLink & {
    __typename?: "ContentfulClpEmailCaptureBodyContentResourcesHyperlink";
    sys: ContentfulResourceSys;
  };

export type ContentfulClpEmailCaptureBodyContentResourcesInline =
  ContentfulResourceLink & {
    __typename?: "ContentfulClpEmailCaptureBodyContentResourcesInline";
    sys: ContentfulResourceSys;
  };

export enum ContentfulClpEmailCaptureClpPagesCollectionOrder {
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  ValueAsc = "value_ASC",
  ValueDesc = "value_DESC",
}

export type ContentfulClpEmailCaptureClpPagesCollection = {
  __typename?: "ContentfulClpEmailCaptureClpPagesCollection";
  items: Array<Maybe<ContentfulCategoryLabel>>;
  limit: Scalars["Int"];
  skip: Scalars["Int"];
  total: Scalars["Int"];
};

export type ContentfulClpEmailCaptureLinkingCollections = {
  __typename?: "ContentfulClpEmailCaptureLinkingCollections";
  entryCollection: Maybe<ContentfulEntryCollection>;
  landingPageCollection: Maybe<ContentfulLandingPageCollection>;
};

export type ContentfulClpEmailCaptureLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type ContentfulClpEmailCaptureLinkingCollectionsLandingPageCollectionArgs =
  {
    limit?: InputMaybe<Scalars["Int"]>;
    locale?: InputMaybe<Scalars["String"]>;
    order?: InputMaybe<
      Array<
        InputMaybe<ContentfulClpEmailCaptureLinkingCollectionsLandingPageCollectionOrder>
      >
    >;
    preview?: InputMaybe<Scalars["Boolean"]>;
    skip?: InputMaybe<Scalars["Int"]>;
  };

export enum ContentfulClpEmailCaptureLinkingCollectionsLandingPageCollectionOrder {
  MetaTagCanonicalUrlAsc = "metaTagCanonicalUrl_ASC",
  MetaTagCanonicalUrlDesc = "metaTagCanonicalUrl_DESC",
  MetaTagDescriptionAsc = "metaTagDescription_ASC",
  MetaTagDescriptionDesc = "metaTagDescription_DESC",
  NavbarCtaTextAsc = "navbarCtaText_ASC",
  NavbarCtaTextDesc = "navbarCtaText_DESC",
  NavbarCtaUrlAsc = "navbarCtaUrl_ASC",
  NavbarCtaUrlDesc = "navbarCtaUrl_DESC",
  NavbarCtaUrlLoggedInAsc = "navbarCtaUrlLoggedIn_ASC",
  NavbarCtaUrlLoggedInDesc = "navbarCtaUrlLoggedIn_DESC",
  SlugAsc = "slug_ASC",
  SlugDesc = "slug_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export type ContentfulLandingPageCollection = {
  __typename?: "ContentfulLandingPageCollection";
  items: Array<Maybe<ContentfulLandingPage>>;
  limit: Scalars["Int"];
  skip: Scalars["Int"];
  total: Scalars["Int"];
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPage) */
export type ContentfulLandingPage = Contentful_Node &
  ContentfulEntry & {
    __typename?: "ContentfulLandingPage";
    _id: Scalars["ID"];
    contentfulMetadata: ContentfulContentfulMetadata;
    linkedFrom: Maybe<ContentfulLandingPageLinkingCollections>;
    metaTagCanonicalUrl: Maybe<Scalars["String"]>;
    metaTagDescription: Maybe<Scalars["String"]>;
    metaTagSharedLinkRichPreviewImage: Maybe<ContentfulAsset>;
    navbarCtaText: Maybe<Scalars["String"]>;
    navbarCtaUrl: Maybe<Scalars["String"]>;
    navbarCtaUrlLoggedIn: Maybe<Scalars["String"]>;
    sectionsCollection: Maybe<ContentfulLandingPageSectionsCollection>;
    slug: Maybe<Scalars["String"]>;
    sys: ContentfulSys;
    title: Maybe<Scalars["String"]>;
  };

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPage) */
export type ContentfulLandingPageLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPage) */
export type ContentfulLandingPageMetaTagCanonicalUrlArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPage) */
export type ContentfulLandingPageMetaTagDescriptionArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPage) */
export type ContentfulLandingPageMetaTagSharedLinkRichPreviewImageArgs = {
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPage) */
export type ContentfulLandingPageNavbarCtaTextArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPage) */
export type ContentfulLandingPageNavbarCtaUrlArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPage) */
export type ContentfulLandingPageNavbarCtaUrlLoggedInArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPage) */
export type ContentfulLandingPageSectionsCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ContentfulLandingPageSectionsFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPage) */
export type ContentfulLandingPageSlugArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPage) */
export type ContentfulLandingPageTitleArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

export type ContentfulLandingPageLinkingCollections = {
  __typename?: "ContentfulLandingPageLinkingCollections";
  entryCollection: Maybe<ContentfulEntryCollection>;
};

export type ContentfulLandingPageLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type ContentfulLandingPageSectionsFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulLandingPageSectionsFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulLandingPageSectionsFilter>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
};

export type ContentfulLandingPageSectionsCollection = {
  __typename?: "ContentfulLandingPageSectionsCollection";
  items: Array<Maybe<ContentfulLandingPageSectionsItem>>;
  limit: Scalars["Int"];
  skip: Scalars["Int"];
  total: Scalars["Int"];
};

export type ContentfulLandingPageSectionsItem =
  | ContentfulClpEmailCapture
  | ContentfulLandingPageClassCarousel
  | ContentfulLandingPageHeroSection
  | ContentfulLandingPageIconInfoSection
  | ContentfulLandingPageImageTextCtaSection
  | ContentfulLandingPageJourneySection
  | ContentfulLandingPageLessonPreviewSection
  | ContentfulLandingPageSingleReviewSection
  | ContentfulLandingPageStatBoxSection
  | ContentfulLandingPageTextOnlyStackedCtaSection;

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageClassCarousel) */
export type ContentfulLandingPageClassCarousel = Contentful_Node &
  ContentfulEntry & {
    __typename?: "ContentfulLandingPageClassCarousel";
    _id: Scalars["ID"];
    contentfulMetadata: ContentfulContentfulMetadata;
    id: Maybe<Scalars["String"]>;
    linkedFrom: Maybe<ContentfulLandingPageClassCarouselLinkingCollections>;
    searchFilters: Maybe<ContentfulSearchFilters>;
    sys: ContentfulSys;
    title: Maybe<Scalars["String"]>;
  };

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageClassCarousel) */
export type ContentfulLandingPageClassCarouselIdArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageClassCarousel) */
export type ContentfulLandingPageClassCarouselLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageClassCarousel) */
export type ContentfulLandingPageClassCarouselSearchFiltersArgs = {
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
  where?: InputMaybe<ContentfulSearchFiltersFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageClassCarousel) */
export type ContentfulLandingPageClassCarouselTitleArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

export type ContentfulLandingPageClassCarouselLinkingCollections = {
  __typename?: "ContentfulLandingPageClassCarouselLinkingCollections";
  entryCollection: Maybe<ContentfulEntryCollection>;
  landingPageCollection: Maybe<ContentfulLandingPageCollection>;
};

export type ContentfulLandingPageClassCarouselLinkingCollectionsEntryCollectionArgs =
  {
    limit?: InputMaybe<Scalars["Int"]>;
    locale?: InputMaybe<Scalars["String"]>;
    preview?: InputMaybe<Scalars["Boolean"]>;
    skip?: InputMaybe<Scalars["Int"]>;
  };

export type ContentfulLandingPageClassCarouselLinkingCollectionsLandingPageCollectionArgs =
  {
    limit?: InputMaybe<Scalars["Int"]>;
    locale?: InputMaybe<Scalars["String"]>;
    order?: InputMaybe<
      Array<
        InputMaybe<ContentfulLandingPageClassCarouselLinkingCollectionsLandingPageCollectionOrder>
      >
    >;
    preview?: InputMaybe<Scalars["Boolean"]>;
    skip?: InputMaybe<Scalars["Int"]>;
  };

export enum ContentfulLandingPageClassCarouselLinkingCollectionsLandingPageCollectionOrder {
  MetaTagCanonicalUrlAsc = "metaTagCanonicalUrl_ASC",
  MetaTagCanonicalUrlDesc = "metaTagCanonicalUrl_DESC",
  MetaTagDescriptionAsc = "metaTagDescription_ASC",
  MetaTagDescriptionDesc = "metaTagDescription_DESC",
  NavbarCtaTextAsc = "navbarCtaText_ASC",
  NavbarCtaTextDesc = "navbarCtaText_DESC",
  NavbarCtaUrlAsc = "navbarCtaUrl_ASC",
  NavbarCtaUrlDesc = "navbarCtaUrl_DESC",
  NavbarCtaUrlLoggedInAsc = "navbarCtaUrlLoggedIn_ASC",
  NavbarCtaUrlLoggedInDesc = "navbarCtaUrlLoggedIn_DESC",
  SlugAsc = "slug_ASC",
  SlugDesc = "slug_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export type ContentfulSearchFiltersFilter = {
  adminTags_contains_all?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  adminTags_contains_none?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  adminTags_contains_some?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  adminTags_exists?: InputMaybe<Scalars["Boolean"]>;
  ages_contains_all?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  ages_contains_none?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  ages_contains_some?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  ages_exists?: InputMaybe<Scalars["Boolean"]>;
  AND?: InputMaybe<Array<InputMaybe<ContentfulSearchFiltersFilter>>>;
  capacityMax?: InputMaybe<Scalars["Int"]>;
  capacityMax_exists?: InputMaybe<Scalars["Boolean"]>;
  capacityMax_gt?: InputMaybe<Scalars["Int"]>;
  capacityMax_gte?: InputMaybe<Scalars["Int"]>;
  capacityMax_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  capacityMax_lt?: InputMaybe<Scalars["Int"]>;
  capacityMax_lte?: InputMaybe<Scalars["Int"]>;
  capacityMax_not?: InputMaybe<Scalars["Int"]>;
  capacityMax_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  capacityMin?: InputMaybe<Scalars["Int"]>;
  capacityMin_exists?: InputMaybe<Scalars["Boolean"]>;
  capacityMin_gt?: InputMaybe<Scalars["Int"]>;
  capacityMin_gte?: InputMaybe<Scalars["Int"]>;
  capacityMin_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  capacityMin_lt?: InputMaybe<Scalars["Int"]>;
  capacityMin_lte?: InputMaybe<Scalars["Int"]>;
  capacityMin_not?: InputMaybe<Scalars["Int"]>;
  capacityMin_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  deliveryTypes_contains_all?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  deliveryTypes_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  deliveryTypes_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  deliveryTypes_exists?: InputMaybe<Scalars["Boolean"]>;
  durationWeeksMax?: InputMaybe<Scalars["Int"]>;
  durationWeeksMax_exists?: InputMaybe<Scalars["Boolean"]>;
  durationWeeksMax_gt?: InputMaybe<Scalars["Int"]>;
  durationWeeksMax_gte?: InputMaybe<Scalars["Int"]>;
  durationWeeksMax_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  durationWeeksMax_lt?: InputMaybe<Scalars["Int"]>;
  durationWeeksMax_lte?: InputMaybe<Scalars["Int"]>;
  durationWeeksMax_not?: InputMaybe<Scalars["Int"]>;
  durationWeeksMax_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  durationWeeksMin?: InputMaybe<Scalars["Int"]>;
  durationWeeksMin_exists?: InputMaybe<Scalars["Boolean"]>;
  durationWeeksMin_gt?: InputMaybe<Scalars["Int"]>;
  durationWeeksMin_gte?: InputMaybe<Scalars["Int"]>;
  durationWeeksMin_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  durationWeeksMin_lt?: InputMaybe<Scalars["Int"]>;
  durationWeeksMin_lte?: InputMaybe<Scalars["Int"]>;
  durationWeeksMin_not?: InputMaybe<Scalars["Int"]>;
  durationWeeksMin_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  endBy?: InputMaybe<Scalars["ContentfulDateTime"]>;
  endBy_exists?: InputMaybe<Scalars["Boolean"]>;
  endBy_gt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  endBy_gte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  endBy_in?: InputMaybe<Array<InputMaybe<Scalars["ContentfulDateTime"]>>>;
  endBy_lt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  endBy_lte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  endBy_not?: InputMaybe<Scalars["ContentfulDateTime"]>;
  endBy_not_in?: InputMaybe<Array<InputMaybe<Scalars["ContentfulDateTime"]>>>;
  filtersName?: InputMaybe<Scalars["String"]>;
  filtersName_contains?: InputMaybe<Scalars["String"]>;
  filtersName_exists?: InputMaybe<Scalars["Boolean"]>;
  filtersName_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  filtersName_not?: InputMaybe<Scalars["String"]>;
  filtersName_not_contains?: InputMaybe<Scalars["String"]>;
  filtersName_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  formats_contains_all?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  formats_contains_none?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  formats_contains_some?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  formats_exists?: InputMaybe<Scalars["Boolean"]>;
  fundingPrograms_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  fundingPrograms_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  fundingPrograms_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  fundingPrograms_exists?: InputMaybe<Scalars["Boolean"]>;
  gradeLevel_contains_all?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  gradeLevel_contains_none?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  gradeLevel_contains_some?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  gradeLevel_exists?: InputMaybe<Scalars["Boolean"]>;
  hasFilledOutUniqueLearningNeeds?: InputMaybe<Scalars["Boolean"]>;
  hasFilledOutUniqueLearningNeeds_exists?: InputMaybe<Scalars["Boolean"]>;
  hasFilledOutUniqueLearningNeeds_not?: InputMaybe<Scalars["Boolean"]>;
  multiTermQuery?: InputMaybe<Scalars["String"]>;
  multiTermQuery_contains?: InputMaybe<Scalars["String"]>;
  multiTermQuery_exists?: InputMaybe<Scalars["Boolean"]>;
  multiTermQuery_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  multiTermQuery_not?: InputMaybe<Scalars["String"]>;
  multiTermQuery_not_contains?: InputMaybe<Scalars["String"]>;
  multiTermQuery_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulSearchFiltersFilter>>>;
  pricePerCourseMax?: InputMaybe<Scalars["Int"]>;
  pricePerCourseMax_exists?: InputMaybe<Scalars["Boolean"]>;
  pricePerCourseMax_gt?: InputMaybe<Scalars["Int"]>;
  pricePerCourseMax_gte?: InputMaybe<Scalars["Int"]>;
  pricePerCourseMax_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  pricePerCourseMax_lt?: InputMaybe<Scalars["Int"]>;
  pricePerCourseMax_lte?: InputMaybe<Scalars["Int"]>;
  pricePerCourseMax_not?: InputMaybe<Scalars["Int"]>;
  pricePerCourseMax_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  pricePerCourseMin?: InputMaybe<Scalars["Int"]>;
  pricePerCourseMin_exists?: InputMaybe<Scalars["Boolean"]>;
  pricePerCourseMin_gt?: InputMaybe<Scalars["Int"]>;
  pricePerCourseMin_gte?: InputMaybe<Scalars["Int"]>;
  pricePerCourseMin_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  pricePerCourseMin_lt?: InputMaybe<Scalars["Int"]>;
  pricePerCourseMin_lte?: InputMaybe<Scalars["Int"]>;
  pricePerCourseMin_not?: InputMaybe<Scalars["Int"]>;
  pricePerCourseMin_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  pricePerMeetingMax?: InputMaybe<Scalars["Int"]>;
  pricePerMeetingMax_exists?: InputMaybe<Scalars["Boolean"]>;
  pricePerMeetingMax_gt?: InputMaybe<Scalars["Int"]>;
  pricePerMeetingMax_gte?: InputMaybe<Scalars["Int"]>;
  pricePerMeetingMax_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  pricePerMeetingMax_lt?: InputMaybe<Scalars["Int"]>;
  pricePerMeetingMax_lte?: InputMaybe<Scalars["Int"]>;
  pricePerMeetingMax_not?: InputMaybe<Scalars["Int"]>;
  pricePerMeetingMax_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  pricePerMeetingMin?: InputMaybe<Scalars["Int"]>;
  pricePerMeetingMin_exists?: InputMaybe<Scalars["Boolean"]>;
  pricePerMeetingMin_gt?: InputMaybe<Scalars["Int"]>;
  pricePerMeetingMin_gte?: InputMaybe<Scalars["Int"]>;
  pricePerMeetingMin_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  pricePerMeetingMin_lt?: InputMaybe<Scalars["Int"]>;
  pricePerMeetingMin_lte?: InputMaybe<Scalars["Int"]>;
  pricePerMeetingMin_not?: InputMaybe<Scalars["Int"]>;
  pricePerMeetingMin_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  query?: InputMaybe<Scalars["String"]>;
  query_contains?: InputMaybe<Scalars["String"]>;
  query_exists?: InputMaybe<Scalars["Boolean"]>;
  query_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  query_not?: InputMaybe<Scalars["String"]>;
  query_not_contains?: InputMaybe<Scalars["String"]>;
  query_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  startAfter?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startAfter_exists?: InputMaybe<Scalars["Boolean"]>;
  startAfter_gt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startAfter_gte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startAfter_in?: InputMaybe<Array<InputMaybe<Scalars["ContentfulDateTime"]>>>;
  startAfter_lt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startAfter_lte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startAfter_not?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startAfter_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["ContentfulDateTime"]>>
  >;
  startBefore?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startBefore_exists?: InputMaybe<Scalars["Boolean"]>;
  startBefore_gt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startBefore_gte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startBefore_in?: InputMaybe<Array<InputMaybe<Scalars["ContentfulDateTime"]>>>;
  startBefore_lt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startBefore_lte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startBefore_not?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startBefore_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["ContentfulDateTime"]>>
  >;
  subject?: InputMaybe<Scalars["String"]>;
  subject_contains?: InputMaybe<Scalars["String"]>;
  subject_exists?: InputMaybe<Scalars["Boolean"]>;
  subject_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  subject_not?: InputMaybe<Scalars["String"]>;
  subject_not_contains?: InputMaybe<Scalars["String"]>;
  subject_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
  uniqueLearningNeeds?: InputMaybe<Scalars["String"]>;
  uniqueLearningNeeds_contains?: InputMaybe<Scalars["String"]>;
  uniqueLearningNeeds_exists?: InputMaybe<Scalars["Boolean"]>;
  uniqueLearningNeeds_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  uniqueLearningNeeds_not?: InputMaybe<Scalars["String"]>;
  uniqueLearningNeeds_not_contains?: InputMaybe<Scalars["String"]>;
  uniqueLearningNeeds_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  weeklyMeetingsMax?: InputMaybe<Scalars["Int"]>;
  weeklyMeetingsMax_exists?: InputMaybe<Scalars["Boolean"]>;
  weeklyMeetingsMax_gt?: InputMaybe<Scalars["Int"]>;
  weeklyMeetingsMax_gte?: InputMaybe<Scalars["Int"]>;
  weeklyMeetingsMax_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  weeklyMeetingsMax_lt?: InputMaybe<Scalars["Int"]>;
  weeklyMeetingsMax_lte?: InputMaybe<Scalars["Int"]>;
  weeklyMeetingsMax_not?: InputMaybe<Scalars["Int"]>;
  weeklyMeetingsMax_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  weeklyMeetingsMin?: InputMaybe<Scalars["Int"]>;
  weeklyMeetingsMin_exists?: InputMaybe<Scalars["Boolean"]>;
  weeklyMeetingsMin_gt?: InputMaybe<Scalars["Int"]>;
  weeklyMeetingsMin_gte?: InputMaybe<Scalars["Int"]>;
  weeklyMeetingsMin_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  weeklyMeetingsMin_lt?: InputMaybe<Scalars["Int"]>;
  weeklyMeetingsMin_lte?: InputMaybe<Scalars["Int"]>;
  weeklyMeetingsMin_not?: InputMaybe<Scalars["Int"]>;
  weeklyMeetingsMin_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
};

/** Fields map to the filters on /search [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/searchFilters) */
export type ContentfulSearchFilters = Contentful_Node &
  ContentfulEntry & {
    __typename?: "ContentfulSearchFilters";
    _id: Scalars["ID"];
    adminTags: Maybe<Array<Maybe<Scalars["String"]>>>;
    ages: Maybe<Array<Maybe<Scalars["String"]>>>;
    capacityMax: Maybe<Scalars["Int"]>;
    capacityMin: Maybe<Scalars["Int"]>;
    contentfulMetadata: ContentfulContentfulMetadata;
    deliveryTypes: Maybe<Array<Maybe<Scalars["String"]>>>;
    durationWeeksMax: Maybe<Scalars["Int"]>;
    durationWeeksMin: Maybe<Scalars["Int"]>;
    endBy: Maybe<Scalars["ContentfulDateTime"]>;
    filtersName: Maybe<Scalars["String"]>;
    formats: Maybe<Array<Maybe<Scalars["String"]>>>;
    fundingPrograms: Maybe<Array<Maybe<Scalars["String"]>>>;
    gradeLevel: Maybe<Array<Maybe<Scalars["String"]>>>;
    hasFilledOutUniqueLearningNeeds: Maybe<Scalars["Boolean"]>;
    linkedFrom: Maybe<ContentfulSearchFiltersLinkingCollections>;
    multiTermQuery: Maybe<Scalars["String"]>;
    pricePerCourseMax: Maybe<Scalars["Int"]>;
    pricePerCourseMin: Maybe<Scalars["Int"]>;
    pricePerMeetingMax: Maybe<Scalars["Int"]>;
    pricePerMeetingMin: Maybe<Scalars["Int"]>;
    query: Maybe<Scalars["String"]>;
    startAfter: Maybe<Scalars["ContentfulDateTime"]>;
    startBefore: Maybe<Scalars["ContentfulDateTime"]>;
    subject: Maybe<Scalars["String"]>;
    sys: ContentfulSys;
    uniqueLearningNeeds: Maybe<Scalars["String"]>;
    weeklyMeetingsMax: Maybe<Scalars["Int"]>;
    weeklyMeetingsMin: Maybe<Scalars["Int"]>;
  };

/** Fields map to the filters on /search [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/searchFilters) */
export type ContentfulSearchFiltersAdminTagsArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** Fields map to the filters on /search [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/searchFilters) */
export type ContentfulSearchFiltersAgesArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** Fields map to the filters on /search [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/searchFilters) */
export type ContentfulSearchFiltersCapacityMaxArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** Fields map to the filters on /search [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/searchFilters) */
export type ContentfulSearchFiltersCapacityMinArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** Fields map to the filters on /search [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/searchFilters) */
export type ContentfulSearchFiltersDeliveryTypesArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** Fields map to the filters on /search [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/searchFilters) */
export type ContentfulSearchFiltersDurationWeeksMaxArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** Fields map to the filters on /search [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/searchFilters) */
export type ContentfulSearchFiltersDurationWeeksMinArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** Fields map to the filters on /search [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/searchFilters) */
export type ContentfulSearchFiltersEndByArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** Fields map to the filters on /search [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/searchFilters) */
export type ContentfulSearchFiltersFiltersNameArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** Fields map to the filters on /search [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/searchFilters) */
export type ContentfulSearchFiltersFormatsArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** Fields map to the filters on /search [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/searchFilters) */
export type ContentfulSearchFiltersFundingProgramsArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** Fields map to the filters on /search [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/searchFilters) */
export type ContentfulSearchFiltersGradeLevelArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** Fields map to the filters on /search [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/searchFilters) */
export type ContentfulSearchFiltersHasFilledOutUniqueLearningNeedsArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** Fields map to the filters on /search [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/searchFilters) */
export type ContentfulSearchFiltersLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

/** Fields map to the filters on /search [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/searchFilters) */
export type ContentfulSearchFiltersMultiTermQueryArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** Fields map to the filters on /search [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/searchFilters) */
export type ContentfulSearchFiltersPricePerCourseMaxArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** Fields map to the filters on /search [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/searchFilters) */
export type ContentfulSearchFiltersPricePerCourseMinArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** Fields map to the filters on /search [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/searchFilters) */
export type ContentfulSearchFiltersPricePerMeetingMaxArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** Fields map to the filters on /search [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/searchFilters) */
export type ContentfulSearchFiltersPricePerMeetingMinArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** Fields map to the filters on /search [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/searchFilters) */
export type ContentfulSearchFiltersQueryArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** Fields map to the filters on /search [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/searchFilters) */
export type ContentfulSearchFiltersStartAfterArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** Fields map to the filters on /search [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/searchFilters) */
export type ContentfulSearchFiltersStartBeforeArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** Fields map to the filters on /search [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/searchFilters) */
export type ContentfulSearchFiltersSubjectArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** Fields map to the filters on /search [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/searchFilters) */
export type ContentfulSearchFiltersUniqueLearningNeedsArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** Fields map to the filters on /search [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/searchFilters) */
export type ContentfulSearchFiltersWeeklyMeetingsMaxArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** Fields map to the filters on /search [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/searchFilters) */
export type ContentfulSearchFiltersWeeklyMeetingsMinArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

export type ContentfulSearchFiltersLinkingCollections = {
  __typename?: "ContentfulSearchFiltersLinkingCollections";
  entryCollection: Maybe<ContentfulEntryCollection>;
  landingPageClassCarouselCollection: Maybe<ContentfulLandingPageClassCarouselCollection>;
  listingsPromoBannerButtonCollection: Maybe<ContentfulListingsPromoBannerButtonCollection>;
};

export type ContentfulSearchFiltersLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type ContentfulSearchFiltersLinkingCollectionsLandingPageClassCarouselCollectionArgs =
  {
    limit?: InputMaybe<Scalars["Int"]>;
    locale?: InputMaybe<Scalars["String"]>;
    order?: InputMaybe<
      Array<
        InputMaybe<ContentfulSearchFiltersLinkingCollectionsLandingPageClassCarouselCollectionOrder>
      >
    >;
    preview?: InputMaybe<Scalars["Boolean"]>;
    skip?: InputMaybe<Scalars["Int"]>;
  };

export type ContentfulSearchFiltersLinkingCollectionsListingsPromoBannerButtonCollectionArgs =
  {
    limit?: InputMaybe<Scalars["Int"]>;
    locale?: InputMaybe<Scalars["String"]>;
    order?: InputMaybe<
      Array<
        InputMaybe<ContentfulSearchFiltersLinkingCollectionsListingsPromoBannerButtonCollectionOrder>
      >
    >;
    preview?: InputMaybe<Scalars["Boolean"]>;
    skip?: InputMaybe<Scalars["Int"]>;
  };

export enum ContentfulSearchFiltersLinkingCollectionsLandingPageClassCarouselCollectionOrder {
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export type ContentfulLandingPageClassCarouselCollection = {
  __typename?: "ContentfulLandingPageClassCarouselCollection";
  items: Array<Maybe<ContentfulLandingPageClassCarousel>>;
  limit: Scalars["Int"];
  skip: Scalars["Int"];
  total: Scalars["Int"];
};

export enum ContentfulSearchFiltersLinkingCollectionsListingsPromoBannerButtonCollectionOrder {
  ButtonTextAsc = "buttonText_ASC",
  ButtonTextDesc = "buttonText_DESC",
  ExploreMoreButtonTextAsc = "exploreMoreButtonText_ASC",
  ExploreMoreButtonTextDesc = "exploreMoreButtonText_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
}

export type ContentfulListingsPromoBannerButtonCollection = {
  __typename?: "ContentfulListingsPromoBannerButtonCollection";
  items: Array<Maybe<ContentfulListingsPromoBannerButton>>;
  limit: Scalars["Int"];
  skip: Scalars["Int"];
  total: Scalars["Int"];
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/listingsPromoBannerButton) */
export type ContentfulListingsPromoBannerButton = Contentful_Node &
  ContentfulEntry & {
    __typename?: "ContentfulListingsPromoBannerButton";
    _id: Scalars["ID"];
    buttonSearchFilters: Maybe<ContentfulSearchFilters>;
    buttonText: Maybe<Scalars["String"]>;
    contentfulMetadata: ContentfulContentfulMetadata;
    exploreMoreButtonText: Maybe<Scalars["String"]>;
    linkedFrom: Maybe<ContentfulListingsPromoBannerButtonLinkingCollections>;
    sys: ContentfulSys;
  };

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/listingsPromoBannerButton) */
export type ContentfulListingsPromoBannerButtonButtonSearchFiltersArgs = {
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
  where?: InputMaybe<ContentfulSearchFiltersFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/listingsPromoBannerButton) */
export type ContentfulListingsPromoBannerButtonButtonTextArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/listingsPromoBannerButton) */
export type ContentfulListingsPromoBannerButtonExploreMoreButtonTextArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/listingsPromoBannerButton) */
export type ContentfulListingsPromoBannerButtonLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type ContentfulListingsPromoBannerButtonLinkingCollections = {
  __typename?: "ContentfulListingsPromoBannerButtonLinkingCollections";
  entryCollection: Maybe<ContentfulEntryCollection>;
  listingsPromoBannerCollection: Maybe<ContentfulListingsPromoBannerCollection>;
};

export type ContentfulListingsPromoBannerButtonLinkingCollectionsEntryCollectionArgs =
  {
    limit?: InputMaybe<Scalars["Int"]>;
    locale?: InputMaybe<Scalars["String"]>;
    preview?: InputMaybe<Scalars["Boolean"]>;
    skip?: InputMaybe<Scalars["Int"]>;
  };

export type ContentfulListingsPromoBannerButtonLinkingCollectionsListingsPromoBannerCollectionArgs =
  {
    limit?: InputMaybe<Scalars["Int"]>;
    locale?: InputMaybe<Scalars["String"]>;
    order?: InputMaybe<
      Array<
        InputMaybe<ContentfulListingsPromoBannerButtonLinkingCollectionsListingsPromoBannerCollectionOrder>
      >
    >;
    preview?: InputMaybe<Scalars["Boolean"]>;
    skip?: InputMaybe<Scalars["Int"]>;
  };

export enum ContentfulListingsPromoBannerButtonLinkingCollectionsListingsPromoBannerCollectionOrder {
  ExperimentVersionAsc = "experimentVersion_ASC",
  ExperimentVersionDesc = "experimentVersion_DESC",
  HeaderSubtitleAsc = "headerSubtitle_ASC",
  HeaderSubtitleDesc = "headerSubtitle_DESC",
  HeaderTitleAsc = "headerTitle_ASC",
  HeaderTitleDesc = "headerTitle_DESC",
  IncludeControlGroupAsc = "includeControlGroup_ASC",
  IncludeControlGroupDesc = "includeControlGroup_DESC",
  IsLiveOnSearchAsc = "isLiveOnSearch_ASC",
  IsLiveOnSearchDesc = "isLiveOnSearch_DESC",
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  SidebarTitleAsc = "sidebarTitle_ASC",
  SidebarTitleDesc = "sidebarTitle_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
}

export type ContentfulListingsPromoBannerCollection = {
  __typename?: "ContentfulListingsPromoBannerCollection";
  items: Array<Maybe<ContentfulListingsPromoBanner>>;
  limit: Scalars["Int"];
  skip: Scalars["Int"];
  total: Scalars["Int"];
};

/** CTA banner, with listings, e.g. on home page, search page [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/listingsPromoBanner) */
export type ContentfulListingsPromoBanner = Contentful_Node &
  ContentfulEntry & {
    __typename?: "ContentfulListingsPromoBanner";
    _id: Scalars["ID"];
    backgroundImageLeft: Maybe<ContentfulAsset>;
    backgroundImageMobile: Maybe<ContentfulAsset>;
    backgroundImageRight: Maybe<ContentfulAsset>;
    buttonsCollection: Maybe<ContentfulListingsPromoBannerButtonsCollection>;
    contentfulMetadata: ContentfulContentfulMetadata;
    experimentVersion: Maybe<Scalars["Int"]>;
    headerSubtitle: Maybe<Scalars["String"]>;
    headerTitle: Maybe<Scalars["String"]>;
    headerTitleFontDefinition: Maybe<ContentfulListingsPromoBannerHeaderTitleFontDefinition>;
    includeControlGroup: Maybe<Scalars["Boolean"]>;
    isLiveOnSearch: Maybe<Scalars["Boolean"]>;
    linkedFrom: Maybe<ContentfulListingsPromoBannerLinkingCollections>;
    name: Maybe<Scalars["String"]>;
    sidebarTitle: Maybe<Scalars["String"]>;
    sys: ContentfulSys;
  };

/** CTA banner, with listings, e.g. on home page, search page [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/listingsPromoBanner) */
export type ContentfulListingsPromoBannerBackgroundImageLeftArgs = {
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
};

/** CTA banner, with listings, e.g. on home page, search page [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/listingsPromoBanner) */
export type ContentfulListingsPromoBannerBackgroundImageMobileArgs = {
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
};

/** CTA banner, with listings, e.g. on home page, search page [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/listingsPromoBanner) */
export type ContentfulListingsPromoBannerBackgroundImageRightArgs = {
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
};

/** CTA banner, with listings, e.g. on home page, search page [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/listingsPromoBanner) */
export type ContentfulListingsPromoBannerButtonsCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<
    Array<InputMaybe<ContentfulListingsPromoBannerButtonsCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ContentfulListingsPromoBannerButtonFilter>;
};

/** CTA banner, with listings, e.g. on home page, search page [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/listingsPromoBanner) */
export type ContentfulListingsPromoBannerExperimentVersionArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** CTA banner, with listings, e.g. on home page, search page [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/listingsPromoBanner) */
export type ContentfulListingsPromoBannerHeaderSubtitleArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** CTA banner, with listings, e.g. on home page, search page [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/listingsPromoBanner) */
export type ContentfulListingsPromoBannerHeaderTitleArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** CTA banner, with listings, e.g. on home page, search page [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/listingsPromoBanner) */
export type ContentfulListingsPromoBannerHeaderTitleFontDefinitionArgs = {
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
  where?: InputMaybe<ContentfulListingsPromoBannerHeaderTitleFontDefinitionFilter>;
};

/** CTA banner, with listings, e.g. on home page, search page [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/listingsPromoBanner) */
export type ContentfulListingsPromoBannerIncludeControlGroupArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** CTA banner, with listings, e.g. on home page, search page [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/listingsPromoBanner) */
export type ContentfulListingsPromoBannerIsLiveOnSearchArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** CTA banner, with listings, e.g. on home page, search page [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/listingsPromoBanner) */
export type ContentfulListingsPromoBannerLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

/** CTA banner, with listings, e.g. on home page, search page [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/listingsPromoBanner) */
export type ContentfulListingsPromoBannerNameArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** CTA banner, with listings, e.g. on home page, search page [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/listingsPromoBanner) */
export type ContentfulListingsPromoBannerSidebarTitleArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

export enum ContentfulListingsPromoBannerButtonsCollectionOrder {
  ButtonTextAsc = "buttonText_ASC",
  ButtonTextDesc = "buttonText_DESC",
  ExploreMoreButtonTextAsc = "exploreMoreButtonText_ASC",
  ExploreMoreButtonTextDesc = "exploreMoreButtonText_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
}

export type ContentfulListingsPromoBannerButtonFilter = {
  AND?: InputMaybe<
    Array<InputMaybe<ContentfulListingsPromoBannerButtonFilter>>
  >;
  buttonSearchFilters?: InputMaybe<ContentfulcfSearchFiltersNestedFilter>;
  buttonSearchFilters_exists?: InputMaybe<Scalars["Boolean"]>;
  buttonText?: InputMaybe<Scalars["String"]>;
  buttonText_contains?: InputMaybe<Scalars["String"]>;
  buttonText_exists?: InputMaybe<Scalars["Boolean"]>;
  buttonText_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  buttonText_not?: InputMaybe<Scalars["String"]>;
  buttonText_not_contains?: InputMaybe<Scalars["String"]>;
  buttonText_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  exploreMoreButtonText?: InputMaybe<Scalars["String"]>;
  exploreMoreButtonText_contains?: InputMaybe<Scalars["String"]>;
  exploreMoreButtonText_exists?: InputMaybe<Scalars["Boolean"]>;
  exploreMoreButtonText_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  exploreMoreButtonText_not?: InputMaybe<Scalars["String"]>;
  exploreMoreButtonText_not_contains?: InputMaybe<Scalars["String"]>;
  exploreMoreButtonText_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  OR?: InputMaybe<Array<InputMaybe<ContentfulListingsPromoBannerButtonFilter>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
};

export type ContentfulcfSearchFiltersNestedFilter = {
  adminTags_contains_all?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  adminTags_contains_none?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  adminTags_contains_some?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  adminTags_exists?: InputMaybe<Scalars["Boolean"]>;
  ages_contains_all?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  ages_contains_none?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  ages_contains_some?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  ages_exists?: InputMaybe<Scalars["Boolean"]>;
  AND?: InputMaybe<Array<InputMaybe<ContentfulcfSearchFiltersNestedFilter>>>;
  capacityMax?: InputMaybe<Scalars["Int"]>;
  capacityMax_exists?: InputMaybe<Scalars["Boolean"]>;
  capacityMax_gt?: InputMaybe<Scalars["Int"]>;
  capacityMax_gte?: InputMaybe<Scalars["Int"]>;
  capacityMax_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  capacityMax_lt?: InputMaybe<Scalars["Int"]>;
  capacityMax_lte?: InputMaybe<Scalars["Int"]>;
  capacityMax_not?: InputMaybe<Scalars["Int"]>;
  capacityMax_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  capacityMin?: InputMaybe<Scalars["Int"]>;
  capacityMin_exists?: InputMaybe<Scalars["Boolean"]>;
  capacityMin_gt?: InputMaybe<Scalars["Int"]>;
  capacityMin_gte?: InputMaybe<Scalars["Int"]>;
  capacityMin_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  capacityMin_lt?: InputMaybe<Scalars["Int"]>;
  capacityMin_lte?: InputMaybe<Scalars["Int"]>;
  capacityMin_not?: InputMaybe<Scalars["Int"]>;
  capacityMin_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  deliveryTypes_contains_all?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  deliveryTypes_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  deliveryTypes_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  deliveryTypes_exists?: InputMaybe<Scalars["Boolean"]>;
  durationWeeksMax?: InputMaybe<Scalars["Int"]>;
  durationWeeksMax_exists?: InputMaybe<Scalars["Boolean"]>;
  durationWeeksMax_gt?: InputMaybe<Scalars["Int"]>;
  durationWeeksMax_gte?: InputMaybe<Scalars["Int"]>;
  durationWeeksMax_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  durationWeeksMax_lt?: InputMaybe<Scalars["Int"]>;
  durationWeeksMax_lte?: InputMaybe<Scalars["Int"]>;
  durationWeeksMax_not?: InputMaybe<Scalars["Int"]>;
  durationWeeksMax_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  durationWeeksMin?: InputMaybe<Scalars["Int"]>;
  durationWeeksMin_exists?: InputMaybe<Scalars["Boolean"]>;
  durationWeeksMin_gt?: InputMaybe<Scalars["Int"]>;
  durationWeeksMin_gte?: InputMaybe<Scalars["Int"]>;
  durationWeeksMin_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  durationWeeksMin_lt?: InputMaybe<Scalars["Int"]>;
  durationWeeksMin_lte?: InputMaybe<Scalars["Int"]>;
  durationWeeksMin_not?: InputMaybe<Scalars["Int"]>;
  durationWeeksMin_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  endBy?: InputMaybe<Scalars["ContentfulDateTime"]>;
  endBy_exists?: InputMaybe<Scalars["Boolean"]>;
  endBy_gt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  endBy_gte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  endBy_in?: InputMaybe<Array<InputMaybe<Scalars["ContentfulDateTime"]>>>;
  endBy_lt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  endBy_lte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  endBy_not?: InputMaybe<Scalars["ContentfulDateTime"]>;
  endBy_not_in?: InputMaybe<Array<InputMaybe<Scalars["ContentfulDateTime"]>>>;
  filtersName?: InputMaybe<Scalars["String"]>;
  filtersName_contains?: InputMaybe<Scalars["String"]>;
  filtersName_exists?: InputMaybe<Scalars["Boolean"]>;
  filtersName_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  filtersName_not?: InputMaybe<Scalars["String"]>;
  filtersName_not_contains?: InputMaybe<Scalars["String"]>;
  filtersName_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  formats_contains_all?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  formats_contains_none?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  formats_contains_some?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  formats_exists?: InputMaybe<Scalars["Boolean"]>;
  fundingPrograms_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  fundingPrograms_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  fundingPrograms_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  fundingPrograms_exists?: InputMaybe<Scalars["Boolean"]>;
  gradeLevel_contains_all?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  gradeLevel_contains_none?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  gradeLevel_contains_some?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  gradeLevel_exists?: InputMaybe<Scalars["Boolean"]>;
  hasFilledOutUniqueLearningNeeds?: InputMaybe<Scalars["Boolean"]>;
  hasFilledOutUniqueLearningNeeds_exists?: InputMaybe<Scalars["Boolean"]>;
  hasFilledOutUniqueLearningNeeds_not?: InputMaybe<Scalars["Boolean"]>;
  multiTermQuery?: InputMaybe<Scalars["String"]>;
  multiTermQuery_contains?: InputMaybe<Scalars["String"]>;
  multiTermQuery_exists?: InputMaybe<Scalars["Boolean"]>;
  multiTermQuery_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  multiTermQuery_not?: InputMaybe<Scalars["String"]>;
  multiTermQuery_not_contains?: InputMaybe<Scalars["String"]>;
  multiTermQuery_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulcfSearchFiltersNestedFilter>>>;
  pricePerCourseMax?: InputMaybe<Scalars["Int"]>;
  pricePerCourseMax_exists?: InputMaybe<Scalars["Boolean"]>;
  pricePerCourseMax_gt?: InputMaybe<Scalars["Int"]>;
  pricePerCourseMax_gte?: InputMaybe<Scalars["Int"]>;
  pricePerCourseMax_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  pricePerCourseMax_lt?: InputMaybe<Scalars["Int"]>;
  pricePerCourseMax_lte?: InputMaybe<Scalars["Int"]>;
  pricePerCourseMax_not?: InputMaybe<Scalars["Int"]>;
  pricePerCourseMax_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  pricePerCourseMin?: InputMaybe<Scalars["Int"]>;
  pricePerCourseMin_exists?: InputMaybe<Scalars["Boolean"]>;
  pricePerCourseMin_gt?: InputMaybe<Scalars["Int"]>;
  pricePerCourseMin_gte?: InputMaybe<Scalars["Int"]>;
  pricePerCourseMin_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  pricePerCourseMin_lt?: InputMaybe<Scalars["Int"]>;
  pricePerCourseMin_lte?: InputMaybe<Scalars["Int"]>;
  pricePerCourseMin_not?: InputMaybe<Scalars["Int"]>;
  pricePerCourseMin_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  pricePerMeetingMax?: InputMaybe<Scalars["Int"]>;
  pricePerMeetingMax_exists?: InputMaybe<Scalars["Boolean"]>;
  pricePerMeetingMax_gt?: InputMaybe<Scalars["Int"]>;
  pricePerMeetingMax_gte?: InputMaybe<Scalars["Int"]>;
  pricePerMeetingMax_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  pricePerMeetingMax_lt?: InputMaybe<Scalars["Int"]>;
  pricePerMeetingMax_lte?: InputMaybe<Scalars["Int"]>;
  pricePerMeetingMax_not?: InputMaybe<Scalars["Int"]>;
  pricePerMeetingMax_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  pricePerMeetingMin?: InputMaybe<Scalars["Int"]>;
  pricePerMeetingMin_exists?: InputMaybe<Scalars["Boolean"]>;
  pricePerMeetingMin_gt?: InputMaybe<Scalars["Int"]>;
  pricePerMeetingMin_gte?: InputMaybe<Scalars["Int"]>;
  pricePerMeetingMin_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  pricePerMeetingMin_lt?: InputMaybe<Scalars["Int"]>;
  pricePerMeetingMin_lte?: InputMaybe<Scalars["Int"]>;
  pricePerMeetingMin_not?: InputMaybe<Scalars["Int"]>;
  pricePerMeetingMin_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  query?: InputMaybe<Scalars["String"]>;
  query_contains?: InputMaybe<Scalars["String"]>;
  query_exists?: InputMaybe<Scalars["Boolean"]>;
  query_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  query_not?: InputMaybe<Scalars["String"]>;
  query_not_contains?: InputMaybe<Scalars["String"]>;
  query_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  startAfter?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startAfter_exists?: InputMaybe<Scalars["Boolean"]>;
  startAfter_gt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startAfter_gte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startAfter_in?: InputMaybe<Array<InputMaybe<Scalars["ContentfulDateTime"]>>>;
  startAfter_lt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startAfter_lte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startAfter_not?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startAfter_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["ContentfulDateTime"]>>
  >;
  startBefore?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startBefore_exists?: InputMaybe<Scalars["Boolean"]>;
  startBefore_gt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startBefore_gte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startBefore_in?: InputMaybe<Array<InputMaybe<Scalars["ContentfulDateTime"]>>>;
  startBefore_lt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startBefore_lte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startBefore_not?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startBefore_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["ContentfulDateTime"]>>
  >;
  subject?: InputMaybe<Scalars["String"]>;
  subject_contains?: InputMaybe<Scalars["String"]>;
  subject_exists?: InputMaybe<Scalars["Boolean"]>;
  subject_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  subject_not?: InputMaybe<Scalars["String"]>;
  subject_not_contains?: InputMaybe<Scalars["String"]>;
  subject_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
  uniqueLearningNeeds?: InputMaybe<Scalars["String"]>;
  uniqueLearningNeeds_contains?: InputMaybe<Scalars["String"]>;
  uniqueLearningNeeds_exists?: InputMaybe<Scalars["Boolean"]>;
  uniqueLearningNeeds_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  uniqueLearningNeeds_not?: InputMaybe<Scalars["String"]>;
  uniqueLearningNeeds_not_contains?: InputMaybe<Scalars["String"]>;
  uniqueLearningNeeds_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  weeklyMeetingsMax?: InputMaybe<Scalars["Int"]>;
  weeklyMeetingsMax_exists?: InputMaybe<Scalars["Boolean"]>;
  weeklyMeetingsMax_gt?: InputMaybe<Scalars["Int"]>;
  weeklyMeetingsMax_gte?: InputMaybe<Scalars["Int"]>;
  weeklyMeetingsMax_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  weeklyMeetingsMax_lt?: InputMaybe<Scalars["Int"]>;
  weeklyMeetingsMax_lte?: InputMaybe<Scalars["Int"]>;
  weeklyMeetingsMax_not?: InputMaybe<Scalars["Int"]>;
  weeklyMeetingsMax_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  weeklyMeetingsMin?: InputMaybe<Scalars["Int"]>;
  weeklyMeetingsMin_exists?: InputMaybe<Scalars["Boolean"]>;
  weeklyMeetingsMin_gt?: InputMaybe<Scalars["Int"]>;
  weeklyMeetingsMin_gte?: InputMaybe<Scalars["Int"]>;
  weeklyMeetingsMin_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  weeklyMeetingsMin_lt?: InputMaybe<Scalars["Int"]>;
  weeklyMeetingsMin_lte?: InputMaybe<Scalars["Int"]>;
  weeklyMeetingsMin_not?: InputMaybe<Scalars["Int"]>;
  weeklyMeetingsMin_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
};

export type ContentfulListingsPromoBannerButtonsCollection = {
  __typename?: "ContentfulListingsPromoBannerButtonsCollection";
  items: Array<Maybe<ContentfulListingsPromoBannerButton>>;
  limit: Scalars["Int"];
  skip: Scalars["Int"];
  total: Scalars["Int"];
};

export type ContentfulListingsPromoBannerHeaderTitleFontDefinitionFilter = {
  AND?: InputMaybe<
    Array<
      InputMaybe<ContentfulListingsPromoBannerHeaderTitleFontDefinitionFilter>
    >
  >;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  fontFamily?: InputMaybe<Scalars["String"]>;
  fontFamily_contains?: InputMaybe<Scalars["String"]>;
  fontFamily_exists?: InputMaybe<Scalars["Boolean"]>;
  fontFamily_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  fontFamily_not?: InputMaybe<Scalars["String"]>;
  fontFamily_not_contains?: InputMaybe<Scalars["String"]>;
  fontFamily_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  fontUrl?: InputMaybe<Scalars["String"]>;
  fontUrl_contains?: InputMaybe<Scalars["String"]>;
  fontUrl_exists?: InputMaybe<Scalars["Boolean"]>;
  fontUrl_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  fontUrl_not?: InputMaybe<Scalars["String"]>;
  fontUrl_not_contains?: InputMaybe<Scalars["String"]>;
  fontUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<
    Array<
      InputMaybe<ContentfulListingsPromoBannerHeaderTitleFontDefinitionFilter>
    >
  >;
  sys?: InputMaybe<ContentfulSysFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/listingsPromoBannerHeaderTitleFontDefinition) */
export type ContentfulListingsPromoBannerHeaderTitleFontDefinition =
  Contentful_Node &
    ContentfulEntry & {
      __typename?: "ContentfulListingsPromoBannerHeaderTitleFontDefinition";
      _id: Scalars["ID"];
      contentfulMetadata: ContentfulContentfulMetadata;
      fontFamily: Maybe<Scalars["String"]>;
      fontUrl: Maybe<Scalars["String"]>;
      linkedFrom: Maybe<ContentfulListingsPromoBannerHeaderTitleFontDefinitionLinkingCollections>;
      sys: ContentfulSys;
    };

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/listingsPromoBannerHeaderTitleFontDefinition) */
export type ContentfulListingsPromoBannerHeaderTitleFontDefinitionFontFamilyArgs =
  {
    locale?: InputMaybe<Scalars["String"]>;
  };

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/listingsPromoBannerHeaderTitleFontDefinition) */
export type ContentfulListingsPromoBannerHeaderTitleFontDefinitionFontUrlArgs =
  {
    locale?: InputMaybe<Scalars["String"]>;
  };

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/listingsPromoBannerHeaderTitleFontDefinition) */
export type ContentfulListingsPromoBannerHeaderTitleFontDefinitionLinkedFromArgs =
  {
    allowedLocales?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  };

export type ContentfulListingsPromoBannerHeaderTitleFontDefinitionLinkingCollections =
  {
    __typename?: "ContentfulListingsPromoBannerHeaderTitleFontDefinitionLinkingCollections";
    entryCollection: Maybe<ContentfulEntryCollection>;
    listingsPromoBannerCollection: Maybe<ContentfulListingsPromoBannerCollection>;
  };

export type ContentfulListingsPromoBannerHeaderTitleFontDefinitionLinkingCollectionsEntryCollectionArgs =
  {
    limit?: InputMaybe<Scalars["Int"]>;
    locale?: InputMaybe<Scalars["String"]>;
    preview?: InputMaybe<Scalars["Boolean"]>;
    skip?: InputMaybe<Scalars["Int"]>;
  };

export type ContentfulListingsPromoBannerHeaderTitleFontDefinitionLinkingCollectionsListingsPromoBannerCollectionArgs =
  {
    limit?: InputMaybe<Scalars["Int"]>;
    locale?: InputMaybe<Scalars["String"]>;
    order?: InputMaybe<
      Array<
        InputMaybe<ContentfulListingsPromoBannerHeaderTitleFontDefinitionLinkingCollectionsListingsPromoBannerCollectionOrder>
      >
    >;
    preview?: InputMaybe<Scalars["Boolean"]>;
    skip?: InputMaybe<Scalars["Int"]>;
  };

export enum ContentfulListingsPromoBannerHeaderTitleFontDefinitionLinkingCollectionsListingsPromoBannerCollectionOrder {
  ExperimentVersionAsc = "experimentVersion_ASC",
  ExperimentVersionDesc = "experimentVersion_DESC",
  HeaderSubtitleAsc = "headerSubtitle_ASC",
  HeaderSubtitleDesc = "headerSubtitle_DESC",
  HeaderTitleAsc = "headerTitle_ASC",
  HeaderTitleDesc = "headerTitle_DESC",
  IncludeControlGroupAsc = "includeControlGroup_ASC",
  IncludeControlGroupDesc = "includeControlGroup_DESC",
  IsLiveOnSearchAsc = "isLiveOnSearch_ASC",
  IsLiveOnSearchDesc = "isLiveOnSearch_DESC",
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  SidebarTitleAsc = "sidebarTitle_ASC",
  SidebarTitleDesc = "sidebarTitle_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
}

export type ContentfulListingsPromoBannerLinkingCollections = {
  __typename?: "ContentfulListingsPromoBannerLinkingCollections";
  entryCollection: Maybe<ContentfulEntryCollection>;
};

export type ContentfulListingsPromoBannerLinkingCollectionsEntryCollectionArgs =
  {
    limit?: InputMaybe<Scalars["Int"]>;
    locale?: InputMaybe<Scalars["String"]>;
    preview?: InputMaybe<Scalars["Boolean"]>;
    skip?: InputMaybe<Scalars["Int"]>;
  };

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageHeroSection) */
export type ContentfulLandingPageHeroSection = Contentful_Node &
  ContentfulEntry & {
    __typename?: "ContentfulLandingPageHeroSection";
    _id: Scalars["ID"];
    animationCssDesktop: Maybe<Scalars["ContentfulJSON"]>;
    animationCssMobile: Maybe<Scalars["ContentfulJSON"]>;
    animationUrl: Maybe<Scalars["String"]>;
    backgroundImage: Maybe<ContentfulAsset>;
    buttonCtaText: Maybe<Scalars["String"]>;
    buttonUrl: Maybe<Scalars["String"]>;
    buttonUrlLoggedIn: Maybe<Scalars["String"]>;
    contentfulMetadata: ContentfulContentfulMetadata;
    id: Maybe<Scalars["String"]>;
    linkedFrom: Maybe<ContentfulLandingPageHeroSectionLinkingCollections>;
    mainImage: Maybe<ContentfulAsset>;
    shouldShowCta: Maybe<Scalars["Boolean"]>;
    shouldShowTrustPilot: Maybe<Scalars["Boolean"]>;
    subtitle: Maybe<Scalars["String"]>;
    sys: ContentfulSys;
    title: Maybe<Scalars["String"]>;
  };

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageHeroSection) */
export type ContentfulLandingPageHeroSectionAnimationCssDesktopArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageHeroSection) */
export type ContentfulLandingPageHeroSectionAnimationCssMobileArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageHeroSection) */
export type ContentfulLandingPageHeroSectionAnimationUrlArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageHeroSection) */
export type ContentfulLandingPageHeroSectionBackgroundImageArgs = {
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageHeroSection) */
export type ContentfulLandingPageHeroSectionButtonCtaTextArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageHeroSection) */
export type ContentfulLandingPageHeroSectionButtonUrlArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageHeroSection) */
export type ContentfulLandingPageHeroSectionButtonUrlLoggedInArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageHeroSection) */
export type ContentfulLandingPageHeroSectionIdArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageHeroSection) */
export type ContentfulLandingPageHeroSectionLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageHeroSection) */
export type ContentfulLandingPageHeroSectionMainImageArgs = {
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageHeroSection) */
export type ContentfulLandingPageHeroSectionShouldShowCtaArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageHeroSection) */
export type ContentfulLandingPageHeroSectionShouldShowTrustPilotArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageHeroSection) */
export type ContentfulLandingPageHeroSectionSubtitleArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageHeroSection) */
export type ContentfulLandingPageHeroSectionTitleArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

export type ContentfulLandingPageHeroSectionLinkingCollections = {
  __typename?: "ContentfulLandingPageHeroSectionLinkingCollections";
  entryCollection: Maybe<ContentfulEntryCollection>;
  landingPageCollection: Maybe<ContentfulLandingPageCollection>;
};

export type ContentfulLandingPageHeroSectionLinkingCollectionsEntryCollectionArgs =
  {
    limit?: InputMaybe<Scalars["Int"]>;
    locale?: InputMaybe<Scalars["String"]>;
    preview?: InputMaybe<Scalars["Boolean"]>;
    skip?: InputMaybe<Scalars["Int"]>;
  };

export type ContentfulLandingPageHeroSectionLinkingCollectionsLandingPageCollectionArgs =
  {
    limit?: InputMaybe<Scalars["Int"]>;
    locale?: InputMaybe<Scalars["String"]>;
    order?: InputMaybe<
      Array<
        InputMaybe<ContentfulLandingPageHeroSectionLinkingCollectionsLandingPageCollectionOrder>
      >
    >;
    preview?: InputMaybe<Scalars["Boolean"]>;
    skip?: InputMaybe<Scalars["Int"]>;
  };

export enum ContentfulLandingPageHeroSectionLinkingCollectionsLandingPageCollectionOrder {
  MetaTagCanonicalUrlAsc = "metaTagCanonicalUrl_ASC",
  MetaTagCanonicalUrlDesc = "metaTagCanonicalUrl_DESC",
  MetaTagDescriptionAsc = "metaTagDescription_ASC",
  MetaTagDescriptionDesc = "metaTagDescription_DESC",
  NavbarCtaTextAsc = "navbarCtaText_ASC",
  NavbarCtaTextDesc = "navbarCtaText_DESC",
  NavbarCtaUrlAsc = "navbarCtaUrl_ASC",
  NavbarCtaUrlDesc = "navbarCtaUrl_DESC",
  NavbarCtaUrlLoggedInAsc = "navbarCtaUrlLoggedIn_ASC",
  NavbarCtaUrlLoggedInDesc = "navbarCtaUrlLoggedIn_DESC",
  SlugAsc = "slug_ASC",
  SlugDesc = "slug_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageIconInfoSection) */
export type ContentfulLandingPageIconInfoSection = Contentful_Node &
  ContentfulEntry & {
    __typename?: "ContentfulLandingPageIconInfoSection";
    _id: Scalars["ID"];
    contentfulMetadata: ContentfulContentfulMetadata;
    displayOnMobile: Maybe<Scalars["Boolean"]>;
    iconInfosCollection: Maybe<ContentfulLandingPageIconInfoSectionIconInfosCollection>;
    id: Maybe<Scalars["String"]>;
    linkedFrom: Maybe<ContentfulLandingPageIconInfoSectionLinkingCollections>;
    sys: ContentfulSys;
  };

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageIconInfoSection) */
export type ContentfulLandingPageIconInfoSectionDisplayOnMobileArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageIconInfoSection) */
export type ContentfulLandingPageIconInfoSectionIconInfosCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<
    Array<
      InputMaybe<ContentfulLandingPageIconInfoSectionIconInfosCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ContentfulLandingPageIconInfoFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageIconInfoSection) */
export type ContentfulLandingPageIconInfoSectionIdArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageIconInfoSection) */
export type ContentfulLandingPageIconInfoSectionLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export enum ContentfulLandingPageIconInfoSectionIconInfosCollectionOrder {
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  SubtitleAsc = "subtitle_ASC",
  SubtitleDesc = "subtitle_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
}

export type ContentfulLandingPageIconInfoFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulLandingPageIconInfoFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  iconImage_exists?: InputMaybe<Scalars["Boolean"]>;
  id?: InputMaybe<Scalars["String"]>;
  id_contains?: InputMaybe<Scalars["String"]>;
  id_exists?: InputMaybe<Scalars["Boolean"]>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  id_not?: InputMaybe<Scalars["String"]>;
  id_not_contains?: InputMaybe<Scalars["String"]>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulLandingPageIconInfoFilter>>>;
  subtitle?: InputMaybe<Scalars["String"]>;
  subtitle_contains?: InputMaybe<Scalars["String"]>;
  subtitle_exists?: InputMaybe<Scalars["Boolean"]>;
  subtitle_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  subtitle_not?: InputMaybe<Scalars["String"]>;
  subtitle_not_contains?: InputMaybe<Scalars["String"]>;
  subtitle_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
};

export type ContentfulLandingPageIconInfoSectionIconInfosCollection = {
  __typename?: "ContentfulLandingPageIconInfoSectionIconInfosCollection";
  items: Array<Maybe<ContentfulLandingPageIconInfo>>;
  limit: Scalars["Int"];
  skip: Scalars["Int"];
  total: Scalars["Int"];
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageIconInfo) */
export type ContentfulLandingPageIconInfo = Contentful_Node &
  ContentfulEntry & {
    __typename?: "ContentfulLandingPageIconInfo";
    _id: Scalars["ID"];
    contentfulMetadata: ContentfulContentfulMetadata;
    iconImage: Maybe<ContentfulAsset>;
    id: Maybe<Scalars["String"]>;
    linkedFrom: Maybe<ContentfulLandingPageIconInfoLinkingCollections>;
    subtitle: Maybe<Scalars["String"]>;
    sys: ContentfulSys;
  };

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageIconInfo) */
export type ContentfulLandingPageIconInfoIconImageArgs = {
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageIconInfo) */
export type ContentfulLandingPageIconInfoIdArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageIconInfo) */
export type ContentfulLandingPageIconInfoLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageIconInfo) */
export type ContentfulLandingPageIconInfoSubtitleArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

export type ContentfulLandingPageIconInfoLinkingCollections = {
  __typename?: "ContentfulLandingPageIconInfoLinkingCollections";
  entryCollection: Maybe<ContentfulEntryCollection>;
  landingPageIconInfoSectionCollection: Maybe<ContentfulLandingPageIconInfoSectionCollection>;
};

export type ContentfulLandingPageIconInfoLinkingCollectionsEntryCollectionArgs =
  {
    limit?: InputMaybe<Scalars["Int"]>;
    locale?: InputMaybe<Scalars["String"]>;
    preview?: InputMaybe<Scalars["Boolean"]>;
    skip?: InputMaybe<Scalars["Int"]>;
  };

export type ContentfulLandingPageIconInfoLinkingCollectionsLandingPageIconInfoSectionCollectionArgs =
  {
    limit?: InputMaybe<Scalars["Int"]>;
    locale?: InputMaybe<Scalars["String"]>;
    order?: InputMaybe<
      Array<
        InputMaybe<ContentfulLandingPageIconInfoLinkingCollectionsLandingPageIconInfoSectionCollectionOrder>
      >
    >;
    preview?: InputMaybe<Scalars["Boolean"]>;
    skip?: InputMaybe<Scalars["Int"]>;
  };

export enum ContentfulLandingPageIconInfoLinkingCollectionsLandingPageIconInfoSectionCollectionOrder {
  DisplayOnMobileAsc = "displayOnMobile_ASC",
  DisplayOnMobileDesc = "displayOnMobile_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
}

export type ContentfulLandingPageIconInfoSectionCollection = {
  __typename?: "ContentfulLandingPageIconInfoSectionCollection";
  items: Array<Maybe<ContentfulLandingPageIconInfoSection>>;
  limit: Scalars["Int"];
  skip: Scalars["Int"];
  total: Scalars["Int"];
};

export type ContentfulLandingPageIconInfoSectionLinkingCollections = {
  __typename?: "ContentfulLandingPageIconInfoSectionLinkingCollections";
  entryCollection: Maybe<ContentfulEntryCollection>;
  landingPageCollection: Maybe<ContentfulLandingPageCollection>;
};

export type ContentfulLandingPageIconInfoSectionLinkingCollectionsEntryCollectionArgs =
  {
    limit?: InputMaybe<Scalars["Int"]>;
    locale?: InputMaybe<Scalars["String"]>;
    preview?: InputMaybe<Scalars["Boolean"]>;
    skip?: InputMaybe<Scalars["Int"]>;
  };

export type ContentfulLandingPageIconInfoSectionLinkingCollectionsLandingPageCollectionArgs =
  {
    limit?: InputMaybe<Scalars["Int"]>;
    locale?: InputMaybe<Scalars["String"]>;
    order?: InputMaybe<
      Array<
        InputMaybe<ContentfulLandingPageIconInfoSectionLinkingCollectionsLandingPageCollectionOrder>
      >
    >;
    preview?: InputMaybe<Scalars["Boolean"]>;
    skip?: InputMaybe<Scalars["Int"]>;
  };

export enum ContentfulLandingPageIconInfoSectionLinkingCollectionsLandingPageCollectionOrder {
  MetaTagCanonicalUrlAsc = "metaTagCanonicalUrl_ASC",
  MetaTagCanonicalUrlDesc = "metaTagCanonicalUrl_DESC",
  MetaTagDescriptionAsc = "metaTagDescription_ASC",
  MetaTagDescriptionDesc = "metaTagDescription_DESC",
  NavbarCtaTextAsc = "navbarCtaText_ASC",
  NavbarCtaTextDesc = "navbarCtaText_DESC",
  NavbarCtaUrlAsc = "navbarCtaUrl_ASC",
  NavbarCtaUrlDesc = "navbarCtaUrl_DESC",
  NavbarCtaUrlLoggedInAsc = "navbarCtaUrlLoggedIn_ASC",
  NavbarCtaUrlLoggedInDesc = "navbarCtaUrlLoggedIn_DESC",
  SlugAsc = "slug_ASC",
  SlugDesc = "slug_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageImageTextCtaSection) */
export type ContentfulLandingPageImageTextCtaSection = Contentful_Node &
  ContentfulEntry & {
    __typename?: "ContentfulLandingPageImageTextCtaSection";
    _id: Scalars["ID"];
    buttonCtaText: Maybe<Scalars["String"]>;
    buttonUrl: Maybe<Scalars["String"]>;
    buttonUrlLoggedIn: Maybe<Scalars["String"]>;
    contentfulMetadata: ContentfulContentfulMetadata;
    id: Maybe<Scalars["String"]>;
    image: Maybe<ContentfulAsset>;
    linkedFrom: Maybe<ContentfulLandingPageImageTextCtaSectionLinkingCollections>;
    subtitleRt: Maybe<ContentfulLandingPageImageTextCtaSectionSubtitleRt>;
    sys: ContentfulSys;
    titleEndColor: Maybe<Scalars["String"]>;
    titleEndText: Maybe<Scalars["String"]>;
    titleStartColor: Maybe<Scalars["String"]>;
    titleStartText: Maybe<Scalars["String"]>;
  };

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageImageTextCtaSection) */
export type ContentfulLandingPageImageTextCtaSectionButtonCtaTextArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageImageTextCtaSection) */
export type ContentfulLandingPageImageTextCtaSectionButtonUrlArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageImageTextCtaSection) */
export type ContentfulLandingPageImageTextCtaSectionButtonUrlLoggedInArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageImageTextCtaSection) */
export type ContentfulLandingPageImageTextCtaSectionIdArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageImageTextCtaSection) */
export type ContentfulLandingPageImageTextCtaSectionImageArgs = {
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageImageTextCtaSection) */
export type ContentfulLandingPageImageTextCtaSectionLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageImageTextCtaSection) */
export type ContentfulLandingPageImageTextCtaSectionSubtitleRtArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageImageTextCtaSection) */
export type ContentfulLandingPageImageTextCtaSectionTitleEndColorArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageImageTextCtaSection) */
export type ContentfulLandingPageImageTextCtaSectionTitleEndTextArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageImageTextCtaSection) */
export type ContentfulLandingPageImageTextCtaSectionTitleStartColorArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageImageTextCtaSection) */
export type ContentfulLandingPageImageTextCtaSectionTitleStartTextArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

export type ContentfulLandingPageImageTextCtaSectionLinkingCollections = {
  __typename?: "ContentfulLandingPageImageTextCtaSectionLinkingCollections";
  entryCollection: Maybe<ContentfulEntryCollection>;
  landingPageCollection: Maybe<ContentfulLandingPageCollection>;
};

export type ContentfulLandingPageImageTextCtaSectionLinkingCollectionsEntryCollectionArgs =
  {
    limit?: InputMaybe<Scalars["Int"]>;
    locale?: InputMaybe<Scalars["String"]>;
    preview?: InputMaybe<Scalars["Boolean"]>;
    skip?: InputMaybe<Scalars["Int"]>;
  };

export type ContentfulLandingPageImageTextCtaSectionLinkingCollectionsLandingPageCollectionArgs =
  {
    limit?: InputMaybe<Scalars["Int"]>;
    locale?: InputMaybe<Scalars["String"]>;
    order?: InputMaybe<
      Array<
        InputMaybe<ContentfulLandingPageImageTextCtaSectionLinkingCollectionsLandingPageCollectionOrder>
      >
    >;
    preview?: InputMaybe<Scalars["Boolean"]>;
    skip?: InputMaybe<Scalars["Int"]>;
  };

export enum ContentfulLandingPageImageTextCtaSectionLinkingCollectionsLandingPageCollectionOrder {
  MetaTagCanonicalUrlAsc = "metaTagCanonicalUrl_ASC",
  MetaTagCanonicalUrlDesc = "metaTagCanonicalUrl_DESC",
  MetaTagDescriptionAsc = "metaTagDescription_ASC",
  MetaTagDescriptionDesc = "metaTagDescription_DESC",
  NavbarCtaTextAsc = "navbarCtaText_ASC",
  NavbarCtaTextDesc = "navbarCtaText_DESC",
  NavbarCtaUrlAsc = "navbarCtaUrl_ASC",
  NavbarCtaUrlDesc = "navbarCtaUrl_DESC",
  NavbarCtaUrlLoggedInAsc = "navbarCtaUrlLoggedIn_ASC",
  NavbarCtaUrlLoggedInDesc = "navbarCtaUrlLoggedIn_DESC",
  SlugAsc = "slug_ASC",
  SlugDesc = "slug_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export type ContentfulLandingPageImageTextCtaSectionSubtitleRt = {
  __typename?: "ContentfulLandingPageImageTextCtaSectionSubtitleRt";
  json: Scalars["ContentfulJSON"];
  links: ContentfulLandingPageImageTextCtaSectionSubtitleRtLinks;
};

export type ContentfulLandingPageImageTextCtaSectionSubtitleRtLinks = {
  __typename?: "ContentfulLandingPageImageTextCtaSectionSubtitleRtLinks";
  assets: ContentfulLandingPageImageTextCtaSectionSubtitleRtAssets;
  entries: ContentfulLandingPageImageTextCtaSectionSubtitleRtEntries;
  resources: ContentfulLandingPageImageTextCtaSectionSubtitleRtResources;
};

export type ContentfulLandingPageImageTextCtaSectionSubtitleRtAssets = {
  __typename?: "ContentfulLandingPageImageTextCtaSectionSubtitleRtAssets";
  block: Array<Maybe<ContentfulAsset>>;
  hyperlink: Array<Maybe<ContentfulAsset>>;
};

export type ContentfulLandingPageImageTextCtaSectionSubtitleRtEntries = {
  __typename?: "ContentfulLandingPageImageTextCtaSectionSubtitleRtEntries";
  block: Array<Maybe<ContentfulEntry>>;
  hyperlink: Array<Maybe<ContentfulEntry>>;
  inline: Array<Maybe<ContentfulEntry>>;
};

export type ContentfulLandingPageImageTextCtaSectionSubtitleRtResources = {
  __typename?: "ContentfulLandingPageImageTextCtaSectionSubtitleRtResources";
  block: Array<ContentfulLandingPageImageTextCtaSectionSubtitleRtResourcesBlock>;
  hyperlink: Array<ContentfulLandingPageImageTextCtaSectionSubtitleRtResourcesHyperlink>;
  inline: Array<ContentfulLandingPageImageTextCtaSectionSubtitleRtResourcesInline>;
};

export type ContentfulLandingPageImageTextCtaSectionSubtitleRtResourcesBlock =
  ContentfulResourceLink & {
    __typename?: "ContentfulLandingPageImageTextCtaSectionSubtitleRtResourcesBlock";
    sys: ContentfulResourceSys;
  };

export type ContentfulLandingPageImageTextCtaSectionSubtitleRtResourcesHyperlink =
  ContentfulResourceLink & {
    __typename?: "ContentfulLandingPageImageTextCtaSectionSubtitleRtResourcesHyperlink";
    sys: ContentfulResourceSys;
  };

export type ContentfulLandingPageImageTextCtaSectionSubtitleRtResourcesInline =
  ContentfulResourceLink & {
    __typename?: "ContentfulLandingPageImageTextCtaSectionSubtitleRtResourcesInline";
    sys: ContentfulResourceSys;
  };

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageJourneySection) */
export type ContentfulLandingPageJourneySection = Contentful_Node &
  ContentfulEntry & {
    __typename?: "ContentfulLandingPageJourneySection";
    _id: Scalars["ID"];
    contentfulMetadata: ContentfulContentfulMetadata;
    id: Maybe<Scalars["String"]>;
    journeyItemsCollection: Maybe<ContentfulLandingPageJourneySectionJourneyItemsCollection>;
    linkedFrom: Maybe<ContentfulLandingPageJourneySectionLinkingCollections>;
    sys: ContentfulSys;
    title: Maybe<Scalars["String"]>;
  };

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageJourneySection) */
export type ContentfulLandingPageJourneySectionIdArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageJourneySection) */
export type ContentfulLandingPageJourneySectionJourneyItemsCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<
    Array<
      InputMaybe<ContentfulLandingPageJourneySectionJourneyItemsCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ContentfulLandingPageJourneyItemFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageJourneySection) */
export type ContentfulLandingPageJourneySectionLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageJourneySection) */
export type ContentfulLandingPageJourneySectionTitleArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

export enum ContentfulLandingPageJourneySectionJourneyItemsCollectionOrder {
  AnimationUrlAsc = "animationUrl_ASC",
  AnimationUrlDesc = "animationUrl_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  NumberColorAsc = "numberColor_ASC",
  NumberColorDesc = "numberColor_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export type ContentfulLandingPageJourneyItemFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulLandingPageJourneyItemFilter>>>;
  animationUrl?: InputMaybe<Scalars["String"]>;
  animationUrl_contains?: InputMaybe<Scalars["String"]>;
  animationUrl_exists?: InputMaybe<Scalars["Boolean"]>;
  animationUrl_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  animationUrl_not?: InputMaybe<Scalars["String"]>;
  animationUrl_not_contains?: InputMaybe<Scalars["String"]>;
  animationUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  backgroundImage_exists?: InputMaybe<Scalars["Boolean"]>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  id?: InputMaybe<Scalars["String"]>;
  id_contains?: InputMaybe<Scalars["String"]>;
  id_exists?: InputMaybe<Scalars["Boolean"]>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  id_not?: InputMaybe<Scalars["String"]>;
  id_not_contains?: InputMaybe<Scalars["String"]>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  image_exists?: InputMaybe<Scalars["Boolean"]>;
  numberColor?: InputMaybe<Scalars["String"]>;
  numberColor_contains?: InputMaybe<Scalars["String"]>;
  numberColor_exists?: InputMaybe<Scalars["Boolean"]>;
  numberColor_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  numberColor_not?: InputMaybe<Scalars["String"]>;
  numberColor_not_contains?: InputMaybe<Scalars["String"]>;
  numberColor_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulLandingPageJourneyItemFilter>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type ContentfulLandingPageJourneySectionJourneyItemsCollection = {
  __typename?: "ContentfulLandingPageJourneySectionJourneyItemsCollection";
  items: Array<Maybe<ContentfulLandingPageJourneyItem>>;
  limit: Scalars["Int"];
  skip: Scalars["Int"];
  total: Scalars["Int"];
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageJourneyItem) */
export type ContentfulLandingPageJourneyItem = Contentful_Node &
  ContentfulEntry & {
    __typename?: "ContentfulLandingPageJourneyItem";
    _id: Scalars["ID"];
    animationUrl: Maybe<Scalars["String"]>;
    backgroundImage: Maybe<ContentfulAsset>;
    contentfulMetadata: ContentfulContentfulMetadata;
    id: Maybe<Scalars["String"]>;
    image: Maybe<ContentfulAsset>;
    linkedFrom: Maybe<ContentfulLandingPageJourneyItemLinkingCollections>;
    numberColor: Maybe<Scalars["String"]>;
    sys: ContentfulSys;
    title: Maybe<Scalars["String"]>;
  };

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageJourneyItem) */
export type ContentfulLandingPageJourneyItemAnimationUrlArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageJourneyItem) */
export type ContentfulLandingPageJourneyItemBackgroundImageArgs = {
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageJourneyItem) */
export type ContentfulLandingPageJourneyItemIdArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageJourneyItem) */
export type ContentfulLandingPageJourneyItemImageArgs = {
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageJourneyItem) */
export type ContentfulLandingPageJourneyItemLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageJourneyItem) */
export type ContentfulLandingPageJourneyItemNumberColorArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageJourneyItem) */
export type ContentfulLandingPageJourneyItemTitleArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

export type ContentfulLandingPageJourneyItemLinkingCollections = {
  __typename?: "ContentfulLandingPageJourneyItemLinkingCollections";
  entryCollection: Maybe<ContentfulEntryCollection>;
  landingPageJourneySectionCollection: Maybe<ContentfulLandingPageJourneySectionCollection>;
};

export type ContentfulLandingPageJourneyItemLinkingCollectionsEntryCollectionArgs =
  {
    limit?: InputMaybe<Scalars["Int"]>;
    locale?: InputMaybe<Scalars["String"]>;
    preview?: InputMaybe<Scalars["Boolean"]>;
    skip?: InputMaybe<Scalars["Int"]>;
  };

export type ContentfulLandingPageJourneyItemLinkingCollectionsLandingPageJourneySectionCollectionArgs =
  {
    limit?: InputMaybe<Scalars["Int"]>;
    locale?: InputMaybe<Scalars["String"]>;
    order?: InputMaybe<
      Array<
        InputMaybe<ContentfulLandingPageJourneyItemLinkingCollectionsLandingPageJourneySectionCollectionOrder>
      >
    >;
    preview?: InputMaybe<Scalars["Boolean"]>;
    skip?: InputMaybe<Scalars["Int"]>;
  };

export enum ContentfulLandingPageJourneyItemLinkingCollectionsLandingPageJourneySectionCollectionOrder {
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export type ContentfulLandingPageJourneySectionCollection = {
  __typename?: "ContentfulLandingPageJourneySectionCollection";
  items: Array<Maybe<ContentfulLandingPageJourneySection>>;
  limit: Scalars["Int"];
  skip: Scalars["Int"];
  total: Scalars["Int"];
};

export type ContentfulLandingPageJourneySectionLinkingCollections = {
  __typename?: "ContentfulLandingPageJourneySectionLinkingCollections";
  entryCollection: Maybe<ContentfulEntryCollection>;
  landingPageCollection: Maybe<ContentfulLandingPageCollection>;
};

export type ContentfulLandingPageJourneySectionLinkingCollectionsEntryCollectionArgs =
  {
    limit?: InputMaybe<Scalars["Int"]>;
    locale?: InputMaybe<Scalars["String"]>;
    preview?: InputMaybe<Scalars["Boolean"]>;
    skip?: InputMaybe<Scalars["Int"]>;
  };

export type ContentfulLandingPageJourneySectionLinkingCollectionsLandingPageCollectionArgs =
  {
    limit?: InputMaybe<Scalars["Int"]>;
    locale?: InputMaybe<Scalars["String"]>;
    order?: InputMaybe<
      Array<
        InputMaybe<ContentfulLandingPageJourneySectionLinkingCollectionsLandingPageCollectionOrder>
      >
    >;
    preview?: InputMaybe<Scalars["Boolean"]>;
    skip?: InputMaybe<Scalars["Int"]>;
  };

export enum ContentfulLandingPageJourneySectionLinkingCollectionsLandingPageCollectionOrder {
  MetaTagCanonicalUrlAsc = "metaTagCanonicalUrl_ASC",
  MetaTagCanonicalUrlDesc = "metaTagCanonicalUrl_DESC",
  MetaTagDescriptionAsc = "metaTagDescription_ASC",
  MetaTagDescriptionDesc = "metaTagDescription_DESC",
  NavbarCtaTextAsc = "navbarCtaText_ASC",
  NavbarCtaTextDesc = "navbarCtaText_DESC",
  NavbarCtaUrlAsc = "navbarCtaUrl_ASC",
  NavbarCtaUrlDesc = "navbarCtaUrl_DESC",
  NavbarCtaUrlLoggedInAsc = "navbarCtaUrlLoggedIn_ASC",
  NavbarCtaUrlLoggedInDesc = "navbarCtaUrlLoggedIn_DESC",
  SlugAsc = "slug_ASC",
  SlugDesc = "slug_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageLessonPreviewSection) */
export type ContentfulLandingPageLessonPreviewSection = Contentful_Node &
  ContentfulEntry & {
    __typename?: "ContentfulLandingPageLessonPreviewSection";
    _id: Scalars["ID"];
    contentfulMetadata: ContentfulContentfulMetadata;
    ctaText: Maybe<Scalars["String"]>;
    ctaUrl: Maybe<Scalars["String"]>;
    description: Maybe<ContentfulLandingPageLessonPreviewSectionDescription>;
    linkedFrom: Maybe<ContentfulLandingPageLessonPreviewSectionLinkingCollections>;
    sys: ContentfulSys;
    title: Maybe<Scalars["String"]>;
    video: Maybe<ContentfulAsset>;
  };

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageLessonPreviewSection) */
export type ContentfulLandingPageLessonPreviewSectionCtaTextArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageLessonPreviewSection) */
export type ContentfulLandingPageLessonPreviewSectionCtaUrlArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageLessonPreviewSection) */
export type ContentfulLandingPageLessonPreviewSectionDescriptionArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageLessonPreviewSection) */
export type ContentfulLandingPageLessonPreviewSectionLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageLessonPreviewSection) */
export type ContentfulLandingPageLessonPreviewSectionTitleArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageLessonPreviewSection) */
export type ContentfulLandingPageLessonPreviewSectionVideoArgs = {
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
};

export type ContentfulLandingPageLessonPreviewSectionDescription = {
  __typename?: "ContentfulLandingPageLessonPreviewSectionDescription";
  json: Scalars["ContentfulJSON"];
  links: ContentfulLandingPageLessonPreviewSectionDescriptionLinks;
};

export type ContentfulLandingPageLessonPreviewSectionDescriptionLinks = {
  __typename?: "ContentfulLandingPageLessonPreviewSectionDescriptionLinks";
  assets: ContentfulLandingPageLessonPreviewSectionDescriptionAssets;
  entries: ContentfulLandingPageLessonPreviewSectionDescriptionEntries;
  resources: ContentfulLandingPageLessonPreviewSectionDescriptionResources;
};

export type ContentfulLandingPageLessonPreviewSectionDescriptionAssets = {
  __typename?: "ContentfulLandingPageLessonPreviewSectionDescriptionAssets";
  block: Array<Maybe<ContentfulAsset>>;
  hyperlink: Array<Maybe<ContentfulAsset>>;
};

export type ContentfulLandingPageLessonPreviewSectionDescriptionEntries = {
  __typename?: "ContentfulLandingPageLessonPreviewSectionDescriptionEntries";
  block: Array<Maybe<ContentfulEntry>>;
  hyperlink: Array<Maybe<ContentfulEntry>>;
  inline: Array<Maybe<ContentfulEntry>>;
};

export type ContentfulLandingPageLessonPreviewSectionDescriptionResources = {
  __typename?: "ContentfulLandingPageLessonPreviewSectionDescriptionResources";
  block: Array<ContentfulLandingPageLessonPreviewSectionDescriptionResourcesBlock>;
  hyperlink: Array<ContentfulLandingPageLessonPreviewSectionDescriptionResourcesHyperlink>;
  inline: Array<ContentfulLandingPageLessonPreviewSectionDescriptionResourcesInline>;
};

export type ContentfulLandingPageLessonPreviewSectionDescriptionResourcesBlock =
  ContentfulResourceLink & {
    __typename?: "ContentfulLandingPageLessonPreviewSectionDescriptionResourcesBlock";
    sys: ContentfulResourceSys;
  };

export type ContentfulLandingPageLessonPreviewSectionDescriptionResourcesHyperlink =
  ContentfulResourceLink & {
    __typename?: "ContentfulLandingPageLessonPreviewSectionDescriptionResourcesHyperlink";
    sys: ContentfulResourceSys;
  };

export type ContentfulLandingPageLessonPreviewSectionDescriptionResourcesInline =
  ContentfulResourceLink & {
    __typename?: "ContentfulLandingPageLessonPreviewSectionDescriptionResourcesInline";
    sys: ContentfulResourceSys;
  };

export type ContentfulLandingPageLessonPreviewSectionLinkingCollections = {
  __typename?: "ContentfulLandingPageLessonPreviewSectionLinkingCollections";
  entryCollection: Maybe<ContentfulEntryCollection>;
  landingPageCollection: Maybe<ContentfulLandingPageCollection>;
};

export type ContentfulLandingPageLessonPreviewSectionLinkingCollectionsEntryCollectionArgs =
  {
    limit?: InputMaybe<Scalars["Int"]>;
    locale?: InputMaybe<Scalars["String"]>;
    preview?: InputMaybe<Scalars["Boolean"]>;
    skip?: InputMaybe<Scalars["Int"]>;
  };

export type ContentfulLandingPageLessonPreviewSectionLinkingCollectionsLandingPageCollectionArgs =
  {
    limit?: InputMaybe<Scalars["Int"]>;
    locale?: InputMaybe<Scalars["String"]>;
    order?: InputMaybe<
      Array<
        InputMaybe<ContentfulLandingPageLessonPreviewSectionLinkingCollectionsLandingPageCollectionOrder>
      >
    >;
    preview?: InputMaybe<Scalars["Boolean"]>;
    skip?: InputMaybe<Scalars["Int"]>;
  };

export enum ContentfulLandingPageLessonPreviewSectionLinkingCollectionsLandingPageCollectionOrder {
  MetaTagCanonicalUrlAsc = "metaTagCanonicalUrl_ASC",
  MetaTagCanonicalUrlDesc = "metaTagCanonicalUrl_DESC",
  MetaTagDescriptionAsc = "metaTagDescription_ASC",
  MetaTagDescriptionDesc = "metaTagDescription_DESC",
  NavbarCtaTextAsc = "navbarCtaText_ASC",
  NavbarCtaTextDesc = "navbarCtaText_DESC",
  NavbarCtaUrlAsc = "navbarCtaUrl_ASC",
  NavbarCtaUrlDesc = "navbarCtaUrl_DESC",
  NavbarCtaUrlLoggedInAsc = "navbarCtaUrlLoggedIn_ASC",
  NavbarCtaUrlLoggedInDesc = "navbarCtaUrlLoggedIn_DESC",
  SlugAsc = "slug_ASC",
  SlugDesc = "slug_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageSingleReviewSection) */
export type ContentfulLandingPageSingleReviewSection = Contentful_Node &
  ContentfulEntry & {
    __typename?: "ContentfulLandingPageSingleReviewSection";
    _id: Scalars["ID"];
    className: Maybe<Scalars["String"]>;
    classUrl: Maybe<Scalars["String"]>;
    contentfulMetadata: ContentfulContentfulMetadata;
    id: Maybe<Scalars["String"]>;
    image: Maybe<ContentfulAsset>;
    imageMobile: Maybe<ContentfulAsset>;
    linkedFrom: Maybe<ContentfulLandingPageSingleReviewSectionLinkingCollections>;
    reviewerName: Maybe<Scalars["String"]>;
    reviewImageBackgroundImage: Maybe<ContentfulAsset>;
    reviewText: Maybe<ContentfulLandingPageSingleReviewSectionReviewText>;
    reviewTextBackgroundImage: Maybe<ContentfulAsset>;
    sys: ContentfulSys;
  };

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageSingleReviewSection) */
export type ContentfulLandingPageSingleReviewSectionClassNameArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageSingleReviewSection) */
export type ContentfulLandingPageSingleReviewSectionClassUrlArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageSingleReviewSection) */
export type ContentfulLandingPageSingleReviewSectionIdArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageSingleReviewSection) */
export type ContentfulLandingPageSingleReviewSectionImageArgs = {
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageSingleReviewSection) */
export type ContentfulLandingPageSingleReviewSectionImageMobileArgs = {
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageSingleReviewSection) */
export type ContentfulLandingPageSingleReviewSectionLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageSingleReviewSection) */
export type ContentfulLandingPageSingleReviewSectionReviewerNameArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageSingleReviewSection) */
export type ContentfulLandingPageSingleReviewSectionReviewImageBackgroundImageArgs =
  {
    locale?: InputMaybe<Scalars["String"]>;
    preview?: InputMaybe<Scalars["Boolean"]>;
  };

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageSingleReviewSection) */
export type ContentfulLandingPageSingleReviewSectionReviewTextArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageSingleReviewSection) */
export type ContentfulLandingPageSingleReviewSectionReviewTextBackgroundImageArgs =
  {
    locale?: InputMaybe<Scalars["String"]>;
    preview?: InputMaybe<Scalars["Boolean"]>;
  };

export type ContentfulLandingPageSingleReviewSectionLinkingCollections = {
  __typename?: "ContentfulLandingPageSingleReviewSectionLinkingCollections";
  entryCollection: Maybe<ContentfulEntryCollection>;
  landingPageCollection: Maybe<ContentfulLandingPageCollection>;
};

export type ContentfulLandingPageSingleReviewSectionLinkingCollectionsEntryCollectionArgs =
  {
    limit?: InputMaybe<Scalars["Int"]>;
    locale?: InputMaybe<Scalars["String"]>;
    preview?: InputMaybe<Scalars["Boolean"]>;
    skip?: InputMaybe<Scalars["Int"]>;
  };

export type ContentfulLandingPageSingleReviewSectionLinkingCollectionsLandingPageCollectionArgs =
  {
    limit?: InputMaybe<Scalars["Int"]>;
    locale?: InputMaybe<Scalars["String"]>;
    order?: InputMaybe<
      Array<
        InputMaybe<ContentfulLandingPageSingleReviewSectionLinkingCollectionsLandingPageCollectionOrder>
      >
    >;
    preview?: InputMaybe<Scalars["Boolean"]>;
    skip?: InputMaybe<Scalars["Int"]>;
  };

export enum ContentfulLandingPageSingleReviewSectionLinkingCollectionsLandingPageCollectionOrder {
  MetaTagCanonicalUrlAsc = "metaTagCanonicalUrl_ASC",
  MetaTagCanonicalUrlDesc = "metaTagCanonicalUrl_DESC",
  MetaTagDescriptionAsc = "metaTagDescription_ASC",
  MetaTagDescriptionDesc = "metaTagDescription_DESC",
  NavbarCtaTextAsc = "navbarCtaText_ASC",
  NavbarCtaTextDesc = "navbarCtaText_DESC",
  NavbarCtaUrlAsc = "navbarCtaUrl_ASC",
  NavbarCtaUrlDesc = "navbarCtaUrl_DESC",
  NavbarCtaUrlLoggedInAsc = "navbarCtaUrlLoggedIn_ASC",
  NavbarCtaUrlLoggedInDesc = "navbarCtaUrlLoggedIn_DESC",
  SlugAsc = "slug_ASC",
  SlugDesc = "slug_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export type ContentfulLandingPageSingleReviewSectionReviewText = {
  __typename?: "ContentfulLandingPageSingleReviewSectionReviewText";
  json: Scalars["ContentfulJSON"];
  links: ContentfulLandingPageSingleReviewSectionReviewTextLinks;
};

export type ContentfulLandingPageSingleReviewSectionReviewTextLinks = {
  __typename?: "ContentfulLandingPageSingleReviewSectionReviewTextLinks";
  assets: ContentfulLandingPageSingleReviewSectionReviewTextAssets;
  entries: ContentfulLandingPageSingleReviewSectionReviewTextEntries;
  resources: ContentfulLandingPageSingleReviewSectionReviewTextResources;
};

export type ContentfulLandingPageSingleReviewSectionReviewTextAssets = {
  __typename?: "ContentfulLandingPageSingleReviewSectionReviewTextAssets";
  block: Array<Maybe<ContentfulAsset>>;
  hyperlink: Array<Maybe<ContentfulAsset>>;
};

export type ContentfulLandingPageSingleReviewSectionReviewTextEntries = {
  __typename?: "ContentfulLandingPageSingleReviewSectionReviewTextEntries";
  block: Array<Maybe<ContentfulEntry>>;
  hyperlink: Array<Maybe<ContentfulEntry>>;
  inline: Array<Maybe<ContentfulEntry>>;
};

export type ContentfulLandingPageSingleReviewSectionReviewTextResources = {
  __typename?: "ContentfulLandingPageSingleReviewSectionReviewTextResources";
  block: Array<ContentfulLandingPageSingleReviewSectionReviewTextResourcesBlock>;
  hyperlink: Array<ContentfulLandingPageSingleReviewSectionReviewTextResourcesHyperlink>;
  inline: Array<ContentfulLandingPageSingleReviewSectionReviewTextResourcesInline>;
};

export type ContentfulLandingPageSingleReviewSectionReviewTextResourcesBlock =
  ContentfulResourceLink & {
    __typename?: "ContentfulLandingPageSingleReviewSectionReviewTextResourcesBlock";
    sys: ContentfulResourceSys;
  };

export type ContentfulLandingPageSingleReviewSectionReviewTextResourcesHyperlink =
  ContentfulResourceLink & {
    __typename?: "ContentfulLandingPageSingleReviewSectionReviewTextResourcesHyperlink";
    sys: ContentfulResourceSys;
  };

export type ContentfulLandingPageSingleReviewSectionReviewTextResourcesInline =
  ContentfulResourceLink & {
    __typename?: "ContentfulLandingPageSingleReviewSectionReviewTextResourcesInline";
    sys: ContentfulResourceSys;
  };

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageStatBoxSection) */
export type ContentfulLandingPageStatBoxSection = Contentful_Node &
  ContentfulEntry & {
    __typename?: "ContentfulLandingPageStatBoxSection";
    _id: Scalars["ID"];
    contentfulMetadata: ContentfulContentfulMetadata;
    displayOnMobile: Maybe<Scalars["Boolean"]>;
    id: Maybe<Scalars["String"]>;
    linkedFrom: Maybe<ContentfulLandingPageStatBoxSectionLinkingCollections>;
    statBoxesCollection: Maybe<ContentfulLandingPageStatBoxSectionStatBoxesCollection>;
    sys: ContentfulSys;
  };

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageStatBoxSection) */
export type ContentfulLandingPageStatBoxSectionDisplayOnMobileArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageStatBoxSection) */
export type ContentfulLandingPageStatBoxSectionIdArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageStatBoxSection) */
export type ContentfulLandingPageStatBoxSectionLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageStatBoxSection) */
export type ContentfulLandingPageStatBoxSectionStatBoxesCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<
    Array<
      InputMaybe<ContentfulLandingPageStatBoxSectionStatBoxesCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ContentfulLandingPageStatBoxFilter>;
};

export type ContentfulLandingPageStatBoxSectionLinkingCollections = {
  __typename?: "ContentfulLandingPageStatBoxSectionLinkingCollections";
  entryCollection: Maybe<ContentfulEntryCollection>;
  landingPageCollection: Maybe<ContentfulLandingPageCollection>;
};

export type ContentfulLandingPageStatBoxSectionLinkingCollectionsEntryCollectionArgs =
  {
    limit?: InputMaybe<Scalars["Int"]>;
    locale?: InputMaybe<Scalars["String"]>;
    preview?: InputMaybe<Scalars["Boolean"]>;
    skip?: InputMaybe<Scalars["Int"]>;
  };

export type ContentfulLandingPageStatBoxSectionLinkingCollectionsLandingPageCollectionArgs =
  {
    limit?: InputMaybe<Scalars["Int"]>;
    locale?: InputMaybe<Scalars["String"]>;
    order?: InputMaybe<
      Array<
        InputMaybe<ContentfulLandingPageStatBoxSectionLinkingCollectionsLandingPageCollectionOrder>
      >
    >;
    preview?: InputMaybe<Scalars["Boolean"]>;
    skip?: InputMaybe<Scalars["Int"]>;
  };

export enum ContentfulLandingPageStatBoxSectionLinkingCollectionsLandingPageCollectionOrder {
  MetaTagCanonicalUrlAsc = "metaTagCanonicalUrl_ASC",
  MetaTagCanonicalUrlDesc = "metaTagCanonicalUrl_DESC",
  MetaTagDescriptionAsc = "metaTagDescription_ASC",
  MetaTagDescriptionDesc = "metaTagDescription_DESC",
  NavbarCtaTextAsc = "navbarCtaText_ASC",
  NavbarCtaTextDesc = "navbarCtaText_DESC",
  NavbarCtaUrlAsc = "navbarCtaUrl_ASC",
  NavbarCtaUrlDesc = "navbarCtaUrl_DESC",
  NavbarCtaUrlLoggedInAsc = "navbarCtaUrlLoggedIn_ASC",
  NavbarCtaUrlLoggedInDesc = "navbarCtaUrlLoggedIn_DESC",
  SlugAsc = "slug_ASC",
  SlugDesc = "slug_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export enum ContentfulLandingPageStatBoxSectionStatBoxesCollectionOrder {
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  SubtitleAsc = "subtitle_ASC",
  SubtitleDesc = "subtitle_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export type ContentfulLandingPageStatBoxFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulLandingPageStatBoxFilter>>>;
  backgroundImage_exists?: InputMaybe<Scalars["Boolean"]>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  hoverBackgroundImage_exists?: InputMaybe<Scalars["Boolean"]>;
  id?: InputMaybe<Scalars["String"]>;
  id_contains?: InputMaybe<Scalars["String"]>;
  id_exists?: InputMaybe<Scalars["Boolean"]>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  id_not?: InputMaybe<Scalars["String"]>;
  id_not_contains?: InputMaybe<Scalars["String"]>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulLandingPageStatBoxFilter>>>;
  subtitle?: InputMaybe<Scalars["String"]>;
  subtitle_contains?: InputMaybe<Scalars["String"]>;
  subtitle_exists?: InputMaybe<Scalars["Boolean"]>;
  subtitle_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  subtitle_not?: InputMaybe<Scalars["String"]>;
  subtitle_not_contains?: InputMaybe<Scalars["String"]>;
  subtitle_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type ContentfulLandingPageStatBoxSectionStatBoxesCollection = {
  __typename?: "ContentfulLandingPageStatBoxSectionStatBoxesCollection";
  items: Array<Maybe<ContentfulLandingPageStatBox>>;
  limit: Scalars["Int"];
  skip: Scalars["Int"];
  total: Scalars["Int"];
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageStatBox) */
export type ContentfulLandingPageStatBox = Contentful_Node &
  ContentfulEntry & {
    __typename?: "ContentfulLandingPageStatBox";
    _id: Scalars["ID"];
    backgroundImage: Maybe<ContentfulAsset>;
    contentfulMetadata: ContentfulContentfulMetadata;
    hoverBackgroundImage: Maybe<ContentfulAsset>;
    id: Maybe<Scalars["String"]>;
    linkedFrom: Maybe<ContentfulLandingPageStatBoxLinkingCollections>;
    subtitle: Maybe<Scalars["String"]>;
    sys: ContentfulSys;
    title: Maybe<Scalars["String"]>;
  };

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageStatBox) */
export type ContentfulLandingPageStatBoxBackgroundImageArgs = {
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageStatBox) */
export type ContentfulLandingPageStatBoxHoverBackgroundImageArgs = {
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageStatBox) */
export type ContentfulLandingPageStatBoxIdArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageStatBox) */
export type ContentfulLandingPageStatBoxLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageStatBox) */
export type ContentfulLandingPageStatBoxSubtitleArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageStatBox) */
export type ContentfulLandingPageStatBoxTitleArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

export type ContentfulLandingPageStatBoxLinkingCollections = {
  __typename?: "ContentfulLandingPageStatBoxLinkingCollections";
  entryCollection: Maybe<ContentfulEntryCollection>;
  landingPageStatBoxSectionCollection: Maybe<ContentfulLandingPageStatBoxSectionCollection>;
};

export type ContentfulLandingPageStatBoxLinkingCollectionsEntryCollectionArgs =
  {
    limit?: InputMaybe<Scalars["Int"]>;
    locale?: InputMaybe<Scalars["String"]>;
    preview?: InputMaybe<Scalars["Boolean"]>;
    skip?: InputMaybe<Scalars["Int"]>;
  };

export type ContentfulLandingPageStatBoxLinkingCollectionsLandingPageStatBoxSectionCollectionArgs =
  {
    limit?: InputMaybe<Scalars["Int"]>;
    locale?: InputMaybe<Scalars["String"]>;
    order?: InputMaybe<
      Array<
        InputMaybe<ContentfulLandingPageStatBoxLinkingCollectionsLandingPageStatBoxSectionCollectionOrder>
      >
    >;
    preview?: InputMaybe<Scalars["Boolean"]>;
    skip?: InputMaybe<Scalars["Int"]>;
  };

export enum ContentfulLandingPageStatBoxLinkingCollectionsLandingPageStatBoxSectionCollectionOrder {
  DisplayOnMobileAsc = "displayOnMobile_ASC",
  DisplayOnMobileDesc = "displayOnMobile_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
}

export type ContentfulLandingPageStatBoxSectionCollection = {
  __typename?: "ContentfulLandingPageStatBoxSectionCollection";
  items: Array<Maybe<ContentfulLandingPageStatBoxSection>>;
  limit: Scalars["Int"];
  skip: Scalars["Int"];
  total: Scalars["Int"];
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageTextOnlyStackedCtaSection) */
export type ContentfulLandingPageTextOnlyStackedCtaSection = Contentful_Node &
  ContentfulEntry & {
    __typename?: "ContentfulLandingPageTextOnlyStackedCtaSection";
    _id: Scalars["ID"];
    backgroundImage: Maybe<ContentfulEntry>;
    buttonCtaText: Maybe<Scalars["String"]>;
    buttonUrl: Maybe<Scalars["String"]>;
    buttonUrlLoggedIn: Maybe<Scalars["String"]>;
    contentfulMetadata: ContentfulContentfulMetadata;
    id: Maybe<Scalars["String"]>;
    includeGradientBackground: Maybe<Scalars["Boolean"]>;
    linkedFrom: Maybe<ContentfulLandingPageTextOnlyStackedCtaSectionLinkingCollections>;
    sys: ContentfulSys;
    title: Maybe<Scalars["String"]>;
  };

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageTextOnlyStackedCtaSection) */
export type ContentfulLandingPageTextOnlyStackedCtaSectionBackgroundImageArgs =
  {
    locale?: InputMaybe<Scalars["String"]>;
    preview?: InputMaybe<Scalars["Boolean"]>;
  };

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageTextOnlyStackedCtaSection) */
export type ContentfulLandingPageTextOnlyStackedCtaSectionButtonCtaTextArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageTextOnlyStackedCtaSection) */
export type ContentfulLandingPageTextOnlyStackedCtaSectionButtonUrlArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageTextOnlyStackedCtaSection) */
export type ContentfulLandingPageTextOnlyStackedCtaSectionButtonUrlLoggedInArgs =
  {
    locale?: InputMaybe<Scalars["String"]>;
  };

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageTextOnlyStackedCtaSection) */
export type ContentfulLandingPageTextOnlyStackedCtaSectionIdArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageTextOnlyStackedCtaSection) */
export type ContentfulLandingPageTextOnlyStackedCtaSectionIncludeGradientBackgroundArgs =
  {
    locale?: InputMaybe<Scalars["String"]>;
  };

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageTextOnlyStackedCtaSection) */
export type ContentfulLandingPageTextOnlyStackedCtaSectionLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/landingPageTextOnlyStackedCtaSection) */
export type ContentfulLandingPageTextOnlyStackedCtaSectionTitleArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

export type ContentfulLandingPageTextOnlyStackedCtaSectionLinkingCollections = {
  __typename?: "ContentfulLandingPageTextOnlyStackedCtaSectionLinkingCollections";
  entryCollection: Maybe<ContentfulEntryCollection>;
  landingPageCollection: Maybe<ContentfulLandingPageCollection>;
};

export type ContentfulLandingPageTextOnlyStackedCtaSectionLinkingCollectionsEntryCollectionArgs =
  {
    limit?: InputMaybe<Scalars["Int"]>;
    locale?: InputMaybe<Scalars["String"]>;
    preview?: InputMaybe<Scalars["Boolean"]>;
    skip?: InputMaybe<Scalars["Int"]>;
  };

export type ContentfulLandingPageTextOnlyStackedCtaSectionLinkingCollectionsLandingPageCollectionArgs =
  {
    limit?: InputMaybe<Scalars["Int"]>;
    locale?: InputMaybe<Scalars["String"]>;
    order?: InputMaybe<
      Array<
        InputMaybe<ContentfulLandingPageTextOnlyStackedCtaSectionLinkingCollectionsLandingPageCollectionOrder>
      >
    >;
    preview?: InputMaybe<Scalars["Boolean"]>;
    skip?: InputMaybe<Scalars["Int"]>;
  };

export enum ContentfulLandingPageTextOnlyStackedCtaSectionLinkingCollectionsLandingPageCollectionOrder {
  MetaTagCanonicalUrlAsc = "metaTagCanonicalUrl_ASC",
  MetaTagCanonicalUrlDesc = "metaTagCanonicalUrl_DESC",
  MetaTagDescriptionAsc = "metaTagDescription_ASC",
  MetaTagDescriptionDesc = "metaTagDescription_DESC",
  NavbarCtaTextAsc = "navbarCtaText_ASC",
  NavbarCtaTextDesc = "navbarCtaText_DESC",
  NavbarCtaUrlAsc = "navbarCtaUrl_ASC",
  NavbarCtaUrlDesc = "navbarCtaUrl_DESC",
  NavbarCtaUrlLoggedInAsc = "navbarCtaUrlLoggedIn_ASC",
  NavbarCtaUrlLoggedInDesc = "navbarCtaUrlLoggedIn_DESC",
  SlugAsc = "slug_ASC",
  SlugDesc = "slug_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export enum ContentfulCategoryLabelLinkingCollectionsMagnetPageCollectionOrder {
  MagnetPageSlugAsc = "magnetPageSlug_ASC",
  MagnetPageSlugDesc = "magnetPageSlug_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export type ContentfulMagnetPageLinkingCollections = {
  __typename?: "ContentfulMagnetPageLinkingCollections";
  categoryPageCollection: Maybe<ContentfulCategoryPageCollection>;
  entryCollection: Maybe<ContentfulEntryCollection>;
  featuredPagesCollection: Maybe<ContentfulFeaturedPagesCollection>;
  tutoringPageCollection: Maybe<ContentfulTutoringPageCollection>;
};

export type ContentfulMagnetPageLinkingCollectionsCategoryPageCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<
    Array<
      InputMaybe<ContentfulMagnetPageLinkingCollectionsCategoryPageCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type ContentfulMagnetPageLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type ContentfulMagnetPageLinkingCollectionsFeaturedPagesCollectionArgs =
  {
    limit?: InputMaybe<Scalars["Int"]>;
    locale?: InputMaybe<Scalars["String"]>;
    order?: InputMaybe<
      Array<
        InputMaybe<ContentfulMagnetPageLinkingCollectionsFeaturedPagesCollectionOrder>
      >
    >;
    preview?: InputMaybe<Scalars["Boolean"]>;
    skip?: InputMaybe<Scalars["Int"]>;
  };

export type ContentfulMagnetPageLinkingCollectionsTutoringPageCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<
    Array<
      InputMaybe<ContentfulMagnetPageLinkingCollectionsTutoringPageCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export enum ContentfulMagnetPageLinkingCollectionsCategoryPageCollectionOrder {
  BelowTheFoldTitleAsc = "belowTheFoldTitle_ASC",
  BelowTheFoldTitleDesc = "belowTheFoldTitle_DESC",
  CategorySlugAsc = "categorySlug_ASC",
  CategorySlugDesc = "categorySlug_DESC",
  FeaturedClassesOverrideSlugAsc = "featuredClassesOverrideSlug_ASC",
  FeaturedClassesOverrideSlugDesc = "featuredClassesOverrideSlug_DESC",
  FooterCtaPathAsc = "footerCtaPath_ASC",
  FooterCtaPathDesc = "footerCtaPath_DESC",
  FooterCtaTitleAsc = "footerCtaTitle_ASC",
  FooterCtaTitleDesc = "footerCtaTitle_DESC",
  HeroTitleAsc = "heroTitle_ASC",
  HeroTitleDesc = "heroTitle_DESC",
  JumpPillsTitleAsc = "jumpPillsTitle_ASC",
  JumpPillsTitleDesc = "jumpPillsTitle_DESC",
  ShowFeaturedClassesAsc = "showFeaturedClasses_ASC",
  ShowFeaturedClassesDesc = "showFeaturedClasses_DESC",
  StartAfterAsc = "startAfter_ASC",
  StartAfterDesc = "startAfter_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
}

export type ContentfulCategoryPageCollection = {
  __typename?: "ContentfulCategoryPageCollection";
  items: Array<Maybe<ContentfulCategoryPage>>;
  limit: Scalars["Int"];
  skip: Scalars["Int"];
  total: Scalars["Int"];
};

/** Category pages with rich and customizable content [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/categoryPage) */
export type ContentfulCategoryPage = Contentful_Node &
  ContentfulEntry & {
    __typename?: "ContentfulCategoryPage";
    _id: Scalars["ID"];
    belowTheFoldDescription: Maybe<ContentfulCategoryPageBelowTheFoldDescription>;
    belowTheFoldTitle: Maybe<Scalars["String"]>;
    categorySlug: Maybe<Scalars["String"]>;
    contentfulMetadata: ContentfulContentfulMetadata;
    featuredClassesOverrideSlug: Maybe<Scalars["String"]>;
    footerCtaPath: Maybe<Scalars["String"]>;
    footerCtaTitle: Maybe<Scalars["String"]>;
    heroDescription: Maybe<ContentfulCategoryPageHeroDescription>;
    heroImage: Maybe<ContentfulAsset>;
    heroTitle: Maybe<Scalars["String"]>;
    heroValueProps: Maybe<ContentfulCategoryPageHeroValueProps>;
    jumpPillLinksCollection: Maybe<ContentfulCategoryPageJumpPillLinksCollection>;
    jumpPillsCollection: Maybe<ContentfulCategoryPageJumpPillsCollection>;
    jumpPillsTitle: Maybe<Scalars["String"]>;
    linkedFrom: Maybe<ContentfulCategoryPageLinkingCollections>;
    magnetPagesCollection: Maybe<ContentfulCategoryPageMagnetPagesCollection>;
    relatedCategoriesCollection: Maybe<ContentfulCategoryPageRelatedCategoriesCollection>;
    showFeaturedClasses: Maybe<Scalars["Boolean"]>;
    startAfter: Maybe<Scalars["String"]>;
    sys: ContentfulSys;
  };

/** Category pages with rich and customizable content [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/categoryPage) */
export type ContentfulCategoryPageBelowTheFoldDescriptionArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** Category pages with rich and customizable content [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/categoryPage) */
export type ContentfulCategoryPageBelowTheFoldTitleArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** Category pages with rich and customizable content [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/categoryPage) */
export type ContentfulCategoryPageCategorySlugArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** Category pages with rich and customizable content [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/categoryPage) */
export type ContentfulCategoryPageFeaturedClassesOverrideSlugArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** Category pages with rich and customizable content [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/categoryPage) */
export type ContentfulCategoryPageFooterCtaPathArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** Category pages with rich and customizable content [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/categoryPage) */
export type ContentfulCategoryPageFooterCtaTitleArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** Category pages with rich and customizable content [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/categoryPage) */
export type ContentfulCategoryPageHeroDescriptionArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** Category pages with rich and customizable content [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/categoryPage) */
export type ContentfulCategoryPageHeroImageArgs = {
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
};

/** Category pages with rich and customizable content [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/categoryPage) */
export type ContentfulCategoryPageHeroTitleArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** Category pages with rich and customizable content [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/categoryPage) */
export type ContentfulCategoryPageHeroValuePropsArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** Category pages with rich and customizable content [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/categoryPage) */
export type ContentfulCategoryPageJumpPillLinksCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<
    Array<InputMaybe<ContentfulCategoryPageJumpPillLinksCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ContentfulVisualLinkFilter>;
};

/** Category pages with rich and customizable content [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/categoryPage) */
export type ContentfulCategoryPageJumpPillsCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<
    Array<InputMaybe<ContentfulCategoryPageJumpPillsCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ContentfulJumpPillsFilter>;
};

/** Category pages with rich and customizable content [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/categoryPage) */
export type ContentfulCategoryPageJumpPillsTitleArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** Category pages with rich and customizable content [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/categoryPage) */
export type ContentfulCategoryPageLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

/** Category pages with rich and customizable content [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/categoryPage) */
export type ContentfulCategoryPageMagnetPagesCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<
    Array<InputMaybe<ContentfulCategoryPageMagnetPagesCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ContentfulMagnetPageFilter>;
};

/** Category pages with rich and customizable content [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/categoryPage) */
export type ContentfulCategoryPageRelatedCategoriesCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<
    Array<InputMaybe<ContentfulCategoryPageRelatedCategoriesCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ContentfulUpsellClassCardFilter>;
};

/** Category pages with rich and customizable content [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/categoryPage) */
export type ContentfulCategoryPageShowFeaturedClassesArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** Category pages with rich and customizable content [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/categoryPage) */
export type ContentfulCategoryPageStartAfterArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

export type ContentfulCategoryPageBelowTheFoldDescription = {
  __typename?: "ContentfulCategoryPageBelowTheFoldDescription";
  json: Scalars["ContentfulJSON"];
  links: ContentfulCategoryPageBelowTheFoldDescriptionLinks;
};

export type ContentfulCategoryPageBelowTheFoldDescriptionLinks = {
  __typename?: "ContentfulCategoryPageBelowTheFoldDescriptionLinks";
  assets: ContentfulCategoryPageBelowTheFoldDescriptionAssets;
  entries: ContentfulCategoryPageBelowTheFoldDescriptionEntries;
  resources: ContentfulCategoryPageBelowTheFoldDescriptionResources;
};

export type ContentfulCategoryPageBelowTheFoldDescriptionAssets = {
  __typename?: "ContentfulCategoryPageBelowTheFoldDescriptionAssets";
  block: Array<Maybe<ContentfulAsset>>;
  hyperlink: Array<Maybe<ContentfulAsset>>;
};

export type ContentfulCategoryPageBelowTheFoldDescriptionEntries = {
  __typename?: "ContentfulCategoryPageBelowTheFoldDescriptionEntries";
  block: Array<Maybe<ContentfulEntry>>;
  hyperlink: Array<Maybe<ContentfulEntry>>;
  inline: Array<Maybe<ContentfulEntry>>;
};

export type ContentfulCategoryPageBelowTheFoldDescriptionResources = {
  __typename?: "ContentfulCategoryPageBelowTheFoldDescriptionResources";
  block: Array<ContentfulCategoryPageBelowTheFoldDescriptionResourcesBlock>;
  hyperlink: Array<ContentfulCategoryPageBelowTheFoldDescriptionResourcesHyperlink>;
  inline: Array<ContentfulCategoryPageBelowTheFoldDescriptionResourcesInline>;
};

export type ContentfulCategoryPageBelowTheFoldDescriptionResourcesBlock =
  ContentfulResourceLink & {
    __typename?: "ContentfulCategoryPageBelowTheFoldDescriptionResourcesBlock";
    sys: ContentfulResourceSys;
  };

export type ContentfulCategoryPageBelowTheFoldDescriptionResourcesHyperlink =
  ContentfulResourceLink & {
    __typename?: "ContentfulCategoryPageBelowTheFoldDescriptionResourcesHyperlink";
    sys: ContentfulResourceSys;
  };

export type ContentfulCategoryPageBelowTheFoldDescriptionResourcesInline =
  ContentfulResourceLink & {
    __typename?: "ContentfulCategoryPageBelowTheFoldDescriptionResourcesInline";
    sys: ContentfulResourceSys;
  };

export type ContentfulCategoryPageHeroDescription = {
  __typename?: "ContentfulCategoryPageHeroDescription";
  json: Scalars["ContentfulJSON"];
  links: ContentfulCategoryPageHeroDescriptionLinks;
};

export type ContentfulCategoryPageHeroDescriptionLinks = {
  __typename?: "ContentfulCategoryPageHeroDescriptionLinks";
  assets: ContentfulCategoryPageHeroDescriptionAssets;
  entries: ContentfulCategoryPageHeroDescriptionEntries;
  resources: ContentfulCategoryPageHeroDescriptionResources;
};

export type ContentfulCategoryPageHeroDescriptionAssets = {
  __typename?: "ContentfulCategoryPageHeroDescriptionAssets";
  block: Array<Maybe<ContentfulAsset>>;
  hyperlink: Array<Maybe<ContentfulAsset>>;
};

export type ContentfulCategoryPageHeroDescriptionEntries = {
  __typename?: "ContentfulCategoryPageHeroDescriptionEntries";
  block: Array<Maybe<ContentfulEntry>>;
  hyperlink: Array<Maybe<ContentfulEntry>>;
  inline: Array<Maybe<ContentfulEntry>>;
};

export type ContentfulCategoryPageHeroDescriptionResources = {
  __typename?: "ContentfulCategoryPageHeroDescriptionResources";
  block: Array<ContentfulCategoryPageHeroDescriptionResourcesBlock>;
  hyperlink: Array<ContentfulCategoryPageHeroDescriptionResourcesHyperlink>;
  inline: Array<ContentfulCategoryPageHeroDescriptionResourcesInline>;
};

export type ContentfulCategoryPageHeroDescriptionResourcesBlock =
  ContentfulResourceLink & {
    __typename?: "ContentfulCategoryPageHeroDescriptionResourcesBlock";
    sys: ContentfulResourceSys;
  };

export type ContentfulCategoryPageHeroDescriptionResourcesHyperlink =
  ContentfulResourceLink & {
    __typename?: "ContentfulCategoryPageHeroDescriptionResourcesHyperlink";
    sys: ContentfulResourceSys;
  };

export type ContentfulCategoryPageHeroDescriptionResourcesInline =
  ContentfulResourceLink & {
    __typename?: "ContentfulCategoryPageHeroDescriptionResourcesInline";
    sys: ContentfulResourceSys;
  };

export type ContentfulCategoryPageHeroValueProps = {
  __typename?: "ContentfulCategoryPageHeroValueProps";
  json: Scalars["ContentfulJSON"];
  links: ContentfulCategoryPageHeroValuePropsLinks;
};

export type ContentfulCategoryPageHeroValuePropsLinks = {
  __typename?: "ContentfulCategoryPageHeroValuePropsLinks";
  assets: ContentfulCategoryPageHeroValuePropsAssets;
  entries: ContentfulCategoryPageHeroValuePropsEntries;
  resources: ContentfulCategoryPageHeroValuePropsResources;
};

export type ContentfulCategoryPageHeroValuePropsAssets = {
  __typename?: "ContentfulCategoryPageHeroValuePropsAssets";
  block: Array<Maybe<ContentfulAsset>>;
  hyperlink: Array<Maybe<ContentfulAsset>>;
};

export type ContentfulCategoryPageHeroValuePropsEntries = {
  __typename?: "ContentfulCategoryPageHeroValuePropsEntries";
  block: Array<Maybe<ContentfulEntry>>;
  hyperlink: Array<Maybe<ContentfulEntry>>;
  inline: Array<Maybe<ContentfulEntry>>;
};

export type ContentfulCategoryPageHeroValuePropsResources = {
  __typename?: "ContentfulCategoryPageHeroValuePropsResources";
  block: Array<ContentfulCategoryPageHeroValuePropsResourcesBlock>;
  hyperlink: Array<ContentfulCategoryPageHeroValuePropsResourcesHyperlink>;
  inline: Array<ContentfulCategoryPageHeroValuePropsResourcesInline>;
};

export type ContentfulCategoryPageHeroValuePropsResourcesBlock =
  ContentfulResourceLink & {
    __typename?: "ContentfulCategoryPageHeroValuePropsResourcesBlock";
    sys: ContentfulResourceSys;
  };

export type ContentfulCategoryPageHeroValuePropsResourcesHyperlink =
  ContentfulResourceLink & {
    __typename?: "ContentfulCategoryPageHeroValuePropsResourcesHyperlink";
    sys: ContentfulResourceSys;
  };

export type ContentfulCategoryPageHeroValuePropsResourcesInline =
  ContentfulResourceLink & {
    __typename?: "ContentfulCategoryPageHeroValuePropsResourcesInline";
    sys: ContentfulResourceSys;
  };

export enum ContentfulCategoryPageJumpPillLinksCollectionOrder {
  ImageAltTextAsc = "imageAltText_ASC",
  ImageAltTextDesc = "imageAltText_DESC",
  ImageAspectRatioAsc = "imageAspectRatio_ASC",
  ImageAspectRatioDesc = "imageAspectRatio_DESC",
  PathAsc = "path_ASC",
  PathDesc = "path_DESC",
  PathBAsc = "pathB_ASC",
  PathBDesc = "pathB_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TextAsc = "text_ASC",
  TextDesc = "text_DESC",
}

export type ContentfulVisualLinkFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulVisualLinkFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  image_exists?: InputMaybe<Scalars["Boolean"]>;
  imageAltText?: InputMaybe<Scalars["String"]>;
  imageAltText_contains?: InputMaybe<Scalars["String"]>;
  imageAltText_exists?: InputMaybe<Scalars["Boolean"]>;
  imageAltText_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  imageAltText_not?: InputMaybe<Scalars["String"]>;
  imageAltText_not_contains?: InputMaybe<Scalars["String"]>;
  imageAltText_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  imageAspectRatio?: InputMaybe<Scalars["String"]>;
  imageAspectRatio_contains?: InputMaybe<Scalars["String"]>;
  imageAspectRatio_exists?: InputMaybe<Scalars["Boolean"]>;
  imageAspectRatio_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  imageAspectRatio_not?: InputMaybe<Scalars["String"]>;
  imageAspectRatio_not_contains?: InputMaybe<Scalars["String"]>;
  imageAspectRatio_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulVisualLinkFilter>>>;
  path?: InputMaybe<Scalars["String"]>;
  path_contains?: InputMaybe<Scalars["String"]>;
  path_exists?: InputMaybe<Scalars["Boolean"]>;
  path_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  path_not?: InputMaybe<Scalars["String"]>;
  path_not_contains?: InputMaybe<Scalars["String"]>;
  path_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  pathB?: InputMaybe<Scalars["String"]>;
  pathB_contains?: InputMaybe<Scalars["String"]>;
  pathB_exists?: InputMaybe<Scalars["Boolean"]>;
  pathB_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  pathB_not?: InputMaybe<Scalars["String"]>;
  pathB_not_contains?: InputMaybe<Scalars["String"]>;
  pathB_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
  text?: InputMaybe<Scalars["String"]>;
  text_contains?: InputMaybe<Scalars["String"]>;
  text_exists?: InputMaybe<Scalars["Boolean"]>;
  text_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  text_not?: InputMaybe<Scalars["String"]>;
  text_not_contains?: InputMaybe<Scalars["String"]>;
  text_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type ContentfulCategoryPageJumpPillLinksCollection = {
  __typename?: "ContentfulCategoryPageJumpPillLinksCollection";
  items: Array<Maybe<ContentfulVisualLink>>;
  limit: Scalars["Int"];
  skip: Scalars["Int"];
  total: Scalars["Int"];
};

/** A generic link including an image. [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/visualLink) */
export type ContentfulVisualLink = Contentful_Node &
  ContentfulEntry & {
    __typename?: "ContentfulVisualLink";
    _id: Scalars["ID"];
    contentfulMetadata: ContentfulContentfulMetadata;
    image: Maybe<ContentfulAsset>;
    imageAltText: Maybe<Scalars["String"]>;
    imageAspectRatio: Maybe<Scalars["String"]>;
    linkedFrom: Maybe<ContentfulVisualLinkLinkingCollections>;
    path: Maybe<Scalars["String"]>;
    pathB: Maybe<Scalars["String"]>;
    sys: ContentfulSys;
    text: Maybe<Scalars["String"]>;
  };

/** A generic link including an image. [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/visualLink) */
export type ContentfulVisualLinkImageArgs = {
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
};

/** A generic link including an image. [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/visualLink) */
export type ContentfulVisualLinkImageAltTextArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** A generic link including an image. [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/visualLink) */
export type ContentfulVisualLinkImageAspectRatioArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** A generic link including an image. [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/visualLink) */
export type ContentfulVisualLinkLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

/** A generic link including an image. [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/visualLink) */
export type ContentfulVisualLinkPathArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** A generic link including an image. [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/visualLink) */
export type ContentfulVisualLinkPathBArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** A generic link including an image. [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/visualLink) */
export type ContentfulVisualLinkTextArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

export type ContentfulVisualLinkLinkingCollections = {
  __typename?: "ContentfulVisualLinkLinkingCollections";
  categoryPageCollection: Maybe<ContentfulCategoryPageCollection>;
  entryCollection: Maybe<ContentfulEntryCollection>;
  homePage2Collection: Maybe<ContentfulHomePage2Collection>;
  tutoringPageCollection: Maybe<ContentfulTutoringPageCollection>;
  visualLinkGridCollection: Maybe<ContentfulVisualLinkGridCollection>;
};

export type ContentfulVisualLinkLinkingCollectionsCategoryPageCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<
    Array<
      InputMaybe<ContentfulVisualLinkLinkingCollectionsCategoryPageCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type ContentfulVisualLinkLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type ContentfulVisualLinkLinkingCollectionsHomePage2CollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<
    Array<
      InputMaybe<ContentfulVisualLinkLinkingCollectionsHomePage2CollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type ContentfulVisualLinkLinkingCollectionsTutoringPageCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<
    Array<
      InputMaybe<ContentfulVisualLinkLinkingCollectionsTutoringPageCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type ContentfulVisualLinkLinkingCollectionsVisualLinkGridCollectionArgs =
  {
    limit?: InputMaybe<Scalars["Int"]>;
    locale?: InputMaybe<Scalars["String"]>;
    order?: InputMaybe<
      Array<
        InputMaybe<ContentfulVisualLinkLinkingCollectionsVisualLinkGridCollectionOrder>
      >
    >;
    preview?: InputMaybe<Scalars["Boolean"]>;
    skip?: InputMaybe<Scalars["Int"]>;
  };

export enum ContentfulVisualLinkLinkingCollectionsCategoryPageCollectionOrder {
  BelowTheFoldTitleAsc = "belowTheFoldTitle_ASC",
  BelowTheFoldTitleDesc = "belowTheFoldTitle_DESC",
  CategorySlugAsc = "categorySlug_ASC",
  CategorySlugDesc = "categorySlug_DESC",
  FeaturedClassesOverrideSlugAsc = "featuredClassesOverrideSlug_ASC",
  FeaturedClassesOverrideSlugDesc = "featuredClassesOverrideSlug_DESC",
  FooterCtaPathAsc = "footerCtaPath_ASC",
  FooterCtaPathDesc = "footerCtaPath_DESC",
  FooterCtaTitleAsc = "footerCtaTitle_ASC",
  FooterCtaTitleDesc = "footerCtaTitle_DESC",
  HeroTitleAsc = "heroTitle_ASC",
  HeroTitleDesc = "heroTitle_DESC",
  JumpPillsTitleAsc = "jumpPillsTitle_ASC",
  JumpPillsTitleDesc = "jumpPillsTitle_DESC",
  ShowFeaturedClassesAsc = "showFeaturedClasses_ASC",
  ShowFeaturedClassesDesc = "showFeaturedClasses_DESC",
  StartAfterAsc = "startAfter_ASC",
  StartAfterDesc = "startAfter_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
}

export enum ContentfulVisualLinkLinkingCollectionsHomePage2CollectionOrder {
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export type ContentfulHomePage2Collection = {
  __typename?: "ContentfulHomePage2Collection";
  items: Array<Maybe<ContentfulHomePage2>>;
  limit: Scalars["Int"];
  skip: Scalars["Int"];
  total: Scalars["Int"];
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/homePage2) */
export type ContentfulHomePage2 = Contentful_Node &
  ContentfulEntry & {
    __typename?: "ContentfulHomePage2";
    _id: Scalars["ID"];
    contentfulMetadata: ContentfulContentfulMetadata;
    heroSlidesCollection: Maybe<ContentfulHomePage2HeroSlidesCollection>;
    linkedFrom: Maybe<ContentfulHomePage2LinkingCollections>;
    popularSearchLinksCollection: Maybe<ContentfulHomePage2PopularSearchLinksCollection>;
    sys: ContentfulSys;
    title: Maybe<Scalars["String"]>;
    valuePropsCollection: Maybe<ContentfulHomePage2ValuePropsCollection>;
    visualLinkGridsCollection: Maybe<ContentfulHomePage2VisualLinkGridsCollection>;
  };

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/homePage2) */
export type ContentfulHomePage2HeroSlidesCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<
    Array<InputMaybe<ContentfulHomePage2HeroSlidesCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ContentfulHeroSlideFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/homePage2) */
export type ContentfulHomePage2LinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/homePage2) */
export type ContentfulHomePage2PopularSearchLinksCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<
    Array<InputMaybe<ContentfulHomePage2PopularSearchLinksCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ContentfulVisualLinkFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/homePage2) */
export type ContentfulHomePage2TitleArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/homePage2) */
export type ContentfulHomePage2ValuePropsCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<
    Array<InputMaybe<ContentfulHomePage2ValuePropsCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ContentfulValuePropFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/homePage2) */
export type ContentfulHomePage2VisualLinkGridsCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<
    Array<InputMaybe<ContentfulHomePage2VisualLinkGridsCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ContentfulVisualLinkGridFilter>;
};

export enum ContentfulHomePage2HeroSlidesCollectionOrder {
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export type ContentfulHeroSlideFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulHeroSlideFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  image_exists?: InputMaybe<Scalars["Boolean"]>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulHeroSlideFilter>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type ContentfulHomePage2HeroSlidesCollection = {
  __typename?: "ContentfulHomePage2HeroSlidesCollection";
  items: Array<Maybe<ContentfulHeroSlide>>;
  limit: Scalars["Int"];
  skip: Scalars["Int"];
  total: Scalars["Int"];
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/heroSlide) */
export type ContentfulHeroSlide = Contentful_Node &
  ContentfulEntry & {
    __typename?: "ContentfulHeroSlide";
    _id: Scalars["ID"];
    contentfulMetadata: ContentfulContentfulMetadata;
    image: Maybe<ContentfulAsset>;
    linkedFrom: Maybe<ContentfulHeroSlideLinkingCollections>;
    sys: ContentfulSys;
    title: Maybe<Scalars["String"]>;
  };

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/heroSlide) */
export type ContentfulHeroSlideImageArgs = {
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/heroSlide) */
export type ContentfulHeroSlideLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/heroSlide) */
export type ContentfulHeroSlideTitleArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

export type ContentfulHeroSlideLinkingCollections = {
  __typename?: "ContentfulHeroSlideLinkingCollections";
  entryCollection: Maybe<ContentfulEntryCollection>;
  homePage2Collection: Maybe<ContentfulHomePage2Collection>;
};

export type ContentfulHeroSlideLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type ContentfulHeroSlideLinkingCollectionsHomePage2CollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<
    Array<
      InputMaybe<ContentfulHeroSlideLinkingCollectionsHomePage2CollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export enum ContentfulHeroSlideLinkingCollectionsHomePage2CollectionOrder {
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export type ContentfulHomePage2LinkingCollections = {
  __typename?: "ContentfulHomePage2LinkingCollections";
  entryCollection: Maybe<ContentfulEntryCollection>;
};

export type ContentfulHomePage2LinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export enum ContentfulHomePage2PopularSearchLinksCollectionOrder {
  ImageAltTextAsc = "imageAltText_ASC",
  ImageAltTextDesc = "imageAltText_DESC",
  ImageAspectRatioAsc = "imageAspectRatio_ASC",
  ImageAspectRatioDesc = "imageAspectRatio_DESC",
  PathAsc = "path_ASC",
  PathDesc = "path_DESC",
  PathBAsc = "pathB_ASC",
  PathBDesc = "pathB_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TextAsc = "text_ASC",
  TextDesc = "text_DESC",
}

export type ContentfulHomePage2PopularSearchLinksCollection = {
  __typename?: "ContentfulHomePage2PopularSearchLinksCollection";
  items: Array<Maybe<ContentfulVisualLink>>;
  limit: Scalars["Int"];
  skip: Scalars["Int"];
  total: Scalars["Int"];
};

export enum ContentfulHomePage2ValuePropsCollectionOrder {
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export type ContentfulValuePropFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulValuePropFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  icon_exists?: InputMaybe<Scalars["Boolean"]>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulValuePropFilter>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type ContentfulHomePage2ValuePropsCollection = {
  __typename?: "ContentfulHomePage2ValuePropsCollection";
  items: Array<Maybe<ContentfulValueProp>>;
  limit: Scalars["Int"];
  skip: Scalars["Int"];
  total: Scalars["Int"];
};

/** An svg icon and a concise value prop [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/valueProp) */
export type ContentfulValueProp = Contentful_Node &
  ContentfulEntry & {
    __typename?: "ContentfulValueProp";
    _id: Scalars["ID"];
    contentfulMetadata: ContentfulContentfulMetadata;
    icon: Maybe<ContentfulAsset>;
    linkedFrom: Maybe<ContentfulValuePropLinkingCollections>;
    sys: ContentfulSys;
    title: Maybe<Scalars["String"]>;
  };

/** An svg icon and a concise value prop [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/valueProp) */
export type ContentfulValuePropIconArgs = {
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
};

/** An svg icon and a concise value prop [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/valueProp) */
export type ContentfulValuePropLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

/** An svg icon and a concise value prop [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/valueProp) */
export type ContentfulValuePropTitleArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

export type ContentfulValuePropLinkingCollections = {
  __typename?: "ContentfulValuePropLinkingCollections";
  entryCollection: Maybe<ContentfulEntryCollection>;
  homePage2Collection: Maybe<ContentfulHomePage2Collection>;
};

export type ContentfulValuePropLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type ContentfulValuePropLinkingCollectionsHomePage2CollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<
    Array<
      InputMaybe<ContentfulValuePropLinkingCollectionsHomePage2CollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export enum ContentfulValuePropLinkingCollectionsHomePage2CollectionOrder {
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export enum ContentfulHomePage2VisualLinkGridsCollectionOrder {
  StyleAsc = "style_ASC",
  StyleDesc = "style_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export type ContentfulVisualLinkGridFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulVisualLinkGridFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulVisualLinkGridFilter>>>;
  style?: InputMaybe<Scalars["String"]>;
  style_contains?: InputMaybe<Scalars["String"]>;
  style_exists?: InputMaybe<Scalars["Boolean"]>;
  style_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  style_not?: InputMaybe<Scalars["String"]>;
  style_not_contains?: InputMaybe<Scalars["String"]>;
  style_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  visualLinks?: InputMaybe<ContentfulcfVisualLinkNestedFilter>;
  visualLinksCollection_exists?: InputMaybe<Scalars["Boolean"]>;
};

export type ContentfulcfVisualLinkNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulcfVisualLinkNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  image_exists?: InputMaybe<Scalars["Boolean"]>;
  imageAltText?: InputMaybe<Scalars["String"]>;
  imageAltText_contains?: InputMaybe<Scalars["String"]>;
  imageAltText_exists?: InputMaybe<Scalars["Boolean"]>;
  imageAltText_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  imageAltText_not?: InputMaybe<Scalars["String"]>;
  imageAltText_not_contains?: InputMaybe<Scalars["String"]>;
  imageAltText_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  imageAspectRatio?: InputMaybe<Scalars["String"]>;
  imageAspectRatio_contains?: InputMaybe<Scalars["String"]>;
  imageAspectRatio_exists?: InputMaybe<Scalars["Boolean"]>;
  imageAspectRatio_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  imageAspectRatio_not?: InputMaybe<Scalars["String"]>;
  imageAspectRatio_not_contains?: InputMaybe<Scalars["String"]>;
  imageAspectRatio_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulcfVisualLinkNestedFilter>>>;
  path?: InputMaybe<Scalars["String"]>;
  path_contains?: InputMaybe<Scalars["String"]>;
  path_exists?: InputMaybe<Scalars["Boolean"]>;
  path_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  path_not?: InputMaybe<Scalars["String"]>;
  path_not_contains?: InputMaybe<Scalars["String"]>;
  path_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  pathB?: InputMaybe<Scalars["String"]>;
  pathB_contains?: InputMaybe<Scalars["String"]>;
  pathB_exists?: InputMaybe<Scalars["Boolean"]>;
  pathB_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  pathB_not?: InputMaybe<Scalars["String"]>;
  pathB_not_contains?: InputMaybe<Scalars["String"]>;
  pathB_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
  text?: InputMaybe<Scalars["String"]>;
  text_contains?: InputMaybe<Scalars["String"]>;
  text_exists?: InputMaybe<Scalars["Boolean"]>;
  text_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  text_not?: InputMaybe<Scalars["String"]>;
  text_not_contains?: InputMaybe<Scalars["String"]>;
  text_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type ContentfulHomePage2VisualLinkGridsCollection = {
  __typename?: "ContentfulHomePage2VisualLinkGridsCollection";
  items: Array<Maybe<ContentfulVisualLinkGrid>>;
  limit: Scalars["Int"];
  skip: Scalars["Int"];
  total: Scalars["Int"];
};

/** A titled collection of Visual Links displayed in a particular style. [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/visualLinkGrid) */
export type ContentfulVisualLinkGrid = Contentful_Node &
  ContentfulEntry & {
    __typename?: "ContentfulVisualLinkGrid";
    _id: Scalars["ID"];
    contentfulMetadata: ContentfulContentfulMetadata;
    linkedFrom: Maybe<ContentfulVisualLinkGridLinkingCollections>;
    style: Maybe<Scalars["String"]>;
    sys: ContentfulSys;
    title: Maybe<Scalars["String"]>;
    visualLinksCollection: Maybe<ContentfulVisualLinkGridVisualLinksCollection>;
  };

/** A titled collection of Visual Links displayed in a particular style. [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/visualLinkGrid) */
export type ContentfulVisualLinkGridLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

/** A titled collection of Visual Links displayed in a particular style. [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/visualLinkGrid) */
export type ContentfulVisualLinkGridStyleArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** A titled collection of Visual Links displayed in a particular style. [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/visualLinkGrid) */
export type ContentfulVisualLinkGridTitleArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** A titled collection of Visual Links displayed in a particular style. [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/visualLinkGrid) */
export type ContentfulVisualLinkGridVisualLinksCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<
    Array<InputMaybe<ContentfulVisualLinkGridVisualLinksCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ContentfulVisualLinkFilter>;
};

export type ContentfulVisualLinkGridLinkingCollections = {
  __typename?: "ContentfulVisualLinkGridLinkingCollections";
  entryCollection: Maybe<ContentfulEntryCollection>;
  homePage2Collection: Maybe<ContentfulHomePage2Collection>;
  tutoringPageCollection: Maybe<ContentfulTutoringPageCollection>;
};

export type ContentfulVisualLinkGridLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type ContentfulVisualLinkGridLinkingCollectionsHomePage2CollectionArgs =
  {
    limit?: InputMaybe<Scalars["Int"]>;
    locale?: InputMaybe<Scalars["String"]>;
    order?: InputMaybe<
      Array<
        InputMaybe<ContentfulVisualLinkGridLinkingCollectionsHomePage2CollectionOrder>
      >
    >;
    preview?: InputMaybe<Scalars["Boolean"]>;
    skip?: InputMaybe<Scalars["Int"]>;
  };

export type ContentfulVisualLinkGridLinkingCollectionsTutoringPageCollectionArgs =
  {
    limit?: InputMaybe<Scalars["Int"]>;
    locale?: InputMaybe<Scalars["String"]>;
    order?: InputMaybe<
      Array<
        InputMaybe<ContentfulVisualLinkGridLinkingCollectionsTutoringPageCollectionOrder>
      >
    >;
    preview?: InputMaybe<Scalars["Boolean"]>;
    skip?: InputMaybe<Scalars["Int"]>;
  };

export enum ContentfulVisualLinkGridLinkingCollectionsHomePage2CollectionOrder {
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export enum ContentfulVisualLinkGridLinkingCollectionsTutoringPageCollectionOrder {
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export type ContentfulTutoringPageCollection = {
  __typename?: "ContentfulTutoringPageCollection";
  items: Array<Maybe<ContentfulTutoringPage>>;
  limit: Scalars["Int"];
  skip: Scalars["Int"];
  total: Scalars["Int"];
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/tutoringPage) */
export type ContentfulTutoringPage = Contentful_Node &
  ContentfulEntry & {
    __typename?: "ContentfulTutoringPage";
    _id: Scalars["ID"];
    articlesCollection: Maybe<ContentfulTutoringPageArticlesCollection>;
    carouselSubcategoriesCollection: Maybe<ContentfulTutoringPageCarouselSubcategoriesCollection>;
    contentfulMetadata: ContentfulContentfulMetadata;
    linkedFrom: Maybe<ContentfulTutoringPageLinkingCollections>;
    popularSearchLinksCollection: Maybe<ContentfulTutoringPagePopularSearchLinksCollection>;
    popularTutoringSubjectsCollection: Maybe<ContentfulTutoringPagePopularTutoringSubjectsCollection>;
    sys: ContentfulSys;
    title: Maybe<Scalars["String"]>;
    tutoringCarouselCollection: Maybe<ContentfulTutoringPageTutoringCarouselCollection>;
    visualLinkGridsCollection: Maybe<ContentfulTutoringPageVisualLinkGridsCollection>;
  };

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/tutoringPage) */
export type ContentfulTutoringPageArticlesCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<
    Array<InputMaybe<ContentfulTutoringPageArticlesCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ContentfulMagnetPageFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/tutoringPage) */
export type ContentfulTutoringPageCarouselSubcategoriesCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<
    Array<
      InputMaybe<ContentfulTutoringPageCarouselSubcategoriesCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ContentfulTopicFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/tutoringPage) */
export type ContentfulTutoringPageLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/tutoringPage) */
export type ContentfulTutoringPagePopularSearchLinksCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<
    Array<InputMaybe<ContentfulTutoringPagePopularSearchLinksCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ContentfulVisualLinkFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/tutoringPage) */
export type ContentfulTutoringPagePopularTutoringSubjectsCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<
    Array<
      InputMaybe<ContentfulTutoringPagePopularTutoringSubjectsCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ContentfulVisualLinkFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/tutoringPage) */
export type ContentfulTutoringPageTitleArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/tutoringPage) */
export type ContentfulTutoringPageTutoringCarouselCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<
    Array<InputMaybe<ContentfulTutoringPageTutoringCarouselCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ContentfulVisualLinkGridFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/tutoringPage) */
export type ContentfulTutoringPageVisualLinkGridsCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<
    Array<InputMaybe<ContentfulTutoringPageVisualLinkGridsCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ContentfulVisualLinkGridFilter>;
};

export enum ContentfulTutoringPageArticlesCollectionOrder {
  MagnetPageSlugAsc = "magnetPageSlug_ASC",
  MagnetPageSlugDesc = "magnetPageSlug_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export type ContentfulMagnetPageFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulMagnetPageFilter>>>;
  author?: InputMaybe<ContentfulcfAuthorNestedFilter>;
  author_exists?: InputMaybe<Scalars["Boolean"]>;
  body_contains?: InputMaybe<Scalars["String"]>;
  body_exists?: InputMaybe<Scalars["Boolean"]>;
  body_not_contains?: InputMaybe<Scalars["String"]>;
  categoryLabel?: InputMaybe<ContentfulcfCategoryLabelNestedFilter>;
  categoryLabel_exists?: InputMaybe<Scalars["Boolean"]>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  headerImage_exists?: InputMaybe<Scalars["Boolean"]>;
  magnetPageSlug?: InputMaybe<Scalars["String"]>;
  magnetPageSlug_contains?: InputMaybe<Scalars["String"]>;
  magnetPageSlug_exists?: InputMaybe<Scalars["Boolean"]>;
  magnetPageSlug_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  magnetPageSlug_not?: InputMaybe<Scalars["String"]>;
  magnetPageSlug_not_contains?: InputMaybe<Scalars["String"]>;
  magnetPageSlug_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulMagnetPageFilter>>>;
  summary_contains?: InputMaybe<Scalars["String"]>;
  summary_exists?: InputMaybe<Scalars["Boolean"]>;
  summary_not_contains?: InputMaybe<Scalars["String"]>;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  upsellClassCarousel?: InputMaybe<ContentfulcfUpsellClassCardNestedFilter>;
  upsellClassCarousel_exists?: InputMaybe<Scalars["Boolean"]>;
};

export type ContentfulcfAuthorNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulcfAuthorNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  description?: InputMaybe<Scalars["String"]>;
  description_contains?: InputMaybe<Scalars["String"]>;
  description_exists?: InputMaybe<Scalars["Boolean"]>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  description_not?: InputMaybe<Scalars["String"]>;
  description_not_contains?: InputMaybe<Scalars["String"]>;
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  isTeacher?: InputMaybe<Scalars["Boolean"]>;
  isTeacher_exists?: InputMaybe<Scalars["Boolean"]>;
  isTeacher_not?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  name_contains?: InputMaybe<Scalars["String"]>;
  name_exists?: InputMaybe<Scalars["Boolean"]>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  name_not?: InputMaybe<Scalars["String"]>;
  name_not_contains?: InputMaybe<Scalars["String"]>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulcfAuthorNestedFilter>>>;
  profileImage_exists?: InputMaybe<Scalars["Boolean"]>;
  sys?: InputMaybe<ContentfulSysFilter>;
  uid?: InputMaybe<Scalars["String"]>;
  uid_contains?: InputMaybe<Scalars["String"]>;
  uid_exists?: InputMaybe<Scalars["Boolean"]>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  uid_not?: InputMaybe<Scalars["String"]>;
  uid_not_contains?: InputMaybe<Scalars["String"]>;
  uid_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type ContentfulcfCategoryLabelNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulcfCategoryLabelNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulcfCategoryLabelNestedFilter>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
  value?: InputMaybe<Scalars["String"]>;
  value_contains?: InputMaybe<Scalars["String"]>;
  value_exists?: InputMaybe<Scalars["Boolean"]>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  value_not?: InputMaybe<Scalars["String"]>;
  value_not_contains?: InputMaybe<Scalars["String"]>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type ContentfulcfUpsellClassCardNestedFilter = {
  adminTags_contains_all?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  adminTags_contains_none?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  adminTags_contains_some?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  adminTags_exists?: InputMaybe<Scalars["Boolean"]>;
  ages_contains_all?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  ages_contains_none?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  ages_contains_some?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  ages_exists?: InputMaybe<Scalars["Boolean"]>;
  AND?: InputMaybe<Array<InputMaybe<ContentfulcfUpsellClassCardNestedFilter>>>;
  callToActionText?: InputMaybe<Scalars["String"]>;
  callToActionText_contains?: InputMaybe<Scalars["String"]>;
  callToActionText_exists?: InputMaybe<Scalars["Boolean"]>;
  callToActionText_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  callToActionText_not?: InputMaybe<Scalars["String"]>;
  callToActionText_not_contains?: InputMaybe<Scalars["String"]>;
  callToActionText_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  callToActionUrl?: InputMaybe<Scalars["String"]>;
  callToActionUrl_contains?: InputMaybe<Scalars["String"]>;
  callToActionUrl_exists?: InputMaybe<Scalars["Boolean"]>;
  callToActionUrl_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  callToActionUrl_not?: InputMaybe<Scalars["String"]>;
  callToActionUrl_not_contains?: InputMaybe<Scalars["String"]>;
  callToActionUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  capacityMax?: InputMaybe<Scalars["Int"]>;
  capacityMax_exists?: InputMaybe<Scalars["Boolean"]>;
  capacityMax_gt?: InputMaybe<Scalars["Int"]>;
  capacityMax_gte?: InputMaybe<Scalars["Int"]>;
  capacityMax_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  capacityMax_lt?: InputMaybe<Scalars["Int"]>;
  capacityMax_lte?: InputMaybe<Scalars["Int"]>;
  capacityMax_not?: InputMaybe<Scalars["Int"]>;
  capacityMax_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  capacityMin?: InputMaybe<Scalars["Int"]>;
  capacityMin_exists?: InputMaybe<Scalars["Boolean"]>;
  capacityMin_gt?: InputMaybe<Scalars["Int"]>;
  capacityMin_gte?: InputMaybe<Scalars["Int"]>;
  capacityMin_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  capacityMin_lt?: InputMaybe<Scalars["Int"]>;
  capacityMin_lte?: InputMaybe<Scalars["Int"]>;
  capacityMin_not?: InputMaybe<Scalars["Int"]>;
  capacityMin_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  category_contains_all?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  category_contains_none?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  category_contains_some?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  category_exists?: InputMaybe<Scalars["Boolean"]>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  deliveryTypes_contains_all?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  deliveryTypes_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  deliveryTypes_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  deliveryTypes_exists?: InputMaybe<Scalars["Boolean"]>;
  formats_contains_all?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  formats_contains_none?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  formats_contains_some?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  formats_exists?: InputMaybe<Scalars["Boolean"]>;
  fundingPrograms_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  fundingPrograms_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  fundingPrograms_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  fundingPrograms_exists?: InputMaybe<Scalars["Boolean"]>;
  gradeLevel_contains_all?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  gradeLevel_contains_none?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  gradeLevel_contains_some?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  gradeLevel_exists?: InputMaybe<Scalars["Boolean"]>;
  hasFilledOutUniqueLearningNeeds?: InputMaybe<Scalars["Boolean"]>;
  hasFilledOutUniqueLearningNeeds_exists?: InputMaybe<Scalars["Boolean"]>;
  hasFilledOutUniqueLearningNeeds_not?: InputMaybe<Scalars["Boolean"]>;
  maxPriceCents?: InputMaybe<Scalars["Int"]>;
  maxPriceCents_exists?: InputMaybe<Scalars["Boolean"]>;
  maxPriceCents_gt?: InputMaybe<Scalars["Int"]>;
  maxPriceCents_gte?: InputMaybe<Scalars["Int"]>;
  maxPriceCents_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  maxPriceCents_lt?: InputMaybe<Scalars["Int"]>;
  maxPriceCents_lte?: InputMaybe<Scalars["Int"]>;
  maxPriceCents_not?: InputMaybe<Scalars["Int"]>;
  maxPriceCents_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  minPriceCents?: InputMaybe<Scalars["Int"]>;
  minPriceCents_exists?: InputMaybe<Scalars["Boolean"]>;
  minPriceCents_gt?: InputMaybe<Scalars["Int"]>;
  minPriceCents_gte?: InputMaybe<Scalars["Int"]>;
  minPriceCents_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  minPriceCents_lt?: InputMaybe<Scalars["Int"]>;
  minPriceCents_lte?: InputMaybe<Scalars["Int"]>;
  minPriceCents_not?: InputMaybe<Scalars["Int"]>;
  minPriceCents_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  multiTermQuery?: InputMaybe<Scalars["String"]>;
  multiTermQuery_contains?: InputMaybe<Scalars["String"]>;
  multiTermQuery_exists?: InputMaybe<Scalars["Boolean"]>;
  multiTermQuery_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  multiTermQuery_not?: InputMaybe<Scalars["String"]>;
  multiTermQuery_not_contains?: InputMaybe<Scalars["String"]>;
  multiTermQuery_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulcfUpsellClassCardNestedFilter>>>;
  query?: InputMaybe<Scalars["String"]>;
  query_contains?: InputMaybe<Scalars["String"]>;
  query_exists?: InputMaybe<Scalars["Boolean"]>;
  query_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  query_not?: InputMaybe<Scalars["String"]>;
  query_not_contains?: InputMaybe<Scalars["String"]>;
  query_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  subtitle?: InputMaybe<Scalars["String"]>;
  subtitle_contains?: InputMaybe<Scalars["String"]>;
  subtitle_exists?: InputMaybe<Scalars["Boolean"]>;
  subtitle_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  subtitle_not?: InputMaybe<Scalars["String"]>;
  subtitle_not_contains?: InputMaybe<Scalars["String"]>;
  subtitle_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  uniqueLearningNeeds?: InputMaybe<Scalars["String"]>;
  uniqueLearningNeeds_contains?: InputMaybe<Scalars["String"]>;
  uniqueLearningNeeds_exists?: InputMaybe<Scalars["Boolean"]>;
  uniqueLearningNeeds_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  uniqueLearningNeeds_not?: InputMaybe<Scalars["String"]>;
  uniqueLearningNeeds_not_contains?: InputMaybe<Scalars["String"]>;
  uniqueLearningNeeds_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  upsellName?: InputMaybe<Scalars["String"]>;
  upsellName_contains?: InputMaybe<Scalars["String"]>;
  upsellName_exists?: InputMaybe<Scalars["Boolean"]>;
  upsellName_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  upsellName_not?: InputMaybe<Scalars["String"]>;
  upsellName_not_contains?: InputMaybe<Scalars["String"]>;
  upsellName_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type ContentfulTutoringPageArticlesCollection = {
  __typename?: "ContentfulTutoringPageArticlesCollection";
  items: Array<Maybe<ContentfulMagnetPage>>;
  limit: Scalars["Int"];
  skip: Scalars["Int"];
  total: Scalars["Int"];
};

export enum ContentfulTutoringPageCarouselSubcategoriesCollectionOrder {
  CategorySlugAsc = "categorySlug_ASC",
  CategorySlugDesc = "categorySlug_DESC",
  NameTitleCasedAsc = "nameTitleCased_ASC",
  NameTitleCasedDesc = "nameTitleCased_DESC",
  SearchPathAsc = "searchPath_ASC",
  SearchPathDesc = "searchPath_DESC",
  SlugAsc = "slug_ASC",
  SlugDesc = "slug_DESC",
  SubjectAsc = "subject_ASC",
  SubjectDesc = "subject_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
}

export type ContentfulTopicFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulTopicFilter>>>;
  categorySlug?: InputMaybe<Scalars["String"]>;
  categorySlug_contains?: InputMaybe<Scalars["String"]>;
  categorySlug_exists?: InputMaybe<Scalars["Boolean"]>;
  categorySlug_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  categorySlug_not?: InputMaybe<Scalars["String"]>;
  categorySlug_not_contains?: InputMaybe<Scalars["String"]>;
  categorySlug_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  nameTitleCased?: InputMaybe<Scalars["String"]>;
  nameTitleCased_contains?: InputMaybe<Scalars["String"]>;
  nameTitleCased_exists?: InputMaybe<Scalars["Boolean"]>;
  nameTitleCased_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  nameTitleCased_not?: InputMaybe<Scalars["String"]>;
  nameTitleCased_not_contains?: InputMaybe<Scalars["String"]>;
  nameTitleCased_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulTopicFilter>>>;
  searchPath?: InputMaybe<Scalars["String"]>;
  searchPath_contains?: InputMaybe<Scalars["String"]>;
  searchPath_exists?: InputMaybe<Scalars["Boolean"]>;
  searchPath_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  searchPath_not?: InputMaybe<Scalars["String"]>;
  searchPath_not_contains?: InputMaybe<Scalars["String"]>;
  searchPath_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  slug?: InputMaybe<Scalars["String"]>;
  slug_contains?: InputMaybe<Scalars["String"]>;
  slug_exists?: InputMaybe<Scalars["Boolean"]>;
  slug_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  slug_not?: InputMaybe<Scalars["String"]>;
  slug_not_contains?: InputMaybe<Scalars["String"]>;
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  subject?: InputMaybe<Scalars["String"]>;
  subject_contains?: InputMaybe<Scalars["String"]>;
  subject_exists?: InputMaybe<Scalars["Boolean"]>;
  subject_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  subject_not?: InputMaybe<Scalars["String"]>;
  subject_not_contains?: InputMaybe<Scalars["String"]>;
  subject_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
};

export type ContentfulTutoringPageCarouselSubcategoriesCollection = {
  __typename?: "ContentfulTutoringPageCarouselSubcategoriesCollection";
  items: Array<Maybe<ContentfulTopic>>;
  limit: Scalars["Int"];
  skip: Scalars["Int"];
  total: Scalars["Int"];
};

/**
 * Title with a corresponding Search Link [See type
 * definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/topic)
 */
export type ContentfulTopic = Contentful_Node &
  ContentfulEntry & {
    __typename?: "ContentfulTopic";
    _id: Scalars["ID"];
    categorySlug: Maybe<Scalars["String"]>;
    contentfulMetadata: ContentfulContentfulMetadata;
    linkedFrom: Maybe<ContentfulTopicLinkingCollections>;
    nameTitleCased: Maybe<Scalars["String"]>;
    searchPath: Maybe<Scalars["String"]>;
    slug: Maybe<Scalars["String"]>;
    subject: Maybe<Scalars["String"]>;
    sys: ContentfulSys;
  };

/**
 * Title with a corresponding Search Link [See type
 * definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/topic)
 */
export type ContentfulTopicCategorySlugArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/**
 * Title with a corresponding Search Link [See type
 * definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/topic)
 */
export type ContentfulTopicLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

/**
 * Title with a corresponding Search Link [See type
 * definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/topic)
 */
export type ContentfulTopicNameTitleCasedArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/**
 * Title with a corresponding Search Link [See type
 * definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/topic)
 */
export type ContentfulTopicSearchPathArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/**
 * Title with a corresponding Search Link [See type
 * definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/topic)
 */
export type ContentfulTopicSlugArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/**
 * Title with a corresponding Search Link [See type
 * definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/topic)
 */
export type ContentfulTopicSubjectArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

export type ContentfulTopicLinkingCollections = {
  __typename?: "ContentfulTopicLinkingCollections";
  entryCollection: Maybe<ContentfulEntryCollection>;
  tutoringPageCollection: Maybe<ContentfulTutoringPageCollection>;
};

export type ContentfulTopicLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type ContentfulTopicLinkingCollectionsTutoringPageCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<
    Array<
      InputMaybe<ContentfulTopicLinkingCollectionsTutoringPageCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export enum ContentfulTopicLinkingCollectionsTutoringPageCollectionOrder {
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export type ContentfulTutoringPageLinkingCollections = {
  __typename?: "ContentfulTutoringPageLinkingCollections";
  entryCollection: Maybe<ContentfulEntryCollection>;
};

export type ContentfulTutoringPageLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export enum ContentfulTutoringPagePopularSearchLinksCollectionOrder {
  ImageAltTextAsc = "imageAltText_ASC",
  ImageAltTextDesc = "imageAltText_DESC",
  ImageAspectRatioAsc = "imageAspectRatio_ASC",
  ImageAspectRatioDesc = "imageAspectRatio_DESC",
  PathAsc = "path_ASC",
  PathDesc = "path_DESC",
  PathBAsc = "pathB_ASC",
  PathBDesc = "pathB_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TextAsc = "text_ASC",
  TextDesc = "text_DESC",
}

export type ContentfulTutoringPagePopularSearchLinksCollection = {
  __typename?: "ContentfulTutoringPagePopularSearchLinksCollection";
  items: Array<Maybe<ContentfulVisualLink>>;
  limit: Scalars["Int"];
  skip: Scalars["Int"];
  total: Scalars["Int"];
};

export enum ContentfulTutoringPagePopularTutoringSubjectsCollectionOrder {
  ImageAltTextAsc = "imageAltText_ASC",
  ImageAltTextDesc = "imageAltText_DESC",
  ImageAspectRatioAsc = "imageAspectRatio_ASC",
  ImageAspectRatioDesc = "imageAspectRatio_DESC",
  PathAsc = "path_ASC",
  PathDesc = "path_DESC",
  PathBAsc = "pathB_ASC",
  PathBDesc = "pathB_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TextAsc = "text_ASC",
  TextDesc = "text_DESC",
}

export type ContentfulTutoringPagePopularTutoringSubjectsCollection = {
  __typename?: "ContentfulTutoringPagePopularTutoringSubjectsCollection";
  items: Array<Maybe<ContentfulVisualLink>>;
  limit: Scalars["Int"];
  skip: Scalars["Int"];
  total: Scalars["Int"];
};

export enum ContentfulTutoringPageTutoringCarouselCollectionOrder {
  StyleAsc = "style_ASC",
  StyleDesc = "style_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export type ContentfulTutoringPageTutoringCarouselCollection = {
  __typename?: "ContentfulTutoringPageTutoringCarouselCollection";
  items: Array<Maybe<ContentfulVisualLinkGrid>>;
  limit: Scalars["Int"];
  skip: Scalars["Int"];
  total: Scalars["Int"];
};

export enum ContentfulTutoringPageVisualLinkGridsCollectionOrder {
  StyleAsc = "style_ASC",
  StyleDesc = "style_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export type ContentfulTutoringPageVisualLinkGridsCollection = {
  __typename?: "ContentfulTutoringPageVisualLinkGridsCollection";
  items: Array<Maybe<ContentfulVisualLinkGrid>>;
  limit: Scalars["Int"];
  skip: Scalars["Int"];
  total: Scalars["Int"];
};

export enum ContentfulVisualLinkGridVisualLinksCollectionOrder {
  ImageAltTextAsc = "imageAltText_ASC",
  ImageAltTextDesc = "imageAltText_DESC",
  ImageAspectRatioAsc = "imageAspectRatio_ASC",
  ImageAspectRatioDesc = "imageAspectRatio_DESC",
  PathAsc = "path_ASC",
  PathDesc = "path_DESC",
  PathBAsc = "pathB_ASC",
  PathBDesc = "pathB_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TextAsc = "text_ASC",
  TextDesc = "text_DESC",
}

export type ContentfulVisualLinkGridVisualLinksCollection = {
  __typename?: "ContentfulVisualLinkGridVisualLinksCollection";
  items: Array<Maybe<ContentfulVisualLink>>;
  limit: Scalars["Int"];
  skip: Scalars["Int"];
  total: Scalars["Int"];
};

export enum ContentfulVisualLinkLinkingCollectionsTutoringPageCollectionOrder {
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export enum ContentfulVisualLinkLinkingCollectionsVisualLinkGridCollectionOrder {
  StyleAsc = "style_ASC",
  StyleDesc = "style_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export type ContentfulVisualLinkGridCollection = {
  __typename?: "ContentfulVisualLinkGridCollection";
  items: Array<Maybe<ContentfulVisualLinkGrid>>;
  limit: Scalars["Int"];
  skip: Scalars["Int"];
  total: Scalars["Int"];
};

export enum ContentfulCategoryPageJumpPillsCollectionOrder {
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  PathAsc = "path_ASC",
  PathDesc = "path_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
}

export type ContentfulJumpPillsFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulJumpPillsFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  name?: InputMaybe<Scalars["String"]>;
  name_contains?: InputMaybe<Scalars["String"]>;
  name_exists?: InputMaybe<Scalars["Boolean"]>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  name_not?: InputMaybe<Scalars["String"]>;
  name_not_contains?: InputMaybe<Scalars["String"]>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulJumpPillsFilter>>>;
  path?: InputMaybe<Scalars["String"]>;
  path_contains?: InputMaybe<Scalars["String"]>;
  path_exists?: InputMaybe<Scalars["Boolean"]>;
  path_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  path_not?: InputMaybe<Scalars["String"]>;
  path_not_contains?: InputMaybe<Scalars["String"]>;
  path_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
};

export type ContentfulCategoryPageJumpPillsCollection = {
  __typename?: "ContentfulCategoryPageJumpPillsCollection";
  items: Array<Maybe<ContentfulJumpPills>>;
  limit: Scalars["Int"];
  skip: Scalars["Int"];
  total: Scalars["Int"];
};

/** These are a set of links that can be configured to link you to other pages [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/jumpPills) */
export type ContentfulJumpPills = Contentful_Node &
  ContentfulEntry & {
    __typename?: "ContentfulJumpPills";
    _id: Scalars["ID"];
    contentfulMetadata: ContentfulContentfulMetadata;
    linkedFrom: Maybe<ContentfulJumpPillsLinkingCollections>;
    name: Maybe<Scalars["String"]>;
    path: Maybe<Scalars["String"]>;
    sys: ContentfulSys;
  };

/** These are a set of links that can be configured to link you to other pages [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/jumpPills) */
export type ContentfulJumpPillsLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

/** These are a set of links that can be configured to link you to other pages [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/jumpPills) */
export type ContentfulJumpPillsNameArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** These are a set of links that can be configured to link you to other pages [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/jumpPills) */
export type ContentfulJumpPillsPathArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

export type ContentfulJumpPillsLinkingCollections = {
  __typename?: "ContentfulJumpPillsLinkingCollections";
  categoryPageCollection: Maybe<ContentfulCategoryPageCollection>;
  entryCollection: Maybe<ContentfulEntryCollection>;
};

export type ContentfulJumpPillsLinkingCollectionsCategoryPageCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<
    Array<
      InputMaybe<ContentfulJumpPillsLinkingCollectionsCategoryPageCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type ContentfulJumpPillsLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export enum ContentfulJumpPillsLinkingCollectionsCategoryPageCollectionOrder {
  BelowTheFoldTitleAsc = "belowTheFoldTitle_ASC",
  BelowTheFoldTitleDesc = "belowTheFoldTitle_DESC",
  CategorySlugAsc = "categorySlug_ASC",
  CategorySlugDesc = "categorySlug_DESC",
  FeaturedClassesOverrideSlugAsc = "featuredClassesOverrideSlug_ASC",
  FeaturedClassesOverrideSlugDesc = "featuredClassesOverrideSlug_DESC",
  FooterCtaPathAsc = "footerCtaPath_ASC",
  FooterCtaPathDesc = "footerCtaPath_DESC",
  FooterCtaTitleAsc = "footerCtaTitle_ASC",
  FooterCtaTitleDesc = "footerCtaTitle_DESC",
  HeroTitleAsc = "heroTitle_ASC",
  HeroTitleDesc = "heroTitle_DESC",
  JumpPillsTitleAsc = "jumpPillsTitle_ASC",
  JumpPillsTitleDesc = "jumpPillsTitle_DESC",
  ShowFeaturedClassesAsc = "showFeaturedClasses_ASC",
  ShowFeaturedClassesDesc = "showFeaturedClasses_DESC",
  StartAfterAsc = "startAfter_ASC",
  StartAfterDesc = "startAfter_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
}

export type ContentfulCategoryPageLinkingCollections = {
  __typename?: "ContentfulCategoryPageLinkingCollections";
  entryCollection: Maybe<ContentfulEntryCollection>;
};

export type ContentfulCategoryPageLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export enum ContentfulCategoryPageMagnetPagesCollectionOrder {
  MagnetPageSlugAsc = "magnetPageSlug_ASC",
  MagnetPageSlugDesc = "magnetPageSlug_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export type ContentfulCategoryPageMagnetPagesCollection = {
  __typename?: "ContentfulCategoryPageMagnetPagesCollection";
  items: Array<Maybe<ContentfulMagnetPage>>;
  limit: Scalars["Int"];
  skip: Scalars["Int"];
  total: Scalars["Int"];
};

export enum ContentfulCategoryPageRelatedCategoriesCollectionOrder {
  CallToActionTextAsc = "callToActionText_ASC",
  CallToActionTextDesc = "callToActionText_DESC",
  CallToActionUrlAsc = "callToActionUrl_ASC",
  CallToActionUrlDesc = "callToActionUrl_DESC",
  CapacityMaxAsc = "capacityMax_ASC",
  CapacityMaxDesc = "capacityMax_DESC",
  CapacityMinAsc = "capacityMin_ASC",
  CapacityMinDesc = "capacityMin_DESC",
  HasFilledOutUniqueLearningNeedsAsc = "hasFilledOutUniqueLearningNeeds_ASC",
  HasFilledOutUniqueLearningNeedsDesc = "hasFilledOutUniqueLearningNeeds_DESC",
  MaxPriceCentsAsc = "maxPriceCents_ASC",
  MaxPriceCentsDesc = "maxPriceCents_DESC",
  MinPriceCentsAsc = "minPriceCents_ASC",
  MinPriceCentsDesc = "minPriceCents_DESC",
  MultiTermQueryAsc = "multiTermQuery_ASC",
  MultiTermQueryDesc = "multiTermQuery_DESC",
  QueryAsc = "query_ASC",
  QueryDesc = "query_DESC",
  SubtitleAsc = "subtitle_ASC",
  SubtitleDesc = "subtitle_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
  UniqueLearningNeedsAsc = "uniqueLearningNeeds_ASC",
  UniqueLearningNeedsDesc = "uniqueLearningNeeds_DESC",
  UpsellNameAsc = "upsellName_ASC",
  UpsellNameDesc = "upsellName_DESC",
}

export type ContentfulUpsellClassCardFilter = {
  adminTags_contains_all?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  adminTags_contains_none?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  adminTags_contains_some?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  adminTags_exists?: InputMaybe<Scalars["Boolean"]>;
  ages_contains_all?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  ages_contains_none?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  ages_contains_some?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  ages_exists?: InputMaybe<Scalars["Boolean"]>;
  AND?: InputMaybe<Array<InputMaybe<ContentfulUpsellClassCardFilter>>>;
  callToActionText?: InputMaybe<Scalars["String"]>;
  callToActionText_contains?: InputMaybe<Scalars["String"]>;
  callToActionText_exists?: InputMaybe<Scalars["Boolean"]>;
  callToActionText_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  callToActionText_not?: InputMaybe<Scalars["String"]>;
  callToActionText_not_contains?: InputMaybe<Scalars["String"]>;
  callToActionText_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  callToActionUrl?: InputMaybe<Scalars["String"]>;
  callToActionUrl_contains?: InputMaybe<Scalars["String"]>;
  callToActionUrl_exists?: InputMaybe<Scalars["Boolean"]>;
  callToActionUrl_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  callToActionUrl_not?: InputMaybe<Scalars["String"]>;
  callToActionUrl_not_contains?: InputMaybe<Scalars["String"]>;
  callToActionUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  capacityMax?: InputMaybe<Scalars["Int"]>;
  capacityMax_exists?: InputMaybe<Scalars["Boolean"]>;
  capacityMax_gt?: InputMaybe<Scalars["Int"]>;
  capacityMax_gte?: InputMaybe<Scalars["Int"]>;
  capacityMax_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  capacityMax_lt?: InputMaybe<Scalars["Int"]>;
  capacityMax_lte?: InputMaybe<Scalars["Int"]>;
  capacityMax_not?: InputMaybe<Scalars["Int"]>;
  capacityMax_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  capacityMin?: InputMaybe<Scalars["Int"]>;
  capacityMin_exists?: InputMaybe<Scalars["Boolean"]>;
  capacityMin_gt?: InputMaybe<Scalars["Int"]>;
  capacityMin_gte?: InputMaybe<Scalars["Int"]>;
  capacityMin_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  capacityMin_lt?: InputMaybe<Scalars["Int"]>;
  capacityMin_lte?: InputMaybe<Scalars["Int"]>;
  capacityMin_not?: InputMaybe<Scalars["Int"]>;
  capacityMin_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  category_contains_all?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  category_contains_none?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  category_contains_some?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  category_exists?: InputMaybe<Scalars["Boolean"]>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  deliveryTypes_contains_all?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  deliveryTypes_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  deliveryTypes_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  deliveryTypes_exists?: InputMaybe<Scalars["Boolean"]>;
  formats_contains_all?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  formats_contains_none?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  formats_contains_some?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  formats_exists?: InputMaybe<Scalars["Boolean"]>;
  fundingPrograms_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  fundingPrograms_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  fundingPrograms_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  fundingPrograms_exists?: InputMaybe<Scalars["Boolean"]>;
  gradeLevel_contains_all?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  gradeLevel_contains_none?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  gradeLevel_contains_some?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  gradeLevel_exists?: InputMaybe<Scalars["Boolean"]>;
  hasFilledOutUniqueLearningNeeds?: InputMaybe<Scalars["Boolean"]>;
  hasFilledOutUniqueLearningNeeds_exists?: InputMaybe<Scalars["Boolean"]>;
  hasFilledOutUniqueLearningNeeds_not?: InputMaybe<Scalars["Boolean"]>;
  maxPriceCents?: InputMaybe<Scalars["Int"]>;
  maxPriceCents_exists?: InputMaybe<Scalars["Boolean"]>;
  maxPriceCents_gt?: InputMaybe<Scalars["Int"]>;
  maxPriceCents_gte?: InputMaybe<Scalars["Int"]>;
  maxPriceCents_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  maxPriceCents_lt?: InputMaybe<Scalars["Int"]>;
  maxPriceCents_lte?: InputMaybe<Scalars["Int"]>;
  maxPriceCents_not?: InputMaybe<Scalars["Int"]>;
  maxPriceCents_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  minPriceCents?: InputMaybe<Scalars["Int"]>;
  minPriceCents_exists?: InputMaybe<Scalars["Boolean"]>;
  minPriceCents_gt?: InputMaybe<Scalars["Int"]>;
  minPriceCents_gte?: InputMaybe<Scalars["Int"]>;
  minPriceCents_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  minPriceCents_lt?: InputMaybe<Scalars["Int"]>;
  minPriceCents_lte?: InputMaybe<Scalars["Int"]>;
  minPriceCents_not?: InputMaybe<Scalars["Int"]>;
  minPriceCents_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  multiTermQuery?: InputMaybe<Scalars["String"]>;
  multiTermQuery_contains?: InputMaybe<Scalars["String"]>;
  multiTermQuery_exists?: InputMaybe<Scalars["Boolean"]>;
  multiTermQuery_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  multiTermQuery_not?: InputMaybe<Scalars["String"]>;
  multiTermQuery_not_contains?: InputMaybe<Scalars["String"]>;
  multiTermQuery_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulUpsellClassCardFilter>>>;
  query?: InputMaybe<Scalars["String"]>;
  query_contains?: InputMaybe<Scalars["String"]>;
  query_exists?: InputMaybe<Scalars["Boolean"]>;
  query_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  query_not?: InputMaybe<Scalars["String"]>;
  query_not_contains?: InputMaybe<Scalars["String"]>;
  query_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  subtitle?: InputMaybe<Scalars["String"]>;
  subtitle_contains?: InputMaybe<Scalars["String"]>;
  subtitle_exists?: InputMaybe<Scalars["Boolean"]>;
  subtitle_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  subtitle_not?: InputMaybe<Scalars["String"]>;
  subtitle_not_contains?: InputMaybe<Scalars["String"]>;
  subtitle_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  uniqueLearningNeeds?: InputMaybe<Scalars["String"]>;
  uniqueLearningNeeds_contains?: InputMaybe<Scalars["String"]>;
  uniqueLearningNeeds_exists?: InputMaybe<Scalars["Boolean"]>;
  uniqueLearningNeeds_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  uniqueLearningNeeds_not?: InputMaybe<Scalars["String"]>;
  uniqueLearningNeeds_not_contains?: InputMaybe<Scalars["String"]>;
  uniqueLearningNeeds_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  upsellName?: InputMaybe<Scalars["String"]>;
  upsellName_contains?: InputMaybe<Scalars["String"]>;
  upsellName_exists?: InputMaybe<Scalars["Boolean"]>;
  upsellName_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  upsellName_not?: InputMaybe<Scalars["String"]>;
  upsellName_not_contains?: InputMaybe<Scalars["String"]>;
  upsellName_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type ContentfulCategoryPageRelatedCategoriesCollection = {
  __typename?: "ContentfulCategoryPageRelatedCategoriesCollection";
  items: Array<Maybe<ContentfulUpsellClassCard>>;
  limit: Scalars["Int"];
  skip: Scalars["Int"];
  total: Scalars["Int"];
};

/**
 * Used to define what classes will show up in a class carousel - Fields map to the
 * filters on /search [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/upsellClassCard)
 */
export type ContentfulUpsellClassCard = Contentful_Node &
  ContentfulEntry & {
    __typename?: "ContentfulUpsellClassCard";
    _id: Scalars["ID"];
    adminTags: Maybe<Array<Maybe<Scalars["String"]>>>;
    ages: Maybe<Array<Maybe<Scalars["String"]>>>;
    callToActionText: Maybe<Scalars["String"]>;
    callToActionUrl: Maybe<Scalars["String"]>;
    capacityMax: Maybe<Scalars["Int"]>;
    capacityMin: Maybe<Scalars["Int"]>;
    category: Maybe<Array<Maybe<Scalars["String"]>>>;
    contentfulMetadata: ContentfulContentfulMetadata;
    deliveryTypes: Maybe<Array<Maybe<Scalars["String"]>>>;
    formats: Maybe<Array<Maybe<Scalars["String"]>>>;
    fundingPrograms: Maybe<Array<Maybe<Scalars["String"]>>>;
    gradeLevel: Maybe<Array<Maybe<Scalars["String"]>>>;
    hasFilledOutUniqueLearningNeeds: Maybe<Scalars["Boolean"]>;
    linkedFrom: Maybe<ContentfulUpsellClassCardLinkingCollections>;
    listings: SearchListingsConnection;
    maxPriceCents: Maybe<Scalars["Int"]>;
    minPriceCents: Maybe<Scalars["Int"]>;
    multiTermQuery: Maybe<Scalars["String"]>;
    query: Maybe<Scalars["String"]>;
    subtitle: Maybe<Scalars["String"]>;
    sys: ContentfulSys;
    title: Maybe<Scalars["String"]>;
    uniqueLearningNeeds: Maybe<Scalars["String"]>;
    upsellName: Maybe<Scalars["String"]>;
  };

/**
 * Used to define what classes will show up in a class carousel - Fields map to the
 * filters on /search [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/upsellClassCard)
 */
export type ContentfulUpsellClassCardAdminTagsArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/**
 * Used to define what classes will show up in a class carousel - Fields map to the
 * filters on /search [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/upsellClassCard)
 */
export type ContentfulUpsellClassCardAgesArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/**
 * Used to define what classes will show up in a class carousel - Fields map to the
 * filters on /search [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/upsellClassCard)
 */
export type ContentfulUpsellClassCardCallToActionTextArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/**
 * Used to define what classes will show up in a class carousel - Fields map to the
 * filters on /search [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/upsellClassCard)
 */
export type ContentfulUpsellClassCardCallToActionUrlArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/**
 * Used to define what classes will show up in a class carousel - Fields map to the
 * filters on /search [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/upsellClassCard)
 */
export type ContentfulUpsellClassCardCapacityMaxArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/**
 * Used to define what classes will show up in a class carousel - Fields map to the
 * filters on /search [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/upsellClassCard)
 */
export type ContentfulUpsellClassCardCapacityMinArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/**
 * Used to define what classes will show up in a class carousel - Fields map to the
 * filters on /search [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/upsellClassCard)
 */
export type ContentfulUpsellClassCardCategoryArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/**
 * Used to define what classes will show up in a class carousel - Fields map to the
 * filters on /search [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/upsellClassCard)
 */
export type ContentfulUpsellClassCardDeliveryTypesArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/**
 * Used to define what classes will show up in a class carousel - Fields map to the
 * filters on /search [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/upsellClassCard)
 */
export type ContentfulUpsellClassCardFormatsArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/**
 * Used to define what classes will show up in a class carousel - Fields map to the
 * filters on /search [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/upsellClassCard)
 */
export type ContentfulUpsellClassCardFundingProgramsArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/**
 * Used to define what classes will show up in a class carousel - Fields map to the
 * filters on /search [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/upsellClassCard)
 */
export type ContentfulUpsellClassCardGradeLevelArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/**
 * Used to define what classes will show up in a class carousel - Fields map to the
 * filters on /search [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/upsellClassCard)
 */
export type ContentfulUpsellClassCardHasFilledOutUniqueLearningNeedsArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/**
 * Used to define what classes will show up in a class carousel - Fields map to the
 * filters on /search [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/upsellClassCard)
 */
export type ContentfulUpsellClassCardLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

/**
 * Used to define what classes will show up in a class carousel - Fields map to the
 * filters on /search [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/upsellClassCard)
 */
export type ContentfulUpsellClassCardListingsArgs = {
  cursor?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  searchFilters?: InputMaybe<SearchFilters>;
};

/**
 * Used to define what classes will show up in a class carousel - Fields map to the
 * filters on /search [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/upsellClassCard)
 */
export type ContentfulUpsellClassCardMaxPriceCentsArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/**
 * Used to define what classes will show up in a class carousel - Fields map to the
 * filters on /search [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/upsellClassCard)
 */
export type ContentfulUpsellClassCardMinPriceCentsArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/**
 * Used to define what classes will show up in a class carousel - Fields map to the
 * filters on /search [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/upsellClassCard)
 */
export type ContentfulUpsellClassCardMultiTermQueryArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/**
 * Used to define what classes will show up in a class carousel - Fields map to the
 * filters on /search [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/upsellClassCard)
 */
export type ContentfulUpsellClassCardQueryArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/**
 * Used to define what classes will show up in a class carousel - Fields map to the
 * filters on /search [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/upsellClassCard)
 */
export type ContentfulUpsellClassCardSubtitleArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/**
 * Used to define what classes will show up in a class carousel - Fields map to the
 * filters on /search [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/upsellClassCard)
 */
export type ContentfulUpsellClassCardTitleArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/**
 * Used to define what classes will show up in a class carousel - Fields map to the
 * filters on /search [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/upsellClassCard)
 */
export type ContentfulUpsellClassCardUniqueLearningNeedsArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/**
 * Used to define what classes will show up in a class carousel - Fields map to the
 * filters on /search [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/upsellClassCard)
 */
export type ContentfulUpsellClassCardUpsellNameArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

export type ContentfulUpsellClassCardLinkingCollections = {
  __typename?: "ContentfulUpsellClassCardLinkingCollections";
  categoryPageCollection: Maybe<ContentfulCategoryPageCollection>;
  entryCollection: Maybe<ContentfulEntryCollection>;
  magnetPageCollection: Maybe<ContentfulMagnetPageCollection>;
};

export type ContentfulUpsellClassCardLinkingCollectionsCategoryPageCollectionArgs =
  {
    limit?: InputMaybe<Scalars["Int"]>;
    locale?: InputMaybe<Scalars["String"]>;
    order?: InputMaybe<
      Array<
        InputMaybe<ContentfulUpsellClassCardLinkingCollectionsCategoryPageCollectionOrder>
      >
    >;
    preview?: InputMaybe<Scalars["Boolean"]>;
    skip?: InputMaybe<Scalars["Int"]>;
  };

export type ContentfulUpsellClassCardLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type ContentfulUpsellClassCardLinkingCollectionsMagnetPageCollectionArgs =
  {
    limit?: InputMaybe<Scalars["Int"]>;
    locale?: InputMaybe<Scalars["String"]>;
    order?: InputMaybe<
      Array<
        InputMaybe<ContentfulUpsellClassCardLinkingCollectionsMagnetPageCollectionOrder>
      >
    >;
    preview?: InputMaybe<Scalars["Boolean"]>;
    skip?: InputMaybe<Scalars["Int"]>;
  };

export enum ContentfulUpsellClassCardLinkingCollectionsCategoryPageCollectionOrder {
  BelowTheFoldTitleAsc = "belowTheFoldTitle_ASC",
  BelowTheFoldTitleDesc = "belowTheFoldTitle_DESC",
  CategorySlugAsc = "categorySlug_ASC",
  CategorySlugDesc = "categorySlug_DESC",
  FeaturedClassesOverrideSlugAsc = "featuredClassesOverrideSlug_ASC",
  FeaturedClassesOverrideSlugDesc = "featuredClassesOverrideSlug_DESC",
  FooterCtaPathAsc = "footerCtaPath_ASC",
  FooterCtaPathDesc = "footerCtaPath_DESC",
  FooterCtaTitleAsc = "footerCtaTitle_ASC",
  FooterCtaTitleDesc = "footerCtaTitle_DESC",
  HeroTitleAsc = "heroTitle_ASC",
  HeroTitleDesc = "heroTitle_DESC",
  JumpPillsTitleAsc = "jumpPillsTitle_ASC",
  JumpPillsTitleDesc = "jumpPillsTitle_DESC",
  ShowFeaturedClassesAsc = "showFeaturedClasses_ASC",
  ShowFeaturedClassesDesc = "showFeaturedClasses_DESC",
  StartAfterAsc = "startAfter_ASC",
  StartAfterDesc = "startAfter_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
}

export enum ContentfulUpsellClassCardLinkingCollectionsMagnetPageCollectionOrder {
  MagnetPageSlugAsc = "magnetPageSlug_ASC",
  MagnetPageSlugDesc = "magnetPageSlug_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export type SearchListingsConnection = {
  __typename?: "SearchListingsConnection";
  autoAppliedFilters: Maybe<AutoAppliedFilters>;
  cursor: Maybe<Scalars["String"]>;
  listings: Array<Listing>;
  total: Maybe<Scalars["Int"]>;
};

/**
 * Experimental
 * Filters that are automatically applied by the back-end based on user query
 * so that UI can properly signal to user
 */
export type AutoAppliedFilters = {
  __typename?: "AutoAppliedFilters";
  gradeLevels: Maybe<Array<Scalars["String"]>>;
};

export enum ContentfulMagnetPageLinkingCollectionsFeaturedPagesCollectionOrder {
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
}

export type ContentfulFeaturedPagesCollection = {
  __typename?: "ContentfulFeaturedPagesCollection";
  items: Array<Maybe<ContentfulFeaturedPages>>;
  limit: Scalars["Int"];
  skip: Scalars["Int"];
  total: Scalars["Int"];
};

/** Collection of magnet pages to be featured in different places of the app [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/featuredPages) */
export type ContentfulFeaturedPages = Contentful_Node &
  ContentfulEntry & {
    __typename?: "ContentfulFeaturedPages";
    _id: Scalars["ID"];
    contentfulMetadata: ContentfulContentfulMetadata;
    id: Maybe<Scalars["String"]>;
    linkedFrom: Maybe<ContentfulFeaturedPagesLinkingCollections>;
    pagesCollection: Maybe<ContentfulFeaturedPagesPagesCollection>;
    sys: ContentfulSys;
  };

/** Collection of magnet pages to be featured in different places of the app [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/featuredPages) */
export type ContentfulFeaturedPagesIdArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** Collection of magnet pages to be featured in different places of the app [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/featuredPages) */
export type ContentfulFeaturedPagesLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

/** Collection of magnet pages to be featured in different places of the app [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/featuredPages) */
export type ContentfulFeaturedPagesPagesCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<
    Array<InputMaybe<ContentfulFeaturedPagesPagesCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ContentfulMagnetPageFilter>;
};

export type ContentfulFeaturedPagesLinkingCollections = {
  __typename?: "ContentfulFeaturedPagesLinkingCollections";
  entryCollection: Maybe<ContentfulEntryCollection>;
};

export type ContentfulFeaturedPagesLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export enum ContentfulFeaturedPagesPagesCollectionOrder {
  MagnetPageSlugAsc = "magnetPageSlug_ASC",
  MagnetPageSlugDesc = "magnetPageSlug_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export type ContentfulFeaturedPagesPagesCollection = {
  __typename?: "ContentfulFeaturedPagesPagesCollection";
  items: Array<Maybe<ContentfulMagnetPage>>;
  limit: Scalars["Int"];
  skip: Scalars["Int"];
  total: Scalars["Int"];
};

export enum ContentfulMagnetPageLinkingCollectionsTutoringPageCollectionOrder {
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export enum ContentfulMagnetPageOrder {
  MagnetPageSlugAsc = "magnetPageSlug_ASC",
  MagnetPageSlugDesc = "magnetPageSlug_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export type ContentfulMagnetPageSummary = {
  __typename?: "ContentfulMagnetPageSummary";
  json: Scalars["ContentfulJSON"];
  links: ContentfulMagnetPageSummaryLinks;
};

export type ContentfulMagnetPageSummaryLinks = {
  __typename?: "ContentfulMagnetPageSummaryLinks";
  assets: ContentfulMagnetPageSummaryAssets;
  entries: ContentfulMagnetPageSummaryEntries;
  resources: ContentfulMagnetPageSummaryResources;
};

export type ContentfulMagnetPageSummaryAssets = {
  __typename?: "ContentfulMagnetPageSummaryAssets";
  block: Array<Maybe<ContentfulAsset>>;
  hyperlink: Array<Maybe<ContentfulAsset>>;
};

export type ContentfulMagnetPageSummaryEntries = {
  __typename?: "ContentfulMagnetPageSummaryEntries";
  block: Array<Maybe<ContentfulEntry>>;
  hyperlink: Array<Maybe<ContentfulEntry>>;
  inline: Array<Maybe<ContentfulEntry>>;
};

export type ContentfulMagnetPageSummaryResources = {
  __typename?: "ContentfulMagnetPageSummaryResources";
  block: Array<ContentfulMagnetPageSummaryResourcesBlock>;
  hyperlink: Array<ContentfulMagnetPageSummaryResourcesHyperlink>;
  inline: Array<ContentfulMagnetPageSummaryResourcesInline>;
};

export type ContentfulMagnetPageSummaryResourcesBlock =
  ContentfulResourceLink & {
    __typename?: "ContentfulMagnetPageSummaryResourcesBlock";
    sys: ContentfulResourceSys;
  };

export type ContentfulMagnetPageSummaryResourcesHyperlink =
  ContentfulResourceLink & {
    __typename?: "ContentfulMagnetPageSummaryResourcesHyperlink";
    sys: ContentfulResourceSys;
  };

export type ContentfulMagnetPageSummaryResourcesInline =
  ContentfulResourceLink & {
    __typename?: "ContentfulMagnetPageSummaryResourcesInline";
    sys: ContentfulResourceSys;
  };

export type PublicProfile = {
  __typename?: "PublicProfile";
  leader: Maybe<Leader>;
  parent: Maybe<Parent>;
  reviews: Array<PublicReview>;
  uid: Scalars["ID"];
};

export type ContentfulBannerCollection = {
  __typename?: "ContentfulBannerCollection";
  items: Array<Maybe<ContentfulBanner>>;
  limit: Scalars["Int"];
  skip: Scalars["Int"];
  total: Scalars["Int"];
};

/** CTA banner, e.g. on home page [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/banner) */
export type ContentfulBanner = Contentful_Node &
  ContentfulEntry & {
    __typename?: "ContentfulBanner";
    _id: Scalars["ID"];
    buttonText: Maybe<Scalars["String"]>;
    campaign: Maybe<ContentfulCampaign>;
    content: Maybe<ContentfulBannerContent>;
    contentfulMetadata: ContentfulContentfulMetadata;
    image: Maybe<ContentfulAsset>;
    linkedFrom: Maybe<ContentfulBannerLinkingCollections>;
    name: Maybe<Scalars["String"]>;
    sys: ContentfulSys;
    title: Maybe<Scalars["String"]>;
    url: Maybe<Scalars["String"]>;
  };

/** CTA banner, e.g. on home page [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/banner) */
export type ContentfulBannerButtonTextArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** CTA banner, e.g. on home page [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/banner) */
export type ContentfulBannerCampaignArgs = {
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
  where?: InputMaybe<ContentfulCampaignFilter>;
};

/** CTA banner, e.g. on home page [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/banner) */
export type ContentfulBannerContentArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** CTA banner, e.g. on home page [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/banner) */
export type ContentfulBannerImageArgs = {
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
};

/** CTA banner, e.g. on home page [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/banner) */
export type ContentfulBannerLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

/** CTA banner, e.g. on home page [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/banner) */
export type ContentfulBannerNameArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** CTA banner, e.g. on home page [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/banner) */
export type ContentfulBannerTitleArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** CTA banner, e.g. on home page [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/banner) */
export type ContentfulBannerUrlArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

export type ContentfulCampaignFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulCampaignFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  country?: InputMaybe<ContentfulcfCampaignCountryNestedFilter>;
  countryCollection_exists?: InputMaybe<Scalars["Boolean"]>;
  endAt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  endAt_exists?: InputMaybe<Scalars["Boolean"]>;
  endAt_gt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  endAt_gte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  endAt_in?: InputMaybe<Array<InputMaybe<Scalars["ContentfulDateTime"]>>>;
  endAt_lt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  endAt_lte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  endAt_not?: InputMaybe<Scalars["ContentfulDateTime"]>;
  endAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["ContentfulDateTime"]>>>;
  hasSubscription?: InputMaybe<Scalars["Boolean"]>;
  hasSubscription_exists?: InputMaybe<Scalars["Boolean"]>;
  hasSubscription_not?: InputMaybe<Scalars["Boolean"]>;
  maxAge?: InputMaybe<Scalars["Int"]>;
  maxAge_exists?: InputMaybe<Scalars["Boolean"]>;
  maxAge_gt?: InputMaybe<Scalars["Int"]>;
  maxAge_gte?: InputMaybe<Scalars["Int"]>;
  maxAge_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  maxAge_lt?: InputMaybe<Scalars["Int"]>;
  maxAge_lte?: InputMaybe<Scalars["Int"]>;
  maxAge_not?: InputMaybe<Scalars["Int"]>;
  maxAge_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  minAge?: InputMaybe<Scalars["Int"]>;
  minAge_exists?: InputMaybe<Scalars["Boolean"]>;
  minAge_gt?: InputMaybe<Scalars["Int"]>;
  minAge_gte?: InputMaybe<Scalars["Int"]>;
  minAge_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  minAge_lt?: InputMaybe<Scalars["Int"]>;
  minAge_lte?: InputMaybe<Scalars["Int"]>;
  minAge_not?: InputMaybe<Scalars["Int"]>;
  minAge_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  name?: InputMaybe<Scalars["String"]>;
  name_contains?: InputMaybe<Scalars["String"]>;
  name_exists?: InputMaybe<Scalars["Boolean"]>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  name_not?: InputMaybe<Scalars["String"]>;
  name_not_contains?: InputMaybe<Scalars["String"]>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  onlyForTreatmentInExperiment?: InputMaybe<Scalars["String"]>;
  onlyForTreatmentInExperiment_contains?: InputMaybe<Scalars["String"]>;
  onlyForTreatmentInExperiment_exists?: InputMaybe<Scalars["Boolean"]>;
  onlyForTreatmentInExperiment_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  onlyForTreatmentInExperiment_not?: InputMaybe<Scalars["String"]>;
  onlyForTreatmentInExperiment_not_contains?: InputMaybe<Scalars["String"]>;
  onlyForTreatmentInExperiment_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  OR?: InputMaybe<Array<InputMaybe<ContentfulCampaignFilter>>>;
  schoolingApproaches_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  schoolingApproaches_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  schoolingApproaches_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  schoolingApproaches_exists?: InputMaybe<Scalars["Boolean"]>;
  startAt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startAt_exists?: InputMaybe<Scalars["Boolean"]>;
  startAt_gt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startAt_gte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startAt_in?: InputMaybe<Array<InputMaybe<Scalars["ContentfulDateTime"]>>>;
  startAt_lt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startAt_lte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startAt_not?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["ContentfulDateTime"]>>>;
  stateOrProvince?: InputMaybe<ContentfulcfCampaignStateOrProvinceNestedFilter>;
  stateOrProvinceCollection_exists?: InputMaybe<Scalars["Boolean"]>;
  sys?: InputMaybe<ContentfulSysFilter>;
};

export type ContentfulcfCampaignCountryNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulcfCampaignCountryNestedFilter>>>;
  code?: InputMaybe<Scalars["String"]>;
  code_contains?: InputMaybe<Scalars["String"]>;
  code_exists?: InputMaybe<Scalars["Boolean"]>;
  code_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  code_not?: InputMaybe<Scalars["String"]>;
  code_not_contains?: InputMaybe<Scalars["String"]>;
  code_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  matchingText_contains_all?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  matchingText_contains_none?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  matchingText_contains_some?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  matchingText_exists?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  name_contains?: InputMaybe<Scalars["String"]>;
  name_exists?: InputMaybe<Scalars["Boolean"]>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  name_not?: InputMaybe<Scalars["String"]>;
  name_not_contains?: InputMaybe<Scalars["String"]>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulcfCampaignCountryNestedFilter>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
};

export type ContentfulcfCampaignStateOrProvinceNestedFilter = {
  AND?: InputMaybe<
    Array<InputMaybe<ContentfulcfCampaignStateOrProvinceNestedFilter>>
  >;
  code?: InputMaybe<Scalars["String"]>;
  code_contains?: InputMaybe<Scalars["String"]>;
  code_exists?: InputMaybe<Scalars["Boolean"]>;
  code_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  code_not?: InputMaybe<Scalars["String"]>;
  code_not_contains?: InputMaybe<Scalars["String"]>;
  code_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  name?: InputMaybe<Scalars["String"]>;
  name_contains?: InputMaybe<Scalars["String"]>;
  name_exists?: InputMaybe<Scalars["Boolean"]>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  name_not?: InputMaybe<Scalars["String"]>;
  name_not_contains?: InputMaybe<Scalars["String"]>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<
    Array<InputMaybe<ContentfulcfCampaignStateOrProvinceNestedFilter>>
  >;
  sys?: InputMaybe<ContentfulSysFilter>;
};

/**
 * Description of marketing campaign -- dates, target audience, etc. -- to connect
 * with assets [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/campaign)
 */
export type ContentfulCampaign = Contentful_Node &
  ContentfulEntry & {
    __typename?: "ContentfulCampaign";
    _id: Scalars["ID"];
    contentfulMetadata: ContentfulContentfulMetadata;
    countryCollection: Maybe<ContentfulCampaignCountryCollection>;
    endAt: Maybe<Scalars["ContentfulDateTime"]>;
    hasSubscription: Maybe<Scalars["Boolean"]>;
    linkedFrom: Maybe<ContentfulCampaignLinkingCollections>;
    maxAge: Maybe<Scalars["Int"]>;
    minAge: Maybe<Scalars["Int"]>;
    name: Maybe<Scalars["String"]>;
    onlyForTreatmentInExperiment: Maybe<Scalars["String"]>;
    schoolingApproaches: Maybe<Array<Maybe<Scalars["String"]>>>;
    startAt: Maybe<Scalars["ContentfulDateTime"]>;
    stateOrProvinceCollection: Maybe<ContentfulCampaignStateOrProvinceCollection>;
    sys: ContentfulSys;
  };

/**
 * Description of marketing campaign -- dates, target audience, etc. -- to connect
 * with assets [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/campaign)
 */
export type ContentfulCampaignCountryCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<
    Array<InputMaybe<ContentfulCampaignCountryCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ContentfulCampaignCountryFilter>;
};

/**
 * Description of marketing campaign -- dates, target audience, etc. -- to connect
 * with assets [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/campaign)
 */
export type ContentfulCampaignEndAtArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/**
 * Description of marketing campaign -- dates, target audience, etc. -- to connect
 * with assets [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/campaign)
 */
export type ContentfulCampaignHasSubscriptionArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/**
 * Description of marketing campaign -- dates, target audience, etc. -- to connect
 * with assets [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/campaign)
 */
export type ContentfulCampaignLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

/**
 * Description of marketing campaign -- dates, target audience, etc. -- to connect
 * with assets [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/campaign)
 */
export type ContentfulCampaignMaxAgeArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/**
 * Description of marketing campaign -- dates, target audience, etc. -- to connect
 * with assets [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/campaign)
 */
export type ContentfulCampaignMinAgeArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/**
 * Description of marketing campaign -- dates, target audience, etc. -- to connect
 * with assets [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/campaign)
 */
export type ContentfulCampaignNameArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/**
 * Description of marketing campaign -- dates, target audience, etc. -- to connect
 * with assets [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/campaign)
 */
export type ContentfulCampaignOnlyForTreatmentInExperimentArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/**
 * Description of marketing campaign -- dates, target audience, etc. -- to connect
 * with assets [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/campaign)
 */
export type ContentfulCampaignSchoolingApproachesArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/**
 * Description of marketing campaign -- dates, target audience, etc. -- to connect
 * with assets [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/campaign)
 */
export type ContentfulCampaignStartAtArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/**
 * Description of marketing campaign -- dates, target audience, etc. -- to connect
 * with assets [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/campaign)
 */
export type ContentfulCampaignStateOrProvinceCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<
    Array<InputMaybe<ContentfulCampaignStateOrProvinceCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ContentfulCampaignStateOrProvinceFilter>;
};

export enum ContentfulCampaignCountryCollectionOrder {
  CodeAsc = "code_ASC",
  CodeDesc = "code_DESC",
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
}

export type ContentfulCampaignCountryFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulCampaignCountryFilter>>>;
  code?: InputMaybe<Scalars["String"]>;
  code_contains?: InputMaybe<Scalars["String"]>;
  code_exists?: InputMaybe<Scalars["Boolean"]>;
  code_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  code_not?: InputMaybe<Scalars["String"]>;
  code_not_contains?: InputMaybe<Scalars["String"]>;
  code_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  matchingText_contains_all?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  matchingText_contains_none?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  matchingText_contains_some?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  matchingText_exists?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  name_contains?: InputMaybe<Scalars["String"]>;
  name_exists?: InputMaybe<Scalars["Boolean"]>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  name_not?: InputMaybe<Scalars["String"]>;
  name_not_contains?: InputMaybe<Scalars["String"]>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulCampaignCountryFilter>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
};

export type ContentfulCampaignCountryCollection = {
  __typename?: "ContentfulCampaignCountryCollection";
  items: Array<Maybe<ContentfulCampaignCountry>>;
  limit: Scalars["Int"];
  skip: Scalars["Int"];
  total: Scalars["Int"];
};

/** Country we can use to restrict marketing campaigns to a segment of users [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/campaignCountry) */
export type ContentfulCampaignCountry = Contentful_Node &
  ContentfulEntry & {
    __typename?: "ContentfulCampaignCountry";
    _id: Scalars["ID"];
    code: Maybe<Scalars["String"]>;
    contentfulMetadata: ContentfulContentfulMetadata;
    linkedFrom: Maybe<ContentfulCampaignCountryLinkingCollections>;
    matchingText: Maybe<Array<Maybe<Scalars["String"]>>>;
    name: Maybe<Scalars["String"]>;
    sys: ContentfulSys;
  };

/** Country we can use to restrict marketing campaigns to a segment of users [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/campaignCountry) */
export type ContentfulCampaignCountryCodeArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** Country we can use to restrict marketing campaigns to a segment of users [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/campaignCountry) */
export type ContentfulCampaignCountryLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

/** Country we can use to restrict marketing campaigns to a segment of users [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/campaignCountry) */
export type ContentfulCampaignCountryMatchingTextArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** Country we can use to restrict marketing campaigns to a segment of users [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/campaignCountry) */
export type ContentfulCampaignCountryNameArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

export type ContentfulCampaignCountryLinkingCollections = {
  __typename?: "ContentfulCampaignCountryLinkingCollections";
  campaignCollection: Maybe<ContentfulCampaignCollection>;
  entryCollection: Maybe<ContentfulEntryCollection>;
};

export type ContentfulCampaignCountryLinkingCollectionsCampaignCollectionArgs =
  {
    limit?: InputMaybe<Scalars["Int"]>;
    locale?: InputMaybe<Scalars["String"]>;
    order?: InputMaybe<
      Array<
        InputMaybe<ContentfulCampaignCountryLinkingCollectionsCampaignCollectionOrder>
      >
    >;
    preview?: InputMaybe<Scalars["Boolean"]>;
    skip?: InputMaybe<Scalars["Int"]>;
  };

export type ContentfulCampaignCountryLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export enum ContentfulCampaignCountryLinkingCollectionsCampaignCollectionOrder {
  EndAtAsc = "endAt_ASC",
  EndAtDesc = "endAt_DESC",
  HasSubscriptionAsc = "hasSubscription_ASC",
  HasSubscriptionDesc = "hasSubscription_DESC",
  MaxAgeAsc = "maxAge_ASC",
  MaxAgeDesc = "maxAge_DESC",
  MinAgeAsc = "minAge_ASC",
  MinAgeDesc = "minAge_DESC",
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  OnlyForTreatmentInExperimentAsc = "onlyForTreatmentInExperiment_ASC",
  OnlyForTreatmentInExperimentDesc = "onlyForTreatmentInExperiment_DESC",
  StartAtAsc = "startAt_ASC",
  StartAtDesc = "startAt_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
}

export type ContentfulCampaignCollection = {
  __typename?: "ContentfulCampaignCollection";
  items: Array<Maybe<ContentfulCampaign>>;
  limit: Scalars["Int"];
  skip: Scalars["Int"];
  total: Scalars["Int"];
};

export type ContentfulCampaignLinkingCollections = {
  __typename?: "ContentfulCampaignLinkingCollections";
  bannerCollection: Maybe<ContentfulBannerCollection>;
  entryCollection: Maybe<ContentfulEntryCollection>;
  learnerBannerCollection: Maybe<ContentfulLearnerBannerCollection>;
};

export type ContentfulCampaignLinkingCollectionsBannerCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<
    Array<InputMaybe<ContentfulCampaignLinkingCollectionsBannerCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type ContentfulCampaignLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type ContentfulCampaignLinkingCollectionsLearnerBannerCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<
    Array<
      InputMaybe<ContentfulCampaignLinkingCollectionsLearnerBannerCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export enum ContentfulCampaignLinkingCollectionsBannerCollectionOrder {
  ButtonTextAsc = "buttonText_ASC",
  ButtonTextDesc = "buttonText_DESC",
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
  UrlAsc = "url_ASC",
  UrlDesc = "url_DESC",
}

export enum ContentfulCampaignLinkingCollectionsLearnerBannerCollectionOrder {
  CtaTextAsc = "ctaText_ASC",
  CtaTextDesc = "ctaText_DESC",
  CtaUrlAsc = "ctaUrl_ASC",
  CtaUrlDesc = "ctaUrl_DESC",
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export type ContentfulLearnerBannerCollection = {
  __typename?: "ContentfulLearnerBannerCollection";
  items: Array<Maybe<ContentfulLearnerBanner>>;
  limit: Scalars["Int"];
  skip: Scalars["Int"];
  total: Scalars["Int"];
};

/** CTA banner for learners [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/learnerBanner) */
export type ContentfulLearnerBanner = Contentful_Node &
  ContentfulEntry & {
    __typename?: "ContentfulLearnerBanner";
    _id: Scalars["ID"];
    campaign: Maybe<ContentfulCampaign>;
    contentfulMetadata: ContentfulContentfulMetadata;
    ctaText: Maybe<Scalars["String"]>;
    ctaUrl: Maybe<Scalars["String"]>;
    image: Maybe<ContentfulAsset>;
    linkedFrom: Maybe<ContentfulLearnerBannerLinkingCollections>;
    name: Maybe<Scalars["String"]>;
    subtitle: Maybe<Scalars["String"]>;
    sys: ContentfulSys;
    title: Maybe<Scalars["String"]>;
  };

/** CTA banner for learners [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/learnerBanner) */
export type ContentfulLearnerBannerCampaignArgs = {
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
  where?: InputMaybe<ContentfulCampaignFilter>;
};

/** CTA banner for learners [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/learnerBanner) */
export type ContentfulLearnerBannerCtaTextArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** CTA banner for learners [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/learnerBanner) */
export type ContentfulLearnerBannerCtaUrlArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** CTA banner for learners [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/learnerBanner) */
export type ContentfulLearnerBannerImageArgs = {
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
};

/** CTA banner for learners [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/learnerBanner) */
export type ContentfulLearnerBannerLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

/** CTA banner for learners [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/learnerBanner) */
export type ContentfulLearnerBannerNameArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** CTA banner for learners [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/learnerBanner) */
export type ContentfulLearnerBannerSubtitleArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** CTA banner for learners [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/learnerBanner) */
export type ContentfulLearnerBannerTitleArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

export type ContentfulLearnerBannerLinkingCollections = {
  __typename?: "ContentfulLearnerBannerLinkingCollections";
  entryCollection: Maybe<ContentfulEntryCollection>;
};

export type ContentfulLearnerBannerLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export enum ContentfulCampaignStateOrProvinceCollectionOrder {
  CodeAsc = "code_ASC",
  CodeDesc = "code_DESC",
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
}

export type ContentfulCampaignStateOrProvinceFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulCampaignStateOrProvinceFilter>>>;
  code?: InputMaybe<Scalars["String"]>;
  code_contains?: InputMaybe<Scalars["String"]>;
  code_exists?: InputMaybe<Scalars["Boolean"]>;
  code_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  code_not?: InputMaybe<Scalars["String"]>;
  code_not_contains?: InputMaybe<Scalars["String"]>;
  code_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  name?: InputMaybe<Scalars["String"]>;
  name_contains?: InputMaybe<Scalars["String"]>;
  name_exists?: InputMaybe<Scalars["Boolean"]>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  name_not?: InputMaybe<Scalars["String"]>;
  name_not_contains?: InputMaybe<Scalars["String"]>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulCampaignStateOrProvinceFilter>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
};

export type ContentfulCampaignStateOrProvinceCollection = {
  __typename?: "ContentfulCampaignStateOrProvinceCollection";
  items: Array<Maybe<ContentfulCampaignStateOrProvince>>;
  limit: Scalars["Int"];
  skip: Scalars["Int"];
  total: Scalars["Int"];
};

/**
 * State or province we can use to restrict marketing campaigns to a segment of
 * users [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/campaignStateOrProvince)
 */
export type ContentfulCampaignStateOrProvince = Contentful_Node &
  ContentfulEntry & {
    __typename?: "ContentfulCampaignStateOrProvince";
    _id: Scalars["ID"];
    code: Maybe<Scalars["String"]>;
    contentfulMetadata: ContentfulContentfulMetadata;
    linkedFrom: Maybe<ContentfulCampaignStateOrProvinceLinkingCollections>;
    name: Maybe<Scalars["String"]>;
    sys: ContentfulSys;
  };

/**
 * State or province we can use to restrict marketing campaigns to a segment of
 * users [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/campaignStateOrProvince)
 */
export type ContentfulCampaignStateOrProvinceCodeArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/**
 * State or province we can use to restrict marketing campaigns to a segment of
 * users [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/campaignStateOrProvince)
 */
export type ContentfulCampaignStateOrProvinceLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

/**
 * State or province we can use to restrict marketing campaigns to a segment of
 * users [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/campaignStateOrProvince)
 */
export type ContentfulCampaignStateOrProvinceNameArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

export type ContentfulCampaignStateOrProvinceLinkingCollections = {
  __typename?: "ContentfulCampaignStateOrProvinceLinkingCollections";
  campaignCollection: Maybe<ContentfulCampaignCollection>;
  entryCollection: Maybe<ContentfulEntryCollection>;
};

export type ContentfulCampaignStateOrProvinceLinkingCollectionsCampaignCollectionArgs =
  {
    limit?: InputMaybe<Scalars["Int"]>;
    locale?: InputMaybe<Scalars["String"]>;
    order?: InputMaybe<
      Array<
        InputMaybe<ContentfulCampaignStateOrProvinceLinkingCollectionsCampaignCollectionOrder>
      >
    >;
    preview?: InputMaybe<Scalars["Boolean"]>;
    skip?: InputMaybe<Scalars["Int"]>;
  };

export type ContentfulCampaignStateOrProvinceLinkingCollectionsEntryCollectionArgs =
  {
    limit?: InputMaybe<Scalars["Int"]>;
    locale?: InputMaybe<Scalars["String"]>;
    preview?: InputMaybe<Scalars["Boolean"]>;
    skip?: InputMaybe<Scalars["Int"]>;
  };

export enum ContentfulCampaignStateOrProvinceLinkingCollectionsCampaignCollectionOrder {
  EndAtAsc = "endAt_ASC",
  EndAtDesc = "endAt_DESC",
  HasSubscriptionAsc = "hasSubscription_ASC",
  HasSubscriptionDesc = "hasSubscription_DESC",
  MaxAgeAsc = "maxAge_ASC",
  MaxAgeDesc = "maxAge_DESC",
  MinAgeAsc = "minAge_ASC",
  MinAgeDesc = "minAge_DESC",
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  OnlyForTreatmentInExperimentAsc = "onlyForTreatmentInExperiment_ASC",
  OnlyForTreatmentInExperimentDesc = "onlyForTreatmentInExperiment_DESC",
  StartAtAsc = "startAt_ASC",
  StartAtDesc = "startAt_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
}

export type ContentfulBannerContent = {
  __typename?: "ContentfulBannerContent";
  json: Scalars["ContentfulJSON"];
  links: ContentfulBannerContentLinks;
};

export type ContentfulBannerContentLinks = {
  __typename?: "ContentfulBannerContentLinks";
  assets: ContentfulBannerContentAssets;
  entries: ContentfulBannerContentEntries;
  resources: ContentfulBannerContentResources;
};

export type ContentfulBannerContentAssets = {
  __typename?: "ContentfulBannerContentAssets";
  block: Array<Maybe<ContentfulAsset>>;
  hyperlink: Array<Maybe<ContentfulAsset>>;
};

export type ContentfulBannerContentEntries = {
  __typename?: "ContentfulBannerContentEntries";
  block: Array<Maybe<ContentfulEntry>>;
  hyperlink: Array<Maybe<ContentfulEntry>>;
  inline: Array<Maybe<ContentfulEntry>>;
};

export type ContentfulBannerContentResources = {
  __typename?: "ContentfulBannerContentResources";
  block: Array<ContentfulBannerContentResourcesBlock>;
  hyperlink: Array<ContentfulBannerContentResourcesHyperlink>;
  inline: Array<ContentfulBannerContentResourcesInline>;
};

export type ContentfulBannerContentResourcesBlock = ContentfulResourceLink & {
  __typename?: "ContentfulBannerContentResourcesBlock";
  sys: ContentfulResourceSys;
};

export type ContentfulBannerContentResourcesHyperlink =
  ContentfulResourceLink & {
    __typename?: "ContentfulBannerContentResourcesHyperlink";
    sys: ContentfulResourceSys;
  };

export type ContentfulBannerContentResourcesInline = ContentfulResourceLink & {
  __typename?: "ContentfulBannerContentResourcesInline";
  sys: ContentfulResourceSys;
};

export type ContentfulBannerLinkingCollections = {
  __typename?: "ContentfulBannerLinkingCollections";
  bannerPlacementCollection: Maybe<ContentfulBannerPlacementCollection>;
  entryCollection: Maybe<ContentfulEntryCollection>;
};

export type ContentfulBannerLinkingCollectionsBannerPlacementCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<
    Array<
      InputMaybe<ContentfulBannerLinkingCollectionsBannerPlacementCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type ContentfulBannerLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export enum ContentfulBannerLinkingCollectionsBannerPlacementCollectionOrder {
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
}

export type ContentfulBannerPlacementCollection = {
  __typename?: "ContentfulBannerPlacementCollection";
  items: Array<Maybe<ContentfulBannerPlacement>>;
  limit: Scalars["Int"];
  skip: Scalars["Int"];
  total: Scalars["Int"];
};

/** Location for managed marketing banner. [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/bannerPlacement) */
export type ContentfulBannerPlacement = Contentful_Node &
  ContentfulEntry & {
    __typename?: "ContentfulBannerPlacement";
    _id: Scalars["ID"];
    bannersCollection: Maybe<ContentfulBannerPlacementBannersCollection>;
    contentfulMetadata: ContentfulContentfulMetadata;
    linkedFrom: Maybe<ContentfulBannerPlacementLinkingCollections>;
    name: Maybe<Scalars["String"]>;
    sys: ContentfulSys;
  };

/** Location for managed marketing banner. [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/bannerPlacement) */
export type ContentfulBannerPlacementBannersCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<
    Array<InputMaybe<ContentfulBannerPlacementBannersCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ContentfulBannerFilter>;
};

/** Location for managed marketing banner. [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/bannerPlacement) */
export type ContentfulBannerPlacementLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

/** Location for managed marketing banner. [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/bannerPlacement) */
export type ContentfulBannerPlacementNameArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

export enum ContentfulBannerPlacementBannersCollectionOrder {
  ButtonTextAsc = "buttonText_ASC",
  ButtonTextDesc = "buttonText_DESC",
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
  UrlAsc = "url_ASC",
  UrlDesc = "url_DESC",
}

export type ContentfulBannerFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulBannerFilter>>>;
  buttonText?: InputMaybe<Scalars["String"]>;
  buttonText_contains?: InputMaybe<Scalars["String"]>;
  buttonText_exists?: InputMaybe<Scalars["Boolean"]>;
  buttonText_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  buttonText_not?: InputMaybe<Scalars["String"]>;
  buttonText_not_contains?: InputMaybe<Scalars["String"]>;
  buttonText_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  campaign?: InputMaybe<ContentfulcfCampaignNestedFilter>;
  campaign_exists?: InputMaybe<Scalars["Boolean"]>;
  content_contains?: InputMaybe<Scalars["String"]>;
  content_exists?: InputMaybe<Scalars["Boolean"]>;
  content_not_contains?: InputMaybe<Scalars["String"]>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  image_exists?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  name_contains?: InputMaybe<Scalars["String"]>;
  name_exists?: InputMaybe<Scalars["Boolean"]>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  name_not?: InputMaybe<Scalars["String"]>;
  name_not_contains?: InputMaybe<Scalars["String"]>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulBannerFilter>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  url?: InputMaybe<Scalars["String"]>;
  url_contains?: InputMaybe<Scalars["String"]>;
  url_exists?: InputMaybe<Scalars["Boolean"]>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  url_not?: InputMaybe<Scalars["String"]>;
  url_not_contains?: InputMaybe<Scalars["String"]>;
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type ContentfulcfCampaignNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulcfCampaignNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  countryCollection_exists?: InputMaybe<Scalars["Boolean"]>;
  endAt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  endAt_exists?: InputMaybe<Scalars["Boolean"]>;
  endAt_gt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  endAt_gte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  endAt_in?: InputMaybe<Array<InputMaybe<Scalars["ContentfulDateTime"]>>>;
  endAt_lt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  endAt_lte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  endAt_not?: InputMaybe<Scalars["ContentfulDateTime"]>;
  endAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["ContentfulDateTime"]>>>;
  hasSubscription?: InputMaybe<Scalars["Boolean"]>;
  hasSubscription_exists?: InputMaybe<Scalars["Boolean"]>;
  hasSubscription_not?: InputMaybe<Scalars["Boolean"]>;
  maxAge?: InputMaybe<Scalars["Int"]>;
  maxAge_exists?: InputMaybe<Scalars["Boolean"]>;
  maxAge_gt?: InputMaybe<Scalars["Int"]>;
  maxAge_gte?: InputMaybe<Scalars["Int"]>;
  maxAge_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  maxAge_lt?: InputMaybe<Scalars["Int"]>;
  maxAge_lte?: InputMaybe<Scalars["Int"]>;
  maxAge_not?: InputMaybe<Scalars["Int"]>;
  maxAge_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  minAge?: InputMaybe<Scalars["Int"]>;
  minAge_exists?: InputMaybe<Scalars["Boolean"]>;
  minAge_gt?: InputMaybe<Scalars["Int"]>;
  minAge_gte?: InputMaybe<Scalars["Int"]>;
  minAge_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  minAge_lt?: InputMaybe<Scalars["Int"]>;
  minAge_lte?: InputMaybe<Scalars["Int"]>;
  minAge_not?: InputMaybe<Scalars["Int"]>;
  minAge_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  name?: InputMaybe<Scalars["String"]>;
  name_contains?: InputMaybe<Scalars["String"]>;
  name_exists?: InputMaybe<Scalars["Boolean"]>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  name_not?: InputMaybe<Scalars["String"]>;
  name_not_contains?: InputMaybe<Scalars["String"]>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  onlyForTreatmentInExperiment?: InputMaybe<Scalars["String"]>;
  onlyForTreatmentInExperiment_contains?: InputMaybe<Scalars["String"]>;
  onlyForTreatmentInExperiment_exists?: InputMaybe<Scalars["Boolean"]>;
  onlyForTreatmentInExperiment_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  onlyForTreatmentInExperiment_not?: InputMaybe<Scalars["String"]>;
  onlyForTreatmentInExperiment_not_contains?: InputMaybe<Scalars["String"]>;
  onlyForTreatmentInExperiment_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  OR?: InputMaybe<Array<InputMaybe<ContentfulcfCampaignNestedFilter>>>;
  schoolingApproaches_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  schoolingApproaches_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  schoolingApproaches_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  schoolingApproaches_exists?: InputMaybe<Scalars["Boolean"]>;
  startAt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startAt_exists?: InputMaybe<Scalars["Boolean"]>;
  startAt_gt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startAt_gte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startAt_in?: InputMaybe<Array<InputMaybe<Scalars["ContentfulDateTime"]>>>;
  startAt_lt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startAt_lte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startAt_not?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["ContentfulDateTime"]>>>;
  stateOrProvinceCollection_exists?: InputMaybe<Scalars["Boolean"]>;
  sys?: InputMaybe<ContentfulSysFilter>;
};

export type ContentfulBannerPlacementBannersCollection = {
  __typename?: "ContentfulBannerPlacementBannersCollection";
  items: Array<Maybe<ContentfulBanner>>;
  limit: Scalars["Int"];
  skip: Scalars["Int"];
  total: Scalars["Int"];
};

export type ContentfulBannerPlacementLinkingCollections = {
  __typename?: "ContentfulBannerPlacementLinkingCollections";
  entryCollection: Maybe<ContentfulEntryCollection>;
};

export type ContentfulBannerPlacementLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type ContentfulBasicPageCollection = {
  __typename?: "ContentfulBasicPageCollection";
  items: Array<Maybe<ContentfulBasicPage>>;
  limit: Scalars["Int"];
  skip: Scalars["Int"];
  total: Scalars["Int"];
};

/** A simple page with a title and rich text body. [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/basicPage) */
export type ContentfulBasicPage = Contentful_Node &
  ContentfulEntry & {
    __typename?: "ContentfulBasicPage";
    _id: Scalars["ID"];
    body: Maybe<ContentfulBasicPageBody>;
    contentfulMetadata: ContentfulContentfulMetadata;
    headerImage: Maybe<ContentfulAsset>;
    linkedFrom: Maybe<ContentfulBasicPageLinkingCollections>;
    publishedAt: Maybe<Scalars["ContentfulDateTime"]>;
    slug: Maybe<Scalars["String"]>;
    sys: ContentfulSys;
    title: Maybe<Scalars["String"]>;
  };

/** A simple page with a title and rich text body. [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/basicPage) */
export type ContentfulBasicPageBodyArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** A simple page with a title and rich text body. [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/basicPage) */
export type ContentfulBasicPageHeaderImageArgs = {
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
};

/** A simple page with a title and rich text body. [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/basicPage) */
export type ContentfulBasicPageLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

/** A simple page with a title and rich text body. [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/basicPage) */
export type ContentfulBasicPagePublishedAtArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** A simple page with a title and rich text body. [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/basicPage) */
export type ContentfulBasicPageSlugArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** A simple page with a title and rich text body. [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/basicPage) */
export type ContentfulBasicPageTitleArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

export type ContentfulBasicPageBody = {
  __typename?: "ContentfulBasicPageBody";
  json: Scalars["ContentfulJSON"];
  links: ContentfulBasicPageBodyLinks;
};

export type ContentfulBasicPageBodyLinks = {
  __typename?: "ContentfulBasicPageBodyLinks";
  assets: ContentfulBasicPageBodyAssets;
  entries: ContentfulBasicPageBodyEntries;
  resources: ContentfulBasicPageBodyResources;
};

export type ContentfulBasicPageBodyAssets = {
  __typename?: "ContentfulBasicPageBodyAssets";
  block: Array<Maybe<ContentfulAsset>>;
  hyperlink: Array<Maybe<ContentfulAsset>>;
};

export type ContentfulBasicPageBodyEntries = {
  __typename?: "ContentfulBasicPageBodyEntries";
  block: Array<Maybe<ContentfulEntry>>;
  hyperlink: Array<Maybe<ContentfulEntry>>;
  inline: Array<Maybe<ContentfulEntry>>;
};

export type ContentfulBasicPageBodyResources = {
  __typename?: "ContentfulBasicPageBodyResources";
  block: Array<ContentfulBasicPageBodyResourcesBlock>;
  hyperlink: Array<ContentfulBasicPageBodyResourcesHyperlink>;
  inline: Array<ContentfulBasicPageBodyResourcesInline>;
};

export type ContentfulBasicPageBodyResourcesBlock = ContentfulResourceLink & {
  __typename?: "ContentfulBasicPageBodyResourcesBlock";
  sys: ContentfulResourceSys;
};

export type ContentfulBasicPageBodyResourcesHyperlink =
  ContentfulResourceLink & {
    __typename?: "ContentfulBasicPageBodyResourcesHyperlink";
    sys: ContentfulResourceSys;
  };

export type ContentfulBasicPageBodyResourcesInline = ContentfulResourceLink & {
  __typename?: "ContentfulBasicPageBodyResourcesInline";
  sys: ContentfulResourceSys;
};

export type ContentfulBasicPageLinkingCollections = {
  __typename?: "ContentfulBasicPageLinkingCollections";
  entryCollection: Maybe<ContentfulEntryCollection>;
};

export type ContentfulBasicPageLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type ContentfulHeroSlideCollection = {
  __typename?: "ContentfulHeroSlideCollection";
  items: Array<Maybe<ContentfulHeroSlide>>;
  limit: Scalars["Int"];
  skip: Scalars["Int"];
  total: Scalars["Int"];
};

export type ContentfulLandingPageHeroSectionCollection = {
  __typename?: "ContentfulLandingPageHeroSectionCollection";
  items: Array<Maybe<ContentfulLandingPageHeroSection>>;
  limit: Scalars["Int"];
  skip: Scalars["Int"];
  total: Scalars["Int"];
};

export type ContentfulLandingPageIconInfoCollection = {
  __typename?: "ContentfulLandingPageIconInfoCollection";
  items: Array<Maybe<ContentfulLandingPageIconInfo>>;
  limit: Scalars["Int"];
  skip: Scalars["Int"];
  total: Scalars["Int"];
};

export type ContentfulLandingPageImageTextCtaSectionCollection = {
  __typename?: "ContentfulLandingPageImageTextCtaSectionCollection";
  items: Array<Maybe<ContentfulLandingPageImageTextCtaSection>>;
  limit: Scalars["Int"];
  skip: Scalars["Int"];
  total: Scalars["Int"];
};

export type ContentfulLandingPageJourneyItemCollection = {
  __typename?: "ContentfulLandingPageJourneyItemCollection";
  items: Array<Maybe<ContentfulLandingPageJourneyItem>>;
  limit: Scalars["Int"];
  skip: Scalars["Int"];
  total: Scalars["Int"];
};

export type ContentfulLandingPageLessonPreviewSectionCollection = {
  __typename?: "ContentfulLandingPageLessonPreviewSectionCollection";
  items: Array<Maybe<ContentfulLandingPageLessonPreviewSection>>;
  limit: Scalars["Int"];
  skip: Scalars["Int"];
  total: Scalars["Int"];
};

export type ContentfulLandingPageSingleReviewSectionCollection = {
  __typename?: "ContentfulLandingPageSingleReviewSectionCollection";
  items: Array<Maybe<ContentfulLandingPageSingleReviewSection>>;
  limit: Scalars["Int"];
  skip: Scalars["Int"];
  total: Scalars["Int"];
};

export type ContentfulLandingPageStatBoxCollection = {
  __typename?: "ContentfulLandingPageStatBoxCollection";
  items: Array<Maybe<ContentfulLandingPageStatBox>>;
  limit: Scalars["Int"];
  skip: Scalars["Int"];
  total: Scalars["Int"];
};

export type ContentfulValuePropCollection = {
  __typename?: "ContentfulValuePropCollection";
  items: Array<Maybe<ContentfulValueProp>>;
  limit: Scalars["Int"];
  skip: Scalars["Int"];
  total: Scalars["Int"];
};

export type ContentfulVisualLinkCollection = {
  __typename?: "ContentfulVisualLinkCollection";
  items: Array<Maybe<ContentfulVisualLink>>;
  limit: Scalars["Int"];
  skip: Scalars["Int"];
  total: Scalars["Int"];
};

export type ContentfulImageTransformOptions = {
  /**
   * Desired background color, used with corner radius or `PAD` resize strategy.
   *         Defaults to transparent (for `PNG`, `PNG8` and `WEBP`) or white (for `JPG` and `JPG_PROGRESSIVE`).
   */
  backgroundColor?: InputMaybe<Scalars["ContentfulHexColor"]>;
  /**
   * Desired corner radius in pixels.
   *         Results in an image with rounded corners (pass `-1` for a full circle/ellipse).
   *         Defaults to `0`. Uses desired background color as padding color,
   *         unless the format is `JPG` or `JPG_PROGRESSIVE` and resize strategy is `PAD`, then defaults to white.
   */
  cornerRadius?: InputMaybe<Scalars["Int"]>;
  /** Desired image format. Defaults to the original image format. */
  format?: InputMaybe<ContentfulImageFormat>;
  /** Desired height in pixels. Defaults to the original image height. */
  height?: InputMaybe<Scalars["ContentfulDimension"]>;
  /**
   * Desired quality of the image in percents.
   *         Used for `PNG8`, `JPG`, `JPG_PROGRESSIVE` and `WEBP` formats.
   */
  quality?: InputMaybe<Scalars["ContentfulQuality"]>;
  /** Desired resize focus area. Defaults to `CENTER`. */
  resizeFocus?: InputMaybe<ContentfulImageResizeFocus>;
  /** Desired resize strategy. Defaults to `FIT`. */
  resizeStrategy?: InputMaybe<ContentfulImageResizeStrategy>;
  /** Desired width in pixels. Defaults to the original image width. */
  width?: InputMaybe<Scalars["ContentfulDimension"]>;
};

export enum ContentfulImageFormat {
  Avif = "AVIF",
  /** JPG image format. */
  Jpg = "JPG",
  /**
   * Progressive JPG format stores multiple passes of an image in progressively higher detail.
   *         When a progressive image is loading, the viewer will first see a lower quality pixelated version which
   *         will gradually improve in detail, until the image is fully downloaded. This is to display an image as
   *         early as possible to make the layout look as designed.
   */
  JpgProgressive = "JPG_PROGRESSIVE",
  /** PNG image format */
  Png = "PNG",
  /**
   * 8-bit PNG images support up to 256 colors and weigh less than the standard 24-bit PNG equivalent.
   *         The 8-bit PNG format is mostly used for simple images, such as icons or logos.
   */
  Png8 = "PNG8",
  /** WebP image format. */
  Webp = "WEBP",
}

export enum ContentfulImageResizeFocus {
  /** Focus the resizing on the bottom. */
  Bottom = "BOTTOM",
  /** Focus the resizing on the bottom left. */
  BottomLeft = "BOTTOM_LEFT",
  /** Focus the resizing on the bottom right. */
  BottomRight = "BOTTOM_RIGHT",
  /** Focus the resizing on the center. */
  Center = "CENTER",
  /** Focus the resizing on the largest face. */
  Face = "FACE",
  /** Focus the resizing on the area containing all the faces. */
  Faces = "FACES",
  /** Focus the resizing on the left. */
  Left = "LEFT",
  /** Focus the resizing on the right. */
  Right = "RIGHT",
  /** Focus the resizing on the top. */
  Top = "TOP",
  /** Focus the resizing on the top left. */
  TopLeft = "TOP_LEFT",
  /** Focus the resizing on the top right. */
  TopRight = "TOP_RIGHT",
}

export enum ContentfulImageResizeStrategy {
  /** Crops a part of the original image to fit into the specified dimensions. */
  Crop = "CROP",
  /** Resizes the image to the specified dimensions, cropping the image if needed. */
  Fill = "FILL",
  /** Resizes the image to fit into the specified dimensions. */
  Fit = "FIT",
  /**
   * Resizes the image to the specified dimensions, padding the image if needed.
   *         Uses desired background color as padding color.
   */
  Pad = "PAD",
  /** Resizes the image to the specified dimensions, changing the original aspect ratio if needed. */
  Scale = "SCALE",
  /** Creates a thumbnail from the image. */
  Thumb = "THUMB",
}

export type ContentfulAccordionPageLinkingCollections = {
  __typename?: "ContentfulAccordionPageLinkingCollections";
  entryCollection: Maybe<ContentfulEntryCollection>;
};

export type ContentfulAccordionPageLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type ContentfulAccordionPagePreamble = {
  __typename?: "ContentfulAccordionPagePreamble";
  json: Scalars["ContentfulJSON"];
  links: ContentfulAccordionPagePreambleLinks;
};

export type ContentfulAccordionPagePreambleLinks = {
  __typename?: "ContentfulAccordionPagePreambleLinks";
  assets: ContentfulAccordionPagePreambleAssets;
  entries: ContentfulAccordionPagePreambleEntries;
  resources: ContentfulAccordionPagePreambleResources;
};

export type ContentfulAccordionPagePreambleAssets = {
  __typename?: "ContentfulAccordionPagePreambleAssets";
  block: Array<Maybe<ContentfulAsset>>;
  hyperlink: Array<Maybe<ContentfulAsset>>;
};

export type ContentfulAccordionPagePreambleEntries = {
  __typename?: "ContentfulAccordionPagePreambleEntries";
  block: Array<Maybe<ContentfulEntry>>;
  hyperlink: Array<Maybe<ContentfulEntry>>;
  inline: Array<Maybe<ContentfulEntry>>;
};

export type ContentfulAccordionPagePreambleResources = {
  __typename?: "ContentfulAccordionPagePreambleResources";
  block: Array<ContentfulAccordionPagePreambleResourcesBlock>;
  hyperlink: Array<ContentfulAccordionPagePreambleResourcesHyperlink>;
  inline: Array<ContentfulAccordionPagePreambleResourcesInline>;
};

export type ContentfulAccordionPagePreambleResourcesBlock =
  ContentfulResourceLink & {
    __typename?: "ContentfulAccordionPagePreambleResourcesBlock";
    sys: ContentfulResourceSys;
  };

export type ContentfulAccordionPagePreambleResourcesHyperlink =
  ContentfulResourceLink & {
    __typename?: "ContentfulAccordionPagePreambleResourcesHyperlink";
    sys: ContentfulResourceSys;
  };

export type ContentfulAccordionPagePreambleResourcesInline =
  ContentfulResourceLink & {
    __typename?: "ContentfulAccordionPagePreambleResourcesInline";
    sys: ContentfulResourceSys;
  };

export enum ContentfulAccordionPageSectionsCollectionOrder {
  HeadingAsc = "heading_ASC",
  HeadingDesc = "heading_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
}

export type ContentfulAccordionSectionFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulAccordionSectionFilter>>>;
  body_contains?: InputMaybe<Scalars["String"]>;
  body_exists?: InputMaybe<Scalars["Boolean"]>;
  body_not_contains?: InputMaybe<Scalars["String"]>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  heading?: InputMaybe<Scalars["String"]>;
  heading_contains?: InputMaybe<Scalars["String"]>;
  heading_exists?: InputMaybe<Scalars["Boolean"]>;
  heading_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  heading_not?: InputMaybe<Scalars["String"]>;
  heading_not_contains?: InputMaybe<Scalars["String"]>;
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulAccordionSectionFilter>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
};

export type ContentfulAccordionPageSectionsCollection = {
  __typename?: "ContentfulAccordionPageSectionsCollection";
  items: Array<Maybe<ContentfulAccordionSection>>;
  limit: Scalars["Int"];
  skip: Scalars["Int"];
  total: Scalars["Int"];
};

/** A heading with collapsed rich text content. [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/accordionSection) */
export type ContentfulAccordionSection = Contentful_Node &
  ContentfulEntry & {
    __typename?: "ContentfulAccordionSection";
    _id: Scalars["ID"];
    body: Maybe<ContentfulAccordionSectionBody>;
    contentfulMetadata: ContentfulContentfulMetadata;
    heading: Maybe<Scalars["String"]>;
    linkedFrom: Maybe<ContentfulAccordionSectionLinkingCollections>;
    sys: ContentfulSys;
  };

/** A heading with collapsed rich text content. [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/accordionSection) */
export type ContentfulAccordionSectionBodyArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** A heading with collapsed rich text content. [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/accordionSection) */
export type ContentfulAccordionSectionHeadingArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** A heading with collapsed rich text content. [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/accordionSection) */
export type ContentfulAccordionSectionLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type ContentfulAccordionSectionBody = {
  __typename?: "ContentfulAccordionSectionBody";
  json: Scalars["ContentfulJSON"];
  links: ContentfulAccordionSectionBodyLinks;
};

export type ContentfulAccordionSectionBodyLinks = {
  __typename?: "ContentfulAccordionSectionBodyLinks";
  assets: ContentfulAccordionSectionBodyAssets;
  entries: ContentfulAccordionSectionBodyEntries;
  resources: ContentfulAccordionSectionBodyResources;
};

export type ContentfulAccordionSectionBodyAssets = {
  __typename?: "ContentfulAccordionSectionBodyAssets";
  block: Array<Maybe<ContentfulAsset>>;
  hyperlink: Array<Maybe<ContentfulAsset>>;
};

export type ContentfulAccordionSectionBodyEntries = {
  __typename?: "ContentfulAccordionSectionBodyEntries";
  block: Array<Maybe<ContentfulEntry>>;
  hyperlink: Array<Maybe<ContentfulEntry>>;
  inline: Array<Maybe<ContentfulEntry>>;
};

export type ContentfulAccordionSectionBodyResources = {
  __typename?: "ContentfulAccordionSectionBodyResources";
  block: Array<ContentfulAccordionSectionBodyResourcesBlock>;
  hyperlink: Array<ContentfulAccordionSectionBodyResourcesHyperlink>;
  inline: Array<ContentfulAccordionSectionBodyResourcesInline>;
};

export type ContentfulAccordionSectionBodyResourcesBlock =
  ContentfulResourceLink & {
    __typename?: "ContentfulAccordionSectionBodyResourcesBlock";
    sys: ContentfulResourceSys;
  };

export type ContentfulAccordionSectionBodyResourcesHyperlink =
  ContentfulResourceLink & {
    __typename?: "ContentfulAccordionSectionBodyResourcesHyperlink";
    sys: ContentfulResourceSys;
  };

export type ContentfulAccordionSectionBodyResourcesInline =
  ContentfulResourceLink & {
    __typename?: "ContentfulAccordionSectionBodyResourcesInline";
    sys: ContentfulResourceSys;
  };

export type ContentfulAccordionSectionLinkingCollections = {
  __typename?: "ContentfulAccordionSectionLinkingCollections";
  accordionPageCollection: Maybe<ContentfulAccordionPageCollection>;
  entryCollection: Maybe<ContentfulEntryCollection>;
};

export type ContentfulAccordionSectionLinkingCollectionsAccordionPageCollectionArgs =
  {
    limit?: InputMaybe<Scalars["Int"]>;
    locale?: InputMaybe<Scalars["String"]>;
    order?: InputMaybe<
      Array<
        InputMaybe<ContentfulAccordionSectionLinkingCollectionsAccordionPageCollectionOrder>
      >
    >;
    preview?: InputMaybe<Scalars["Boolean"]>;
    skip?: InputMaybe<Scalars["Int"]>;
  };

export type ContentfulAccordionSectionLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export enum ContentfulAccordionSectionLinkingCollectionsAccordionPageCollectionOrder {
  PublishedAtAsc = "publishedAt_ASC",
  PublishedAtDesc = "publishedAt_DESC",
  ShowTocAsc = "showToc_ASC",
  ShowTocDesc = "showToc_DESC",
  SlugAsc = "slug_ASC",
  SlugDesc = "slug_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export enum ContentfulAccordionPageOrder {
  PublishedAtAsc = "publishedAt_ASC",
  PublishedAtDesc = "publishedAt_DESC",
  ShowTocAsc = "showToc_ASC",
  ShowTocDesc = "showToc_DESC",
  SlugAsc = "slug_ASC",
  SlugDesc = "slug_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export type ContentfulAccordionPageFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulAccordionPageFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  headerImage_exists?: InputMaybe<Scalars["Boolean"]>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulAccordionPageFilter>>>;
  preamble_contains?: InputMaybe<Scalars["String"]>;
  preamble_exists?: InputMaybe<Scalars["Boolean"]>;
  preamble_not_contains?: InputMaybe<Scalars["String"]>;
  publishedAt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  publishedAt_exists?: InputMaybe<Scalars["Boolean"]>;
  publishedAt_gt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  publishedAt_gte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["ContentfulDateTime"]>>>;
  publishedAt_lt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  publishedAt_lte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  publishedAt_not?: InputMaybe<Scalars["ContentfulDateTime"]>;
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["ContentfulDateTime"]>>
  >;
  sections?: InputMaybe<ContentfulcfAccordionSectionNestedFilter>;
  sectionsCollection_exists?: InputMaybe<Scalars["Boolean"]>;
  showToc?: InputMaybe<Scalars["Boolean"]>;
  showToc_exists?: InputMaybe<Scalars["Boolean"]>;
  showToc_not?: InputMaybe<Scalars["Boolean"]>;
  slug?: InputMaybe<Scalars["String"]>;
  slug_contains?: InputMaybe<Scalars["String"]>;
  slug_exists?: InputMaybe<Scalars["Boolean"]>;
  slug_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  slug_not?: InputMaybe<Scalars["String"]>;
  slug_not_contains?: InputMaybe<Scalars["String"]>;
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type ContentfulcfAccordionSectionNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulcfAccordionSectionNestedFilter>>>;
  body_contains?: InputMaybe<Scalars["String"]>;
  body_exists?: InputMaybe<Scalars["Boolean"]>;
  body_not_contains?: InputMaybe<Scalars["String"]>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  heading?: InputMaybe<Scalars["String"]>;
  heading_contains?: InputMaybe<Scalars["String"]>;
  heading_exists?: InputMaybe<Scalars["Boolean"]>;
  heading_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  heading_not?: InputMaybe<Scalars["String"]>;
  heading_not_contains?: InputMaybe<Scalars["String"]>;
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulcfAccordionSectionNestedFilter>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
};

export enum ContentfulAccordionSectionOrder {
  HeadingAsc = "heading_ASC",
  HeadingDesc = "heading_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
}

export type ContentfulAccordionSectionCollection = {
  __typename?: "ContentfulAccordionSectionCollection";
  items: Array<Maybe<ContentfulAccordionSection>>;
  limit: Scalars["Int"];
  skip: Scalars["Int"];
  total: Scalars["Int"];
};

/** A close able Banner that can be updated from contentful [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/announcementBanner) */
export type ContentfulAnnouncementBanner = Contentful_Node &
  ContentfulEntry & {
    __typename?: "ContentfulAnnouncementBanner";
    _id: Scalars["ID"];
    contentfulMetadata: ContentfulContentfulMetadata;
    copy: Maybe<Scalars["String"]>;
    dateToRemove: Maybe<Scalars["ContentfulDateTime"]>;
    linkCopy: Maybe<Scalars["String"]>;
    linkedFrom: Maybe<ContentfulAnnouncementBannerLinkingCollections>;
    linkUrl: Maybe<Scalars["String"]>;
    name: Maybe<Scalars["String"]>;
    sys: ContentfulSys;
  };

/** A close able Banner that can be updated from contentful [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/announcementBanner) */
export type ContentfulAnnouncementBannerCopyArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** A close able Banner that can be updated from contentful [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/announcementBanner) */
export type ContentfulAnnouncementBannerDateToRemoveArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** A close able Banner that can be updated from contentful [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/announcementBanner) */
export type ContentfulAnnouncementBannerLinkCopyArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** A close able Banner that can be updated from contentful [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/announcementBanner) */
export type ContentfulAnnouncementBannerLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

/** A close able Banner that can be updated from contentful [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/announcementBanner) */
export type ContentfulAnnouncementBannerLinkUrlArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

/** A close able Banner that can be updated from contentful [See type definition](https://app.contentful.com/spaces/ucy64xj66ql7/content_types/announcementBanner) */
export type ContentfulAnnouncementBannerNameArgs = {
  locale?: InputMaybe<Scalars["String"]>;
};

export type ContentfulAnnouncementBannerLinkingCollections = {
  __typename?: "ContentfulAnnouncementBannerLinkingCollections";
  entryCollection: Maybe<ContentfulEntryCollection>;
};

export type ContentfulAnnouncementBannerLinkingCollectionsEntryCollectionArgs =
  {
    limit?: InputMaybe<Scalars["Int"]>;
    locale?: InputMaybe<Scalars["String"]>;
    preview?: InputMaybe<Scalars["Boolean"]>;
    skip?: InputMaybe<Scalars["Int"]>;
  };

export enum ContentfulAnnouncementBannerOrder {
  CopyAsc = "copy_ASC",
  CopyDesc = "copy_DESC",
  DateToRemoveAsc = "dateToRemove_ASC",
  DateToRemoveDesc = "dateToRemove_DESC",
  LinkCopyAsc = "linkCopy_ASC",
  LinkCopyDesc = "linkCopy_DESC",
  LinkUrlAsc = "linkUrl_ASC",
  LinkUrlDesc = "linkUrl_DESC",
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
}

export type ContentfulAnnouncementBannerFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulAnnouncementBannerFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  copy?: InputMaybe<Scalars["String"]>;
  copy_contains?: InputMaybe<Scalars["String"]>;
  copy_exists?: InputMaybe<Scalars["Boolean"]>;
  copy_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  copy_not?: InputMaybe<Scalars["String"]>;
  copy_not_contains?: InputMaybe<Scalars["String"]>;
  copy_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  dateToRemove?: InputMaybe<Scalars["ContentfulDateTime"]>;
  dateToRemove_exists?: InputMaybe<Scalars["Boolean"]>;
  dateToRemove_gt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  dateToRemove_gte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  dateToRemove_in?: InputMaybe<
    Array<InputMaybe<Scalars["ContentfulDateTime"]>>
  >;
  dateToRemove_lt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  dateToRemove_lte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  dateToRemove_not?: InputMaybe<Scalars["ContentfulDateTime"]>;
  dateToRemove_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["ContentfulDateTime"]>>
  >;
  linkCopy?: InputMaybe<Scalars["String"]>;
  linkCopy_contains?: InputMaybe<Scalars["String"]>;
  linkCopy_exists?: InputMaybe<Scalars["Boolean"]>;
  linkCopy_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  linkCopy_not?: InputMaybe<Scalars["String"]>;
  linkCopy_not_contains?: InputMaybe<Scalars["String"]>;
  linkCopy_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  linkUrl?: InputMaybe<Scalars["String"]>;
  linkUrl_contains?: InputMaybe<Scalars["String"]>;
  linkUrl_exists?: InputMaybe<Scalars["Boolean"]>;
  linkUrl_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  linkUrl_not?: InputMaybe<Scalars["String"]>;
  linkUrl_not_contains?: InputMaybe<Scalars["String"]>;
  linkUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  name?: InputMaybe<Scalars["String"]>;
  name_contains?: InputMaybe<Scalars["String"]>;
  name_exists?: InputMaybe<Scalars["Boolean"]>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  name_not?: InputMaybe<Scalars["String"]>;
  name_not_contains?: InputMaybe<Scalars["String"]>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulAnnouncementBannerFilter>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
};

export type ContentfulAnnouncementBannerCollection = {
  __typename?: "ContentfulAnnouncementBannerCollection";
  items: Array<Maybe<ContentfulAnnouncementBanner>>;
  limit: Scalars["Int"];
  skip: Scalars["Int"];
  total: Scalars["Int"];
};

export enum ContentfulAssetOrder {
  ContentTypeAsc = "contentType_ASC",
  ContentTypeDesc = "contentType_DESC",
  FileNameAsc = "fileName_ASC",
  FileNameDesc = "fileName_DESC",
  HeightAsc = "height_ASC",
  HeightDesc = "height_DESC",
  SizeAsc = "size_ASC",
  SizeDesc = "size_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  UrlAsc = "url_ASC",
  UrlDesc = "url_DESC",
  WidthAsc = "width_ASC",
  WidthDesc = "width_DESC",
}

export type ContentfulAssetFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulAssetFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  contentType?: InputMaybe<Scalars["String"]>;
  contentType_contains?: InputMaybe<Scalars["String"]>;
  contentType_exists?: InputMaybe<Scalars["Boolean"]>;
  contentType_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  contentType_not?: InputMaybe<Scalars["String"]>;
  contentType_not_contains?: InputMaybe<Scalars["String"]>;
  contentType_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  description?: InputMaybe<Scalars["String"]>;
  description_contains?: InputMaybe<Scalars["String"]>;
  description_exists?: InputMaybe<Scalars["Boolean"]>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  description_not?: InputMaybe<Scalars["String"]>;
  description_not_contains?: InputMaybe<Scalars["String"]>;
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  fileName?: InputMaybe<Scalars["String"]>;
  fileName_contains?: InputMaybe<Scalars["String"]>;
  fileName_exists?: InputMaybe<Scalars["Boolean"]>;
  fileName_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  fileName_not?: InputMaybe<Scalars["String"]>;
  fileName_not_contains?: InputMaybe<Scalars["String"]>;
  fileName_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  height?: InputMaybe<Scalars["Int"]>;
  height_exists?: InputMaybe<Scalars["Boolean"]>;
  height_gt?: InputMaybe<Scalars["Int"]>;
  height_gte?: InputMaybe<Scalars["Int"]>;
  height_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  height_lt?: InputMaybe<Scalars["Int"]>;
  height_lte?: InputMaybe<Scalars["Int"]>;
  height_not?: InputMaybe<Scalars["Int"]>;
  height_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulAssetFilter>>>;
  size?: InputMaybe<Scalars["Int"]>;
  size_exists?: InputMaybe<Scalars["Boolean"]>;
  size_gt?: InputMaybe<Scalars["Int"]>;
  size_gte?: InputMaybe<Scalars["Int"]>;
  size_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  size_lt?: InputMaybe<Scalars["Int"]>;
  size_lte?: InputMaybe<Scalars["Int"]>;
  size_not?: InputMaybe<Scalars["Int"]>;
  size_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  url?: InputMaybe<Scalars["String"]>;
  url_contains?: InputMaybe<Scalars["String"]>;
  url_exists?: InputMaybe<Scalars["Boolean"]>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  url_not?: InputMaybe<Scalars["String"]>;
  url_not_contains?: InputMaybe<Scalars["String"]>;
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  width?: InputMaybe<Scalars["Int"]>;
  width_exists?: InputMaybe<Scalars["Boolean"]>;
  width_gt?: InputMaybe<Scalars["Int"]>;
  width_gte?: InputMaybe<Scalars["Int"]>;
  width_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  width_lt?: InputMaybe<Scalars["Int"]>;
  width_lte?: InputMaybe<Scalars["Int"]>;
  width_not?: InputMaybe<Scalars["Int"]>;
  width_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
};

export type ContentfulAssetCollection = {
  __typename?: "ContentfulAssetCollection";
  items: Array<Maybe<ContentfulAsset>>;
  limit: Scalars["Int"];
  skip: Scalars["Int"];
  total: Scalars["Int"];
};

export enum ContentfulAuthorOrder {
  DescriptionAsc = "description_ASC",
  DescriptionDesc = "description_DESC",
  IsTeacherAsc = "isTeacher_ASC",
  IsTeacherDesc = "isTeacher_DESC",
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  UidAsc = "uid_ASC",
  UidDesc = "uid_DESC",
}

export enum ContentfulBannerOrder {
  ButtonTextAsc = "buttonText_ASC",
  ButtonTextDesc = "buttonText_DESC",
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
  UrlAsc = "url_ASC",
  UrlDesc = "url_DESC",
}

export enum ContentfulBannerPlacementOrder {
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
}

export type ContentfulBannerPlacementFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulBannerPlacementFilter>>>;
  banners?: InputMaybe<ContentfulcfBannerNestedFilter>;
  bannersCollection_exists?: InputMaybe<Scalars["Boolean"]>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  name?: InputMaybe<Scalars["String"]>;
  name_contains?: InputMaybe<Scalars["String"]>;
  name_exists?: InputMaybe<Scalars["Boolean"]>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  name_not?: InputMaybe<Scalars["String"]>;
  name_not_contains?: InputMaybe<Scalars["String"]>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulBannerPlacementFilter>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
};

export type ContentfulcfBannerNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulcfBannerNestedFilter>>>;
  buttonText?: InputMaybe<Scalars["String"]>;
  buttonText_contains?: InputMaybe<Scalars["String"]>;
  buttonText_exists?: InputMaybe<Scalars["Boolean"]>;
  buttonText_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  buttonText_not?: InputMaybe<Scalars["String"]>;
  buttonText_not_contains?: InputMaybe<Scalars["String"]>;
  buttonText_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  campaign_exists?: InputMaybe<Scalars["Boolean"]>;
  content_contains?: InputMaybe<Scalars["String"]>;
  content_exists?: InputMaybe<Scalars["Boolean"]>;
  content_not_contains?: InputMaybe<Scalars["String"]>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  image_exists?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  name_contains?: InputMaybe<Scalars["String"]>;
  name_exists?: InputMaybe<Scalars["Boolean"]>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  name_not?: InputMaybe<Scalars["String"]>;
  name_not_contains?: InputMaybe<Scalars["String"]>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulcfBannerNestedFilter>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  url?: InputMaybe<Scalars["String"]>;
  url_contains?: InputMaybe<Scalars["String"]>;
  url_exists?: InputMaybe<Scalars["Boolean"]>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  url_not?: InputMaybe<Scalars["String"]>;
  url_not_contains?: InputMaybe<Scalars["String"]>;
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export enum ContentfulBasicPageOrder {
  PublishedAtAsc = "publishedAt_ASC",
  PublishedAtDesc = "publishedAt_DESC",
  SlugAsc = "slug_ASC",
  SlugDesc = "slug_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export type ContentfulBasicPageFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulBasicPageFilter>>>;
  body_contains?: InputMaybe<Scalars["String"]>;
  body_exists?: InputMaybe<Scalars["Boolean"]>;
  body_not_contains?: InputMaybe<Scalars["String"]>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  headerImage_exists?: InputMaybe<Scalars["Boolean"]>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulBasicPageFilter>>>;
  publishedAt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  publishedAt_exists?: InputMaybe<Scalars["Boolean"]>;
  publishedAt_gt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  publishedAt_gte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["ContentfulDateTime"]>>>;
  publishedAt_lt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  publishedAt_lte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  publishedAt_not?: InputMaybe<Scalars["ContentfulDateTime"]>;
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["ContentfulDateTime"]>>
  >;
  slug?: InputMaybe<Scalars["String"]>;
  slug_contains?: InputMaybe<Scalars["String"]>;
  slug_exists?: InputMaybe<Scalars["Boolean"]>;
  slug_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  slug_not?: InputMaybe<Scalars["String"]>;
  slug_not_contains?: InputMaybe<Scalars["String"]>;
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export enum ContentfulCampaignOrder {
  EndAtAsc = "endAt_ASC",
  EndAtDesc = "endAt_DESC",
  HasSubscriptionAsc = "hasSubscription_ASC",
  HasSubscriptionDesc = "hasSubscription_DESC",
  MaxAgeAsc = "maxAge_ASC",
  MaxAgeDesc = "maxAge_DESC",
  MinAgeAsc = "minAge_ASC",
  MinAgeDesc = "minAge_DESC",
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  OnlyForTreatmentInExperimentAsc = "onlyForTreatmentInExperiment_ASC",
  OnlyForTreatmentInExperimentDesc = "onlyForTreatmentInExperiment_DESC",
  StartAtAsc = "startAt_ASC",
  StartAtDesc = "startAt_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
}

export enum ContentfulCampaignCountryOrder {
  CodeAsc = "code_ASC",
  CodeDesc = "code_DESC",
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
}

export enum ContentfulCampaignStateOrProvinceOrder {
  CodeAsc = "code_ASC",
  CodeDesc = "code_DESC",
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
}

export enum ContentfulCategoryLabelOrder {
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  ValueAsc = "value_ASC",
  ValueDesc = "value_DESC",
}

export type ContentfulCategoryLabelCollection = {
  __typename?: "ContentfulCategoryLabelCollection";
  items: Array<Maybe<ContentfulCategoryLabel>>;
  limit: Scalars["Int"];
  skip: Scalars["Int"];
  total: Scalars["Int"];
};

export enum ContentfulCategoryPageOrder {
  BelowTheFoldTitleAsc = "belowTheFoldTitle_ASC",
  BelowTheFoldTitleDesc = "belowTheFoldTitle_DESC",
  CategorySlugAsc = "categorySlug_ASC",
  CategorySlugDesc = "categorySlug_DESC",
  FeaturedClassesOverrideSlugAsc = "featuredClassesOverrideSlug_ASC",
  FeaturedClassesOverrideSlugDesc = "featuredClassesOverrideSlug_DESC",
  FooterCtaPathAsc = "footerCtaPath_ASC",
  FooterCtaPathDesc = "footerCtaPath_DESC",
  FooterCtaTitleAsc = "footerCtaTitle_ASC",
  FooterCtaTitleDesc = "footerCtaTitle_DESC",
  HeroTitleAsc = "heroTitle_ASC",
  HeroTitleDesc = "heroTitle_DESC",
  JumpPillsTitleAsc = "jumpPillsTitle_ASC",
  JumpPillsTitleDesc = "jumpPillsTitle_DESC",
  ShowFeaturedClassesAsc = "showFeaturedClasses_ASC",
  ShowFeaturedClassesDesc = "showFeaturedClasses_DESC",
  StartAfterAsc = "startAfter_ASC",
  StartAfterDesc = "startAfter_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
}

export type ContentfulCategoryPageFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulCategoryPageFilter>>>;
  belowTheFoldDescription_contains?: InputMaybe<Scalars["String"]>;
  belowTheFoldDescription_exists?: InputMaybe<Scalars["Boolean"]>;
  belowTheFoldDescription_not_contains?: InputMaybe<Scalars["String"]>;
  belowTheFoldTitle?: InputMaybe<Scalars["String"]>;
  belowTheFoldTitle_contains?: InputMaybe<Scalars["String"]>;
  belowTheFoldTitle_exists?: InputMaybe<Scalars["Boolean"]>;
  belowTheFoldTitle_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  belowTheFoldTitle_not?: InputMaybe<Scalars["String"]>;
  belowTheFoldTitle_not_contains?: InputMaybe<Scalars["String"]>;
  belowTheFoldTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  categorySlug?: InputMaybe<Scalars["String"]>;
  categorySlug_contains?: InputMaybe<Scalars["String"]>;
  categorySlug_exists?: InputMaybe<Scalars["Boolean"]>;
  categorySlug_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  categorySlug_not?: InputMaybe<Scalars["String"]>;
  categorySlug_not_contains?: InputMaybe<Scalars["String"]>;
  categorySlug_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  featuredClassesOverrideSlug?: InputMaybe<Scalars["String"]>;
  featuredClassesOverrideSlug_contains?: InputMaybe<Scalars["String"]>;
  featuredClassesOverrideSlug_exists?: InputMaybe<Scalars["Boolean"]>;
  featuredClassesOverrideSlug_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  featuredClassesOverrideSlug_not?: InputMaybe<Scalars["String"]>;
  featuredClassesOverrideSlug_not_contains?: InputMaybe<Scalars["String"]>;
  featuredClassesOverrideSlug_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  footerCtaPath?: InputMaybe<Scalars["String"]>;
  footerCtaPath_contains?: InputMaybe<Scalars["String"]>;
  footerCtaPath_exists?: InputMaybe<Scalars["Boolean"]>;
  footerCtaPath_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  footerCtaPath_not?: InputMaybe<Scalars["String"]>;
  footerCtaPath_not_contains?: InputMaybe<Scalars["String"]>;
  footerCtaPath_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  footerCtaTitle?: InputMaybe<Scalars["String"]>;
  footerCtaTitle_contains?: InputMaybe<Scalars["String"]>;
  footerCtaTitle_exists?: InputMaybe<Scalars["Boolean"]>;
  footerCtaTitle_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  footerCtaTitle_not?: InputMaybe<Scalars["String"]>;
  footerCtaTitle_not_contains?: InputMaybe<Scalars["String"]>;
  footerCtaTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  heroDescription_contains?: InputMaybe<Scalars["String"]>;
  heroDescription_exists?: InputMaybe<Scalars["Boolean"]>;
  heroDescription_not_contains?: InputMaybe<Scalars["String"]>;
  heroImage_exists?: InputMaybe<Scalars["Boolean"]>;
  heroTitle?: InputMaybe<Scalars["String"]>;
  heroTitle_contains?: InputMaybe<Scalars["String"]>;
  heroTitle_exists?: InputMaybe<Scalars["Boolean"]>;
  heroTitle_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  heroTitle_not?: InputMaybe<Scalars["String"]>;
  heroTitle_not_contains?: InputMaybe<Scalars["String"]>;
  heroTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  heroValueProps_contains?: InputMaybe<Scalars["String"]>;
  heroValueProps_exists?: InputMaybe<Scalars["Boolean"]>;
  heroValueProps_not_contains?: InputMaybe<Scalars["String"]>;
  jumpPillLinks?: InputMaybe<ContentfulcfVisualLinkNestedFilter>;
  jumpPillLinksCollection_exists?: InputMaybe<Scalars["Boolean"]>;
  jumpPills?: InputMaybe<ContentfulcfJumpPillsNestedFilter>;
  jumpPillsCollection_exists?: InputMaybe<Scalars["Boolean"]>;
  jumpPillsTitle?: InputMaybe<Scalars["String"]>;
  jumpPillsTitle_contains?: InputMaybe<Scalars["String"]>;
  jumpPillsTitle_exists?: InputMaybe<Scalars["Boolean"]>;
  jumpPillsTitle_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  jumpPillsTitle_not?: InputMaybe<Scalars["String"]>;
  jumpPillsTitle_not_contains?: InputMaybe<Scalars["String"]>;
  jumpPillsTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  magnetPages?: InputMaybe<ContentfulcfMagnetPageNestedFilter>;
  magnetPagesCollection_exists?: InputMaybe<Scalars["Boolean"]>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulCategoryPageFilter>>>;
  relatedCategories?: InputMaybe<ContentfulcfUpsellClassCardNestedFilter>;
  relatedCategoriesCollection_exists?: InputMaybe<Scalars["Boolean"]>;
  showFeaturedClasses?: InputMaybe<Scalars["Boolean"]>;
  showFeaturedClasses_exists?: InputMaybe<Scalars["Boolean"]>;
  showFeaturedClasses_not?: InputMaybe<Scalars["Boolean"]>;
  startAfter?: InputMaybe<Scalars["String"]>;
  startAfter_contains?: InputMaybe<Scalars["String"]>;
  startAfter_exists?: InputMaybe<Scalars["Boolean"]>;
  startAfter_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  startAfter_not?: InputMaybe<Scalars["String"]>;
  startAfter_not_contains?: InputMaybe<Scalars["String"]>;
  startAfter_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
};

export type ContentfulcfJumpPillsNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulcfJumpPillsNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  name?: InputMaybe<Scalars["String"]>;
  name_contains?: InputMaybe<Scalars["String"]>;
  name_exists?: InputMaybe<Scalars["Boolean"]>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  name_not?: InputMaybe<Scalars["String"]>;
  name_not_contains?: InputMaybe<Scalars["String"]>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulcfJumpPillsNestedFilter>>>;
  path?: InputMaybe<Scalars["String"]>;
  path_contains?: InputMaybe<Scalars["String"]>;
  path_exists?: InputMaybe<Scalars["Boolean"]>;
  path_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  path_not?: InputMaybe<Scalars["String"]>;
  path_not_contains?: InputMaybe<Scalars["String"]>;
  path_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
};

export type ContentfulcfMagnetPageNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulcfMagnetPageNestedFilter>>>;
  author_exists?: InputMaybe<Scalars["Boolean"]>;
  body_contains?: InputMaybe<Scalars["String"]>;
  body_exists?: InputMaybe<Scalars["Boolean"]>;
  body_not_contains?: InputMaybe<Scalars["String"]>;
  categoryLabel_exists?: InputMaybe<Scalars["Boolean"]>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  headerImage_exists?: InputMaybe<Scalars["Boolean"]>;
  magnetPageSlug?: InputMaybe<Scalars["String"]>;
  magnetPageSlug_contains?: InputMaybe<Scalars["String"]>;
  magnetPageSlug_exists?: InputMaybe<Scalars["Boolean"]>;
  magnetPageSlug_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  magnetPageSlug_not?: InputMaybe<Scalars["String"]>;
  magnetPageSlug_not_contains?: InputMaybe<Scalars["String"]>;
  magnetPageSlug_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulcfMagnetPageNestedFilter>>>;
  summary_contains?: InputMaybe<Scalars["String"]>;
  summary_exists?: InputMaybe<Scalars["Boolean"]>;
  summary_not_contains?: InputMaybe<Scalars["String"]>;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  upsellClassCarousel_exists?: InputMaybe<Scalars["Boolean"]>;
};

export enum ContentfulClpEmailCaptureOrder {
  CallToActionTextAsc = "callToActionText_ASC",
  CallToActionTextDesc = "callToActionText_DESC",
  ErrorMessageAsc = "errorMessage_ASC",
  ErrorMessageDesc = "errorMessage_DESC",
  IterableLinkAsc = "iterableLink_ASC",
  IterableLinkDesc = "iterableLink_DESC",
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  ProdIterableAudienceIdAsc = "prodIterableAudienceId_ASC",
  ProdIterableAudienceIdDesc = "prodIterableAudienceId_DESC",
  StagingIterableAudienceIdAsc = "stagingIterableAudienceId_ASC",
  StagingIterableAudienceIdDesc = "stagingIterableAudienceId_DESC",
  SuccessMessageAsc = "successMessage_ASC",
  SuccessMessageDesc = "successMessage_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export type ContentfulClpEmailCaptureFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulClpEmailCaptureFilter>>>;
  bodyContent_contains?: InputMaybe<Scalars["String"]>;
  bodyContent_exists?: InputMaybe<Scalars["Boolean"]>;
  bodyContent_not_contains?: InputMaybe<Scalars["String"]>;
  callToActionText?: InputMaybe<Scalars["String"]>;
  callToActionText_contains?: InputMaybe<Scalars["String"]>;
  callToActionText_exists?: InputMaybe<Scalars["Boolean"]>;
  callToActionText_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  callToActionText_not?: InputMaybe<Scalars["String"]>;
  callToActionText_not_contains?: InputMaybe<Scalars["String"]>;
  callToActionText_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  clpPages?: InputMaybe<ContentfulcfCategoryLabelNestedFilter>;
  clpPagesCollection_exists?: InputMaybe<Scalars["Boolean"]>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  errorMessage?: InputMaybe<Scalars["String"]>;
  errorMessage_contains?: InputMaybe<Scalars["String"]>;
  errorMessage_exists?: InputMaybe<Scalars["Boolean"]>;
  errorMessage_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  errorMessage_not?: InputMaybe<Scalars["String"]>;
  errorMessage_not_contains?: InputMaybe<Scalars["String"]>;
  errorMessage_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  iterableLink?: InputMaybe<Scalars["String"]>;
  iterableLink_contains?: InputMaybe<Scalars["String"]>;
  iterableLink_exists?: InputMaybe<Scalars["Boolean"]>;
  iterableLink_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  iterableLink_not?: InputMaybe<Scalars["String"]>;
  iterableLink_not_contains?: InputMaybe<Scalars["String"]>;
  iterableLink_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  name?: InputMaybe<Scalars["String"]>;
  name_contains?: InputMaybe<Scalars["String"]>;
  name_exists?: InputMaybe<Scalars["Boolean"]>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  name_not?: InputMaybe<Scalars["String"]>;
  name_not_contains?: InputMaybe<Scalars["String"]>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulClpEmailCaptureFilter>>>;
  prodIterableAudienceId?: InputMaybe<Scalars["String"]>;
  prodIterableAudienceId_contains?: InputMaybe<Scalars["String"]>;
  prodIterableAudienceId_exists?: InputMaybe<Scalars["Boolean"]>;
  prodIterableAudienceId_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  prodIterableAudienceId_not?: InputMaybe<Scalars["String"]>;
  prodIterableAudienceId_not_contains?: InputMaybe<Scalars["String"]>;
  prodIterableAudienceId_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  stagingIterableAudienceId?: InputMaybe<Scalars["String"]>;
  stagingIterableAudienceId_contains?: InputMaybe<Scalars["String"]>;
  stagingIterableAudienceId_exists?: InputMaybe<Scalars["Boolean"]>;
  stagingIterableAudienceId_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  stagingIterableAudienceId_not?: InputMaybe<Scalars["String"]>;
  stagingIterableAudienceId_not_contains?: InputMaybe<Scalars["String"]>;
  stagingIterableAudienceId_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  successMessage?: InputMaybe<Scalars["String"]>;
  successMessage_contains?: InputMaybe<Scalars["String"]>;
  successMessage_exists?: InputMaybe<Scalars["Boolean"]>;
  successMessage_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  successMessage_not?: InputMaybe<Scalars["String"]>;
  successMessage_not_contains?: InputMaybe<Scalars["String"]>;
  successMessage_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export enum ContentfulEntryOrder {
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
}

export type ContentfulEntryFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulEntryFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulEntryFilter>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
};

export enum ContentfulFeaturedPagesOrder {
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
}

export type ContentfulFeaturedPagesFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulFeaturedPagesFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  id?: InputMaybe<Scalars["String"]>;
  id_contains?: InputMaybe<Scalars["String"]>;
  id_exists?: InputMaybe<Scalars["Boolean"]>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  id_not?: InputMaybe<Scalars["String"]>;
  id_not_contains?: InputMaybe<Scalars["String"]>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulFeaturedPagesFilter>>>;
  pages?: InputMaybe<ContentfulcfMagnetPageNestedFilter>;
  pagesCollection_exists?: InputMaybe<Scalars["Boolean"]>;
  sys?: InputMaybe<ContentfulSysFilter>;
};

export enum ContentfulHeroSlideOrder {
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export enum ContentfulHomePage2Order {
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export type ContentfulHomePage2Filter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulHomePage2Filter>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  heroSlides?: InputMaybe<ContentfulcfHeroSlideNestedFilter>;
  heroSlidesCollection_exists?: InputMaybe<Scalars["Boolean"]>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulHomePage2Filter>>>;
  popularSearchLinks?: InputMaybe<ContentfulcfVisualLinkNestedFilter>;
  popularSearchLinksCollection_exists?: InputMaybe<Scalars["Boolean"]>;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  valueProps?: InputMaybe<ContentfulcfValuePropNestedFilter>;
  valuePropsCollection_exists?: InputMaybe<Scalars["Boolean"]>;
  visualLinkGrids?: InputMaybe<ContentfulcfVisualLinkGridNestedFilter>;
  visualLinkGridsCollection_exists?: InputMaybe<Scalars["Boolean"]>;
};

export type ContentfulcfHeroSlideNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulcfHeroSlideNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  image_exists?: InputMaybe<Scalars["Boolean"]>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulcfHeroSlideNestedFilter>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type ContentfulcfValuePropNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulcfValuePropNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  icon_exists?: InputMaybe<Scalars["Boolean"]>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulcfValuePropNestedFilter>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type ContentfulcfVisualLinkGridNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulcfVisualLinkGridNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulcfVisualLinkGridNestedFilter>>>;
  style?: InputMaybe<Scalars["String"]>;
  style_contains?: InputMaybe<Scalars["String"]>;
  style_exists?: InputMaybe<Scalars["Boolean"]>;
  style_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  style_not?: InputMaybe<Scalars["String"]>;
  style_not_contains?: InputMaybe<Scalars["String"]>;
  style_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  visualLinksCollection_exists?: InputMaybe<Scalars["Boolean"]>;
};

export enum ContentfulJumpPillsOrder {
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  PathAsc = "path_ASC",
  PathDesc = "path_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
}

export type ContentfulJumpPillsCollection = {
  __typename?: "ContentfulJumpPillsCollection";
  items: Array<Maybe<ContentfulJumpPills>>;
  limit: Scalars["Int"];
  skip: Scalars["Int"];
  total: Scalars["Int"];
};

export enum ContentfulLandingPageClassCarouselOrder {
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export type ContentfulLandingPageClassCarouselFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulLandingPageClassCarouselFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  id?: InputMaybe<Scalars["String"]>;
  id_contains?: InputMaybe<Scalars["String"]>;
  id_exists?: InputMaybe<Scalars["Boolean"]>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  id_not?: InputMaybe<Scalars["String"]>;
  id_not_contains?: InputMaybe<Scalars["String"]>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulLandingPageClassCarouselFilter>>>;
  searchFilters?: InputMaybe<ContentfulcfSearchFiltersNestedFilter>;
  searchFilters_exists?: InputMaybe<Scalars["Boolean"]>;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export enum ContentfulLandingPageOrder {
  MetaTagCanonicalUrlAsc = "metaTagCanonicalUrl_ASC",
  MetaTagCanonicalUrlDesc = "metaTagCanonicalUrl_DESC",
  MetaTagDescriptionAsc = "metaTagDescription_ASC",
  MetaTagDescriptionDesc = "metaTagDescription_DESC",
  NavbarCtaTextAsc = "navbarCtaText_ASC",
  NavbarCtaTextDesc = "navbarCtaText_DESC",
  NavbarCtaUrlAsc = "navbarCtaUrl_ASC",
  NavbarCtaUrlDesc = "navbarCtaUrl_DESC",
  NavbarCtaUrlLoggedInAsc = "navbarCtaUrlLoggedIn_ASC",
  NavbarCtaUrlLoggedInDesc = "navbarCtaUrlLoggedIn_DESC",
  SlugAsc = "slug_ASC",
  SlugDesc = "slug_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export type ContentfulLandingPageFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulLandingPageFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  metaTagCanonicalUrl?: InputMaybe<Scalars["String"]>;
  metaTagCanonicalUrl_contains?: InputMaybe<Scalars["String"]>;
  metaTagCanonicalUrl_exists?: InputMaybe<Scalars["Boolean"]>;
  metaTagCanonicalUrl_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  metaTagCanonicalUrl_not?: InputMaybe<Scalars["String"]>;
  metaTagCanonicalUrl_not_contains?: InputMaybe<Scalars["String"]>;
  metaTagCanonicalUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  metaTagDescription?: InputMaybe<Scalars["String"]>;
  metaTagDescription_contains?: InputMaybe<Scalars["String"]>;
  metaTagDescription_exists?: InputMaybe<Scalars["Boolean"]>;
  metaTagDescription_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  metaTagDescription_not?: InputMaybe<Scalars["String"]>;
  metaTagDescription_not_contains?: InputMaybe<Scalars["String"]>;
  metaTagDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  metaTagSharedLinkRichPreviewImage_exists?: InputMaybe<Scalars["Boolean"]>;
  navbarCtaText?: InputMaybe<Scalars["String"]>;
  navbarCtaText_contains?: InputMaybe<Scalars["String"]>;
  navbarCtaText_exists?: InputMaybe<Scalars["Boolean"]>;
  navbarCtaText_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  navbarCtaText_not?: InputMaybe<Scalars["String"]>;
  navbarCtaText_not_contains?: InputMaybe<Scalars["String"]>;
  navbarCtaText_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  navbarCtaUrl?: InputMaybe<Scalars["String"]>;
  navbarCtaUrl_contains?: InputMaybe<Scalars["String"]>;
  navbarCtaUrl_exists?: InputMaybe<Scalars["Boolean"]>;
  navbarCtaUrl_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  navbarCtaUrl_not?: InputMaybe<Scalars["String"]>;
  navbarCtaUrl_not_contains?: InputMaybe<Scalars["String"]>;
  navbarCtaUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  navbarCtaUrlLoggedIn?: InputMaybe<Scalars["String"]>;
  navbarCtaUrlLoggedIn_contains?: InputMaybe<Scalars["String"]>;
  navbarCtaUrlLoggedIn_exists?: InputMaybe<Scalars["Boolean"]>;
  navbarCtaUrlLoggedIn_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  navbarCtaUrlLoggedIn_not?: InputMaybe<Scalars["String"]>;
  navbarCtaUrlLoggedIn_not_contains?: InputMaybe<Scalars["String"]>;
  navbarCtaUrlLoggedIn_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  OR?: InputMaybe<Array<InputMaybe<ContentfulLandingPageFilter>>>;
  sections?: InputMaybe<ContentfulcfsectionsMultiTypeNestedFilter>;
  sectionsCollection_exists?: InputMaybe<Scalars["Boolean"]>;
  slug?: InputMaybe<Scalars["String"]>;
  slug_contains?: InputMaybe<Scalars["String"]>;
  slug_exists?: InputMaybe<Scalars["Boolean"]>;
  slug_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  slug_not?: InputMaybe<Scalars["String"]>;
  slug_not_contains?: InputMaybe<Scalars["String"]>;
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type ContentfulcfsectionsMultiTypeNestedFilter = {
  AND?: InputMaybe<
    Array<InputMaybe<ContentfulcfsectionsMultiTypeNestedFilter>>
  >;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulcfsectionsMultiTypeNestedFilter>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
};

export enum ContentfulLandingPageHeroSectionOrder {
  AnimationUrlAsc = "animationUrl_ASC",
  AnimationUrlDesc = "animationUrl_DESC",
  ButtonCtaTextAsc = "buttonCtaText_ASC",
  ButtonCtaTextDesc = "buttonCtaText_DESC",
  ButtonUrlAsc = "buttonUrl_ASC",
  ButtonUrlDesc = "buttonUrl_DESC",
  ButtonUrlLoggedInAsc = "buttonUrlLoggedIn_ASC",
  ButtonUrlLoggedInDesc = "buttonUrlLoggedIn_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  ShouldShowCtaAsc = "shouldShowCta_ASC",
  ShouldShowCtaDesc = "shouldShowCta_DESC",
  ShouldShowTrustPilotAsc = "shouldShowTrustPilot_ASC",
  ShouldShowTrustPilotDesc = "shouldShowTrustPilot_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
}

export type ContentfulLandingPageHeroSectionFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulLandingPageHeroSectionFilter>>>;
  animationCssDesktop_exists?: InputMaybe<Scalars["Boolean"]>;
  animationCssMobile_exists?: InputMaybe<Scalars["Boolean"]>;
  animationUrl?: InputMaybe<Scalars["String"]>;
  animationUrl_contains?: InputMaybe<Scalars["String"]>;
  animationUrl_exists?: InputMaybe<Scalars["Boolean"]>;
  animationUrl_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  animationUrl_not?: InputMaybe<Scalars["String"]>;
  animationUrl_not_contains?: InputMaybe<Scalars["String"]>;
  animationUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  backgroundImage_exists?: InputMaybe<Scalars["Boolean"]>;
  buttonCtaText?: InputMaybe<Scalars["String"]>;
  buttonCtaText_contains?: InputMaybe<Scalars["String"]>;
  buttonCtaText_exists?: InputMaybe<Scalars["Boolean"]>;
  buttonCtaText_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  buttonCtaText_not?: InputMaybe<Scalars["String"]>;
  buttonCtaText_not_contains?: InputMaybe<Scalars["String"]>;
  buttonCtaText_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  buttonUrl?: InputMaybe<Scalars["String"]>;
  buttonUrl_contains?: InputMaybe<Scalars["String"]>;
  buttonUrl_exists?: InputMaybe<Scalars["Boolean"]>;
  buttonUrl_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  buttonUrl_not?: InputMaybe<Scalars["String"]>;
  buttonUrl_not_contains?: InputMaybe<Scalars["String"]>;
  buttonUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  buttonUrlLoggedIn?: InputMaybe<Scalars["String"]>;
  buttonUrlLoggedIn_contains?: InputMaybe<Scalars["String"]>;
  buttonUrlLoggedIn_exists?: InputMaybe<Scalars["Boolean"]>;
  buttonUrlLoggedIn_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  buttonUrlLoggedIn_not?: InputMaybe<Scalars["String"]>;
  buttonUrlLoggedIn_not_contains?: InputMaybe<Scalars["String"]>;
  buttonUrlLoggedIn_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  ID?: InputMaybe<Scalars["String"]>;
  ID_contains?: InputMaybe<Scalars["String"]>;
  ID_exists?: InputMaybe<Scalars["Boolean"]>;
  ID_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  ID_not?: InputMaybe<Scalars["String"]>;
  ID_not_contains?: InputMaybe<Scalars["String"]>;
  ID_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  mainImage_exists?: InputMaybe<Scalars["Boolean"]>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulLandingPageHeroSectionFilter>>>;
  shouldShowCta?: InputMaybe<Scalars["Boolean"]>;
  shouldShowCta_exists?: InputMaybe<Scalars["Boolean"]>;
  shouldShowCta_not?: InputMaybe<Scalars["Boolean"]>;
  shouldShowTrustPilot?: InputMaybe<Scalars["Boolean"]>;
  shouldShowTrustPilot_exists?: InputMaybe<Scalars["Boolean"]>;
  shouldShowTrustPilot_not?: InputMaybe<Scalars["Boolean"]>;
  subtitle?: InputMaybe<Scalars["String"]>;
  subtitle_contains?: InputMaybe<Scalars["String"]>;
  subtitle_exists?: InputMaybe<Scalars["Boolean"]>;
  subtitle_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  subtitle_not?: InputMaybe<Scalars["String"]>;
  subtitle_not_contains?: InputMaybe<Scalars["String"]>;
  subtitle_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export enum ContentfulLandingPageIconInfoOrder {
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  SubtitleAsc = "subtitle_ASC",
  SubtitleDesc = "subtitle_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
}

export enum ContentfulLandingPageIconInfoSectionOrder {
  DisplayOnMobileAsc = "displayOnMobile_ASC",
  DisplayOnMobileDesc = "displayOnMobile_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
}

export type ContentfulLandingPageIconInfoSectionFilter = {
  AND?: InputMaybe<
    Array<InputMaybe<ContentfulLandingPageIconInfoSectionFilter>>
  >;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  displayOnMobile?: InputMaybe<Scalars["Boolean"]>;
  displayOnMobile_exists?: InputMaybe<Scalars["Boolean"]>;
  displayOnMobile_not?: InputMaybe<Scalars["Boolean"]>;
  iconInfos?: InputMaybe<ContentfulcfLandingPageIconInfoNestedFilter>;
  iconInfosCollection_exists?: InputMaybe<Scalars["Boolean"]>;
  id?: InputMaybe<Scalars["String"]>;
  id_contains?: InputMaybe<Scalars["String"]>;
  id_exists?: InputMaybe<Scalars["Boolean"]>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  id_not?: InputMaybe<Scalars["String"]>;
  id_not_contains?: InputMaybe<Scalars["String"]>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<
    Array<InputMaybe<ContentfulLandingPageIconInfoSectionFilter>>
  >;
  sys?: InputMaybe<ContentfulSysFilter>;
};

export type ContentfulcfLandingPageIconInfoNestedFilter = {
  AND?: InputMaybe<
    Array<InputMaybe<ContentfulcfLandingPageIconInfoNestedFilter>>
  >;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  iconImage_exists?: InputMaybe<Scalars["Boolean"]>;
  id?: InputMaybe<Scalars["String"]>;
  id_contains?: InputMaybe<Scalars["String"]>;
  id_exists?: InputMaybe<Scalars["Boolean"]>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  id_not?: InputMaybe<Scalars["String"]>;
  id_not_contains?: InputMaybe<Scalars["String"]>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<
    Array<InputMaybe<ContentfulcfLandingPageIconInfoNestedFilter>>
  >;
  subtitle?: InputMaybe<Scalars["String"]>;
  subtitle_contains?: InputMaybe<Scalars["String"]>;
  subtitle_exists?: InputMaybe<Scalars["Boolean"]>;
  subtitle_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  subtitle_not?: InputMaybe<Scalars["String"]>;
  subtitle_not_contains?: InputMaybe<Scalars["String"]>;
  subtitle_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
};

export enum ContentfulLandingPageImageTextCtaSectionOrder {
  ButtonCtaTextAsc = "buttonCtaText_ASC",
  ButtonCtaTextDesc = "buttonCtaText_DESC",
  ButtonUrlAsc = "buttonUrl_ASC",
  ButtonUrlDesc = "buttonUrl_DESC",
  ButtonUrlLoggedInAsc = "buttonUrlLoggedIn_ASC",
  ButtonUrlLoggedInDesc = "buttonUrlLoggedIn_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleEndColorAsc = "titleEndColor_ASC",
  TitleEndColorDesc = "titleEndColor_DESC",
  TitleEndTextAsc = "titleEndText_ASC",
  TitleEndTextDesc = "titleEndText_DESC",
  TitleStartColorAsc = "titleStartColor_ASC",
  TitleStartColorDesc = "titleStartColor_DESC",
  TitleStartTextAsc = "titleStartText_ASC",
  TitleStartTextDesc = "titleStartText_DESC",
}

export type ContentfulLandingPageImageTextCtaSectionFilter = {
  AND?: InputMaybe<
    Array<InputMaybe<ContentfulLandingPageImageTextCtaSectionFilter>>
  >;
  buttonCtaText?: InputMaybe<Scalars["String"]>;
  buttonCtaText_contains?: InputMaybe<Scalars["String"]>;
  buttonCtaText_exists?: InputMaybe<Scalars["Boolean"]>;
  buttonCtaText_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  buttonCtaText_not?: InputMaybe<Scalars["String"]>;
  buttonCtaText_not_contains?: InputMaybe<Scalars["String"]>;
  buttonCtaText_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  buttonUrl?: InputMaybe<Scalars["String"]>;
  buttonUrl_contains?: InputMaybe<Scalars["String"]>;
  buttonUrl_exists?: InputMaybe<Scalars["Boolean"]>;
  buttonUrl_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  buttonUrl_not?: InputMaybe<Scalars["String"]>;
  buttonUrl_not_contains?: InputMaybe<Scalars["String"]>;
  buttonUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  buttonUrlLoggedIn?: InputMaybe<Scalars["String"]>;
  buttonUrlLoggedIn_contains?: InputMaybe<Scalars["String"]>;
  buttonUrlLoggedIn_exists?: InputMaybe<Scalars["Boolean"]>;
  buttonUrlLoggedIn_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  buttonUrlLoggedIn_not?: InputMaybe<Scalars["String"]>;
  buttonUrlLoggedIn_not_contains?: InputMaybe<Scalars["String"]>;
  buttonUrlLoggedIn_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  id?: InputMaybe<Scalars["String"]>;
  id_contains?: InputMaybe<Scalars["String"]>;
  id_exists?: InputMaybe<Scalars["Boolean"]>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  id_not?: InputMaybe<Scalars["String"]>;
  id_not_contains?: InputMaybe<Scalars["String"]>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  image_exists?: InputMaybe<Scalars["Boolean"]>;
  OR?: InputMaybe<
    Array<InputMaybe<ContentfulLandingPageImageTextCtaSectionFilter>>
  >;
  subtitleRt_contains?: InputMaybe<Scalars["String"]>;
  subtitleRt_exists?: InputMaybe<Scalars["Boolean"]>;
  subtitleRt_not_contains?: InputMaybe<Scalars["String"]>;
  sys?: InputMaybe<ContentfulSysFilter>;
  titleEndColor?: InputMaybe<Scalars["String"]>;
  titleEndColor_contains?: InputMaybe<Scalars["String"]>;
  titleEndColor_exists?: InputMaybe<Scalars["Boolean"]>;
  titleEndColor_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  titleEndColor_not?: InputMaybe<Scalars["String"]>;
  titleEndColor_not_contains?: InputMaybe<Scalars["String"]>;
  titleEndColor_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  titleEndText?: InputMaybe<Scalars["String"]>;
  titleEndText_contains?: InputMaybe<Scalars["String"]>;
  titleEndText_exists?: InputMaybe<Scalars["Boolean"]>;
  titleEndText_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  titleEndText_not?: InputMaybe<Scalars["String"]>;
  titleEndText_not_contains?: InputMaybe<Scalars["String"]>;
  titleEndText_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  titleStartColor?: InputMaybe<Scalars["String"]>;
  titleStartColor_contains?: InputMaybe<Scalars["String"]>;
  titleStartColor_exists?: InputMaybe<Scalars["Boolean"]>;
  titleStartColor_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  titleStartColor_not?: InputMaybe<Scalars["String"]>;
  titleStartColor_not_contains?: InputMaybe<Scalars["String"]>;
  titleStartColor_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  titleStartText?: InputMaybe<Scalars["String"]>;
  titleStartText_contains?: InputMaybe<Scalars["String"]>;
  titleStartText_exists?: InputMaybe<Scalars["Boolean"]>;
  titleStartText_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  titleStartText_not?: InputMaybe<Scalars["String"]>;
  titleStartText_not_contains?: InputMaybe<Scalars["String"]>;
  titleStartText_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export enum ContentfulLandingPageJourneyItemOrder {
  AnimationUrlAsc = "animationUrl_ASC",
  AnimationUrlDesc = "animationUrl_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  NumberColorAsc = "numberColor_ASC",
  NumberColorDesc = "numberColor_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export enum ContentfulLandingPageJourneySectionOrder {
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export type ContentfulLandingPageJourneySectionFilter = {
  AND?: InputMaybe<
    Array<InputMaybe<ContentfulLandingPageJourneySectionFilter>>
  >;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  id?: InputMaybe<Scalars["String"]>;
  id_contains?: InputMaybe<Scalars["String"]>;
  id_exists?: InputMaybe<Scalars["Boolean"]>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  id_not?: InputMaybe<Scalars["String"]>;
  id_not_contains?: InputMaybe<Scalars["String"]>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  journeyItems?: InputMaybe<ContentfulcfLandingPageJourneyItemNestedFilter>;
  journeyItemsCollection_exists?: InputMaybe<Scalars["Boolean"]>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulLandingPageJourneySectionFilter>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type ContentfulcfLandingPageJourneyItemNestedFilter = {
  AND?: InputMaybe<
    Array<InputMaybe<ContentfulcfLandingPageJourneyItemNestedFilter>>
  >;
  animationUrl?: InputMaybe<Scalars["String"]>;
  animationUrl_contains?: InputMaybe<Scalars["String"]>;
  animationUrl_exists?: InputMaybe<Scalars["Boolean"]>;
  animationUrl_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  animationUrl_not?: InputMaybe<Scalars["String"]>;
  animationUrl_not_contains?: InputMaybe<Scalars["String"]>;
  animationUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  backgroundImage_exists?: InputMaybe<Scalars["Boolean"]>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  id?: InputMaybe<Scalars["String"]>;
  id_contains?: InputMaybe<Scalars["String"]>;
  id_exists?: InputMaybe<Scalars["Boolean"]>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  id_not?: InputMaybe<Scalars["String"]>;
  id_not_contains?: InputMaybe<Scalars["String"]>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  image_exists?: InputMaybe<Scalars["Boolean"]>;
  numberColor?: InputMaybe<Scalars["String"]>;
  numberColor_contains?: InputMaybe<Scalars["String"]>;
  numberColor_exists?: InputMaybe<Scalars["Boolean"]>;
  numberColor_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  numberColor_not?: InputMaybe<Scalars["String"]>;
  numberColor_not_contains?: InputMaybe<Scalars["String"]>;
  numberColor_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<
    Array<InputMaybe<ContentfulcfLandingPageJourneyItemNestedFilter>>
  >;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export enum ContentfulLandingPageLessonPreviewSectionOrder {
  CtaTextAsc = "ctaText_ASC",
  CtaTextDesc = "ctaText_DESC",
  CtaUrlAsc = "ctaUrl_ASC",
  CtaUrlDesc = "ctaUrl_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export type ContentfulLandingPageLessonPreviewSectionFilter = {
  AND?: InputMaybe<
    Array<InputMaybe<ContentfulLandingPageLessonPreviewSectionFilter>>
  >;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  ctaText?: InputMaybe<Scalars["String"]>;
  ctaText_contains?: InputMaybe<Scalars["String"]>;
  ctaText_exists?: InputMaybe<Scalars["Boolean"]>;
  ctaText_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  ctaText_not?: InputMaybe<Scalars["String"]>;
  ctaText_not_contains?: InputMaybe<Scalars["String"]>;
  ctaText_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  ctaUrl?: InputMaybe<Scalars["String"]>;
  ctaUrl_contains?: InputMaybe<Scalars["String"]>;
  ctaUrl_exists?: InputMaybe<Scalars["Boolean"]>;
  ctaUrl_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  ctaUrl_not?: InputMaybe<Scalars["String"]>;
  ctaUrl_not_contains?: InputMaybe<Scalars["String"]>;
  ctaUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  description_contains?: InputMaybe<Scalars["String"]>;
  description_exists?: InputMaybe<Scalars["Boolean"]>;
  description_not_contains?: InputMaybe<Scalars["String"]>;
  OR?: InputMaybe<
    Array<InputMaybe<ContentfulLandingPageLessonPreviewSectionFilter>>
  >;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  video_exists?: InputMaybe<Scalars["Boolean"]>;
};

export enum ContentfulLandingPageSingleReviewSectionOrder {
  ClassNameAsc = "className_ASC",
  ClassNameDesc = "className_DESC",
  ClassUrlAsc = "classUrl_ASC",
  ClassUrlDesc = "classUrl_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  ReviewerNameAsc = "reviewerName_ASC",
  ReviewerNameDesc = "reviewerName_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
}

export type ContentfulLandingPageSingleReviewSectionFilter = {
  AND?: InputMaybe<
    Array<InputMaybe<ContentfulLandingPageSingleReviewSectionFilter>>
  >;
  className?: InputMaybe<Scalars["String"]>;
  className_contains?: InputMaybe<Scalars["String"]>;
  className_exists?: InputMaybe<Scalars["Boolean"]>;
  className_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  className_not?: InputMaybe<Scalars["String"]>;
  className_not_contains?: InputMaybe<Scalars["String"]>;
  className_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  classUrl?: InputMaybe<Scalars["String"]>;
  classUrl_contains?: InputMaybe<Scalars["String"]>;
  classUrl_exists?: InputMaybe<Scalars["Boolean"]>;
  classUrl_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  classUrl_not?: InputMaybe<Scalars["String"]>;
  classUrl_not_contains?: InputMaybe<Scalars["String"]>;
  classUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  id?: InputMaybe<Scalars["String"]>;
  id_contains?: InputMaybe<Scalars["String"]>;
  id_exists?: InputMaybe<Scalars["Boolean"]>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  id_not?: InputMaybe<Scalars["String"]>;
  id_not_contains?: InputMaybe<Scalars["String"]>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  image_exists?: InputMaybe<Scalars["Boolean"]>;
  imageMobile_exists?: InputMaybe<Scalars["Boolean"]>;
  OR?: InputMaybe<
    Array<InputMaybe<ContentfulLandingPageSingleReviewSectionFilter>>
  >;
  reviewerName?: InputMaybe<Scalars["String"]>;
  reviewerName_contains?: InputMaybe<Scalars["String"]>;
  reviewerName_exists?: InputMaybe<Scalars["Boolean"]>;
  reviewerName_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  reviewerName_not?: InputMaybe<Scalars["String"]>;
  reviewerName_not_contains?: InputMaybe<Scalars["String"]>;
  reviewerName_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  reviewImageBackgroundImage_exists?: InputMaybe<Scalars["Boolean"]>;
  reviewText_contains?: InputMaybe<Scalars["String"]>;
  reviewText_exists?: InputMaybe<Scalars["Boolean"]>;
  reviewText_not_contains?: InputMaybe<Scalars["String"]>;
  reviewTextBackgroundImage_exists?: InputMaybe<Scalars["Boolean"]>;
  sys?: InputMaybe<ContentfulSysFilter>;
};

export enum ContentfulLandingPageStatBoxOrder {
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  SubtitleAsc = "subtitle_ASC",
  SubtitleDesc = "subtitle_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export enum ContentfulLandingPageStatBoxSectionOrder {
  DisplayOnMobileAsc = "displayOnMobile_ASC",
  DisplayOnMobileDesc = "displayOnMobile_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
}

export type ContentfulLandingPageStatBoxSectionFilter = {
  AND?: InputMaybe<
    Array<InputMaybe<ContentfulLandingPageStatBoxSectionFilter>>
  >;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  displayOnMobile?: InputMaybe<Scalars["Boolean"]>;
  displayOnMobile_exists?: InputMaybe<Scalars["Boolean"]>;
  displayOnMobile_not?: InputMaybe<Scalars["Boolean"]>;
  id?: InputMaybe<Scalars["String"]>;
  id_contains?: InputMaybe<Scalars["String"]>;
  id_exists?: InputMaybe<Scalars["Boolean"]>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  id_not?: InputMaybe<Scalars["String"]>;
  id_not_contains?: InputMaybe<Scalars["String"]>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulLandingPageStatBoxSectionFilter>>>;
  statBoxes?: InputMaybe<ContentfulcfLandingPageStatBoxNestedFilter>;
  statBoxesCollection_exists?: InputMaybe<Scalars["Boolean"]>;
  sys?: InputMaybe<ContentfulSysFilter>;
};

export type ContentfulcfLandingPageStatBoxNestedFilter = {
  AND?: InputMaybe<
    Array<InputMaybe<ContentfulcfLandingPageStatBoxNestedFilter>>
  >;
  backgroundImage_exists?: InputMaybe<Scalars["Boolean"]>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  hoverBackgroundImage_exists?: InputMaybe<Scalars["Boolean"]>;
  id?: InputMaybe<Scalars["String"]>;
  id_contains?: InputMaybe<Scalars["String"]>;
  id_exists?: InputMaybe<Scalars["Boolean"]>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  id_not?: InputMaybe<Scalars["String"]>;
  id_not_contains?: InputMaybe<Scalars["String"]>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<
    Array<InputMaybe<ContentfulcfLandingPageStatBoxNestedFilter>>
  >;
  subtitle?: InputMaybe<Scalars["String"]>;
  subtitle_contains?: InputMaybe<Scalars["String"]>;
  subtitle_exists?: InputMaybe<Scalars["Boolean"]>;
  subtitle_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  subtitle_not?: InputMaybe<Scalars["String"]>;
  subtitle_not_contains?: InputMaybe<Scalars["String"]>;
  subtitle_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export enum ContentfulLandingPageTextOnlyStackedCtaSectionOrder {
  ButtonCtaTextAsc = "buttonCtaText_ASC",
  ButtonCtaTextDesc = "buttonCtaText_DESC",
  ButtonUrlAsc = "buttonUrl_ASC",
  ButtonUrlDesc = "buttonUrl_DESC",
  ButtonUrlLoggedInAsc = "buttonUrlLoggedIn_ASC",
  ButtonUrlLoggedInDesc = "buttonUrlLoggedIn_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  IncludeGradientBackgroundAsc = "includeGradientBackground_ASC",
  IncludeGradientBackgroundDesc = "includeGradientBackground_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export type ContentfulLandingPageTextOnlyStackedCtaSectionFilter = {
  AND?: InputMaybe<
    Array<InputMaybe<ContentfulLandingPageTextOnlyStackedCtaSectionFilter>>
  >;
  backgroundImage_exists?: InputMaybe<Scalars["Boolean"]>;
  buttonCtaText?: InputMaybe<Scalars["String"]>;
  buttonCtaText_contains?: InputMaybe<Scalars["String"]>;
  buttonCtaText_exists?: InputMaybe<Scalars["Boolean"]>;
  buttonCtaText_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  buttonCtaText_not?: InputMaybe<Scalars["String"]>;
  buttonCtaText_not_contains?: InputMaybe<Scalars["String"]>;
  buttonCtaText_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  buttonUrl?: InputMaybe<Scalars["String"]>;
  buttonUrl_contains?: InputMaybe<Scalars["String"]>;
  buttonUrl_exists?: InputMaybe<Scalars["Boolean"]>;
  buttonUrl_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  buttonUrl_not?: InputMaybe<Scalars["String"]>;
  buttonUrl_not_contains?: InputMaybe<Scalars["String"]>;
  buttonUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  buttonUrlLoggedIn?: InputMaybe<Scalars["String"]>;
  buttonUrlLoggedIn_contains?: InputMaybe<Scalars["String"]>;
  buttonUrlLoggedIn_exists?: InputMaybe<Scalars["Boolean"]>;
  buttonUrlLoggedIn_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  buttonUrlLoggedIn_not?: InputMaybe<Scalars["String"]>;
  buttonUrlLoggedIn_not_contains?: InputMaybe<Scalars["String"]>;
  buttonUrlLoggedIn_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  id?: InputMaybe<Scalars["String"]>;
  id_contains?: InputMaybe<Scalars["String"]>;
  id_exists?: InputMaybe<Scalars["Boolean"]>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  id_not?: InputMaybe<Scalars["String"]>;
  id_not_contains?: InputMaybe<Scalars["String"]>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  includeGradientBackground?: InputMaybe<Scalars["Boolean"]>;
  includeGradientBackground_exists?: InputMaybe<Scalars["Boolean"]>;
  includeGradientBackground_not?: InputMaybe<Scalars["Boolean"]>;
  OR?: InputMaybe<
    Array<InputMaybe<ContentfulLandingPageTextOnlyStackedCtaSectionFilter>>
  >;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type ContentfulLandingPageTextOnlyStackedCtaSectionCollection = {
  __typename?: "ContentfulLandingPageTextOnlyStackedCtaSectionCollection";
  items: Array<Maybe<ContentfulLandingPageTextOnlyStackedCtaSection>>;
  limit: Scalars["Int"];
  skip: Scalars["Int"];
  total: Scalars["Int"];
};

export enum ContentfulLearnerBannerOrder {
  CtaTextAsc = "ctaText_ASC",
  CtaTextDesc = "ctaText_DESC",
  CtaUrlAsc = "ctaUrl_ASC",
  CtaUrlDesc = "ctaUrl_DESC",
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export type ContentfulLearnerBannerFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulLearnerBannerFilter>>>;
  campaign?: InputMaybe<ContentfulcfCampaignNestedFilter>;
  campaign_exists?: InputMaybe<Scalars["Boolean"]>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  ctaText?: InputMaybe<Scalars["String"]>;
  ctaText_contains?: InputMaybe<Scalars["String"]>;
  ctaText_exists?: InputMaybe<Scalars["Boolean"]>;
  ctaText_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  ctaText_not?: InputMaybe<Scalars["String"]>;
  ctaText_not_contains?: InputMaybe<Scalars["String"]>;
  ctaText_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  ctaUrl?: InputMaybe<Scalars["String"]>;
  ctaUrl_contains?: InputMaybe<Scalars["String"]>;
  ctaUrl_exists?: InputMaybe<Scalars["Boolean"]>;
  ctaUrl_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  ctaUrl_not?: InputMaybe<Scalars["String"]>;
  ctaUrl_not_contains?: InputMaybe<Scalars["String"]>;
  ctaUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  image_exists?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  name_contains?: InputMaybe<Scalars["String"]>;
  name_exists?: InputMaybe<Scalars["Boolean"]>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  name_not?: InputMaybe<Scalars["String"]>;
  name_not_contains?: InputMaybe<Scalars["String"]>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulLearnerBannerFilter>>>;
  subtitle?: InputMaybe<Scalars["String"]>;
  subtitle_contains?: InputMaybe<Scalars["String"]>;
  subtitle_exists?: InputMaybe<Scalars["Boolean"]>;
  subtitle_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  subtitle_not?: InputMaybe<Scalars["String"]>;
  subtitle_not_contains?: InputMaybe<Scalars["String"]>;
  subtitle_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export enum ContentfulListingsPromoBannerButtonOrder {
  ButtonTextAsc = "buttonText_ASC",
  ButtonTextDesc = "buttonText_DESC",
  ExploreMoreButtonTextAsc = "exploreMoreButtonText_ASC",
  ExploreMoreButtonTextDesc = "exploreMoreButtonText_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
}

export enum ContentfulListingsPromoBannerOrder {
  ExperimentVersionAsc = "experimentVersion_ASC",
  ExperimentVersionDesc = "experimentVersion_DESC",
  HeaderSubtitleAsc = "headerSubtitle_ASC",
  HeaderSubtitleDesc = "headerSubtitle_DESC",
  HeaderTitleAsc = "headerTitle_ASC",
  HeaderTitleDesc = "headerTitle_DESC",
  IncludeControlGroupAsc = "includeControlGroup_ASC",
  IncludeControlGroupDesc = "includeControlGroup_DESC",
  IsLiveOnSearchAsc = "isLiveOnSearch_ASC",
  IsLiveOnSearchDesc = "isLiveOnSearch_DESC",
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  SidebarTitleAsc = "sidebarTitle_ASC",
  SidebarTitleDesc = "sidebarTitle_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
}

export type ContentfulListingsPromoBannerFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulListingsPromoBannerFilter>>>;
  backgroundImageLeft_exists?: InputMaybe<Scalars["Boolean"]>;
  backgroundImageMobile_exists?: InputMaybe<Scalars["Boolean"]>;
  backgroundImageRight_exists?: InputMaybe<Scalars["Boolean"]>;
  buttons?: InputMaybe<ContentfulcfListingsPromoBannerButtonNestedFilter>;
  buttonsCollection_exists?: InputMaybe<Scalars["Boolean"]>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  experimentVersion?: InputMaybe<Scalars["Int"]>;
  experimentVersion_exists?: InputMaybe<Scalars["Boolean"]>;
  experimentVersion_gt?: InputMaybe<Scalars["Int"]>;
  experimentVersion_gte?: InputMaybe<Scalars["Int"]>;
  experimentVersion_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  experimentVersion_lt?: InputMaybe<Scalars["Int"]>;
  experimentVersion_lte?: InputMaybe<Scalars["Int"]>;
  experimentVersion_not?: InputMaybe<Scalars["Int"]>;
  experimentVersion_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  headerSubtitle?: InputMaybe<Scalars["String"]>;
  headerSubtitle_contains?: InputMaybe<Scalars["String"]>;
  headerSubtitle_exists?: InputMaybe<Scalars["Boolean"]>;
  headerSubtitle_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  headerSubtitle_not?: InputMaybe<Scalars["String"]>;
  headerSubtitle_not_contains?: InputMaybe<Scalars["String"]>;
  headerSubtitle_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  headerTitle?: InputMaybe<Scalars["String"]>;
  headerTitle_contains?: InputMaybe<Scalars["String"]>;
  headerTitle_exists?: InputMaybe<Scalars["Boolean"]>;
  headerTitle_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  headerTitle_not?: InputMaybe<Scalars["String"]>;
  headerTitle_not_contains?: InputMaybe<Scalars["String"]>;
  headerTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  headerTitleFontDefinition?: InputMaybe<ContentfulcfListingsPromoBannerHeaderTitleFontDefinitionNestedFilter>;
  headerTitleFontDefinition_exists?: InputMaybe<Scalars["Boolean"]>;
  includeControlGroup?: InputMaybe<Scalars["Boolean"]>;
  includeControlGroup_exists?: InputMaybe<Scalars["Boolean"]>;
  includeControlGroup_not?: InputMaybe<Scalars["Boolean"]>;
  isLiveOnSearch?: InputMaybe<Scalars["Boolean"]>;
  isLiveOnSearch_exists?: InputMaybe<Scalars["Boolean"]>;
  isLiveOnSearch_not?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  name_contains?: InputMaybe<Scalars["String"]>;
  name_exists?: InputMaybe<Scalars["Boolean"]>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  name_not?: InputMaybe<Scalars["String"]>;
  name_not_contains?: InputMaybe<Scalars["String"]>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulListingsPromoBannerFilter>>>;
  sidebarTitle?: InputMaybe<Scalars["String"]>;
  sidebarTitle_contains?: InputMaybe<Scalars["String"]>;
  sidebarTitle_exists?: InputMaybe<Scalars["Boolean"]>;
  sidebarTitle_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  sidebarTitle_not?: InputMaybe<Scalars["String"]>;
  sidebarTitle_not_contains?: InputMaybe<Scalars["String"]>;
  sidebarTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
};

export type ContentfulcfListingsPromoBannerButtonNestedFilter = {
  AND?: InputMaybe<
    Array<InputMaybe<ContentfulcfListingsPromoBannerButtonNestedFilter>>
  >;
  buttonSearchFilters_exists?: InputMaybe<Scalars["Boolean"]>;
  buttonText?: InputMaybe<Scalars["String"]>;
  buttonText_contains?: InputMaybe<Scalars["String"]>;
  buttonText_exists?: InputMaybe<Scalars["Boolean"]>;
  buttonText_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  buttonText_not?: InputMaybe<Scalars["String"]>;
  buttonText_not_contains?: InputMaybe<Scalars["String"]>;
  buttonText_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  exploreMoreButtonText?: InputMaybe<Scalars["String"]>;
  exploreMoreButtonText_contains?: InputMaybe<Scalars["String"]>;
  exploreMoreButtonText_exists?: InputMaybe<Scalars["Boolean"]>;
  exploreMoreButtonText_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  exploreMoreButtonText_not?: InputMaybe<Scalars["String"]>;
  exploreMoreButtonText_not_contains?: InputMaybe<Scalars["String"]>;
  exploreMoreButtonText_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  OR?: InputMaybe<
    Array<InputMaybe<ContentfulcfListingsPromoBannerButtonNestedFilter>>
  >;
  sys?: InputMaybe<ContentfulSysFilter>;
};

export type ContentfulcfListingsPromoBannerHeaderTitleFontDefinitionNestedFilter =
  {
    AND?: InputMaybe<
      Array<
        InputMaybe<ContentfulcfListingsPromoBannerHeaderTitleFontDefinitionNestedFilter>
      >
    >;
    contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
    fontFamily?: InputMaybe<Scalars["String"]>;
    fontFamily_contains?: InputMaybe<Scalars["String"]>;
    fontFamily_exists?: InputMaybe<Scalars["Boolean"]>;
    fontFamily_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
    fontFamily_not?: InputMaybe<Scalars["String"]>;
    fontFamily_not_contains?: InputMaybe<Scalars["String"]>;
    fontFamily_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
    fontUrl?: InputMaybe<Scalars["String"]>;
    fontUrl_contains?: InputMaybe<Scalars["String"]>;
    fontUrl_exists?: InputMaybe<Scalars["Boolean"]>;
    fontUrl_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
    fontUrl_not?: InputMaybe<Scalars["String"]>;
    fontUrl_not_contains?: InputMaybe<Scalars["String"]>;
    fontUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
    OR?: InputMaybe<
      Array<
        InputMaybe<ContentfulcfListingsPromoBannerHeaderTitleFontDefinitionNestedFilter>
      >
    >;
    sys?: InputMaybe<ContentfulSysFilter>;
  };

export enum ContentfulListingsPromoBannerHeaderTitleFontDefinitionOrder {
  FontFamilyAsc = "fontFamily_ASC",
  FontFamilyDesc = "fontFamily_DESC",
  FontUrlAsc = "fontUrl_ASC",
  FontUrlDesc = "fontUrl_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
}

export type ContentfulListingsPromoBannerHeaderTitleFontDefinitionCollection = {
  __typename?: "ContentfulListingsPromoBannerHeaderTitleFontDefinitionCollection";
  items: Array<Maybe<ContentfulListingsPromoBannerHeaderTitleFontDefinition>>;
  limit: Scalars["Int"];
  skip: Scalars["Int"];
  total: Scalars["Int"];
};

export enum ContentfulSearchFiltersOrder {
  CapacityMaxAsc = "capacityMax_ASC",
  CapacityMaxDesc = "capacityMax_DESC",
  CapacityMinAsc = "capacityMin_ASC",
  CapacityMinDesc = "capacityMin_DESC",
  DurationWeeksMaxAsc = "durationWeeksMax_ASC",
  DurationWeeksMaxDesc = "durationWeeksMax_DESC",
  DurationWeeksMinAsc = "durationWeeksMin_ASC",
  DurationWeeksMinDesc = "durationWeeksMin_DESC",
  EndByAsc = "endBy_ASC",
  EndByDesc = "endBy_DESC",
  FiltersNameAsc = "filtersName_ASC",
  FiltersNameDesc = "filtersName_DESC",
  HasFilledOutUniqueLearningNeedsAsc = "hasFilledOutUniqueLearningNeeds_ASC",
  HasFilledOutUniqueLearningNeedsDesc = "hasFilledOutUniqueLearningNeeds_DESC",
  MultiTermQueryAsc = "multiTermQuery_ASC",
  MultiTermQueryDesc = "multiTermQuery_DESC",
  PricePerCourseMaxAsc = "pricePerCourseMax_ASC",
  PricePerCourseMaxDesc = "pricePerCourseMax_DESC",
  PricePerCourseMinAsc = "pricePerCourseMin_ASC",
  PricePerCourseMinDesc = "pricePerCourseMin_DESC",
  PricePerMeetingMaxAsc = "pricePerMeetingMax_ASC",
  PricePerMeetingMaxDesc = "pricePerMeetingMax_DESC",
  PricePerMeetingMinAsc = "pricePerMeetingMin_ASC",
  PricePerMeetingMinDesc = "pricePerMeetingMin_DESC",
  QueryAsc = "query_ASC",
  QueryDesc = "query_DESC",
  StartAfterAsc = "startAfter_ASC",
  StartAfterDesc = "startAfter_DESC",
  StartBeforeAsc = "startBefore_ASC",
  StartBeforeDesc = "startBefore_DESC",
  SubjectAsc = "subject_ASC",
  SubjectDesc = "subject_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  UniqueLearningNeedsAsc = "uniqueLearningNeeds_ASC",
  UniqueLearningNeedsDesc = "uniqueLearningNeeds_DESC",
  WeeklyMeetingsMaxAsc = "weeklyMeetingsMax_ASC",
  WeeklyMeetingsMaxDesc = "weeklyMeetingsMax_DESC",
  WeeklyMeetingsMinAsc = "weeklyMeetingsMin_ASC",
  WeeklyMeetingsMinDesc = "weeklyMeetingsMin_DESC",
}

export type ContentfulSearchFiltersCollection = {
  __typename?: "ContentfulSearchFiltersCollection";
  items: Array<Maybe<ContentfulSearchFilters>>;
  limit: Scalars["Int"];
  skip: Scalars["Int"];
  total: Scalars["Int"];
};

export enum ContentfulTopicOrder {
  CategorySlugAsc = "categorySlug_ASC",
  CategorySlugDesc = "categorySlug_DESC",
  NameTitleCasedAsc = "nameTitleCased_ASC",
  NameTitleCasedDesc = "nameTitleCased_DESC",
  SearchPathAsc = "searchPath_ASC",
  SearchPathDesc = "searchPath_DESC",
  SlugAsc = "slug_ASC",
  SlugDesc = "slug_DESC",
  SubjectAsc = "subject_ASC",
  SubjectDesc = "subject_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
}

export type ContentfulTopicCollection = {
  __typename?: "ContentfulTopicCollection";
  items: Array<Maybe<ContentfulTopic>>;
  limit: Scalars["Int"];
  skip: Scalars["Int"];
  total: Scalars["Int"];
};

export enum ContentfulTutoringPageOrder {
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export type ContentfulTutoringPageFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulTutoringPageFilter>>>;
  articles?: InputMaybe<ContentfulcfMagnetPageNestedFilter>;
  articlesCollection_exists?: InputMaybe<Scalars["Boolean"]>;
  carouselSubcategories?: InputMaybe<ContentfulcfTopicNestedFilter>;
  carouselSubcategoriesCollection_exists?: InputMaybe<Scalars["Boolean"]>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulTutoringPageFilter>>>;
  popularSearchLinks?: InputMaybe<ContentfulcfVisualLinkNestedFilter>;
  popularSearchLinksCollection_exists?: InputMaybe<Scalars["Boolean"]>;
  popularTutoringSubjects?: InputMaybe<ContentfulcfVisualLinkNestedFilter>;
  popularTutoringSubjectsCollection_exists?: InputMaybe<Scalars["Boolean"]>;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  tutoringCarousel?: InputMaybe<ContentfulcfVisualLinkGridNestedFilter>;
  tutoringCarouselCollection_exists?: InputMaybe<Scalars["Boolean"]>;
  visualLinkGrids?: InputMaybe<ContentfulcfVisualLinkGridNestedFilter>;
  visualLinkGridsCollection_exists?: InputMaybe<Scalars["Boolean"]>;
};

export type ContentfulcfTopicNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulcfTopicNestedFilter>>>;
  categorySlug?: InputMaybe<Scalars["String"]>;
  categorySlug_contains?: InputMaybe<Scalars["String"]>;
  categorySlug_exists?: InputMaybe<Scalars["Boolean"]>;
  categorySlug_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  categorySlug_not?: InputMaybe<Scalars["String"]>;
  categorySlug_not_contains?: InputMaybe<Scalars["String"]>;
  categorySlug_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  nameTitleCased?: InputMaybe<Scalars["String"]>;
  nameTitleCased_contains?: InputMaybe<Scalars["String"]>;
  nameTitleCased_exists?: InputMaybe<Scalars["Boolean"]>;
  nameTitleCased_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  nameTitleCased_not?: InputMaybe<Scalars["String"]>;
  nameTitleCased_not_contains?: InputMaybe<Scalars["String"]>;
  nameTitleCased_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulcfTopicNestedFilter>>>;
  searchPath?: InputMaybe<Scalars["String"]>;
  searchPath_contains?: InputMaybe<Scalars["String"]>;
  searchPath_exists?: InputMaybe<Scalars["Boolean"]>;
  searchPath_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  searchPath_not?: InputMaybe<Scalars["String"]>;
  searchPath_not_contains?: InputMaybe<Scalars["String"]>;
  searchPath_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  slug?: InputMaybe<Scalars["String"]>;
  slug_contains?: InputMaybe<Scalars["String"]>;
  slug_exists?: InputMaybe<Scalars["Boolean"]>;
  slug_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  slug_not?: InputMaybe<Scalars["String"]>;
  slug_not_contains?: InputMaybe<Scalars["String"]>;
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  subject?: InputMaybe<Scalars["String"]>;
  subject_contains?: InputMaybe<Scalars["String"]>;
  subject_exists?: InputMaybe<Scalars["Boolean"]>;
  subject_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  subject_not?: InputMaybe<Scalars["String"]>;
  subject_not_contains?: InputMaybe<Scalars["String"]>;
  subject_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
};

export enum ContentfulUpsellClassCardOrder {
  CallToActionTextAsc = "callToActionText_ASC",
  CallToActionTextDesc = "callToActionText_DESC",
  CallToActionUrlAsc = "callToActionUrl_ASC",
  CallToActionUrlDesc = "callToActionUrl_DESC",
  CapacityMaxAsc = "capacityMax_ASC",
  CapacityMaxDesc = "capacityMax_DESC",
  CapacityMinAsc = "capacityMin_ASC",
  CapacityMinDesc = "capacityMin_DESC",
  HasFilledOutUniqueLearningNeedsAsc = "hasFilledOutUniqueLearningNeeds_ASC",
  HasFilledOutUniqueLearningNeedsDesc = "hasFilledOutUniqueLearningNeeds_DESC",
  MaxPriceCentsAsc = "maxPriceCents_ASC",
  MaxPriceCentsDesc = "maxPriceCents_DESC",
  MinPriceCentsAsc = "minPriceCents_ASC",
  MinPriceCentsDesc = "minPriceCents_DESC",
  MultiTermQueryAsc = "multiTermQuery_ASC",
  MultiTermQueryDesc = "multiTermQuery_DESC",
  QueryAsc = "query_ASC",
  QueryDesc = "query_DESC",
  SubtitleAsc = "subtitle_ASC",
  SubtitleDesc = "subtitle_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
  UniqueLearningNeedsAsc = "uniqueLearningNeeds_ASC",
  UniqueLearningNeedsDesc = "uniqueLearningNeeds_DESC",
  UpsellNameAsc = "upsellName_ASC",
  UpsellNameDesc = "upsellName_DESC",
}

export type ContentfulUpsellClassCardCollection = {
  __typename?: "ContentfulUpsellClassCardCollection";
  items: Array<Maybe<ContentfulUpsellClassCard>>;
  limit: Scalars["Int"];
  skip: Scalars["Int"];
  total: Scalars["Int"];
};

export enum ContentfulValuePropOrder {
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export enum ContentfulVisualLinkOrder {
  ImageAltTextAsc = "imageAltText_ASC",
  ImageAltTextDesc = "imageAltText_DESC",
  ImageAspectRatioAsc = "imageAspectRatio_ASC",
  ImageAspectRatioDesc = "imageAspectRatio_DESC",
  PathAsc = "path_ASC",
  PathDesc = "path_DESC",
  PathBAsc = "pathB_ASC",
  PathBDesc = "pathB_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TextAsc = "text_ASC",
  TextDesc = "text_DESC",
}

export enum ContentfulVisualLinkGridOrder {
  StyleAsc = "style_ASC",
  StyleDesc = "style_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export type CouponCodeListFilter = {
  code?: InputMaybe<Scalars["String"]>;
  couponType: CouponType;
  couponUid?: InputMaybe<Scalars["String"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  status?: InputMaybe<CouponListFilterStatus>;
};

export enum CouponListFilterStatus {
  Active = "Active",
  All = "All",
  Inactive = "Inactive",
}

export type CouponCodeList = {
  __typename?: "CouponCodeList";
  data: Array<Maybe<CouponCode>>;
  limit: Maybe<Scalars["Int"]>;
  offset: Maybe<Scalars["Int"]>;
  totalCount: Scalars["Int"];
};

export type CouponCodeRedemptionDetailsList = {
  __typename?: "CouponCodeRedemptionDetailsList";
  data: Array<CouponCodeRedemptionDetails>;
  limit: Maybe<Scalars["Int"]>;
  offset: Maybe<Scalars["Int"]>;
  totalCount: Maybe<Scalars["Int"]>;
};

export type CouponCodeRedemptionDetails = {
  __typename?: "CouponCodeRedemptionDetails";
  enrollmentPayments: Array<EnrollmentPayment>;
  enrollments: Array<Enrollment>;
  orderSlugId: Maybe<Scalars["String"]>;
};

export type CouponCodeRedemptionList = {
  __typename?: "CouponCodeRedemptionList";
  data: Array<Maybe<Array<Maybe<Enrollment>>>>;
  limit: Maybe<Scalars["Int"]>;
  offset: Maybe<Scalars["Int"]>;
  totalCount: Maybe<Scalars["Int"]>;
};

export type CouponListFilter = {
  couponType: CouponType;
  createdBy?: InputMaybe<Scalars["ID"]>;
  name?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<CouponListFilterStatus>;
};

export type CouponList = {
  __typename?: "CouponList";
  data: Array<Maybe<Coupon>>;
  totalCount: Scalars["Int"];
};

export type CurrentLearner = {
  __typename?: "CurrentLearner";
  about_me: Maybe<Scalars["String"]>;
  accessLevel: LearnerAccessLevel;
  age: Maybe<Scalars["Int"]>;
  avatar: Maybe<Scalars["String"]>;
  email: Maybe<Scalars["String"]>;
  enrollmentToReviewSoon: Maybe<Enrollment>;
  experiments: Array<LearnerExperiment>;
  hasUpcomingScheduleEvent: Scalars["Boolean"];
  hasWrittenClubPost: Maybe<Scalars["Boolean"]>;
  hasWrittenClubPostComment: Maybe<Scalars["Boolean"]>;
  interfacePreferences: Array<LearnerInterfacePreference>;
  intro: Maybe<Scalars["String"]>;
  isLoginEnabled: Scalars["Boolean"];
  isVerified: Scalars["Boolean"];
  name: Maybe<Scalars["String"]>;
  optedOutOfPersonalizedRecommendationsAt: Maybe<Scalars["DateTime"]>;
  parent: Parent;
  pronoun: Maybe<Scalars["String"]>;
  savedActivities: Array<SavedActivity>;
  siblings: Array<Child>;
  timeZone: Scalars["String"];
  uid: Scalars["ID"];
  unlockedAvatars: Array<Scalars["String"]>;
  username: Maybe<Scalars["String"]>;
};

export type CurrentLearnerSavedActivitiesArgs = {
  order?: InputMaybe<Scalars["String"]>;
};

export enum LearnerAccessLevel {
  Full = "Full",
  Limited = "Limited",
  Restricted = "Restricted",
}

export type LearnerExperiment = {
  __typename?: "LearnerExperiment";
  name: Scalars["String"];
  variant: Scalars["String"];
};

/** Cross-device interface preferences that are learner-specific */
export type LearnerInterfacePreference = {
  __typename?: "LearnerInterfacePreference";
  name: Scalars["String"];
  value: Scalars["JSON"];
};

export type PaginatedPastActivities = {
  __typename?: "PaginatedPastActivities";
  activities: Array<Activity>;
  totalResults: Scalars["Int"];
};

export type PastClasses = {
  __typename?: "PastClasses";
  pastEnrollments: Array<Enrollment>;
};

export type PastClassesPastEnrollmentsArgs = {
  end?: InputMaybe<Scalars["DateTime"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  start?: InputMaybe<Scalars["DateTime"]>;
};

export type RecommendedTeachers = {
  __typename?: "RecommendedTeachers";
  recommendationType: Maybe<TeacherRecommendationType>;
  teachers: Array<Leader>;
};

export enum TeacherRecommendationType {
  CurrentLearnersFavoriteTeachers = "CurrentLearnersFavoriteTeachers",
  PopularAndCurrentLearnerFavoriteTeachers = "PopularAndCurrentLearnerFavoriteTeachers",
  PopularTeachers = "PopularTeachers",
  SimilarTeachers = "SimilarTeachers",
}

export type ScheduleEvents = {
  __typename?: "ScheduleEvents";
  events: Array<ScheduleEvent>;
};

export type ScheduleEventsEventsArgs = {
  end?: InputMaybe<Scalars["DateTime"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  start: Scalars["DateTime"];
  withPaused?: InputMaybe<Scalars["Boolean"]>;
};

export type ScheduleEvent = {
  __typename?: "ScheduleEvent";
  endTime: Scalars["DateTime"];
  enrollment: Maybe<Enrollment>;
  section: Section;
  startTime: Scalars["DateTime"];
  uid: Scalars["ID"];
};

export type EnrollmentForm = {
  __typename?: "EnrollmentForm";
  activity: Activity;
  conflictingEnrollments: Array<Enrollment>;
  currentUserEnrollments: Array<Enrollment>;
  pendingEnrollments: Array<Enrollment>;
  section: Section;
  uid: Scalars["ID"];
  waitlistSeatsCount: Scalars["Int"];
};

export type EnrollmentMeetingPurchaseForm = {
  __typename?: "EnrollmentMeetingPurchaseForm";
  activity: Activity;
  /** if is 1on1 active recurring enrollment, can pay later */
  canPayLater: Scalars["Boolean"];
  conflictingEnrollments: Array<Enrollment>;
  enrollmentsForActivityAndParent: Array<Enrollment>;
  learner: Maybe<Learner>;
  meetingRequests: Maybe<Array<MeetingRequest>>;
  orgTeacher: Maybe<Leader>;
  pendingEnrollments: Array<Enrollment>;
  uid: Scalars["ID"];
};

export type ExchangeRate = {
  __typename?: "ExchangeRate";
  currency_code: CurrencyCode;
  exchange_rate: Scalars["Float"];
  uid: Scalars["ID"];
};

export type ExperimentOverride = {
  __typename?: "ExperimentOverride";
  name: Scalars["ID"];
  variants: Array<Scalars["String"]>;
};

export type Featured = {
  __typename?: "Featured";
  aggregatedUpsell: Array<Listing>;
  backToOutschool: Array<Listing>;
  favoriteListings: Maybe<Array<Listing>>;
  interestTopics: Array<Listing>;
  learnerInterestTopicListings: Array<Listing>;
  perLearnerRecommendations: Array<Listing>;
  personalizedPopularListings: Array<Listing>;
  personalizedRecommendations: Array<Listing>;
  popularClubs: Array<Listing>;
  popularListings: Maybe<Array<Listing>>;
  /** @deprecated Deprecated experiment */
  popularListingsForAgeRange: Maybe<Array<Listing>>;
  popularTeachers: Maybe<Array<Leader>>;
  popularTutorListings: Array<Listing>;
  startingSoon: Maybe<Array<Listing>>;
  trendingTopics: Array<Listing>;
  underSomeCents: Array<Listing>;
  underTwentyDollars: Array<Listing>;
};

export type FeaturedInterestTopicsArgs = {
  learnerAge: Scalars["Int"];
  searchFilters?: InputMaybe<InterestTopicSearchFilters>;
  topicUids: Array<Scalars["ID"]>;
};

export type FeaturedLearnerInterestTopicListingsArgs = {
  learnerUid: Scalars["String"];
  searchFilters?: InputMaybe<InterestTopicSearchFilters>;
};

export type FeaturedPerLearnerRecommendationsArgs = {
  learnerUid?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  shuffle?: InputMaybe<Scalars["Boolean"]>;
};

export type FeaturedPopularListingsForAgeRangeArgs = {
  maxAge?: InputMaybe<Scalars["Int"]>;
  minAge?: InputMaybe<Scalars["Int"]>;
};

export type FeaturedPopularTeachersArgs = {
  activityFormat?: InputMaybe<ClassFormat>;
};

export type FeaturedTrendingTopicsArgs = {
  deliveryTypes?: InputMaybe<Array<DeliveryType>>;
  topics?: InputMaybe<Array<Scalars["String"]>>;
};

export type FeaturedUnderSomeCentsArgs = {
  amountCents: Scalars["Int"];
};

export type InterestTopicSearchFilters = {
  startAfter?: InputMaybe<Scalars["String"]>;
  startBefore?: InputMaybe<Scalars["String"]>;
  timeZone?: InputMaybe<Scalars["String"]>;
};

export type FeedbackForCategoryPageResult = {
  __typename?: "FeedbackForCategoryPageResult";
  hasPhotos: Maybe<Array<Maybe<PublicReview>>>;
  noPhotos: Maybe<Array<Maybe<PublicReview>>>;
};

export type PublicPricingOffer = {
  __typename?: "PublicPricingOffer";
  isTotalCapReached: Scalars["Boolean"];
  name: Scalars["String"];
  totalCapCents: Scalars["Int"];
  uid: Scalars["ID"];
  userLimitCents: Scalars["Int"];
};

export type ClassInquiry = {
  __typename?: "ClassInquiry";
  created_at: Maybe<Scalars["Date"]>;
  inquiry_text: Scalars["String"];
  status: Scalars["String"];
  uid: Scalars["ID"];
};

export type GetPayoutsCsvUrlInput = {
  leaderUserId?: InputMaybe<Scalars["String"]>;
  payoutMonth: Scalars["Int"];
  payoutYear: Scalars["Int"];
};

export type TeacherClassInquiries = {
  __typename?: "TeacherClassInquiries";
  classInquiriesWithLearnerAges: Array<Maybe<ClassInquiryWithLearnerAge>>;
  teacherHasInquiriesButHasNotRepliedToday: Scalars["Boolean"];
  teacherLastReplyMessage: Maybe<Scalars["String"]>;
  teacherRepliedCountToday: Scalars["Int"];
};

export type ClassInquiryWithLearnerAge = {
  __typename?: "ClassInquiryWithLearnerAge";
  created_at: Maybe<Scalars["Date"]>;
  inquiry_text: Scalars["String"];
  learner_age: Maybe<Scalars["Int"]>;
  status: Scalars["String"];
  uid: Scalars["ID"];
};

export type TnsIncidentsSummary = {
  __typename?: "TnsIncidentsSummary";
  accountStatus: TnsAccountStatus;
  incidents: Array<TnsIncident>;
  totalStrikes: Scalars["Int"];
  uid: Scalars["ID"];
};

export type TnsIncidentsSummaryIncidentsArgs = {
  categoryOrActionFilter?: InputMaybe<Scalars["String"]>;
};

export enum TnsAccountStatus {
  Open = "Open",
  OtherSuspended = "OtherSuspended",
  TnsSuspended = "TnsSuspended",
}

export type TnsIncident = {
  __typename?: "TnsIncident";
  action: TnsAction;
  adminName: Scalars["String"];
  category: Maybe<TnsActionCategory>;
  createdAt: Scalars["DateTime"];
  cumulativeStrikes: Scalars["Int"];
  link: Maybe<Scalars["String"]>;
  notes: Maybe<Scalars["String"]>;
  strike: Scalars["Int"];
  uid: Scalars["ID"];
};

export enum TnsAction {
  ImmediateRemoval = "ImmediateRemoval",
  RemoveStrike = "RemoveStrike",
  Strike = "Strike",
  ToWatchTrend = "ToWatchTrend",
  UndoImmediateRemoval = "UndoImmediateRemoval",
  Warning = "Warning",
}

export enum TnsActionCategory {
  ClassroomSecurityIssues = "ClassroomSecurityIssues",
  ContentManagementPii = "ContentManagementPii",
  TeacherDangerousBehavior = "TeacherDangerousBehavior",
  TeacherMisbehaviorFraud = "TeacherMisbehaviorFraud",
}

export type JoinMeeting = {
  __typename?: "JoinMeeting";
  canManage: Scalars["Boolean"];
  classRecordingUids: Array<Maybe<Scalars["String"]>>;
  enrollmentUid: Maybe<Scalars["String"]>;
  isFlexSchedule: Scalars["Boolean"];
  isSellerOrg: Maybe<Scalars["Boolean"]>;
  meetingEndTime: Maybe<Scalars["DateTime"]>;
  meetingsCount: Scalars["Int"];
  meetingStartTime: Maybe<Scalars["DateTime"]>;
  sectionStartTime: Maybe<Scalars["DateTime"]>;
  uid: Scalars["ID"];
  usersCanHaveLiveMeeting: Maybe<Scalars["Boolean"]>;
};

export type NextMeeting = {
  __typename?: "NextMeeting";
  currentUserCanManage: Scalars["Boolean"];
  shouldMarkAttendance: Scalars["Boolean"];
  uid: Scalars["ID"];
  url: Scalars["String"];
};

/**
 * Which direction to paginate in.
 * Past returns notifications between cursor and the start of time
 * Future returns notifications between cursor and the end of time
 */
export enum CursorDirection {
  Future = "Future",
  Past = "Past",
}

/** Represents a paginated list of notifications to learners */
export type LearnerNotificationConnection = {
  __typename?: "LearnerNotificationConnection";
  pageInfo: LearnerNotificationsPageInfo;
  results: Array<LearnerNotifications>;
};

/** Describes metadata about pagination for notifications */
export type LearnerNotificationsPageInfo = {
  __typename?: "LearnerNotificationsPageInfo";
  /** What direction the pagination was in */
  direction: CursorDirection;
  /** Whether or not there is another page available after the last one returned */
  hasNextPage: Scalars["Boolean"];
};

/** A union representing all possible notifications to learners */
export type LearnerNotifications =
  | AssignmentNotification
  | ClassPostNotification
  | ClubNotification
  | PrivateClassMessageNotification;

/** Represents a notification about an assignment */
export type AssignmentNotification = INotification & {
  __typename?: "AssignmentNotification";
  action: AssignmentNotificationAction;
  assignmentUid: Scalars["ID"];
  createdAt: Scalars["DateTime"];
  sectionUid: Scalars["ID"];
  sender: NotificationSender;
  status: NotificationStatus;
  uid: Scalars["ID"];
};

/** The fields which are common to all notifications */
export type INotification = {
  createdAt: Scalars["DateTime"];
  sender: NotificationSender;
  status: NotificationStatus;
  uid: Scalars["ID"];
};

/** Represents the sender of a notification which can be a learner or user */
export type NotificationSender = LearnerSender | UserSender;

/** Represents the learner that sent the notification */
export type LearnerSender = {
  __typename?: "LearnerSender";
  avatar: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  uid: Scalars["ID"];
};

/** Represents the user that sent the notification */
export type UserSender = {
  __typename?: "UserSender";
  name: Scalars["String"];
  photo: Maybe<Scalars["String"]>;
  uid: Scalars["ID"];
};

/** The status of the notification.  "Seen" is currently unused */
export enum NotificationStatus {
  New = "New",
  Read = "Read",
  Seen = "Seen",
}

/** Represents what type of class post notification happened */
export enum AssignmentNotificationAction {
  NewAssignment = "NewAssignment",
  NewAssignmentComment = "NewAssignmentComment",
}

/** Represents a notification about a class post */
export type ClassPostNotification = INotification & {
  __typename?: "ClassPostNotification";
  action: ClassPostNotificationAction;
  classPostUid: Scalars["ID"];
  createdAt: Scalars["DateTime"];
  sectionUid: Scalars["ID"];
  sender: NotificationSender;
  status: NotificationStatus;
  uid: Scalars["ID"];
};

/** Represents what type of class post notification happened */
export enum ClassPostNotificationAction {
  Mentioned = "Mentioned",
  NewClassPost = "NewClassPost",
  NewClassPostComment = "NewClassPostComment",
}

/** Represents a notification about club activity */
export type ClubNotification = INotification & {
  __typename?: "ClubNotification";
  action: ClubNotificationAction;
  classPostCommentUid: Maybe<Scalars["ID"]>;
  classPostUid: Scalars["ID"];
  createdAt: Scalars["DateTime"];
  sectionUid: Scalars["ID"];
  sender: NotificationSender;
  status: NotificationStatus;
  threadParentCommentUid: Maybe<Scalars["ID"]>;
  uid: Scalars["ID"];
};

/** Represents what type of club notification happened */
export enum ClubNotificationAction {
  Mentioned = "Mentioned",
  PostPromoted = "PostPromoted",
  RepliedToComment = "RepliedToComment",
  RepliedToPost = "RepliedToPost",
}

/**
 * A notification for private class messsages.
 * Currently only send when a teacher sends a message to a learner
 */
export type PrivateClassMessageNotification = INotification & {
  __typename?: "PrivateClassMessageNotification";
  createdAt: Scalars["DateTime"];
  isClub: Scalars["Boolean"];
  privateClassMessageUid: Scalars["ID"];
  sectionUid: Scalars["ID"];
  sender: NotificationSender;
  status: NotificationStatus;
  uid: Scalars["ID"];
};

export type LearnerPlanRejectedSuggestion = {
  __typename?: "LearnerPlanRejectedSuggestion";
  activity: Maybe<Activity>;
  learner: Maybe<Learner>;
  learnerPlanGoal: Maybe<LearnerPlanGoal>;
  uid: Scalars["ID"];
};

export type LearnerActivityByTopicSearchInput = {
  activityExperiences?: InputMaybe<Array<ActivityExperience>>;
  topicUid?: InputMaybe<Scalars["ID"]>;
};

export enum ActivityExperience {
  Classroom = "Classroom",
  Club = "Club",
}

export type LearnerActivityByTopicSearch = {
  __typename?: "LearnerActivityByTopicSearch";
  activities: Array<Activity>;
  cursor: Maybe<Scalars["String"]>;
  topicLabel: Maybe<Scalars["String"]>;
};

export type LearnerKeywordSearchInput = {
  activityExperiences?: InputMaybe<Array<ActivityExperience>>;
  query?: InputMaybe<Scalars["String"]>;
};

export type LearnerKeywordSearch = {
  __typename?: "LearnerKeywordSearch";
  activities: Array<Activity>;
  cursor: Maybe<Scalars["String"]>;
};

export type LearnerSearchSuggestionsInput = {
  query: Scalars["String"];
};

export type LearnerSearchSuggestions = {
  __typename?: "LearnerSearchSuggestions";
  teacherSuggestions: Array<Leader>;
  topicSuggestions: Array<Topic>;
};

export type LearnerSearchTeacherActivities = {
  __typename?: "LearnerSearchTeacherActivities";
  activities: Array<Activity>;
  cursor: Maybe<Scalars["String"]>;
};

export type LearnerToPick = {
  __typename?: "LearnerToPick";
  age: Scalars["Int"];
  enrollmentUid: Scalars["ID"];
  isEnrollmentPaused: Scalars["Boolean"];
  name: Scalars["String"];
  uid: Scalars["ID"];
};

export type LearnerSupportTopic = {
  __typename?: "LearnerSupportTopic";
  description: Scalars["String"];
  placeholder: Scalars["String"];
  prompt: Maybe<Scalars["String"]>;
  title: Scalars["String"];
  type: LearnerSupportType;
};

export enum LearnerSupportType {
  Glitch = "GLITCH",
  IFeelUnsafe = "I_FEEL_UNSAFE",
  OtherLearnerBadBehavior = "OTHER_LEARNER_BAD_BEHAVIOR",
  OtherLearnerNeedsHelp = "OTHER_LEARNER_NEEDS_HELP",
}

export type ListingsByTeacher = {
  __typename?: "ListingsByTeacher";
  leader: Leader;
  listings: Array<Listing>;
};

export type MeetingAiSummary = {
  __typename?: "MeetingAiSummary";
  ai_summary_text: Scalars["String"];
  created_at: Scalars["DateTime"];
  deleted_at: Maybe<Scalars["DateTime"]>;
  edited_summary_text: Maybe<Scalars["String"]>;
  is_skipped: Scalars["Boolean"];
  meeting_uid: Scalars["ID"];
  sent_at: Maybe<Scalars["DateTime"]>;
  updated_at: Scalars["DateTime"];
};

export type MessageThread = IMessageThread & {
  __typename?: "MessageThread";
  action: MessageThreadAction;
  activity: Maybe<Activity>;
  isMarkedSpam: Scalars["Boolean"];
  isRead: Scalars["Boolean"];
  matchedSearchResults: Maybe<Array<ConversationSearchResult>>;
  messages: Array<Message>;
  participants: Array<Participant>;
  section: Maybe<Section>;
  uid: Scalars["ID"];
};

export enum MessageThreadAction {
  ParentToClass = "parentToClass",
  ParentToParent = "parentToParent",
  ParentToTeacher = "parentToTeacher",
  PrivateClassMessage = "privateClassMessage",
  TeacherToParent = "teacherToParent",
  TeacherToTeacher = "teacherToTeacher",
  Unknown = "unknown",
}

export enum ActivityReviewMode {
  Orgs = "orgs",
  Regular = "regular",
  Skipped = "skipped",
  Subject = "subject",
}

export type NextMeetingReminder = {
  __typename?: "NextMeetingReminder";
  activityTitle: Scalars["String"];
  sectionUid: Scalars["ID"];
  startTime: Maybe<Scalars["DateTime"]>;
  uid: Scalars["ID"];
};

export type Order = {
  __typename?: "Order";
  currencyCode: Maybe<CurrencyCode>;
  donation: Maybe<Donation>;
  enrollmentMeetingPurchase: Maybe<EnrollmentMeetingPurchase>;
  enrollmentMeetingPurchases: Maybe<Array<EnrollmentMeetingPurchase>>;
  enrollments: Array<Enrollment>;
  exchangeRate: Maybe<Scalars["Float"]>;
  giftCard: Maybe<GiftCard>;
  giftEnrollments: Maybe<Array<GiftEnrollment>>;
  isFirstPurchaseByUser: Scalars["Boolean"];
  /** @deprecated Doesn't exist anymore */
  learnerAllowancePayment: Maybe<LearnerAllowancePayment>;
  orderSlugId: Scalars["ID"];
  organization: Maybe<Organization>;
  otherTendersApplied: Scalars["Int"];
  payments: Array<OrderPayment>;
  subscriptionCreditsApplied: Scalars["Int"];
  totalAmountCents: Scalars["Int"];
};

export type OrderEnrollmentsArgs = {
  filter?: InputMaybe<UserEnrollmentsFilters>;
};

export type LearnerAllowancePayment = {
  __typename?: "LearnerAllowancePayment";
  amountCents: Maybe<Scalars["Int"]>;
  confirmedAt: Maybe<Scalars["Date"]>;
  isRecurringPayment: Maybe<Scalars["Boolean"]>;
  learnerName: Maybe<Scalars["String"]>;
  /** @deprecated Removed */
  orderSlugId: Maybe<Scalars["ID"]>;
  uid: Scalars["ID"];
};

export enum OrganizationAdminFilter {
  Active = "Active",
  All = "All",
  Deleted = "Deleted",
}

export type PricingOfferAttendanceLog = {
  __typename?: "PricingOfferAttendanceLog";
  attendedAt: Maybe<Scalars["DateTime"]>;
  day: Scalars["DateTime"];
  email: Scalars["String"];
  startTime: Scalars["DateTime"];
  title: Scalars["String"];
};

export type PricingOfferAttendancesByWeek = {
  __typename?: "PricingOfferAttendancesByWeek";
  attendanceCount: Scalars["Int"];
  meetingCount: Scalars["Int"];
  weekStart: Scalars["DateTime"];
};

export type DateFilterInput = {
  fromDate?: InputMaybe<Scalars["DateTime"]>;
  toDate?: InputMaybe<Scalars["DateTime"]>;
};

export type PricingOfferEnrollmentsSummary = {
  __typename?: "PricingOfferEnrollmentsSummary";
  byActivity: Array<Maybe<PricingOffersSummaryByActivity>>;
  byClassType: Array<Maybe<PricingOffersSummaryGroup>>;
  byLearnerAge: Array<Maybe<PricingOffersSummaryGroup>>;
  bySubject: Array<Maybe<PricingOffersSummaryGroup>>;
  byUser: Array<Maybe<PricingOffersSummaryByUser>>;
  enrollmentCount: Scalars["Int"];
  learnerCount: Scalars["Int"];
  learnersByUser: Array<Maybe<PricingOffersSummaryGroup>>;
};

export type PricingOffersSummaryByActivity = {
  __typename?: "PricingOffersSummaryByActivity";
  classType: Scalars["String"];
  priceCents: Scalars["Int"];
  subject: Scalars["String"];
  title: Scalars["String"];
  totalEnrollments: Scalars["Int"];
  totalSpend: Scalars["Int"];
};

export type PricingOffersSummaryGroup = {
  __typename?: "PricingOffersSummaryGroup";
  count: Scalars["Int"];
  label: Scalars["String"];
};

export type PricingOffersSummaryByUser = {
  __typename?: "PricingOffersSummaryByUser";
  email: Scalars["String"];
  learnerCount: Scalars["Int"];
  name: Scalars["String"];
  secondaryEmail: Maybe<Scalars["String"]>;
  totalEnrollments: Scalars["Int"];
  totalLearnerMinutes: Scalars["Int"];
  totalLimit: Maybe<Scalars["Int"]>;
  totalSpend: Scalars["Int"];
};

export type PricingOffersPaymentsByWeek = {
  __typename?: "PricingOffersPaymentsByWeek";
  newUserCount: Scalars["Int"];
  totalSpend: Scalars["Int"];
  userCount: Scalars["Int"];
  weekStart: Scalars["DateTime"];
};

export type PricingOffersTransaction = {
  __typename?: "PricingOffersTransaction";
  amountCents: Maybe<Scalars["Int"]>;
  amountRefundedCents: Maybe<Scalars["Int"]>;
  day: Scalars["DateTime"];
  email: Scalars["String"];
  name: Scalars["String"];
  secondaryEmail: Maybe<Scalars["String"]>;
};

export type PrivateClassMessage = IMessage & {
  __typename?: "PrivateClassMessage";
  activity_uid: Scalars["ID"];
  content: Maybe<Scalars["String"]>;
  messageContent: MessageContent;
  participants: Array<Participant>;
  section_uid: Scalars["ID"];
  sender: MessageSender;
  sentAt: Scalars["DateTime"];
  subject: Maybe<Scalars["String"]>;
  thread_uid: Scalars["ID"];
  uid: Scalars["ID"];
};

export type PrivateClassThread = IMessageThread & {
  __typename?: "PrivateClassThread";
  activity: Maybe<Activity>;
  enrollment: Maybe<Enrollment>;
  isRead: Scalars["Boolean"];
  matchedSearchResults: Maybe<Array<ConversationSearchResult>>;
  messages: Array<PrivateClassMessage>;
  participants: Array<Participant>;
  section: Maybe<Section>;
  uid: Scalars["ID"];
};

export type RaceEthnicityOption = {
  __typename?: "RaceEthnicityOption";
  label: Scalars["String"];
  type: RaceEthnicityType;
};

export type SearchSuggestionsInput = {
  query: Scalars["String"];
};

export type RelatedTermSuggestions = {
  __typename?: "RelatedTermSuggestions";
  relatedTerms: Array<PopularTerms>;
};

export type PopularTerms = {
  __typename?: "PopularTerms";
  category: Scalars["String"];
  subCategory: Scalars["String"];
  uid: Scalars["ID"];
};

export type SchoolPage = {
  __typename?: "SchoolPage";
  address: Maybe<Scalars["String"]>;
  basePath: Scalars["String"];
  city: Maybe<Scalars["String"]>;
  endGrade: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  schoolLevel: Maybe<Scalars["String"]>;
  searchFilters: Maybe<SavedSearchFilters>;
  slug: Scalars["String"];
  startGrade: Maybe<Scalars["String"]>;
  state: Maybe<Scalars["String"]>;
  uid: Scalars["ID"];
};

export type ConversationFilter = {
  __typename?: "ConversationFilter";
  highlight: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  resultCount: Maybe<Scalars["Int"]>;
  type: ConversationFilterType;
  uid: Scalars["ID"];
};

export enum ConversationFilterType {
  Activity = "activity",
  Participant = "participant",
  Search = "search",
  Thread = "thread",
}

export type SearchCounselorSuggestion = {
  __typename?: "SearchCounselorSuggestion";
  activityUid: Maybe<Scalars["String"]>;
  reason: Scalars["String"];
};

export enum CustomSearchUseCase {
  LearnerPlannerSearch = "LEARNER_PLANNER_SEARCH",
  PitchPageSearch = "PITCH_PAGE_SEARCH",
  TeacherPullSearch = "TEACHER_PULL_SEARCH",
  TutorMatchSearch = "TUTOR_MATCH_SEARCH",
}

/**
 * Experimental
 * Different types of filters that could be applied on the server-side.
 * Used by the client to signal which types of auto applied filters
 * it does not want the server-side to apply.
 */
export enum AutoAppliedFilterType {
  GradeLevels = "GradeLevels",
}

export enum PromotedExperimentGroup {
  Control = "control",
  Holdout = "holdout",
  Treatment = "treatment",
}

export enum SearchObjective {
  AvailableListings = "available_listings",
  UnavailableListings = "unavailable_listings",
}

export enum SearchUseCase {
  CategoryContent = "CATEGORY_CONTENT",
  CdpAsClp = "CDP_AS_CLP",
  Custom = "CUSTOM",
  Discover = "DISCOVER",
  Feed = "FEED",
  Search = "SEARCH",
}

export type SearchSuggestions = {
  __typename?: "SearchSuggestions";
  popularTermSuggestions: Array<PopularTerms>;
  teacherSuggestions: Array<Leader>;
  topicSuggestions: Array<Topic>;
};

export type SelfPacedEnrollmentForm = {
  __typename?: "SelfPacedEnrollmentForm";
  activity: Activity;
  conflictingEnrollments: Array<Enrollment>;
  currentUserEnrollments: Array<Enrollment>;
  pendingEnrollments: Array<Enrollment>;
  uid: Scalars["ID"];
};

/**
 * Experimental
 * Only spelling suggestions for user search queries is currently supported
 */
export enum SpellingSuggestionType {
  SearchQuery = "SearchQuery",
}

/** Experimental */
export type SpellingSuggestion = {
  __typename?: "SpellingSuggestion";
  highConfidence: Scalars["Boolean"];
  query: Scalars["String"];
  spellingSuggestion: Maybe<Scalars["String"]>;
};

export type SubscriptionCreditBalance = {
  __typename?: "SubscriptionCreditBalance";
  amountCents: Maybe<Scalars["Int"]>;
  availableCredits: Maybe<Scalars["Int"]>;
  balance: Scalars["Int"];
  billingCycleEnd: Maybe<Scalars["Date"]>;
  billingCycleStart: Maybe<Scalars["Date"]>;
  cancelAtPeriodEnd: Maybe<Scalars["Boolean"]>;
  checkoutId: Maybe<Scalars["String"]>;
  creditValue: Maybe<Scalars["Int"]>;
  numCredits: Maybe<Scalars["Int"]>;
  openToBuy: Scalars["Int"];
  packageName: Maybe<Scalars["String"]>;
  status: Maybe<Scalars["String"]>;
  subscriptionId: Maybe<Scalars["String"]>;
  uid: Scalars["ID"];
  upcomingPackage: Maybe<SubscriptionPackageInformation>;
};

export type SubscriptionPackageInformation = {
  __typename?: "SubscriptionPackageInformation";
  amountCents: Maybe<Scalars["Int"]>;
  creditValue: Maybe<Scalars["Int"]>;
  numCredits: Maybe<Scalars["Int"]>;
  packageName: Maybe<Scalars["String"]>;
};

export type TeacherMarketingSettings = {
  __typename?: "TeacherMarketingSettings";
  abandoned_cart_auto_message_default: Scalars["String"];
  abandoned_cart_auto_message_is_on: Scalars["Boolean"];
  abandoned_cart_auto_message_template: Maybe<Scalars["String"]>;
  ai_meeting_summary_is_on: Scalars["Boolean"];
  ai_review_summary_is_on: Scalars["Boolean"];
  teacher_uid: Scalars["ID"];
  uid: Scalars["ID"];
};

export type TeacherSearchSuggestions = {
  __typename?: "TeacherSearchSuggestions";
  teachers: Array<Leader>;
  term: Scalars["String"];
};

export type TransactionListings = {
  __typename?: "TransactionListings";
  data: Array<TransactionListing>;
  totalCount: Scalars["Int"];
};

export type TransactionListing = {
  date: Scalars["DateTime"];
  id: Scalars["ID"];
  type: TransactionType;
};

export enum TransactionType {
  ClassWallet = "ClassWallet",
  Credit = "Credit",
  Order = "Order",
}

export type TrendingSearchTopic = {
  __typename?: "TrendingSearchTopic";
  topic: Scalars["String"];
  translatedTopic: Scalars["String"];
};

export type TutorMatchListingsConnection = {
  __typename?: "TutorMatchListingsConnection";
  badgedEducatorListings: Array<Listing>;
  generalEducatorListings: Array<Listing>;
  highQualityActivityListings: Array<Listing>;
  richContentsActivityListings: Array<Listing>;
};

export type BulkOrderInput = {
  attribution?: InputMaybe<Attribution>;
  cartUid?: InputMaybe<Scalars["String"]>;
  charterParentInput?: InputMaybe<EnrollmentCharterParentInput>;
  currency_code?: InputMaybe<CurrencyCode>;
  enrollmentInputs: Array<BulkEnrollmentInput>;
  exchange_rate?: InputMaybe<Scalars["Float"]>;
  impressionId?: InputMaybe<Scalars["String"]>;
  learnerPlanUid?: InputMaybe<Scalars["ID"]>;
  message?: InputMaybe<Scalars["String"]>;
  organizationUid?: InputMaybe<Scalars["ID"]>;
  purchaseOrderCoverage?: InputMaybe<Scalars["DateTime"]>;
};

export type Attribution = {
  activity_uid?: InputMaybe<Scalars["String"]>;
  addressBar?: InputMaybe<Scalars["Boolean"]>;
  addressBarUserSlugId?: InputMaybe<Scalars["String"]>;
  anonymousId?: InputMaybe<Scalars["String"]>;
  awc?: InputMaybe<Scalars["String"]>;
  campaign?: InputMaybe<Scalars["String"]>;
  content?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  landingPage?: InputMaybe<Scalars["String"]>;
  manualReferralType?: InputMaybe<Scalars["String"]>;
  medium?: InputMaybe<Scalars["String"]>;
  referer?: InputMaybe<Scalars["String"]>;
  ruid?: InputMaybe<Scalars["String"]>;
  source?: InputMaybe<Scalars["String"]>;
  term?: InputMaybe<Scalars["String"]>;
  usid?: InputMaybe<Scalars["String"]>;
};

export type EnrollmentCharterParentInput = {
  email: Scalars["String"];
  name: Scalars["String"];
};

export type BulkEnrollmentInput = {
  activityUid: Scalars["ID"];
  canScheduleContinuously?: InputMaybe<Scalars["Boolean"]>;
  endTime?: InputMaybe<Scalars["DateTime"]>;
  enrollableMeetings?: InputMaybe<Array<BulkFutureMeetingInput>>;
  isGift?: InputMaybe<Scalars["Boolean"]>;
  isOneOnOneInput?: InputMaybe<Scalars["Boolean"]>;
  isWeeklyPayment?: InputMaybe<Scalars["Boolean"]>;
  learner?: InputMaybe<EnrollmentLearnerInput>;
  meetingDurationWeeks?: InputMaybe<Scalars["Int"]>;
  oneOnOneDuration?: InputMaybe<Scalars["DateTime"]>;
  ongoingEndTime?: InputMaybe<Scalars["DateTime"]>;
  ongoingStartTime?: InputMaybe<Scalars["DateTime"]>;
  orgTeacherUid?: InputMaybe<Scalars["ID"]>;
  pricing_offer?: InputMaybe<Scalars["String"]>;
  sectionUid?: InputMaybe<Scalars["ID"]>;
  selfPacedStartTime?: InputMaybe<Scalars["DateTime"]>;
  startTime?: InputMaybe<Scalars["DateTime"]>;
  uid?: InputMaybe<Scalars["ID"]>;
};

export type EnrollmentLearnerInput = {
  age: Scalars["Int"];
  date_of_birth?: InputMaybe<Scalars["DateTime"]>;
  name: Scalars["String"];
  uid?: InputMaybe<Scalars["ID"]>;
};

export type ValidatedBulkEnrollment = {
  __typename?: "ValidatedBulkEnrollment";
  validatedEnrollmentsStatus: Array<ValidatedEnrollmentStatus>;
};

export type ValidatedEnrollmentStatus = {
  __typename?: "ValidatedEnrollmentStatus";
  activityUid: Scalars["ID"];
  canScheduleContinuously: Maybe<Scalars["Boolean"]>;
  endTime: Maybe<Scalars["DateTime"]>;
  enrollableMeetings: Maybe<Array<BulkFutureMeeting>>;
  isGift: Maybe<Scalars["Boolean"]>;
  isOneOnOneInput: Maybe<Scalars["Boolean"]>;
  isWeeklyPayment: Maybe<Scalars["Boolean"]>;
  learner: Maybe<ValidatedBulkEnrollmentLearner>;
  meetingDurationWeeks: Maybe<Scalars["Int"]>;
  oneOnOneDuration: Maybe<Scalars["DateTime"]>;
  ongoingEndTime: Maybe<Scalars["DateTime"]>;
  ongoingStartTime: Maybe<Scalars["DateTime"]>;
  orgTeacherUid: Maybe<Scalars["ID"]>;
  pricing_offer: Maybe<Scalars["String"]>;
  sectionUid: Maybe<Scalars["ID"]>;
  selfPacedStartTime: Maybe<Scalars["DateTime"]>;
  startTime: Maybe<Scalars["DateTime"]>;
  uid: Maybe<Scalars["ID"]>;
  /** @deprecated replaced with validationErrorStatus */
  validationError: Maybe<Scalars["String"]>;
  validationErrorStatus: Maybe<ValidationErrorStatus>;
};

export type BulkFutureMeeting = {
  __typename?: "BulkFutureMeeting";
  endTime: Scalars["DateTime"];
  isAvailable: Scalars["Boolean"];
  startTime: Scalars["DateTime"];
};

export type ValidatedBulkEnrollmentLearner = {
  __typename?: "ValidatedBulkEnrollmentLearner";
  age: Scalars["Int"];
  name: Scalars["String"];
  uid: Maybe<Scalars["ID"]>;
};

export type ValidationErrorStatus = {
  __typename?: "ValidationErrorStatus";
  isEsaError: Scalars["Boolean"];
  message: Scalars["String"];
};

export type Mutation = {
  __typename?: "Mutation";
  acceptGuidelinesForSection: Scalars["Boolean"];
  acceptInviteToSellerOrg: AuthenticationV2;
  acceptMeetingReschedule: Array<Meeting>;
  acceptPaymentWithAppleIAP: Scalars["Boolean"];
  acceptPaymentWithClassWallet: Scalars["Boolean"];
  acceptPaymentWithCouponCode: CouponState;
  acceptPaymentWithFundingProgramInvoice: Scalars["Boolean"];
  acceptPaymentWithPricingOffer: Scalars["Boolean"];
  acceptPaymentWithPurchaseOrder: Scalars["Boolean"];
  acceptPaymentWithStoredCredit: Scalars["ID"];
  acceptPaymentWithStripe: StripePaymentIntent;
  acceptPaymentWithSubscriptionCredit: Scalars["Boolean"];
  acceptScheduleSuggestion: Section;
  /**
   * Enable a device to receive push notifications given the data provided.
   * If the same deviceId was previously activated with different data,
   * we will update the database with the provided data.
   */
  activateLearnerDevice: Scalars["Boolean"];
  addAdminActivityAction: ActivityAction;
  addAdminUserAction: AdminUserAction;
  addAdminUserIncident: AdminUserIncident;
  addApprovalActivityAction: ActivityAction;
  addAssignmentClassPost: AssignmentClassPost;
  /**
   * Comment on an assignment class post as a learner or educator.
   * User is inferred from the presence of auth token (learnerAuthToken for learner, authToken for educator).
   * User must be allowed to comment on the given assignment (e.g. educator for the
   * section, org admin for the activity, student in section, etc.)
   * A learner's first comment on an assignment is considered a submission.
   */
  addAssignmentClassPostComment: AssignmentClassPostComment;
  addAssignmentLessonPost: AssignmentLessonPost;
  addAttachment: Attachment;
  addBulkGiftCards: BulkGiftCardResponse;
  addChild: Child;
  addChildren: Array<Child>;
  addClassPost: ClassPost;
  /**
   * Reply to a class post comment.
   * This does not start a new thread.
   */
  addClassPostComment: ClassPostComment;
  /**
   * Reply to a class post comment. There must already be a class post comment
   * (via addClassPostComment) to reply.  This either starts a new thread or
   * continues a thread. If a class class post comment is a reply to another
   * comment, then it should have a non-empty replies field and
   * thread_parent_comment_uid is set in the corresponding DB row.
   */
  addClassPostCommentReply: ClassPostComment;
  addClassPostForLesson: ClassPost;
  addDonationItemToOrder: Donation;
  addExternalResource: ExternalResource;
  addGiftCardItemWithDeliveryDateToOrder: GiftCard;
  addLesson: Syllabus;
  addLessonPost: LessonPost;
  addPrivateClassMessage: PrivateClassMessage;
  addPrivateMeetingRequest: MeetingRequest;
  addQuizAnswers: Array<Maybe<QuizAnswer>>;
  addSectionModerator: Leader;
  addTeacherUnavailableBlock: UnavailableBlock;
  addUnit: Syllabus;
  addUserIncident: Scalars["Boolean"];
  approveClubIntroduction: Scalars["Boolean"];
  assignAsOrganizationAdmin: AdminUserAction;
  assignExperimentToUser: Scalars["Boolean"];
  assignOrgTeacherToActivitySchedule: ScheduleSettings;
  assignTeacherTags: TeacherTagsConnection;
  assignTeacherToSellerOrg: AdminUserAction;
  blockLearner: Scalars["Boolean"];
  bulkCreateAccounts: Array<CreateAccountResult>;
  cancelAllOneOnOneSectionMeetings: Scalars["Boolean"];
  cancelGiftCard: Maybe<GiftCard>;
  cancelMeetingRequest: Scalars["Boolean"];
  cancelOneOnOneMeeting: Meeting;
  cancelOneOnOneRecurringMeetings: Array<Maybe<Scalars["String"]>>;
  cancelOrder: Scalars["Boolean"];
  cancelPendingEsaEnrollment: Scalars["Boolean"];
  cancelPendingOneOnOneEsaEnrollmentMutation: Scalars["Boolean"];
  cancelSection: Section;
  changeCurrentUserEmail: User;
  changeCurrentUserOtherEmail: User;
  changeLearnerPassword: AuthTransferToken;
  /** @deprecated Use changeLoggedInLearnerSettings() instead */
  changeLoggedInLearnerPassword: Scalars["Boolean"];
  changeLoggedInLearnerSettings: Child;
  changePassword: AuthenticationV2;
  changePin: ChangePinResult;
  claimGiftEnrollment: Enrollment;
  confirmChildEmail: Scalars["Boolean"];
  confirmEmail: AuthenticationV2;
  confirmMergeAccount: User;
  confirmOtherEmail: Scalars["Boolean"];
  convertAssignmentLessonPostToAssignmentClassPost: AssignmentClassPost;
  convertLessonPostToClassPost: ClassPost;
  createAccount: Maybe<AuthenticationResult>;
  createActivity: Activity;
  createAiGuideSupportIssue: Scalars["Boolean"];
  createBulkEnrollments: Order;
  createClassRequest: Maybe<ClassRequest>;
  createClassScheduleRequest: ClassScheduleRequest;
  createClubEnrollment: Enrollment;
  createCoupon: Coupon;
  createCouponWithCode: Coupon;
  createCreditPurchaseOrder: OrderType;
  createEnrollmentFutureMeetings: Maybe<Array<Maybe<Meeting>>>;
  createEnrollmentMeetingPurchase: EnrollmentMeetingPurchaseOrder;
  createEnrollments: Order;
  createLearnerLearningStatements: Array<LearningStatement>;
  /**
   * Specifically creates a learner entity availability schedule
   * Requires a learner plan uid to associate the created schedule to a learner plan
   */
  createLearnerPlanSchedule: EntityAvailabilitySchedule;
  createMeeting: Meeting;
  createMeetingReview: MeetingReview;
  createMeetings: Array<Meeting>;
  createMeetingsWithLessonUids: Array<Meeting>;
  createNewBundle: Bundle;
  createNewBundleElement: BundleElement;
  createOrder: OrderType;
  createOrganization: Organization;
  createOrUpdateFeedback: Feedback;
  createOrUpdateLearnerSectionReview: LearnerSectionReview;
  createPaymentSchedule: PaymentSchedule;
  createPricingOffer: PricingOffer;
  createSavedClassList: SavedClassList;
  createSection: Section;
  createSectionWithMeetings: Section;
  createSellerOrgPin: Scalars["Boolean"];
  createSetupIntent: SetupIntent;
  createSubscriptionCreditPurchaseOrder: OrderType;
  createSyllabus: Syllabus;
  createTeacherApplication: TeacherApplication;
  createTeacherCredential: TeacherCredential;
  createWaitlistSeats: Array<WaitlistSeat>;
  declineMeetingRequests: Array<MeetingRequest>;
  deleteAdminUserIncident: Scalars["Boolean"];
  deleteAssignmentClassPost: AssignmentClassPost;
  /**
   * Delete given comment on an assignment class post.
   * User must either own the comment, or be an educator / org admin for the section.
   */
  deleteAssignmentClassPostComment: AssignmentClassPostComment;
  deleteAssignmentLessonPost: AssignmentLessonPost;
  deleteBundle: Bundle;
  deleteChild: Scalars["Boolean"];
  deleteClassPost: Maybe<Scalars["Boolean"]>;
  deleteClassPostComment: Maybe<Scalars["Boolean"]>;
  deleteClassPostForLesson: Maybe<Scalars["Boolean"]>;
  deleteClassRecording: Scalars["Boolean"];
  deleteCurrentUserOtherEmail: User;
  deleteExternalResource: Scalars["Boolean"];
  deleteLesson: Syllabus;
  deleteLessonPost: LessonPost;
  deleteMeeting: Scalars["Boolean"];
  deleteOrganization: Organization;
  deletePaymentMethod: Scalars["Boolean"];
  deletePricingOffer: PricingOffer;
  deleteRecording: Scalars["Boolean"];
  deleteReview: Scalars["Boolean"];
  deleteSavedClassList: Scalars["Boolean"];
  deleteSavedSearch: DeleteSavedSearchPayload;
  deleteSections: Activity;
  deleteSyllabus: Syllabus;
  deleteTeacherCredential: Scalars["Boolean"];
  deleteTeacherUnavailableBlock: Scalars["Boolean"];
  deleteUnit: Syllabus;
  deleteWaitlistSeats: Array<WaitlistSeat>;
  flagClubIntroduction: Scalars["Boolean"];
  followUser: FollowedUser;
  generateProgressReport: Scalars["String"];
  introduceUnits: Syllabus;
  inviteTeacherToSellerOrg: User;
  learnerModeTransferToken: AuthTransferToken;
  learnerToParentTransferToken: AuthTransfer;
  learnerToSiblingTransferToken: AuthTransferToken;
  login: Credentials;
  loginOrCreateAccountWithApple: LoginResultOrError;
  loginOrCreateAccountWithAppleV2: LoginResultOrErrorV2;
  loginOrCreateAccountWithFacebook: LoginResultOrError;
  loginOrCreateAccountWithGoogle: LoginResultOrError;
  loginOrCreateAccountWithGoogleV2: LoginResultOrErrorV2;
  markAttended: Scalars["Boolean"];
  markClubPostViewed: Scalars["Boolean"];
  markEnrollmentPaid: Enrollment;
  markEnrollmentSubscriptionStopped: Enrollment;
  markEnrollmentSubscriptionStoppedAtDate: Enrollment;
  markEnrollmentWithdrawn: Enrollment;
  markExternalActivityComplete: Maybe<Scalars["Boolean"]>;
  markLearnerPlanActivityAsConfirmed: Maybe<LearnerPlan>;
  markLessonCompleteForLearner: Scalars["Boolean"];
  markLessonCompleteFromClassRecording: Scalars["Boolean"];
  markMessageThreadAsRead: Scalars["Boolean"];
  markMessageThreadsAsSpam: Scalars["Boolean"];
  markMessageThreadsAsUnread: Scalars["Boolean"];
  /** Mark a set of notification (as described by their INotification.uid field) as read */
  markNotificationRead: MarkNotificationReadResult;
  markPrivateClassMessageThreadAsRead: Scalars["Boolean"];
  markPrivateClassMessageThreadsAsUnread: Scalars["Boolean"];
  markTeacherAttended: Scalars["Boolean"];
  markUnclaimedGiftSubscriptionStopped: GiftEnrollment;
  markUnclaimedGiftWithdrawn: GiftEnrollment;
  mergeLearner: Scalars["Boolean"];
  mergeUserAccount: AdminUserAction;
  pauseOngoingEnrollments: Array<Enrollment>;
  pinClassPost: Scalars["Boolean"];
  promoteClassPost: Maybe<Scalars["Boolean"]>;
  publishSyllabus: Syllabus;
  recreateZoomMeeting: Scalars["Boolean"];
  redeemGiftCard: GiftCard;
  /** @deprecated replaced with refresh() */
  refreshLearnerSessionToken: AuthenticationV1;
  refreshV2: AuthenticationV2;
  refundAllEnrollmentsInSection: Section;
  refundEnrollmentPayment: EnrollmentPayment;
  refundEnrollmentPaymentCredits: EnrollmentPayment;
  refundOneOnOneMeeting: Scalars["Boolean"];
  rejectClubIntroduction: Scalars["Boolean"];
  rejectSellerOrgApplication: AdminUserAction;
  removeActivityFromAllLearnerPlan: Maybe<LearnerPlan>;
  /** Disable a device from receiving push notifications given a deviceId */
  removeLearnerDevice: Scalars["Boolean"];
  removeLearnerPlanGoal: Maybe<LearnerPlan>;
  removeLearnerPlanGoalFilter: Maybe<Scalars["Boolean"]>;
  removeSectionModerator: Scalars["Boolean"];
  removeUnits: Syllabus;
  renameSavedClassList: Scalars["Boolean"];
  replyClassInquiry: ClassInquiryTeacher;
  repriceTutoringEnrollment: RepriceTutoringResult;
  requestClassInquiry: Maybe<RequestClassInquiryResponse>;
  requestListing: Activity;
  requestMeetingRecordingsShown: Meeting;
  requestMeetingReschedule: MeetingRequest;
  requestMeetings: Array<MeetingRequest>;
  requestMergeAccount: Scalars["Boolean"];
  requestPasswordChange: Scalars["Boolean"];
  requestPinChange: Scalars["Boolean"];
  rescheduleMeeting: Array<Meeting>;
  resendChildEmailConfirmation: Child;
  resendEmailConfirmation: Scalars["Boolean"];
  resendOtherEmailConfirmation: Scalars["Boolean"];
  respondToReview: PublicReview;
  resumeOngoingEnrollment: Enrollment;
  saveActivity: SavedActivity;
  saveLearnerPlanActivity: Maybe<Array<LearnerPlanActivity>>;
  saveLearnerPlanActivityWithEnrollment: Maybe<Array<LearnerPlanActivity>>;
  saveLearnerPlanActivityWithMeetingTimes: Maybe<Array<LearnerPlanActivity>>;
  saveLearnerPlanGoal: Maybe<LearnerPlan>;
  saveLearnerPlanGoalActivity: LearnerPlanGoal;
  saveLearnerPlanGoalFilter: LearnerPlanGoalFilter;
  saveLearnerProfile: LearnerProfile;
  saveLearnerProfileClasses: SavedClassList;
  saveLearnerProfileSettings: Scalars["Boolean"];
  saveSearch: SavedSearch;
  sendActivityInviteEmail: Scalars["Boolean"];
  sendFollowUpQuestion: Maybe<FollowUpQuestionReply>;
  sendMessage: Message;
  sendMessageToOutschool: Scalars["Boolean"];
  sendProgressReport: Array<Message>;
  sendReferralNotifications: Scalars["Boolean"];
  sendSignupInviteEmail: Scalars["Boolean"];
  setLearnerInterfacePreference: Scalars["Boolean"];
  setLearnerUnlockableAvatars: Scalars["Boolean"];
  setMeetingRecordingsShown: Meeting;
  settleOrder: Order;
  settleOrderEnrollmentMeetingPurchase: Order;
  signSellerOrgAgreement: SellerOrgAgreement;
  startBackgroundCheck: Scalars["String"];
  startIdVerification: Maybe<Scalars["Url"]>;
  startPersonaIdVerification: Maybe<Scalars["Url"]>;
  stopOngoingSection: Section;
  stopPaymentSchedule: Scalars["Boolean"];
  stopSubscription: Enrollment;
  submitAccountDeletionRequest: Maybe<Scalars["Boolean"]>;
  submitClubIntroduction: Scalars["Boolean"];
  submitLearnerSupportIssue: Scalars["Boolean"];
  submitSellerOrgApplication: SellerOrgApplication;
  submitTeacherApplication: TeacherApplication;
  subscribe: User;
  subscribeToCreationEmails: Scalars["Boolean"];
  subscribeToEmail: EmailPreference;
  surpriseMe: Listing;
  trackAnalyticsInitialized: Maybe<Scalars["Boolean"]>;
  /** @deprecated This mutation is no longer in use. will delete in the next MR */
  trackCheckoutSessionCompleted: Maybe<Scalars["Boolean"]>;
  trackClassPageImpression: Maybe<Scalars["Boolean"]>;
  trackNewBuyerAcquired: Maybe<Scalars["Boolean"]>;
  trackOrderCompleted: Maybe<Scalars["Boolean"]>;
  trackPageLoad: Maybe<Scalars["Boolean"]>;
  trackSubscriptionInterest: Maybe<Scalars["Boolean"]>;
  trackUserAttributionSurveyResponse: Maybe<Scalars["Boolean"]>;
  trackUserCreated: Maybe<Scalars["Boolean"]>;
  transferEnrollment: Maybe<Enrollment>;
  transferUnclaimedGiftEnrollment: Maybe<GiftEnrollment>;
  unassignOrgTeacherToActivitySchedule: ScheduleSettings;
  unblockLearner: Scalars["Boolean"];
  unfollowUser: Scalars["Boolean"];
  unmarkMessageThreadsAsSpam: Scalars["Boolean"];
  unpauseOngoingEnrollmentsPausedForPaymentFailure: UnpausedEnrollmentResults;
  unpinClassPost: Scalars["Boolean"];
  unsaveActivity: Scalars["Boolean"];
  unsubscribeFromAllEmails: Array<EmailPreference>;
  unsubscribeFromEmail: EmailPreference;
  /** @deprecated replaced with updateTeacherMarketingSettings */
  updateAbandonedCartAutoMessageSettings: TeacherMarketingSettings;
  /** Update the auto-scheduling settings for a list of activities. */
  updateActivitiesAutoScheduling: Array<Activity>;
  updateActivity: Activity;
  updateActivityExternalResources: Activity;
  updateActivityRefundPolicyType: Activity;
  updateAdminUserIncident: AdminUserIncident;
  updateAssignmentClassPost: AssignmentClassPost;
  updateAssignmentLessonPost: AssignmentLessonPost;
  updateBundle: Bundle;
  updateChild: Child;
  updateClassPost: ClassPost;
  updateClassPostComment: ClassPostComment;
  updateCoupon: Coupon;
  updateCurrentUser: User;
  updateCurrentUserReferrer: Scalars["String"];
  updateEnrollmentPaymentRefund: EnrollmentPaymentRefund;
  /**
   * Updates entity availabilities for a schedule
   *
   * Returns EntityAvailabilitySchedule
   */
  updateEntityAvailabilitySchedule: EntityAvailabilitySchedule;
  updateExternalResource: ExternalResource;
  updateFilestackMetadata: Scalars["Boolean"];
  updateGiftCard: Maybe<GiftCard>;
  updateLearnerNote: Learner;
  updateLearnerPlanActivity: Maybe<Array<LearnerPlanActivity>>;
  updateLearnerPlanGoalFilter: Maybe<LearnerPlanGoalFilter>;
  updateLesson: Syllabus;
  updateLessonPost: LessonPost;
  updateMeetingAiSummary: Maybe<MeetingAiSummary>;
  updateMeetings: Array<Meeting>;
  /** @deprecated Use updateMeetings instead */
  updateMeetingsLesson: Array<Meeting>;
  updateOrganization: Organization;
  updatePricingOffer: PricingOffer;
  updatePrivacyStatus: SavedClassList;
  updateScheduleSettings: Scalars["Boolean"];
  updateSection: Section;
  updateSectionAdmin: Section;
  updateSellerOrgAgreement: SellerOrgAgreement;
  updateSellerOrgApplication: SellerOrgApplication;
  updateSubscription: Maybe<SubscriptionInformation>;
  updateTeacherApplication: TeacherApplication;
  updateTeacherAttributes: Scalars["ID"];
  updateTeacherAvailability: User;
  updateTeacherMarketingSettings: TeacherMarketingSettings;
  updateTeacherSchedule: Scalars["Boolean"];
  updateTeacherScheduleAvailability: Scalars["Boolean"];
  updateUnit: Syllabus;
  updateUserAdmin: User;
  validateMeetingRequestsForPurchase: ValidateMeetingRequestsForPurchaseResponse;
  verifyLearnerTransfer: Credentials;
  verifyParentTransfer: Credentials;
  verifySellerOrgPin: VerifyPinResult;
  withdrawAndRefundEnrollment: Enrollment;
  withdrawAndRefundGiftEnrollment: GiftEnrollment;
};

export type MutationAcceptGuidelinesForSectionArgs = {
  sectionUid: Scalars["ID"];
};

export type MutationAcceptInviteToSellerOrgArgs = {
  password: Scalars["String"];
  token: Scalars["String"];
  userUid: Scalars["ID"];
};

export type MutationAcceptMeetingRescheduleArgs = {
  requestUid: Scalars["ID"];
};

export type MutationAcceptPaymentWithAppleIapArgs = {
  amountCents: Scalars["Int"];
  mobileCurrencyCode?: InputMaybe<Scalars["String"]>;
  orderSlugId: Scalars["ID"];
  receiptData: Scalars["String"];
};

export type MutationAcceptPaymentWithClassWalletArgs = {
  amountCents: Scalars["Int"];
  orderSlugId: Scalars["ID"];
};

export type MutationAcceptPaymentWithCouponCodeArgs = {
  activityUid?: InputMaybe<Scalars["ID"]>;
  amountCents: Scalars["Int"];
  code: Scalars["String"];
  couponOrderInputs?: InputMaybe<Array<CouponOrderInput>>;
  currencyCode?: InputMaybe<CurrencyCode>;
  exchangeRate?: InputMaybe<Scalars["Float"]>;
  orderSlugId: Scalars["ID"];
};

export type MutationAcceptPaymentWithFundingProgramInvoiceArgs = {
  amountCents: Scalars["Int"];
  orderSlugId: Scalars["ID"];
  userDetails: FundingProgramInvoiceInput;
};

export type MutationAcceptPaymentWithPricingOfferArgs = {
  amountCents: Scalars["Int"];
  currencyCode?: InputMaybe<Scalars["String"]>;
  exchangeRate?: InputMaybe<Scalars["Float"]>;
  orderSlugId: Scalars["ID"];
  pricingOfferUid: Scalars["ID"];
};

export type MutationAcceptPaymentWithPurchaseOrderArgs = {
  amountCents: Scalars["Int"];
  charterSchoolUid: Scalars["String"];
  intercomUrl: Scalars["String"];
  orderSlugId: Scalars["ID"];
  purchaseOrderId: Scalars["String"];
};

export type MutationAcceptPaymentWithStoredCreditArgs = {
  amountCents: Scalars["Int"];
  currencyCode?: InputMaybe<CurrencyCode>;
  exchangeRate?: InputMaybe<Scalars["Float"]>;
  orderSlugId: Scalars["ID"];
};

export type MutationAcceptPaymentWithStripeArgs = {
  amountCents: Scalars["Int"];
  charterSchoolUid?: InputMaybe<Scalars["ID"]>;
  currencyCode?: InputMaybe<CurrencyCode>;
  exchangeRate?: InputMaybe<Scalars["Float"]>;
  orderPaymentUid?: InputMaybe<Scalars["ID"]>;
  orderSlugId: Scalars["ID"];
  purchaserEmail?: InputMaybe<Scalars["String"]>;
  purchaserName?: InputMaybe<Scalars["String"]>;
  savePaymentMethod?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationAcceptPaymentWithSubscriptionCreditArgs = {
  amountCredits: Scalars["Int"];
  currencyCode?: InputMaybe<CurrencyCode>;
  exchangeRate?: InputMaybe<Scalars["Float"]>;
  orderSlugId: Scalars["ID"];
};

export type MutationAcceptScheduleSuggestionArgs = {
  activityUid: Scalars["ID"];
  suggestedMeetings: Array<MeetingSuggestionInput>;
};

export type MutationActivateLearnerDeviceArgs = {
  data: DeviceActivationInfo;
  deviceId: Scalars["ID"];
};

export type MutationAddAdminActivityActionArgs = {
  action: Scalars["String"];
  activityUid: Scalars["ID"];
  messageToTeacher?: InputMaybe<Scalars["String"]>;
  notes?: InputMaybe<Scalars["String"]>;
};

export type MutationAddAdminUserActionArgs = {
  action: Scalars["String"];
  confirmation?: InputMaybe<Scalars["String"]>;
  customValue?: InputMaybe<Scalars["String"]>;
  notes?: InputMaybe<Scalars["String"]>;
  userUid: Scalars["ID"];
};

export type MutationAddAdminUserIncidentArgs = {
  category: Scalars["String"];
  notes?: InputMaybe<Scalars["String"]>;
  userUid: Scalars["ID"];
};

export type MutationAddApprovalActivityActionArgs = {
  action: Scalars["String"];
  activityUid: Scalars["ID"];
  messageToTeacher?: InputMaybe<Scalars["String"]>;
  notes?: InputMaybe<Scalars["String"]>;
};

export type MutationAddAssignmentClassPostArgs = {
  createAssignmentClassPostInput: CreateAssignmentClassPostInput;
};

export type MutationAddAssignmentClassPostCommentArgs = {
  assignmentClassPostUid: Scalars["ID"];
  learnerUid?: InputMaybe<Scalars["ID"]>;
  messageContent: MessageContentInput;
};

export type MutationAddAssignmentLessonPostArgs = {
  createAssignmentLessonPostInput: CreateAssignmentLessonPostInput;
};

export type MutationAddAttachmentArgs = {
  convert?: InputMaybe<Scalars["Boolean"]>;
  file: FileInput;
};

export type MutationAddBulkGiftCardsArgs = {
  bulkGiftCardsInput: BulkGiftCardsInput;
};

export type MutationAddChildArgs = {
  childInput: ChildInput;
};

export type MutationAddChildrenArgs = {
  childInputs?: InputMaybe<Array<ChildInput>>;
};

export type MutationAddClassPostArgs = {
  classPostInput: ClassPostInput;
};

export type MutationAddClassPostCommentArgs = {
  classPostCommentInput: ClassPostCommentInput;
};

export type MutationAddClassPostCommentReplyArgs = {
  classPostCommentReplyInput: ClassPostCommentReplyInput;
};

export type MutationAddClassPostForLessonArgs = {
  classPostForLessonInput: ClassPostForLessonInput;
};

export type MutationAddDonationItemToOrderArgs = {
  donationInput: DonationInput;
};

export type MutationAddExternalResourceArgs = {
  activityUid: Scalars["ID"];
  input: ExternalResourceInput;
};

export type MutationAddGiftCardItemWithDeliveryDateToOrderArgs = {
  giftCardInput: GiftCardInputWithDeliveryDate;
};

export type MutationAddLessonArgs = {
  previousLessonUid: Scalars["ID"];
  syllabusUid: Scalars["ID"];
};

export type MutationAddLessonPostArgs = {
  createLessonPostInput: CreateLessonPostInput;
};

export type MutationAddPrivateClassMessageArgs = {
  privateClassMessageInput: PrivateClassMessageInput;
};

export type MutationAddPrivateMeetingRequestArgs = {
  activityUid: Scalars["ID"];
  isRecurring: Scalars["Boolean"];
  orgTeacherUid?: InputMaybe<Scalars["String"]>;
  proposedTime: StartEndTime;
};

export type MutationAddQuizAnswersArgs = {
  anonymousId: Scalars["String"];
  answers: Array<UserQuizTypeAndValueInput>;
};

export type MutationAddSectionModeratorArgs = {
  sectionUid: Scalars["String"];
  userUid: Scalars["String"];
};

export type MutationAddTeacherUnavailableBlockArgs = {
  unavailableBlock: UnavailableBlockInput;
};

export type MutationAddUnitArgs = {
  syllabusUid: Scalars["ID"];
};

export type MutationAddUserIncidentArgs = {
  action: TnsAction;
  category?: InputMaybe<TnsActionCategory>;
  link?: InputMaybe<Scalars["String"]>;
  notes?: InputMaybe<Scalars["String"]>;
  userUid: Scalars["ID"];
};

export type MutationApproveClubIntroductionArgs = {
  enrollmentUid: Scalars["ID"];
};

export type MutationAssignAsOrganizationAdminArgs = {
  notes?: InputMaybe<Scalars["String"]>;
  organizationUid: Scalars["ID"];
  userUid: Scalars["ID"];
};

export type MutationAssignExperimentToUserArgs = {
  experimentId: Scalars["String"];
};

export type MutationAssignOrgTeacherToActivityScheduleArgs = {
  activityUid: Scalars["String"];
  orgTeacherUid: Scalars["String"];
};

export type MutationAssignTeacherTagsArgs = {
  tags: Array<TeacherTagInput>;
  userUid: Scalars["ID"];
};

export type MutationAssignTeacherToSellerOrgArgs = {
  notes?: InputMaybe<Scalars["String"]>;
  sellerOrgUserUid: Scalars["ID"];
  userUid: Scalars["ID"];
};

export type MutationBlockLearnerArgs = {
  educatorUid: Scalars["ID"];
  learnerUid: Scalars["ID"];
  note?: InputMaybe<Scalars["String"]>;
  reason: Scalars["String"];
};

export type MutationBulkCreateAccountsArgs = {
  data: Array<CreateAccountInput>;
  learnerUsesParentMode?: InputMaybe<Scalars["Boolean"]>;
  options?: InputMaybe<BulkCreateAccountOptions>;
  organizationUid: Scalars["ID"];
  persistChanges?: InputMaybe<Scalars["Boolean"]>;
  unsubscribe?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCancelAllOneOnOneSectionMeetingsArgs = {
  cancelNote?: InputMaybe<Scalars["String"]>;
  cancelReason: Scalars["String"];
  reducesTeacherPayout?: InputMaybe<Scalars["Boolean"]>;
  sectionUid: Scalars["ID"];
};

export type MutationCancelGiftCardArgs = {
  uid: Scalars["ID"];
};

export type MutationCancelMeetingRequestArgs = {
  requestUid: Scalars["ID"];
};

export type MutationCancelOneOnOneMeetingArgs = {
  cancelNote?: InputMaybe<Scalars["String"]>;
  cancelReason: Scalars["String"];
  reducesTeacherPayout?: InputMaybe<Scalars["Boolean"]>;
  refundAmountCents?: InputMaybe<Scalars["Int"]>;
  uid: Scalars["ID"];
};

export type MutationCancelOneOnOneRecurringMeetingsArgs = {
  cancelNote?: InputMaybe<Scalars["String"]>;
  cancelReason: Scalars["String"];
  reducesTeacherPayout?: InputMaybe<Scalars["Boolean"]>;
  uid: Scalars["ID"];
};

export type MutationCancelOrderArgs = {
  orderSlugId: Scalars["String"];
};

export type MutationCancelPendingEsaEnrollmentArgs = {
  orderSlugId: Scalars["ID"];
};

export type MutationCancelPendingOneOnOneEsaEnrollmentMutationArgs = {
  enrollmentUid: Scalars["ID"];
};

export type MutationCancelSectionArgs = {
  cancellationNote?: InputMaybe<Scalars["String"]>;
  messageToParents?: InputMaybe<Scalars["String"]>;
  reducesTeacherPayout?: InputMaybe<Scalars["Boolean"]>;
  refundAmountCents?: InputMaybe<Scalars["Int"]>;
  uid: Scalars["ID"];
  withdraw?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationChangeCurrentUserEmailArgs = {
  email: Scalars["String"];
};

export type MutationChangeCurrentUserOtherEmailArgs = {
  otherEmail: Scalars["String"];
};

export type MutationChangeLearnerPasswordArgs = {
  password: Scalars["String"];
  token: Scalars["String"];
  uid: Scalars["ID"];
};

export type MutationChangeLoggedInLearnerPasswordArgs = {
  currentPassword: Scalars["String"];
  newPassword: Scalars["String"];
};

export type MutationChangeLoggedInLearnerSettingsArgs = {
  currentPassword?: InputMaybe<Scalars["String"]>;
  newPassword?: InputMaybe<Scalars["String"]>;
  username?: InputMaybe<Scalars["String"]>;
};

export type MutationChangePasswordArgs = {
  password: Scalars["String"];
  token: Scalars["String"];
  uid: Scalars["ID"];
};

export type MutationChangePinArgs = {
  pin?: InputMaybe<Scalars["String"]>;
  sellerOrgUid: Scalars["ID"];
  token: Scalars["String"];
  userUid: Scalars["ID"];
};

export type MutationClaimGiftEnrollmentArgs = {
  acceptGiftSlugId: Scalars["ID"];
  expectedSectionUid?: InputMaybe<Scalars["ID"]>;
  learner: EnrollmentLearnerInput;
  recipientWillPurchaseOngoingExtension?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationConfirmChildEmailArgs = {
  childUid: Scalars["ID"];
  token: Scalars["String"];
};

export type MutationConfirmEmailArgs = {
  token: Scalars["String"];
  uid: Scalars["ID"];
};

export type MutationConfirmMergeAccountArgs = {
  email: Scalars["String"];
  token: Scalars["String"];
};

export type MutationConfirmOtherEmailArgs = {
  token: Scalars["String"];
  uid: Scalars["ID"];
};

export type MutationConvertAssignmentLessonPostToAssignmentClassPostArgs = {
  assignmentLessonPostUid: Scalars["ID"];
  publishAt?: InputMaybe<Scalars["DateTime"]>;
  sectionUid: Scalars["ID"];
};

export type MutationConvertLessonPostToClassPostArgs = {
  lessonPostUid: Scalars["ID"];
  publishAt?: InputMaybe<Scalars["DateTime"]>;
  sectionUid: Scalars["ID"];
};

export type MutationCreateAccountArgs = {
  attribution?: InputMaybe<Attribution>;
  browserTimeZone?: InputMaybe<Scalars["String"]>;
  defaultLearnerAge?: InputMaybe<Scalars["Int"]>;
  email: Scalars["String"];
  isGiftCardSignup?: InputMaybe<Scalars["Boolean"]>;
  isLeader?: InputMaybe<Scalars["Boolean"]>;
  isOnboardingSignup?: InputMaybe<Scalars["Boolean"]>;
  locale?: InputMaybe<UserLocale>;
  name?: InputMaybe<Scalars["String"]>;
  osRef?: InputMaybe<OsRefInput>;
  password: Scalars["String"];
  sellerOrgPrimaryContactName?: InputMaybe<Scalars["String"]>;
  shouldCreateDefaultLearner?: InputMaybe<Scalars["Boolean"]>;
  shouldNotSellPersonalInfo?: InputMaybe<Scalars["Boolean"]>;
  subscribe?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateActivityArgs = {
  activityType?: InputMaybe<CreateActivityType>;
  attribution?: InputMaybe<Attribution>;
  classRequestUid?: InputMaybe<Scalars["ID"]>;
  classroomType?: InputMaybe<ClassroomType>;
  format?: InputMaybe<EducatorSelectedFormat>;
  title: Scalars["String"];
  useTeacherSchedule?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateAiGuideSupportIssueArgs = {
  userMessage?: InputMaybe<Scalars["String"]>;
};

export type MutationCreateBulkEnrollmentsArgs = {
  bulkOrderInput: BulkOrderInput;
  esaState?: InputMaybe<Scalars["String"]>;
};

export type MutationCreateClassRequestArgs = {
  age: Scalars["Int"];
  content: Scalars["String"];
};

export type MutationCreateClassScheduleRequestArgs = {
  activityUid: Scalars["ID"];
  message?: InputMaybe<Scalars["String"]>;
  timetable: TimetableInput;
  timeZone: Scalars["String"];
};

export type MutationCreateClubEnrollmentArgs = {
  learnerUid: Scalars["ID"];
  sectionUid: Scalars["ID"];
};

export type MutationCreateCouponArgs = {
  couponActivityConstraintsInput?: InputMaybe<CouponActivityConstraintsInput>;
  couponInput: CouponCreateInput;
};

export type MutationCreateCouponWithCodeArgs = {
  couponActivityConstraintsInput?: InputMaybe<CouponActivityConstraintsInput>;
  couponCodeInput: CouponCodeCreateInput;
  couponInput: CouponCreateInput;
};

export type MutationCreateCreditPurchaseOrderArgs = {
  createCreditPurchaseOrderInput: CreateCreditPurchaseOrderInput;
};

export type MutationCreateEnrollmentFutureMeetingsArgs = {
  activityUid: Scalars["String"];
  durationWeeks?: InputMaybe<Scalars["Int"]>;
  endTime?: InputMaybe<Scalars["DateTime"]>;
  isRecurring?: InputMaybe<Scalars["Boolean"]>;
  meetingRequestUids?: InputMaybe<Array<Scalars["String"]>>;
  orgTeacherUid?: InputMaybe<Scalars["String"]>;
  recurringScheduleUid?: InputMaybe<Scalars["ID"]>;
  sectionUid: Scalars["String"];
  startTime: Scalars["DateTime"];
};

export type MutationCreateEnrollmentMeetingPurchaseArgs = {
  esaState?: InputMaybe<Scalars["String"]>;
  orderInput: EnrollmentMeetingPurchaseOrderInput;
};

export type MutationCreateEnrollmentsArgs = {
  esaState?: InputMaybe<Scalars["String"]>;
  isPrepay?: InputMaybe<Scalars["Boolean"]>;
  orderInput: OrderInput;
};

export type MutationCreateLearnerLearningStatementsArgs = {
  inputs: Array<CreateLearnerLearningStatementsInput>;
};

export type MutationCreateLearnerPlanScheduleArgs = {
  input: CreateLearnerPlanScheduleInput;
};

export type MutationCreateMeetingArgs = {
  end_time: Scalars["DateTime"];
  lesson_uid?: InputMaybe<Scalars["ID"]>;
  sectionUid: Scalars["ID"];
  start_time: Scalars["DateTime"];
};

export type MutationCreateMeetingReviewArgs = {
  rating: Scalars["Int"];
  sectionUid: Scalars["ID"];
};

export type MutationCreateMeetingsArgs = {
  sectionUid: Scalars["ID"];
  times: Array<StartEndTime>;
};

export type MutationCreateMeetingsWithLessonUidsArgs = {
  meetingDetails: Array<MeetingDetails>;
  sectionUid: Scalars["ID"];
};

export type MutationCreateNewBundleArgs = {
  createBundleInput: CreateBundleInput;
};

export type MutationCreateNewBundleElementArgs = {
  createBundleElementInput: CreateBundleElementInput;
};

export type MutationCreateOrganizationArgs = {
  data: CreateOrganizationInput;
};

export type MutationCreateOrUpdateFeedbackArgs = {
  feedbackInput: FeedbackInput;
};

export type MutationCreateOrUpdateLearnerSectionReviewArgs = {
  learnerSectionReviewInput: LearnerSectionReviewInput;
};

export type MutationCreatePaymentScheduleArgs = {
  createPaymentScheduleInput: CreatePaymentScheduleInput;
};

export type MutationCreatePricingOfferArgs = {
  data?: InputMaybe<CreatePricingOfferInput>;
};

export type MutationCreateSavedClassListArgs = {
  name: Scalars["String"];
};

export type MutationCreateSectionArgs = {
  activityUid: Scalars["ID"];
  attribution?: InputMaybe<Attribution>;
};

export type MutationCreateSectionWithMeetingsArgs = {
  activityUid: Scalars["ID"];
  makePublic: Scalars["Boolean"];
  suggestedMeetings: Array<MeetingSuggestionInput>;
};

export type MutationCreateSellerOrgPinArgs = {
  pin: Scalars["String"];
  sellerOrgUid: Scalars["ID"];
};

export type MutationCreateSubscriptionCreditPurchaseOrderArgs = {
  creditValue?: InputMaybe<Scalars["Int"]>;
  numCredits: Scalars["Int"];
  priceCents?: InputMaybe<Scalars["Int"]>;
};

export type MutationCreateSyllabusArgs = {
  activityUid: Scalars["ID"];
  createNewVersion?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateTeacherCredentialArgs = {
  collegeDegreeCredentialInput?: InputMaybe<CollegeDegreeCredentialInput>;
  credentialType: CredentialType;
  file: CredentialFileInput;
  otherCredentialInput?: InputMaybe<OtherCredentialInput>;
  teacherCertificateCredentialInput?: InputMaybe<TeacherCertificateCredentialInput>;
};

export type MutationCreateWaitlistSeatsArgs = {
  inputs: Array<CreateWaitlistSeatInput>;
};

export type MutationDeclineMeetingRequestsArgs = {
  requestUids: Array<Scalars["ID"]>;
};

export type MutationDeleteAdminUserIncidentArgs = {
  incidentUid: Scalars["ID"];
};

export type MutationDeleteAssignmentClassPostArgs = {
  uid: Scalars["ID"];
};

export type MutationDeleteAssignmentClassPostCommentArgs = {
  uid: Scalars["ID"];
};

export type MutationDeleteAssignmentLessonPostArgs = {
  assignmentLessonPostUid: Scalars["ID"];
};

export type MutationDeleteBundleArgs = {
  bundleUid: Scalars["ID"];
};

export type MutationDeleteChildArgs = {
  uid: Scalars["ID"];
};

export type MutationDeleteClassPostArgs = {
  classPostUid: Scalars["ID"];
};

export type MutationDeleteClassPostCommentArgs = {
  classPostCommentUid: Scalars["ID"];
};

export type MutationDeleteClassPostForLessonArgs = {
  classPostUid: Scalars["ID"];
};

export type MutationDeleteClassRecordingArgs = {
  uid: Scalars["ID"];
};

export type MutationDeleteCurrentUserOtherEmailArgs = {
  uid: Scalars["ID"];
};

export type MutationDeleteExternalResourceArgs = {
  uid: Scalars["ID"];
};

export type MutationDeleteLessonArgs = {
  lessonUid: Scalars["ID"];
};

export type MutationDeleteLessonPostArgs = {
  lessonPostUid: Scalars["ID"];
};

export type MutationDeleteMeetingArgs = {
  uid: Scalars["ID"];
};

export type MutationDeleteOrganizationArgs = {
  uid: Scalars["ID"];
};

export type MutationDeletePaymentMethodArgs = {
  paymentMethodId: Scalars["ID"];
};

export type MutationDeletePricingOfferArgs = {
  name?: InputMaybe<Scalars["String"]>;
  uid?: InputMaybe<Scalars["String"]>;
};

export type MutationDeleteRecordingArgs = {
  uid: Scalars["ID"];
};

export type MutationDeleteReviewArgs = {
  note?: InputMaybe<Scalars["String"]>;
  reason: Scalars["String"];
  uid: Scalars["ID"];
};

export type MutationDeleteSavedClassListArgs = {
  uid: Scalars["ID"];
};

export type MutationDeleteSavedSearchArgs = {
  uid: Scalars["ID"];
};

export type MutationDeleteSectionsArgs = {
  activityUid: Scalars["ID"];
};

export type MutationDeleteSyllabusArgs = {
  activityUid: Scalars["ID"];
  syllabusVersionUid?: InputMaybe<Scalars["ID"]>;
};

export type MutationDeleteTeacherCredentialArgs = {
  uid: Scalars["ID"];
};

export type MutationDeleteTeacherUnavailableBlockArgs = {
  unavailableBlockUid: Scalars["ID"];
};

export type MutationDeleteUnitArgs = {
  syllabusUid: Scalars["ID"];
  unitUid: Scalars["ID"];
};

export type MutationDeleteWaitlistSeatsArgs = {
  uids: Array<Scalars["String"]>;
};

export type MutationFlagClubIntroductionArgs = {
  enrollmentUid: Scalars["ID"];
  notes?: InputMaybe<Scalars["String"]>;
};

export type MutationFollowUserArgs = {
  attribution?: InputMaybe<Attribution>;
  referer?: InputMaybe<Scalars["String"]>;
  userUid: Scalars["ID"];
};

export type MutationGenerateProgressReportArgs = {
  notes: Scalars["String"];
  reportLength?: InputMaybe<ReportLength>;
  sectionUid: Scalars["ID"];
};

export type MutationIntroduceUnitsArgs = {
  syllabusUid: Scalars["ID"];
};

export type MutationInviteTeacherToSellerOrgArgs = {
  email: Scalars["String"];
  name: Scalars["String"];
};

export type MutationLearnerModeTransferTokenArgs = {
  learnerUid: Scalars["ID"];
};

export type MutationLearnerToParentTransferTokenArgs = {
  password?: InputMaybe<Scalars["String"]>;
};

export type MutationLearnerToSiblingTransferTokenArgs = {
  siblingUid: Scalars["ID"];
};

export type MutationLoginArgs = {
  emailOrUsername: Scalars["String"];
  password: Scalars["String"];
  postLoginOption?: InputMaybe<PostLoginOption>;
};

export type MutationLoginOrCreateAccountWithAppleArgs = {
  canCreateAccount: Scalars["Boolean"];
  client?: InputMaybe<LoginClient>;
  code: Scalars["String"];
  createOptions?: InputMaybe<CreateAccountWithProviderInput>;
  name?: InputMaybe<Scalars["String"]>;
  osRef?: InputMaybe<OsRefInput>;
};

export type MutationLoginOrCreateAccountWithAppleV2Args = {
  canCreateAccount: Scalars["Boolean"];
  client?: InputMaybe<LoginClient>;
  code: Scalars["String"];
  createOptions?: InputMaybe<CreateAccountWithProviderInput>;
  name?: InputMaybe<Scalars["String"]>;
  osRef?: InputMaybe<OsRefInput>;
};

export type MutationLoginOrCreateAccountWithFacebookArgs = {
  accessToken: Scalars["String"];
  canCreateAccount: Scalars["Boolean"];
  createOptions?: InputMaybe<CreateAccountWithProviderInput>;
};

export type MutationLoginOrCreateAccountWithGoogleArgs = {
  canCreateAccount: Scalars["Boolean"];
  createOptions?: InputMaybe<CreateAccountWithProviderInput>;
  idToken: Scalars["String"];
  osRef?: InputMaybe<OsRefInput>;
};

export type MutationLoginOrCreateAccountWithGoogleV2Args = {
  canCreateAccount: Scalars["Boolean"];
  createOptions?: InputMaybe<CreateAccountWithProviderInput>;
  idToken: Scalars["String"];
  osRef?: InputMaybe<OsRefInput>;
};

export type MutationMarkAttendedArgs = {
  sectionUid?: InputMaybe<Scalars["ID"]>;
};

export type MutationMarkClubPostViewedArgs = {
  classPostUid: Scalars["ID"];
};

export type MutationMarkEnrollmentPaidArgs = {
  enrollmentUid: Scalars["ID"];
};

export type MutationMarkEnrollmentSubscriptionStoppedArgs = {
  enrollmentUid: Scalars["ID"];
  note: Scalars["String"];
  reason: Scalars["String"];
};

export type MutationMarkEnrollmentSubscriptionStoppedAtDateArgs = {
  endDate: Scalars["DateTime"];
  enrollmentUid: Scalars["ID"];
  note: Scalars["String"];
  reason: Scalars["String"];
};

export type MutationMarkEnrollmentWithdrawnArgs = {
  enrollmentUid: Scalars["ID"];
  withdrawnNote: Scalars["String"];
  withdrawnReason: Scalars["String"];
};

export type MutationMarkExternalActivityCompleteArgs = {
  externalActivityId: Scalars["String"];
  source: ExternalActivitySource;
};

export type MutationMarkLearnerPlanActivityAsConfirmedArgs = {
  learnerPlanActivityUid: Scalars["ID"];
};

export type MutationMarkLessonCompleteForLearnerArgs = {
  lessonUid: Scalars["ID"];
  sectionUid: Scalars["ID"];
};

export type MutationMarkLessonCompleteFromClassRecordingArgs = {
  classRecordingUid: Scalars["ID"];
};

export type MutationMarkMessageThreadAsReadArgs = {
  threadUid: Scalars["ID"];
};

export type MutationMarkMessageThreadsAsSpamArgs = {
  threadUids: Array<Scalars["ID"]>;
};

export type MutationMarkMessageThreadsAsUnreadArgs = {
  threadUids: Array<Scalars["ID"]>;
};

export type MutationMarkNotificationReadArgs = {
  after: Scalars["DateTime"];
  uids: Array<Scalars["ID"]>;
};

export type MutationMarkPrivateClassMessageThreadAsReadArgs = {
  threadUid: Scalars["ID"];
};

export type MutationMarkPrivateClassMessageThreadsAsUnreadArgs = {
  threadUids: Array<Scalars["ID"]>;
};

export type MutationMarkTeacherAttendedArgs = {
  sectionUid: Scalars["ID"];
};

export type MutationMarkUnclaimedGiftSubscriptionStoppedArgs = {
  enrollmentUid: Scalars["ID"];
  note: Scalars["String"];
  reason: Scalars["String"];
};

export type MutationMarkUnclaimedGiftWithdrawnArgs = {
  enrollmentUid: Scalars["ID"];
  withdrawnNote: Scalars["String"];
  withdrawnReason: Scalars["String"];
};

export type MutationMergeLearnerArgs = {
  sourceLearnerUid: Scalars["ID"];
  targetLearnerUid: Scalars["ID"];
};

export type MutationMergeUserAccountArgs = {
  mergeAccountUserUid: Scalars["ID"];
  notes?: InputMaybe<Scalars["String"]>;
  userUid: Scalars["ID"];
};

export type MutationPauseOngoingEnrollmentsArgs = {
  input: Array<PauseOngoingEnrollmentInput>;
};

export type MutationPinClassPostArgs = {
  classPostUid: Scalars["ID"];
};

export type MutationPromoteClassPostArgs = {
  classPostUid: Scalars["ID"];
};

export type MutationPublishSyllabusArgs = {
  syllabusUid: Scalars["ID"];
};

export type MutationRecreateZoomMeetingArgs = {
  sectionUid: Scalars["String"];
};

export type MutationRedeemGiftCardArgs = {
  redemptionCode: Scalars["String"];
};

export type MutationRefreshLearnerSessionTokenArgs = {
  doNotTrack?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationRefreshV2Args = {
  browserTimeZone?: InputMaybe<Scalars["String"]>;
  doNotTrack?: InputMaybe<Scalars["Boolean"]>;
  refreshSource?: InputMaybe<TokenRefreshSource>;
  refreshToken?: InputMaybe<Scalars["String"]>;
};

export type MutationRefundAllEnrollmentsInSectionArgs = {
  cancellationNote?: InputMaybe<Scalars["String"]>;
  reducesTeacherPayout?: InputMaybe<Scalars["Boolean"]>;
  refundAmountCents?: InputMaybe<Scalars["Int"]>;
  shouldWithdrawEnrollments?: InputMaybe<Scalars["Boolean"]>;
  uid: Scalars["ID"];
};

export type MutationRefundEnrollmentPaymentArgs = {
  enrollmentPaymentUid: Scalars["ID"];
  reducesTeacherPayout: Scalars["Boolean"];
  refundAmountCents: Scalars["Int"];
  refundNote?: InputMaybe<Scalars["String"]>;
  refundReason?: InputMaybe<Scalars["String"]>;
  refundType?: InputMaybe<RefundType>;
};

export type MutationRefundEnrollmentPaymentCreditsArgs = {
  enrollmentPaymentUid: Scalars["ID"];
  reducesTeacherPayout: Scalars["Boolean"];
  refundAmountCredits: Scalars["Int"];
  refundNote?: InputMaybe<Scalars["String"]>;
  refundReason?: InputMaybe<Scalars["String"]>;
  refundType?: InputMaybe<RefundType>;
};

export type MutationRefundOneOnOneMeetingArgs = {
  reducesTeacherPayout?: InputMaybe<Scalars["Boolean"]>;
  refundAmountCents: Scalars["Int"];
  refundNote?: InputMaybe<Scalars["String"]>;
  refundReason: Scalars["String"];
  uid: Scalars["ID"];
};

export type MutationRejectClubIntroductionArgs = {
  enrollmentUid: Scalars["ID"];
  notes?: InputMaybe<Scalars["String"]>;
};

export type MutationRejectSellerOrgApplicationArgs = {
  isSendingEmail: Scalars["Boolean"];
  notes?: InputMaybe<Scalars["String"]>;
  userUid: Scalars["ID"];
};

export type MutationRemoveActivityFromAllLearnerPlanArgs = {
  activityUid: Scalars["ID"];
  learnerPlanGoalUid?: InputMaybe<Scalars["ID"]>;
  learnerPlanUid: Scalars["ID"];
  learnerUid: Scalars["ID"];
  sectionUid?: InputMaybe<Scalars["ID"]>;
};

export type MutationRemoveLearnerDeviceArgs = {
  deviceId: Scalars["ID"];
};

export type MutationRemoveLearnerPlanGoalArgs = {
  learnerPlanGoalUid: Scalars["ID"];
};

export type MutationRemoveLearnerPlanGoalFilterArgs = {
  learnerPlanGoalFilterUid: Scalars["ID"];
};

export type MutationRemoveSectionModeratorArgs = {
  sectionUid: Scalars["String"];
  userUid: Scalars["String"];
};

export type MutationRemoveUnitsArgs = {
  syllabusUid: Scalars["ID"];
};

export type MutationRenameSavedClassListArgs = {
  name: Scalars["String"];
  uid: Scalars["ID"];
};

export type MutationReplyClassInquiryArgs = {
  classInquiryUid: Scalars["ID"];
  relevantActivityUid: Scalars["ID"];
  replyText: Scalars["String"];
  teacherUid: Scalars["ID"];
};

export type MutationRepriceTutoringEnrollmentArgs = {
  enrollmentUid: Scalars["ID"];
  newPriceCents: Scalars["Int"];
};

export type MutationRequestClassInquiryArgs = {
  inquiryText: Scalars["String"];
  isV2_5Treatment?: InputMaybe<Scalars["Boolean"]>;
  learnerUid?: InputMaybe<Scalars["ID"]>;
  searchFilters?: InputMaybe<SearchFilters>;
  userUid: Scalars["ID"];
};

export type MutationRequestListingArgs = {
  activityUid: Scalars["ID"];
  note?: InputMaybe<Scalars["String"]>;
};

export type MutationRequestMeetingRecordingsShownArgs = {
  learnerUid: Scalars["ID"];
  meetingUid: Scalars["ID"];
  message?: InputMaybe<Scalars["String"]>;
};

export type MutationRequestMeetingRescheduleArgs = {
  meetingUid: Scalars["ID"];
  message?: InputMaybe<Scalars["String"]>;
  proposedTime: StartEndTime;
  reschedulesFutureMeetings?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationRequestMeetingsArgs = {
  isRecurring?: InputMaybe<Scalars["Boolean"]>;
  proposedTimes: Array<StartEndTime>;
  sectionUid: Scalars["ID"];
};

export type MutationRequestMergeAccountArgs = {
  email: Scalars["String"];
};

export type MutationRequestPasswordChangeArgs = {
  emailOrUsername: Scalars["String"];
};

export type MutationRequestPinChangeArgs = {
  email: Scalars["String"];
};

export type MutationRescheduleMeetingArgs = {
  endTime: Scalars["DateTime"];
  rescheduleFutureRecurringMeetings?: InputMaybe<Scalars["Boolean"]>;
  startTime: Scalars["DateTime"];
  uid: Scalars["ID"];
};

export type MutationResendChildEmailConfirmationArgs = {
  childUid: Scalars["ID"];
};

export type MutationResendEmailConfirmationArgs = {
  userUid: Scalars["ID"];
};

export type MutationResendOtherEmailConfirmationArgs = {
  otherEmail: Scalars["String"];
  userUid: Scalars["ID"];
};

export type MutationRespondToReviewArgs = {
  feedbackUid: Scalars["ID"];
  response: Scalars["String"];
};

export type MutationResumeOngoingEnrollmentArgs = {
  enrollmentUid: Scalars["ID"];
};

export type MutationSaveActivityArgs = {
  activityUid: Scalars["ID"];
  attribution?: InputMaybe<Attribution>;
  referer?: InputMaybe<Scalars["String"]>;
  savedClassListUids?: InputMaybe<Array<Scalars["ID"]>>;
};

export type MutationSaveLearnerPlanActivityArgs = {
  activityUid: Scalars["ID"];
  isPending?: InputMaybe<Scalars["Boolean"]>;
  isSuggested?: InputMaybe<Scalars["Boolean"]>;
  learnerUid: Scalars["ID"];
  sectionUid: Scalars["ID"];
};

export type MutationSaveLearnerPlanActivityWithEnrollmentArgs = {
  activityUid: Scalars["ID"];
  enrollmentUid: Scalars["ID"];
  learnerUid: Scalars["ID"];
};

export type MutationSaveLearnerPlanActivityWithMeetingTimesArgs = {
  activityUid: Scalars["ID"];
  isPending?: InputMaybe<Scalars["Boolean"]>;
  isSuggested?: InputMaybe<Scalars["Boolean"]>;
  learnerUid: Scalars["ID"];
  meetingDurationWeeks: Scalars["Int"];
  meetingEndTime: Scalars["DateTime"];
  meetingStartTime: Scalars["DateTime"];
};

export type MutationSaveLearnerPlanGoalArgs = {
  learnerPlanUid: Scalars["ID"];
  learnerUid: Scalars["ID"];
  subject?: InputMaybe<Scalars["String"]>;
  topic?: InputMaybe<Scalars["String"]>;
};

export type MutationSaveLearnerPlanGoalActivityArgs = {
  activityUid: Scalars["ID"];
  learnerPlanGoalUid: Scalars["ID"];
  learnerUid: Scalars["ID"];
  sectionUid?: InputMaybe<Scalars["ID"]>;
};

export type MutationSaveLearnerPlanGoalFilterArgs = {
  learnerPlanGoalUid: Scalars["ID"];
  learnerUid: Scalars["ID"];
  type?: InputMaybe<Scalars["String"]>;
  value?: InputMaybe<Scalars["String"]>;
};

export type MutationSaveLearnerProfileArgs = {
  learnerProfile: LearnerProfileInput;
};

export type MutationSaveLearnerProfileClassesArgs = {
  activityUids: Array<Scalars["ID"]>;
};

export type MutationSaveLearnerProfileSettingsArgs = {
  learnerProfileSettings: LearnerProfileSettingsInput;
};

export type MutationSaveSearchArgs = {
  filters: SearchFilters;
};

export type MutationSendActivityInviteEmailArgs = {
  activityUid: Scalars["ID"];
  campaignPrefix?: InputMaybe<Scalars["String"]>;
  customMessage?: InputMaybe<Scalars["String"]>;
  isEnrolled?: InputMaybe<Scalars["Boolean"]>;
  recipientEmails: Scalars["String"];
  sectionUid?: InputMaybe<Scalars["ID"]>;
};

export type MutationSendFollowUpQuestionArgs = {
  classInquiryUid: Scalars["ID"];
  conversation: Array<InputMaybe<ChatConversationMessageInput>>;
};

export type MutationSendMessageArgs = {
  messageInput: MessageInput;
  previousMessageUid?: InputMaybe<Scalars["ID"]>;
  recipientUids: Array<Scalars["ID"]>;
};

export type MutationSendMessageToOutschoolArgs = {
  messageInput: MessageInput;
};

export type MutationSendProgressReportArgs = {
  details: ProgressReportDetailsInput;
  learnerUid?: InputMaybe<Scalars["ID"]>;
  sectionUid: Scalars["ID"];
  text: Scalars["String"];
};

export type MutationSendReferralNotificationsArgs = {
  isTeacherReferral: Scalars["Boolean"];
  recipientEmails: Array<Scalars["String"]>;
};

export type MutationSendSignupInviteEmailArgs = {
  email: Scalars["String"];
};

export type MutationSetLearnerInterfacePreferenceArgs = {
  name: Scalars["String"];
  value: Scalars["JSON"];
};

export type MutationSetLearnerUnlockableAvatarsArgs = {
  learnerUid: Scalars["String"];
  value: Array<Scalars["String"]>;
};

export type MutationSetMeetingRecordingsShownArgs = {
  isShown: Scalars["Boolean"];
  meetingUid: Scalars["ID"];
  reason?: InputMaybe<Scalars["String"]>;
};

export type MutationSettleOrderArgs = {
  bulkPurchaseRecurringMeetingsInfo?: InputMaybe<
    Array<BulkPurchaseRecurringMeetingsInfo>
  >;
  orderSlugId: Scalars["ID"];
  prepaidPurchaseStartDate?: InputMaybe<Scalars["DateTime"]>;
};

export type MutationSettleOrderEnrollmentMeetingPurchaseArgs = {
  meetingRequestUids?: InputMaybe<Array<Scalars["ID"]>>;
  orderSlugId: Scalars["ID"];
  purchaseRecurringMeetingsInfo?: InputMaybe<PurchaseRecurringMeetingsInfo>;
};

export type MutationStartBackgroundCheckArgs = {
  country: Scalars["String"];
  userUid: Scalars["ID"];
};

export type MutationStopOngoingSectionArgs = {
  cancellationNote?: InputMaybe<Scalars["String"]>;
  reducesTeacherPayout?: InputMaybe<Scalars["Boolean"]>;
  refundAmountCents?: InputMaybe<Scalars["Int"]>;
  sectionUid: Scalars["ID"];
  stopAfter?: InputMaybe<Scalars["DateTime"]>;
};

export type MutationStopPaymentScheduleArgs = {
  stopPaymentScheduleInput: StopPaymentScheduleInput;
};

export type MutationStopSubscriptionArgs = {
  enrollmentUid: Scalars["ID"];
  note?: InputMaybe<Scalars["String"]>;
  reason?: InputMaybe<Scalars["String"]>;
  refundPaymentUid?: InputMaybe<Scalars["ID"]>;
  refundPaymentUids?: InputMaybe<Array<Scalars["ID"]>>;
};

export type MutationSubmitClubIntroductionArgs = {
  sectionUid: Scalars["ID"];
  videoUid: Scalars["ID"];
};

export type MutationSubmitLearnerSupportIssueArgs = {
  issueType: LearnerSupportType;
  text?: InputMaybe<Scalars["String"]>;
};

export type MutationSubmitTeacherApplicationArgs = {
  teacherApplicationUid: Scalars["ID"];
};

export type MutationSubscribeArgs = {
  attribution?: InputMaybe<Attribution>;
  isSubscribed: Scalars["Boolean"];
};

export type MutationSubscribeToCreationEmailsArgs = {
  attribution?: InputMaybe<Attribution>;
};

export type MutationSubscribeToEmailArgs = {
  attribution?: InputMaybe<Attribution>;
  name: Scalars["String"];
  token?: InputMaybe<Scalars["String"]>;
};

export type MutationSurpriseMeArgs = {
  activityUids: Array<UserSavedActivity>;
  ages?: InputMaybe<Array<Scalars["Int"]>>;
  excludeUids?: InputMaybe<Array<Scalars["ID"]>>;
  variant: SurpriseMeVariants;
};

export type MutationTrackAnalyticsInitializedArgs = {
  properties?: InputMaybe<TrackAnalyticsInitializedProperties>;
};

export type MutationTrackCheckoutSessionCompletedArgs = {
  properties: TrackCheckoutSessionCompletedProperties;
};

export type MutationTrackClassPageImpressionArgs = {
  input?: InputMaybe<TrackClassPageImpressionInput>;
};

export type MutationTrackNewBuyerAcquiredArgs = {
  amount?: InputMaybe<Scalars["Int"]>;
  properties?: InputMaybe<TrackNewBuyerAcquiredProperties>;
};

export type MutationTrackOrderCompletedArgs = {
  properties: TrackOrderCompletedProperties;
};

export type MutationTrackPageLoadArgs = {
  properties?: InputMaybe<TrackPageLoadProperties>;
};

export type MutationTrackSubscriptionInterestArgs = {
  properties: TrackSubscriptionInterestProperties;
};

export type MutationTrackUserAttributionSurveyResponseArgs = {
  input: TrackUserAttributionSurveyResponseInput;
};

export type MutationTrackUserCreatedArgs = {
  properties?: InputMaybe<TrackUserCreatedProperties>;
};

export type MutationTransferEnrollmentArgs = {
  enrollmentUid: Scalars["ID"];
  newSectionUid: Scalars["ID"];
};

export type MutationTransferUnclaimedGiftEnrollmentArgs = {
  enrollmentUid: Scalars["ID"];
  newSectionUid: Scalars["ID"];
};

export type MutationUnassignOrgTeacherToActivityScheduleArgs = {
  activityUid: Scalars["String"];
  orgTeacherUid: Scalars["String"];
};

export type MutationUnblockLearnerArgs = {
  educatorUid: Scalars["ID"];
  learnerUid: Scalars["ID"];
};

export type MutationUnfollowUserArgs = {
  userUid: Scalars["ID"];
};

export type MutationUnmarkMessageThreadsAsSpamArgs = {
  threadUids: Array<Scalars["ID"]>;
};

export type MutationUnpauseOngoingEnrollmentsPausedForPaymentFailureArgs = {
  input: UnpauseOngoingEnrollmentsPausedForPaymentFailureInput;
};

export type MutationUnpinClassPostArgs = {
  classPostUid: Scalars["ID"];
};

export type MutationUnsaveActivityArgs = {
  activityUid: Scalars["ID"];
  savedClassListUids?: InputMaybe<Array<Scalars["ID"]>>;
};

export type MutationUnsubscribeFromAllEmailsArgs = {
  attribution?: InputMaybe<Attribution>;
  token?: InputMaybe<Scalars["String"]>;
};

export type MutationUnsubscribeFromEmailArgs = {
  attribution?: InputMaybe<Attribution>;
  name: Scalars["String"];
  token?: InputMaybe<Scalars["String"]>;
};

export type MutationUpdateAbandonedCartAutoMessageSettingsArgs = {
  abandoned_cart_auto_message_is_on: Scalars["Boolean"];
  abandoned_cart_auto_message_template?: InputMaybe<Scalars["String"]>;
};

export type MutationUpdateActivitiesAutoSchedulingArgs = {
  enabled?: InputMaybe<Scalars["Boolean"]>;
  maxDaysNotice?: InputMaybe<Scalars["Int"]>;
  minDaysNotice?: InputMaybe<Scalars["Int"]>;
  uids: Array<Scalars["ID"]>;
};

export type MutationUpdateActivityArgs = {
  details?: InputMaybe<ActivityDetailsInput>;
  uid: Scalars["ID"];
  updates?: InputMaybe<ActivityInput>;
};

export type MutationUpdateActivityExternalResourcesArgs = {
  enabled?: InputMaybe<Scalars["Boolean"]>;
  uid: Scalars["ID"];
};

export type MutationUpdateActivityRefundPolicyTypeArgs = {
  activityUid: Scalars["ID"];
  refundPolicyType: RefundPolicyType;
};

export type MutationUpdateAdminUserIncidentArgs = {
  category: Scalars["String"];
  incidentUid: Scalars["ID"];
  notes?: InputMaybe<Scalars["String"]>;
};

export type MutationUpdateAssignmentClassPostArgs = {
  uid: Scalars["ID"];
  updateAssignmentClassPostInput: UpdateAssignmentClassPostInput;
};

export type MutationUpdateAssignmentLessonPostArgs = {
  assignmentLessonPostUid: Scalars["ID"];
  updateAssignmentLessonPostInput: UpdateAssignmentLessonPostInput;
};

export type MutationUpdateBundleArgs = {
  bundleUid: Scalars["ID"];
  updateBundleInput: UpdateBundleInput;
};

export type MutationUpdateChildArgs = {
  childInput: ChildInput;
  uid: Scalars["ID"];
};

export type MutationUpdateClassPostArgs = {
  classPostUid: Scalars["ID"];
  lessonUid?: InputMaybe<Scalars["ID"]>;
  messageContentInput?: InputMaybe<MessageContentInput>;
  publishAt?: InputMaybe<Scalars["DateTime"]>;
  title?: InputMaybe<Scalars["String"]>;
};

export type MutationUpdateClassPostCommentArgs = {
  classPostCommentUid: Scalars["ID"];
  messageContentInput?: InputMaybe<MessageContentInput>;
};

export type MutationUpdateCouponArgs = {
  input: CouponUpdateInput;
  uid: Scalars["ID"];
};

export type MutationUpdateCurrentUserArgs = {
  ageEmailSubscription?: InputMaybe<Scalars["Boolean"]>;
  consent_to_share_class_history?: InputMaybe<Scalars["Boolean"]>;
  consent_to_share_favourites?: InputMaybe<Scalars["Boolean"]>;
  consented_to_coppa_notice_at?: InputMaybe<Scalars["DateTime"]>;
  default_currency?: InputMaybe<Scalars["String"]>;
  details?: InputMaybe<UserDetailsInput>;
  do_not_sell_my_personal_info?: InputMaybe<Scalars["Boolean"]>;
  email?: InputMaybe<Scalars["String"]>;
  isLeader?: InputMaybe<Scalars["Boolean"]>;
  isRecordingAutopostEnabled?: InputMaybe<Scalars["Boolean"]>;
  learner_mode_switch_auth_requirement?: InputMaybe<LearnerModeSwitchAuthRequirement>;
  locale?: InputMaybe<UserLocale>;
  location?: InputMaybe<LocationInput>;
  name?: InputMaybe<Scalars["String"]>;
  payout_email?: InputMaybe<Scalars["String"]>;
  phone?: InputMaybe<Scalars["String"]>;
  photo?: InputMaybe<Scalars["String"]>;
  privacy_state?: InputMaybe<Scalars["String"]>;
  raceEthnicity?: InputMaybe<Array<RaceEthnicityType>>;
  school_closure?: InputMaybe<SchoolClosureInput>;
  studentAges?: InputMaybe<Array<Scalars["Int"]>>;
  subscribe?: InputMaybe<Scalars["Boolean"]>;
  teacherAttributes?: InputMaybe<TeacherAttributesInput>;
  useOtherFundsForMemberships?: InputMaybe<Scalars["Boolean"]>;
  video?: InputMaybe<Scalars["ID"]>;
};

export type MutationUpdateCurrentUserReferrerArgs = {
  referrerCode: Scalars["String"];
};

export type MutationUpdateEnrollmentPaymentRefundArgs = {
  adminUpdates: AdminEnrollmentPaymentRefundInput;
  enrollmentPaymentRefundUid: Scalars["ID"];
  updates: EnrollmentPaymentRefundInput;
  userUid: Scalars["ID"];
};

export type MutationUpdateEntityAvailabilityScheduleArgs = {
  input: UpdateEntityAvailabilityScheduleInput;
};

export type MutationUpdateExternalResourceArgs = {
  uid: Scalars["ID"];
  updates: ExternalResourceInput;
};

export type MutationUpdateFilestackMetadataArgs = {
  handle: Scalars["String"];
  osS3Path: Scalars["String"];
};

export type MutationUpdateGiftCardArgs = {
  giftCardInput: GiftCardUpdateInput;
  uid: Scalars["ID"];
};

export type MutationUpdateLearnerNoteArgs = {
  learnerUid: Scalars["ID"];
  note: Scalars["String"];
};

export type MutationUpdateLearnerPlanActivityArgs = {
  activityUid: Scalars["ID"];
  learnerPlanGoalUid: Scalars["ID"];
  learnerUid: Scalars["ID"];
  meetingDurationWeeks?: InputMaybe<Scalars["Int"]>;
  meetingEndTime?: InputMaybe<Scalars["DateTime"]>;
  meetingStartTime?: InputMaybe<Scalars["DateTime"]>;
  newSectionUid?: InputMaybe<Scalars["ID"]>;
  oldSectionUid?: InputMaybe<Scalars["ID"]>;
};

export type MutationUpdateLearnerPlanGoalFilterArgs = {
  learnerPlanGoalFilterUid: Scalars["ID"];
  type?: InputMaybe<Scalars["String"]>;
  value?: InputMaybe<Scalars["String"]>;
};

export type MutationUpdateLessonArgs = {
  lessonUid: Scalars["ID"];
  updates: LessonInput;
};

export type MutationUpdateLessonPostArgs = {
  lessonPostUid: Scalars["ID"];
  updateLessonPostInput: UpdateLessonPostInput;
};

export type MutationUpdateMeetingAiSummaryArgs = {
  input: MeetingAiSummaryPatchInput;
  meetingUid: Scalars["ID"];
};

export type MutationUpdateMeetingsArgs = {
  updateMeetingsInput: Array<UpdateMeetingInput>;
};

export type MutationUpdateMeetingsLessonArgs = {
  updateMeetingsLessonInput: Array<UpdateMeetingLessonInput>;
};

export type MutationUpdateOrganizationArgs = {
  data: UpdateOrganizationInput;
  uid: Scalars["ID"];
};

export type MutationUpdatePricingOfferArgs = {
  data?: InputMaybe<UpdatePricingOfferInput>;
  name?: InputMaybe<Scalars["String"]>;
  uid?: InputMaybe<Scalars["String"]>;
};

export type MutationUpdatePrivacyStatusArgs = {
  listUid: Scalars["ID"];
  privacyStatus: SavedClassListStatus;
};

export type MutationUpdateScheduleSettingsArgs = {
  activityUid: Scalars["ID"];
  newScheduleSettings?: InputMaybe<ActivityScheduleSettingsInput>;
};

export type MutationUpdateSectionArgs = {
  details?: InputMaybe<LeaderSectionDetailInput>;
  onlineClassroom?: InputMaybe<OnlineClassroomInput>;
  uid: Scalars["ID"];
  updates?: InputMaybe<LeaderSectionInput>;
};

export type MutationUpdateSectionAdminArgs = {
  uid: Scalars["ID"];
  updates: SectionAdminInput;
};

export type MutationUpdateSellerOrgAgreementArgs = {
  input: UpdateSellerOrgAgreementInput;
};

export type MutationUpdateSellerOrgApplicationArgs = {
  input: SellerOrgApplicationInput;
};

export type MutationUpdateSubscriptionArgs = {
  paymentMethod?: InputMaybe<Scalars["String"]>;
  selectedPackage: Scalars["String"];
};

export type MutationUpdateTeacherApplicationArgs = {
  teacherApplicationInput: TeacherApplicationInput;
  teacherApplicationUid: Scalars["ID"];
};

export type MutationUpdateTeacherAttributesArgs = {
  showCalendar?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationUpdateTeacherAvailabilityArgs = {
  availability: AvailabilityInput;
};

export type MutationUpdateTeacherMarketingSettingsArgs = {
  abandoned_cart_auto_message_is_on?: InputMaybe<Scalars["Boolean"]>;
  abandoned_cart_auto_message_template?: InputMaybe<Scalars["String"]>;
  ai_meeting_summary_is_on?: InputMaybe<Scalars["Boolean"]>;
  ai_review_summary_is_on?: InputMaybe<Scalars["Boolean"]>;
  teacher_uid?: InputMaybe<Scalars["ID"]>;
  uid?: InputMaybe<Scalars["ID"]>;
};

export type MutationUpdateTeacherScheduleArgs = {
  activityUid: Scalars["ID"];
  input: ScheduleInput;
};

export type MutationUpdateTeacherScheduleAvailabilityArgs = {
  activityUid: Scalars["ID"];
  availableTimes: Array<AvailableTimesInput>;
  uidsToDelete: Array<Scalars["ID"]>;
};

export type MutationUpdateUnitArgs = {
  unitUid: Scalars["ID"];
  updates: UnitInput;
};

export type MutationUpdateUserAdminArgs = {
  uid: Scalars["ID"];
  updates: UserAdminInput;
};

export type MutationValidateMeetingRequestsForPurchaseArgs = {
  activityUid: Scalars["String"];
  enrollmentMeetingPurchaseUid: Scalars["String"];
  requestUids: Array<Scalars["String"]>;
  sectionUid?: InputMaybe<Scalars["String"]>;
};

export type MutationVerifyLearnerTransferArgs = {
  isLearnerLogin: Scalars["Boolean"];
  token: Scalars["String"];
};

export type MutationVerifyParentTransferArgs = {
  isParentLogin: Scalars["Boolean"];
  token: Scalars["String"];
};

export type MutationVerifySellerOrgPinArgs = {
  pin: Scalars["String"];
  sellerOrgUid: Scalars["ID"];
};

export type MutationWithdrawAndRefundEnrollmentArgs = {
  enrollmentUid: Scalars["ID"];
  messageForParent?: InputMaybe<Scalars["String"]>;
  refundAllRemaining?: InputMaybe<Scalars["Boolean"]>;
  refundNote?: InputMaybe<Scalars["String"]>;
  refundReason: Scalars["String"];
};

export type MutationWithdrawAndRefundGiftEnrollmentArgs = {
  enrollmentUid: Scalars["ID"];
  messageForParent?: InputMaybe<Scalars["String"]>;
  refundAllRemaining?: InputMaybe<Scalars["Boolean"]>;
  refundNote?: InputMaybe<Scalars["String"]>;
  refundReason: Scalars["String"];
};

export type AuthenticationV2 = Credentials & {
  __typename?: "AuthenticationV2";
  learnerTransferToken: Maybe<Scalars["String"]>;
  refreshToken: Scalars["String"];
  sessionToken: Scalars["String"];
};

export type Credentials = {
  learnerTransferToken: Maybe<Scalars["String"]>;
  sessionToken: Scalars["String"];
};

export type CouponOrderInput = {
  activityUid: Scalars["ID"];
};

export type FundingProgramInvoiceInput = {
  awardId: Scalars["String"];
  childFirstName: Scalars["String"];
  childLastName: Scalars["String"];
  parentFirstName: Scalars["String"];
  parentLastName: Scalars["String"];
};

export type StripePaymentIntent = {
  __typename?: "StripePaymentIntent";
  orderPaymentUid: Scalars["ID"];
  paymentIntentClientId: Scalars["String"];
};

export type MeetingSuggestionInput = {
  endTime: Scalars["DateTime"];
  startTime: Scalars["DateTime"];
};

export type DeviceActivationInfo = {
  /** The app version of the device */
  appVersion: Scalars["String"];
  /** The token which authorizes us to send push notifications to this device */
  deviceToken: Scalars["String"];
  /**
   * If auth token is for a learner, you cannot set this value.  Learner uid is
   * implied based on the auth token provided.
   *
   * If auth token is for a user (parent) and learnerUid is set, the device
   * is associated with a specific learner, and we will only push notifications
   * for a given learner.  If it is not set, we will treat it as a "shared"
   * device and send notifications for all learners.
   */
  learnerUid?: InputMaybe<Scalars["ID"]>;
  /** The OS version of the device */
  osVersion: Scalars["String"];
  /** Which platform this device is on.  One of iOS or Android. */
  platform: DevicePlatform;
  /**
   * What categories of notifications are supported by this device.
   * The backend will only send categories of notifications that are in the provided array.
   * Defaults to [PrivateClassMessage, ClassPost, ClassPostComment, Meeting]
   */
  supportedNotifications?: InputMaybe<Array<SupportedNotifications>>;
};

export enum DevicePlatform {
  /** Device is Android, and we should use FCM */
  Android = "Android",
  /** Device is iOS, and we should use APNs */
  IOs = "iOS",
}

export enum SupportedNotifications {
  AssignmentPost = "AssignmentPost",
  AssignmentPostComment = "AssignmentPostComment",
  ClassPost = "ClassPost",
  ClassPostComment = "ClassPostComment",
  Meeting = "Meeting",
  PrivateClassMessage = "PrivateClassMessage",
  UserMessage = "UserMessage",
}

export type CreateAssignmentClassPostInput = {
  dueDate?: InputMaybe<Scalars["DateTime"]>;
  lessonUid?: InputMaybe<Scalars["ID"]>;
  messageContent: MessageContentInput;
  publishAt?: InputMaybe<Scalars["DateTime"]>;
  sectionUid: Scalars["ID"];
  submissionRequirement?: InputMaybe<SubmissionRequirementType>;
  title: Scalars["String"];
};

export type MessageContentInput = {
  attachmentUids?: InputMaybe<Array<Scalars["ID"]>>;
  mentionsUids?: InputMaybe<Array<Scalars["String"]>>;
  text?: InputMaybe<Scalars["String"]>;
  textMimeType?: InputMaybe<TextMimeType>;
  videoUid?: InputMaybe<Scalars["ID"]>;
};

export type CreateAssignmentLessonPostInput = {
  lessonUid: Scalars["ID"];
  messageContent: MessageContentInput;
  orderInLesson: Scalars["Int"];
  title: Scalars["String"];
};

export type FileInput = {
  filename?: InputMaybe<Scalars["String"]>;
  handle?: InputMaybe<Scalars["ID"]>;
  mimetype?: InputMaybe<Scalars["String"]>;
  originalPath?: InputMaybe<Scalars["String"]>;
  size?: InputMaybe<Scalars["Int"]>;
  source?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["String"]>;
  url?: InputMaybe<Scalars["String"]>;
};

export type BulkGiftCardsInput = {
  amountCentsPerGiftCard: Scalars["Int"];
  numGiftCards: Scalars["Int"];
  paidForBy: Scalars["String"];
  purchasedForReason: Scalars["String"];
};

export type BulkGiftCardResponse = {
  __typename?: "BulkGiftCardResponse";
  orderSlugId: Scalars["String"];
  redemptionCodes: Array<Scalars["String"]>;
};

export type ChildInput = {
  age?: InputMaybe<Scalars["Int"]>;
  attributes?: InputMaybe<Array<LearnerAttribute>>;
  availableTimes?: InputMaybe<Array<EntityAvailabilityInput>>;
  avatar?: InputMaybe<Scalars["String"]>;
  birthMonth?: InputMaybe<Scalars["Int"]>;
  birthYear?: InputMaybe<Scalars["Int"]>;
  date_of_birth?: InputMaybe<Scalars["DateTime"]>;
  email?: InputMaybe<Scalars["String"]>;
  interestTopics?: InputMaybe<Array<Scalars["ID"]>>;
  intro?: InputMaybe<Scalars["String"]>;
  isLoginEnabled?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  optedOutOfPersonalizedRecommendationsAt?: InputMaybe<Scalars["DateTime"]>;
  password?: InputMaybe<Scalars["String"]>;
  pronoun?: InputMaybe<Scalars["String"]>;
  raceEthnicity?: InputMaybe<Array<RaceEthnicityType>>;
  uid?: InputMaybe<Scalars["ID"]>;
  username?: InputMaybe<Scalars["String"]>;
};

export type LearnerAttribute = {
  key?: InputMaybe<Scalars["String"]>;
  value?: InputMaybe<Scalars["String"]>;
};

export type EntityAvailabilityInput = {
  day: Scalars["Int"];
  endHour: Scalars["Int"];
  endMinute: Scalars["Int"];
  startHour: Scalars["Int"];
  startMinute: Scalars["Int"];
  uid?: InputMaybe<Scalars["ID"]>;
};

export type ClassPostInput = {
  backgroundColor?: InputMaybe<Scalars["String"]>;
  messageContent: MessageContentInput;
  publishAt?: InputMaybe<Scalars["DateTime"]>;
  sectionUid: Scalars["ID"];
  title?: InputMaybe<Scalars["String"]>;
};

export type ClassPostCommentInput = {
  backgroundColor?: InputMaybe<Scalars["String"]>;
  classPostUid: Scalars["ID"];
  learnerUid?: InputMaybe<Scalars["ID"]>;
  messageContent: MessageContentInput;
};

export type ClassPostCommentReplyInput = {
  backgroundColor?: InputMaybe<Scalars["String"]>;
  classPostUid: Scalars["ID"];
  messageContentInput: MessageContentInput;
  threadParentCommentUid: Scalars["ID"];
};

export type ClassPostForLessonInput = {
  backgroundColor?: InputMaybe<Scalars["String"]>;
  lessonUid?: InputMaybe<Scalars["ID"]>;
  messageContent: MessageContentInput;
  publishAt?: InputMaybe<Scalars["DateTime"]>;
  sectionUid: Scalars["ID"];
  title?: InputMaybe<Scalars["String"]>;
};

export type DonationInput = {
  amountCents: Scalars["Int"];
  orderSlugId: Scalars["ID"];
};

export type ExternalResourceInput = {
  name?: InputMaybe<Scalars["String"]>;
  url?: InputMaybe<Scalars["String"]>;
};

export type GiftCardInputWithDeliveryDate = {
  amountCents: Scalars["Int"];
  deliverAfter?: InputMaybe<Scalars["DateTime"]>;
  deliveryTimezone: Scalars["String"];
  note?: InputMaybe<Scalars["String"]>;
  orderSlugId: Scalars["ID"];
  recipientEmail: Scalars["String"];
  recipientName: Scalars["String"];
  senderEmail: Scalars["String"];
  senderName: Scalars["String"];
};

export type CreateLessonPostInput = {
  lessonUid: Scalars["ID"];
  messageContent: MessageContentInput;
  orderInLesson: Scalars["Int"];
  title: Scalars["String"];
};

export type PrivateClassMessageInput = {
  learnerUid: Scalars["ID"];
  messageContentInput: MessageContentInput;
  sectionUid: Scalars["ID"];
  threadUid?: InputMaybe<Scalars["ID"]>;
};

export type UserQuizTypeAndValueInput = {
  type: Scalars["String"];
  value?: InputMaybe<Scalars["String"]>;
  /**
   * example:
   * [
   *   [{type: "gradeLevel", value: 9}, {type: "subject", value: "math"}],
   *   [{type: "gradeLevel", value: 9}, {type: "subject", value: "english"}]
   * ]
   */
  valueLists?: InputMaybe<
    Array<InputMaybe<Array<InputMaybe<UserQuizTypeAndValue>>>>
  >;
};

export type UserQuizTypeAndValue = {
  type: Scalars["String"];
  value: Scalars["String"];
};

export type QuizAnswer = {
  __typename?: "QuizAnswer";
  uid: Scalars["ID"];
};

export type UnavailableBlockInput = {
  endTime: Scalars["DateTime"];
  startTime: Scalars["DateTime"];
};

export type TeacherTagInput = {
  uid: Scalars["ID"];
};

export type CreateAccountInput = {
  learnerAge?: InputMaybe<Scalars["Int"]>;
  learnerEmail?: InputMaybe<Scalars["String"]>;
  learnerName?: InputMaybe<Scalars["String"]>;
  learnerPassword?: InputMaybe<Scalars["String"]>;
  parentEmail?: InputMaybe<Scalars["String"]>;
  parentName?: InputMaybe<Scalars["String"]>;
  password?: InputMaybe<Scalars["String"]>;
};

export type BulkCreateAccountOptions = {
  defaultTimeZone?: InputMaybe<Scalars["String"]>;
  learnerUsesParentMode?: InputMaybe<Scalars["Boolean"]>;
  persistChanges?: InputMaybe<Scalars["Boolean"]>;
  sendCoppaEmails?: InputMaybe<Scalars["Boolean"]>;
  unsubscribe?: InputMaybe<Scalars["Boolean"]>;
};

export type CreateAccountResult = {
  __typename?: "CreateAccountResult";
  comments: Maybe<Scalars["String"]>;
  learnerAge: Maybe<Scalars["Int"]>;
  learnerEmail: Maybe<Scalars["String"]>;
  learnerName: Maybe<Scalars["String"]>;
  parentEmail: Maybe<Scalars["String"]>;
  parentName: Maybe<Scalars["String"]>;
};

export type AuthTransferToken = {
  __typename?: "AuthTransferToken";
  transferToken: Scalars["String"];
};

export type ChangePinResult = {
  __typename?: "ChangePinResult";
  hasPinSet: Scalars["Boolean"];
};

export type OsRefInput = {
  a8?: InputMaybe<Scalars["String"]>;
  anonymousId?: InputMaybe<Scalars["String"]>;
  attributionVersionNumber?: InputMaybe<Scalars["Int"]>;
  cookieAddressBarTrackingId?: InputMaybe<Scalars["String"]>;
  cookieAddressBarUserSlugId?: InputMaybe<Scalars["String"]>;
  couponCode?: InputMaybe<Scalars["String"]>;
  currentUrl?: InputMaybe<Scalars["String"]>;
  landingPage?: InputMaybe<Scalars["String"]>;
  noResetReason?: InputMaybe<Scalars["String"]>;
  originalReferrer?: InputMaybe<Scalars["String"]>;
  otherAddressBarTrackingId?: InputMaybe<Scalars["String"]>;
  otherAddressBarUserSlugId?: InputMaybe<Scalars["String"]>;
  pageViewNumberOfSession?: InputMaybe<Scalars["Int"]>;
  previousUrl?: InputMaybe<Scalars["String"]>;
  resetReason?: InputMaybe<Scalars["String"]>;
  resetTimestamp?: InputMaybe<Scalars["String"]>;
  ruid?: InputMaybe<Scalars["String"]>;
  trackingSessionUid?: InputMaybe<Scalars["String"]>;
  usid?: InputMaybe<Scalars["String"]>;
  utm_campaign?: InputMaybe<Scalars["String"]>;
  utm_content?: InputMaybe<Scalars["String"]>;
  utm_medium?: InputMaybe<Scalars["String"]>;
  utm_source?: InputMaybe<Scalars["String"]>;
  utm_term?: InputMaybe<Scalars["String"]>;
};

export type AuthenticationResult = {
  __typename?: "AuthenticationResult";
  currentUser: User;
  refreshToken: Scalars["String"];
  sessionToken: Scalars["String"];
  uid: Scalars["ID"];
};

export type TimetableInput = {
  fri: TimetableTimesInput;
  mon: TimetableTimesInput;
  sat: TimetableTimesInput;
  sun: TimetableTimesInput;
  thu: TimetableTimesInput;
  tue: TimetableTimesInput;
  wed: TimetableTimesInput;
};

export type TimetableTimesInput = {
  f12_4: Scalars["Boolean"];
  f4_8: Scalars["Boolean"];
  f8_12: Scalars["Boolean"];
};

export type CouponActivityConstraintsInput = {
  activitiesUids: Array<Scalars["ID"]>;
};

export type CouponCreateInput = {
  classFormatsAllowed?: InputMaybe<Array<InputMaybe<CouponClassFormats>>>;
  couponType: CouponType;
  description?: InputMaybe<Scalars["String"]>;
  expiresAt: Scalars["DateTime"];
  isBuyerFirstPurchaseOfClassFormatOnly?: InputMaybe<Scalars["Boolean"]>;
  isFirstPurchaseOnly: Scalars["Boolean"];
  maxAmountCentsPerUse?: InputMaybe<Scalars["Int"]>;
  maxUses?: InputMaybe<Scalars["Int"]>;
  maxUsesPerUser?: InputMaybe<Scalars["Int"]>;
  minPurchaseAmountCents?: InputMaybe<Scalars["Int"]>;
  name: Scalars["String"];
  percentOff?: InputMaybe<Scalars["Int"]>;
  startsAt: Scalars["DateTime"];
  subjectsAllowed?: InputMaybe<Array<Scalars["String"]>>;
};

export type CouponCodeCreateInput = {
  code: Scalars["String"];
};

export type CreateCreditPurchaseOrderInput = {
  amountCents: Scalars["Int"];
  amountGrantedCents: Scalars["Int"];
};

export type OrderType = {
  __typename?: "OrderType";
  orderSlugId: Scalars["ID"];
};

export type EnrollmentMeetingPurchaseOrderInput = {
  activityUid: Scalars["ID"];
  attribution?: InputMaybe<Attribution>;
  charterParentInput?: InputMaybe<EnrollmentCharterParentInput>;
  currency_code?: InputMaybe<CurrencyCode>;
  endTime?: InputMaybe<Scalars["DateTime"]>;
  enrollmentInputs: Array<EnrollmentInput>;
  enrollmentUid?: InputMaybe<Scalars["ID"]>;
  exchange_rate?: InputMaybe<Scalars["Float"]>;
  ignoresTeacherAvailability?: InputMaybe<Scalars["Boolean"]>;
  impressionId?: InputMaybe<Scalars["String"]>;
  meetingRequestUids?: InputMaybe<Array<Scalars["String"]>>;
  message?: InputMaybe<Scalars["String"]>;
  orgTeacherUid?: InputMaybe<Scalars["String"]>;
  prepayDurationWeeks?: InputMaybe<Scalars["Int"]>;
  prepayMeetings?: InputMaybe<Array<FutureMeetingInputInfo>>;
  startTime?: InputMaybe<Scalars["DateTime"]>;
};

export type EnrollmentInput = {
  amountInLocalCurrency?: InputMaybe<Scalars["Int"]>;
  isGift?: InputMaybe<Scalars["Boolean"]>;
  learner?: InputMaybe<EnrollmentLearnerInput>;
  price_cents: Scalars["Int"];
  pricing_offer?: InputMaybe<Scalars["String"]>;
};

export type FutureMeetingInputInfo = {
  end_time: Scalars["DateTime"];
  start_time: Scalars["DateTime"];
};

export type EnrollmentMeetingPurchaseOrder = {
  __typename?: "EnrollmentMeetingPurchaseOrder";
  enrollmentMeetingPurchase: EnrollmentMeetingPurchase;
  orderSlugId: Scalars["ID"];
};

export type OrderInput = {
  activityUid: Scalars["ID"];
  amount_in_local_currency?: InputMaybe<Scalars["Int"]>;
  attribution?: InputMaybe<Attribution>;
  charterParentInput?: InputMaybe<EnrollmentCharterParentInput>;
  charterSchoolUid?: InputMaybe<Scalars["ID"]>;
  currency_code?: InputMaybe<CurrencyCode>;
  enrollmentInputs: Array<EnrollmentInput>;
  exchange_rate?: InputMaybe<Scalars["Float"]>;
  impressionId?: InputMaybe<Scalars["String"]>;
  isWeeklyPayment?: InputMaybe<Scalars["Boolean"]>;
  message?: InputMaybe<Scalars["String"]>;
  ongoingEndTime?: InputMaybe<Scalars["DateTime"]>;
  ongoingStartTime?: InputMaybe<Scalars["DateTime"]>;
  organizationUid?: InputMaybe<Scalars["ID"]>;
  purchaseOrderCoverage?: InputMaybe<Scalars["DateTime"]>;
  schoolClosure?: InputMaybe<SchoolClosureInput>;
  sectionUid?: InputMaybe<Scalars["ID"]>;
  selfPacedStartTime?: InputMaybe<Scalars["DateTime"]>;
};

export type SchoolClosureInput = {
  affected?: InputMaybe<Scalars["Boolean"]>;
  city: Scalars["String"];
  covid: Scalars["Boolean"];
  household_size: Scalars["Int"];
  income: Scalars["String"];
  lunch: Scalars["Boolean"];
  name: Scalars["String"];
  noschool: Scalars["Boolean"];
  school_closed?: InputMaybe<Scalars["Boolean"]>;
  state: Scalars["String"];
};

export type CreateLearnerLearningStatementsInput = {
  learnerUid: Scalars["ID"];
  objectType: LearningStatementsObjectType;
  objectUid: Scalars["String"];
  verb: LearningStatementsVerb;
};

export enum LearningStatementsObjectType {
  AssignmentClassPost = "AssignmentClassPost",
  LearningStatement = "LearningStatement",
  Lesson = "Lesson",
}

export type CreateLearnerPlanScheduleInput = {
  entityAvailabilities?: InputMaybe<Array<EntityAvailabilityInput>>;
  learnerPlanUid: Scalars["ID"];
};

export type MeetingReview = {
  __typename?: "MeetingReview";
  activityUid: Scalars["ID"];
  created_at: Scalars["DateTime"];
  deleted_at: Scalars["DateTime"];
  learnerUid: Scalars["ID"];
  meetingUid: Scalars["ID"];
  rating: Scalars["Int"];
  sectionUid: Scalars["ID"];
  uid: Scalars["ID"];
  updated_at: Scalars["DateTime"];
};

export type MeetingDetails = {
  end_time: Scalars["DateTime"];
  lesson_uid?: InputMaybe<Scalars["ID"]>;
  start_time: Scalars["DateTime"];
};

export type CreateBundleInput = {
  bundleElements: Array<NewBundleElementInput>;
  description?: InputMaybe<Scalars["String"]>;
  isOrdered: Scalars["Boolean"];
  publishedAt?: InputMaybe<Scalars["DateTime"]>;
  thumbnail?: InputMaybe<Scalars["String"]>;
  title: Scalars["String"];
};

export type NewBundleElementInput = {
  activityUid: Scalars["ID"];
  orderInBundle: Scalars["Int"];
};

export type CreateBundleElementInput = {
  activityUid: Scalars["ID"];
  bundleUid: Scalars["ID"];
  orderInBundle: Scalars["Int"];
};

export type CreateOrganizationInput = {
  address?: InputMaybe<Scalars["String"]>;
  invoiceEmail?: InputMaybe<Scalars["String"]>;
  logoUrl?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  notes?: InputMaybe<Scalars["String"]>;
  state?: InputMaybe<Scalars["String"]>;
  techSupportEmail?: InputMaybe<Scalars["String"]>;
  totalCapCents?: InputMaybe<Scalars["Int"]>;
  trustSafetyEmail?: InputMaybe<Scalars["String"]>;
  type: OrganizationType;
  urlName?: InputMaybe<Scalars["String"]>;
};

export type FeedbackInput = {
  activity_star_rating: Scalars["Int"];
  attachments?: InputMaybe<Array<Scalars["ID"]>>;
  attachmentUids?: InputMaybe<Array<Scalars["ID"]>>;
  attribution?: InputMaybe<Attribution>;
  leaderFeedback?: InputMaybe<Scalars["String"]>;
  outschool_rating?: InputMaybe<Scalars["Int"]>;
  outschoolFeedback?: InputMaybe<Scalars["String"]>;
  publicReview?: InputMaybe<Scalars["String"]>;
  section_uid: Scalars["ID"];
};

export type LearnerSectionReviewInput = {
  attribution?: InputMaybe<Attribution>;
  enrollmentUid: Scalars["ID"];
  name: ReviewName;
  response: LearnerReviewResponseInput;
};

export enum ReviewName {
  InterestedInTakingMoreClassesAboutTopic = "interestedInTakingMoreClassesAboutTopic",
  SoughtOutWaysToLearnMoreAboutTopic = "soughtOutWaysToLearnMoreAboutTopic",
}

export type LearnerReviewResponseInput = {
  vote?: InputMaybe<Scalars["String"]>;
};

export type LearnerSectionReview = {
  __typename?: "LearnerSectionReview";
  enrollment: Enrollment;
  name: Maybe<ReviewName>;
  response: Maybe<LearnerReviewResponse>;
  uid: Scalars["ID"];
};

export type LearnerReviewResponse = {
  __typename?: "LearnerReviewResponse";
  vote: Maybe<Scalars["String"]>;
};

export type CreatePaymentScheduleInput = {
  initialPaymentAmountCents?: InputMaybe<Scalars["Int"]>;
  initialPaymentRelativePeriod?: InputMaybe<RelativePeriodOptions>;
  lineItemUid: Scalars["String"];
  orderSlugId?: InputMaybe<Scalars["String"]>;
  recurringPaymentAmountCents: Scalars["Int"];
  scheduledPaymentLineItemType: ScheduledPaymentLineItemType;
  scheduleEndTime?: InputMaybe<Scalars["DateTime"]>;
  scheduleStartTime: Scalars["DateTime"];
  scheduleType: PaymentScheduleTypes;
};

export enum RelativePeriodOptions {
  Current = "Current",
  Next = "Next",
  Previous = "Previous",
}

export enum ScheduledPaymentLineItemType {
  ClubSubscriptions = "ClubSubscriptions",
  OneOnOneSchedules = "OneOnOneSchedules",
  WeeklyClassPayments = "WeeklyClassPayments",
}

export type CreatePricingOfferInput = {
  buyerOrgName: Scalars["String"];
  discount?: InputMaybe<Scalars["Float"]>;
  eligibleEmailDomains: Array<Scalars["String"]>;
  endBy: Scalars["DateTime"];
  excludedEmailAddresses: Array<Scalars["String"]>;
  includedEmailAddresses: Array<Scalars["String"]>;
  isFinancialAid: Scalars["Boolean"];
  organizationUid?: InputMaybe<Scalars["ID"]>;
  requirePaymentMethod: Scalars["Boolean"];
  startAfter: Scalars["DateTime"];
  totalCapCents: Scalars["Int"];
  userLimitCents: Scalars["Int"];
};

export type SetupIntent = {
  __typename?: "SetupIntent";
  setupIntentClientId: Scalars["String"];
};

export type CollegeDegreeCredentialInput = {
  degreeAdditionalInfo?: InputMaybe<Scalars["String"]>;
  degreeInstitution: Scalars["String"];
  degreeIssueDate?: InputMaybe<Scalars["DateTime"]>;
  degreeSubject: DegreeSubject;
  degreeType: DegreeType;
};

export type CredentialFileInput = {
  filename?: InputMaybe<Scalars["String"]>;
  handle?: InputMaybe<Scalars["ID"]>;
  mimetype?: InputMaybe<Scalars["String"]>;
  size?: InputMaybe<Scalars["Int"]>;
  source?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["String"]>;
  url?: InputMaybe<Scalars["String"]>;
};

export type OtherCredentialInput = {
  otherDetails: Scalars["String"];
  otherType: Scalars["String"];
};

export type TeacherCertificateCredentialInput = {
  certificateDiscipline: TeacherCertificateDiscipline;
  certificateDoesNotExpire?: InputMaybe<Scalars["Boolean"]>;
  certificateExpireDate?: InputMaybe<Scalars["DateTime"]>;
  certificateIssueDate?: InputMaybe<Scalars["DateTime"]>;
  certificateNumber: Scalars["String"];
  certificateSecondaryDiscipline?: InputMaybe<TeacherCertificateDiscipline>;
  certificateState: TeacherCertificateState;
};

export type CreateWaitlistSeatInput = {
  learner: WaitlistLearner;
  parentUid: Scalars["ID"];
  sectionUid: Scalars["ID"];
  waitlistSource?: InputMaybe<WaitlistSource>;
};

export type WaitlistLearner = {
  age: Scalars["Int"];
  date_of_birth?: InputMaybe<Scalars["DateTime"]>;
  name: Scalars["String"];
  uid?: InputMaybe<Scalars["String"]>;
};

export type DeleteSavedSearchPayload = {
  __typename?: "DeleteSavedSearchPayload";
  deletedSavedSearchUid: Scalars["ID"];
};

export enum ReportLength {
  /** Two or three paragraphs "long" */
  FewParagraphs = "FewParagraphs",
  /** Only one paragraph "short" */
  OneParagraph = "OneParagraph",
}

export type AuthTransfer = {
  __typename?: "AuthTransfer";
  learnerModeSwitchAuthRequirement: Maybe<LearnerModeSwitchAuthRequirement>;
  transferToken: Maybe<Scalars["String"]>;
};

export type PostLoginOption = {
  defaultLearnerAge?: InputMaybe<Scalars["Int"]>;
  isOnboardingSignup?: InputMaybe<Scalars["Boolean"]>;
  shouldCreateDefaultLearner?: InputMaybe<Scalars["Boolean"]>;
};

/** Which client is attempting to log in */
export enum LoginClient {
  AndroidLearnerApp = "androidLearnerApp",
  IOsLearnerApp = "iOSLearnerApp",
  Web = "Web",
}

export type CreateAccountWithProviderInput = {
  attribution?: InputMaybe<Attribution>;
  browserTimeZone?: InputMaybe<Scalars["String"]>;
  defaultLearnerAge?: InputMaybe<Scalars["Int"]>;
  isGiftCardSignup?: InputMaybe<Scalars["Boolean"]>;
  isLeader?: InputMaybe<Scalars["Boolean"]>;
  isOnboardingSignup?: InputMaybe<Scalars["Boolean"]>;
  locale?: InputMaybe<UserLocale>;
  shouldCreateDefaultLearner?: InputMaybe<Scalars["Boolean"]>;
  subscribe?: InputMaybe<Scalars["Boolean"]>;
};

export type LoginResultOrError = LoginError | LoginResult;

export type LoginError = {
  __typename?: "LoginError";
  error: CreateOrLoginError;
};

export enum CreateOrLoginError {
  AccountAlreadyExists = "AccountAlreadyExists",
  AccountAlreadyLinked = "AccountAlreadyLinked",
  AccountCreationNotAllowed = "AccountCreationNotAllowed",
  AccountLocked = "AccountLocked",
  AccountNotFound = "AccountNotFound",
  AccountSuspended = "AccountSuspended",
  IncorrectApp = "IncorrectApp",
  InvalidEmail = "InvalidEmail",
  InvalidName = "InvalidName",
  InvalidPermissions = "InvalidPermissions",
  LearnerAccountLinkFailed = "LearnerAccountLinkFailed",
  SocialLoginNotAllowed = "SocialLoginNotAllowed",
  UnknownError = "UnknownError",
}

export type LoginResult = {
  __typename?: "LoginResult";
  authentication: AuthenticationV1;
  error: Maybe<CreateOrLoginError>;
  isNewUser: Scalars["Boolean"];
};

export type AuthenticationV1 = Credentials & {
  __typename?: "AuthenticationV1";
  learnerTransferToken: Maybe<Scalars["String"]>;
  sessionToken: Scalars["String"];
};

export type LoginResultOrErrorV2 = LoginError | LoginResultV2;

export type LoginResultV2 = {
  __typename?: "LoginResultV2";
  authentication: AuthenticationV2;
  error: Maybe<CreateOrLoginError>;
  isNewUser: Scalars["Boolean"];
};

export enum ExternalActivitySource {
  Mux = "mux",
}

/** The result of a sucessful call to "markNotificationRead" */
export type MarkNotificationReadResult = {
  __typename?: "MarkNotificationReadResult";
  /** Whether or not the mutation changed if the learner has more unread notifications between now and after */
  learnerHasUnreadNotifications: Scalars["Boolean"];
};

export type PauseOngoingEnrollmentInput = {
  dates: Array<DateRange>;
  enrollmentUid: Scalars["ID"];
};

export type DateRange = {
  endDate: Scalars["DateTime"];
  startDate: Scalars["DateTime"];
};

export enum TokenRefreshSource {
  Apollo = "Apollo",
  Client = "Client",
  LearnerApp = "LearnerApp",
}

export enum RefundType {
  AddOutschoolCredit = "AddOutschoolCredit",
  StandardRefund = "StandardRefund",
}

export type ClassInquiryTeacher = {
  __typename?: "ClassInquiryTeacher";
  reply_message_uid: Scalars["ID"];
  uid: Scalars["ID"];
};

export type RepriceTutoringResult = {
  __typename?: "RepriceTutoringResult";
  canceledMeetingUids: Array<Scalars["String"]>;
  enrollment: Enrollment;
};

export type RequestClassInquiryResponse = {
  __typename?: "RequestClassInquiryResponse";
  classInquiries: Array<Maybe<ClassInquiry>>;
  followUpQuestion: Maybe<Scalars["String"]>;
  searchPath: Maybe<Scalars["String"]>;
  topRecommendedActivityListing: Maybe<Listing>;
};

export type LearnerProfileInput = {
  aboutMe?: InputMaybe<Scalars["String"]>;
  avatar?: InputMaybe<Scalars["String"]>;
};

export type LearnerProfileSettingsInput = {
  isProfileAgeHidden?: InputMaybe<Scalars["Boolean"]>;
  isProfileLocationHidden?: InputMaybe<Scalars["Boolean"]>;
};

export type ChatConversationMessageInput = {
  content: Scalars["String"];
  role: Role;
};

export enum Role {
  Assistant = "assistant",
  System = "system",
  User = "user",
}

export type FollowUpQuestionReply = {
  __typename?: "FollowUpQuestionReply";
  /** @deprecated Use followUpQuestion instead. This field was deprecated to make the casing of followUp consistent. */
  followupQuestion: Scalars["String"];
  followUpQuestion: Scalars["String"];
  searchPath: Maybe<Scalars["String"]>;
  topRecommendedActivityListing: Maybe<Listing>;
};

export type MessageInput = {
  activityUid?: InputMaybe<Scalars["ID"]>;
  bulkGroupUid?: InputMaybe<Scalars["ID"]>;
  content?: InputMaybe<Scalars["String"]>;
  details: MessageInputDetails;
  messageContentInput?: InputMaybe<MessageContentInput>;
  sectionUid?: InputMaybe<Scalars["ID"]>;
};

export type MessageInputDetails = {
  action: Scalars["String"];
  activityTitle?: InputMaybe<Scalars["String"]>;
  activityUrl?: InputMaybe<Scalars["String"]>;
  classScheduleRequestUid?: InputMaybe<Scalars["ID"]>;
  leaderName?: InputMaybe<Scalars["ID"]>;
  leaderUid?: InputMaybe<Scalars["ID"]>;
  parentName?: InputMaybe<Scalars["String"]>;
  parentUid?: InputMaybe<Scalars["ID"]>;
  section?: InputMaybe<Scalars["String"]>;
  sectionUrl?: InputMaybe<Scalars["String"]>;
  studentName?: InputMaybe<Scalars["String"]>;
  subject?: InputMaybe<Scalars["String"]>;
  teacherAdminUrl?: InputMaybe<Scalars["String"]>;
};

export type ProgressReportDetailsInput = {
  didChangeGeneratedReport: Scalars["Boolean"];
  didGenerateReport: Scalars["Boolean"];
  originalNotes: Scalars["String"];
  reportLength: ReportLength;
};

export type BulkPurchaseRecurringMeetingsInfo = {
  durationWeeks?: InputMaybe<Scalars["Int"]>;
  enrollmentUid: Scalars["ID"];
};

export type PurchaseRecurringMeetingsInfo = {
  /**
   * The total number of weeks this enrollment time should repeat. If omitted, the enrollment
   * time will be considered continuous and repeated until either the parent or teacher
   * cancel the enrollment time.
   */
  durationWeeks?: InputMaybe<Scalars["Int"]>;
};

export type StopPaymentScheduleInput = {
  scheduledPaymentLineItemType: ScheduledPaymentLineItemType;
  scheduleUid: Scalars["String"];
  stoppedReason: Scalars["String"];
};

export type UserSavedActivity = {
  activityUid: Scalars["ID"];
  savedAt: Scalars["DateTime"];
};

export enum SurpriseMeVariants {
  Random = "Random",
  Recommendations = "Recommendations",
}

export type TrackAnalyticsInitializedProperties = {
  analyticsLoadDuration?: InputMaybe<Scalars["Int"]>;
  analyticsMode?: InputMaybe<Scalars["String"]>;
  analyticsModeSource?: InputMaybe<Scalars["String"]>;
  analyticsModeSourceOverride?: InputMaybe<Scalars["String"]>;
  analyticsStatus?: InputMaybe<Scalars["String"]>;
  app?: InputMaybe<Scalars["String"]>;
  botCheck?: InputMaybe<Scalars["Boolean"]>;
  cloudflareBotScore?: InputMaybe<Scalars["Int"]>;
  cloudflareBotVerified?: InputMaybe<Scalars["Boolean"]>;
  cloudflareThreatScore?: InputMaybe<Scalars["Int"]>;
  deviceUid?: InputMaybe<Scalars["String"]>;
  height?: InputMaybe<Scalars["Int"]>;
  initializationDuration?: InputMaybe<Scalars["Int"]>;
  isBot?: InputMaybe<Scalars["Boolean"]>;
  isMobile?: InputMaybe<Scalars["Boolean"]>;
  modeResolutionDuration?: InputMaybe<Scalars["Int"]>;
  pageCategory?: InputMaybe<Scalars["String"]>;
  pageImpressionId?: InputMaybe<Scalars["String"]>;
  pageLoadId?: InputMaybe<Scalars["String"]>;
  pageName?: InputMaybe<Scalars["String"]>;
  pagePath?: InputMaybe<Scalars["String"]>;
  pluginLoadDuration?: InputMaybe<Scalars["Int"]>;
  width?: InputMaybe<Scalars["Int"]>;
};

export type TrackCheckoutSessionCompletedProperties = {
  checkoutSessionId?: InputMaybe<Scalars["String"]>;
  osRef?: InputMaybe<OsRefInput>;
};

export type TrackClassPageImpressionInput = {
  activityUid: Scalars["String"];
  sectionUid?: InputMaybe<Scalars["String"]>;
};

export type TrackNewBuyerAcquiredProperties = {
  amount: Scalars["Int"];
  fbp?: InputMaybe<Scalars["String"]>;
  osRef?: InputMaybe<OsRefInput>;
};

export type TrackOrderCompletedProperties = {
  cartUid?: InputMaybe<Scalars["String"]>;
  enrollmentConfirmedAt: Scalars["DateTime"];
  fbp?: InputMaybe<Scalars["String"]>;
  isBulkCheckout?: InputMaybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  orderId: Scalars["String"];
  osRef?: InputMaybe<OsRefInput>;
  price: Scalars["Int"];
  productId: Scalars["String"];
  quantity: Scalars["Int"];
  total: Scalars["Int"];
};

export type TrackPageLoadProperties = {
  anonymousId?: InputMaybe<Scalars["String"]>;
  app?: InputMaybe<Scalars["String"]>;
  cloudflare_bot_score?: InputMaybe<Scalars["Int"]>;
  cloudflare_bot_verified?: InputMaybe<Scalars["Boolean"]>;
  cloudflare_threat_score?: InputMaybe<Scalars["Int"]>;
  is_bot?: InputMaybe<Scalars["Boolean"]>;
  isMobile?: InputMaybe<Scalars["Boolean"]>;
  loggedInUserExperimentUid?: InputMaybe<Scalars["String"]>;
  loggedOutUserExperimentUid?: InputMaybe<Scalars["String"]>;
  pageLoadId?: InputMaybe<Scalars["String"]>;
  pageName?: InputMaybe<Scalars["String"]>;
  pagePath?: InputMaybe<Scalars["String"]>;
};

export type TrackSubscriptionInterestProperties = {
  email: Scalars["String"];
  osRef?: InputMaybe<OsRefInput>;
  package: Scalars["String"];
};

export type TrackUserAttributionSurveyResponseInput = {
  additionalDetails?: InputMaybe<Scalars["String"]>;
  response: Scalars["String"];
};

export type TrackUserCreatedProperties = {
  fbp?: InputMaybe<Scalars["String"]>;
  osRef?: InputMaybe<OsRefInput>;
};

export type UnpauseOngoingEnrollmentsPausedForPaymentFailureInput = {
  enrollmentUids: Array<Scalars["ID"]>;
  pauseEndDate?: InputMaybe<Scalars["Date"]>;
};

export type UnpausedEnrollmentResults = {
  __typename?: "UnpausedEnrollmentResults";
  paidEnrollments: Array<Enrollment>;
  unsubscribedEnrollments: Array<Enrollment>;
  updatedEnrollments: Array<Enrollment>;
};

export type ActivityDetailsInput = {
  asyncTime?: InputMaybe<Scalars["String"]>;
  materials?: InputMaybe<Scalars["String"]>;
  photo?: InputMaybe<Scalars["String"]>;
  photoSquare?: InputMaybe<Scalars["String"]>;
  unpublishReason?: InputMaybe<Scalars["String"]>;
};

export type ActivityInput = {
  age_max?: InputMaybe<Scalars["Int"]>;
  age_min?: InputMaybe<Scalars["Int"]>;
  allows_late_enrollment?: InputMaybe<Scalars["Boolean"]>;
  allows_late_enrollments_for_fixed_length_classes?: InputMaybe<
    Scalars["Boolean"]
  >;
  allows_recurring_payment?: InputMaybe<Scalars["Boolean"]>;
  assessment?: InputMaybe<Scalars["String"]>;
  attributes?: InputMaybe<Array<EntityAttributeInput>>;
  auto_scheduling_days_notice_max?: InputMaybe<Scalars["Int"]>;
  auto_scheduling_days_notice_min?: InputMaybe<Scalars["Int"]>;
  class_experience?: InputMaybe<Scalars["String"]>;
  classFormat?: InputMaybe<EducatorSelectedFormat>;
  club_banner_image_uid?: InputMaybe<Scalars["ID"]>;
  club_welcome_video_uid?: InputMaybe<Scalars["ID"]>;
  description?: InputMaybe<Scalars["String"]>;
  disclaimer?: InputMaybe<Scalars["String"]>;
  duration_minutes?: InputMaybe<Scalars["Int"]>;
  duration_weeks?: InputMaybe<Scalars["Int"]>;
  english_proficiency_level?: InputMaybe<EnglishProficiencyLevel>;
  grade_level_max?: InputMaybe<Scalars["String"]>;
  grade_level_min?: InputMaybe<Scalars["String"]>;
  grading_policy?: InputMaybe<Scalars["String"]>;
  has_building_content?: InputMaybe<Scalars["Boolean"]>;
  homework?: InputMaybe<Scalars["String"]>;
  is_auto_scheduling_enabled?: InputMaybe<Scalars["Boolean"]>;
  is_ongoing_weekly?: InputMaybe<Scalars["Boolean"]>;
  is_online?: InputMaybe<Scalars["Boolean"]>;
  is_waitlist_allowed?: InputMaybe<Scalars["Boolean"]>;
  isArchived?: InputMaybe<Scalars["Boolean"]>;
  isPublished?: InputMaybe<Scalars["Boolean"]>;
  isSelfPaced?: InputMaybe<Scalars["Boolean"]>;
  isSelfPacedEnrollmentEnabled?: InputMaybe<Scalars["Boolean"]>;
  language_of_instruction?: InputMaybe<Scalars["Iso639"]>;
  learning_goals?: InputMaybe<Scalars["String"]>;
  parental_guidance?: InputMaybe<Scalars["String"]>;
  photo?: InputMaybe<Scalars["String"]>;
  prerequisites?: InputMaybe<Scalars["String"]>;
  price_cents?: InputMaybe<Scalars["Int"]>;
  pricingAdjustments?: InputMaybe<PricingAdjustmentInput>;
  proficiency_level?: InputMaybe<ProficiencyLevelInput>;
  refund_policy_type?: InputMaybe<RefundPolicyType>;
  sample_club_post?: InputMaybe<WelcomeMessageInput>;
  sampleClubPosts?: InputMaybe<Array<SampleClubPostInput>>;
  selfPacedMigration?: InputMaybe<SelfPacedMigrationStatusInput>;
  size_max?: InputMaybe<Scalars["Int"]>;
  size_min?: InputMaybe<Scalars["Int"]>;
  sources?: InputMaybe<Scalars["String"]>;
  strictAgeRange?: InputMaybe<AgeRangeInput>;
  subject?: InputMaybe<Scalars["String"]>;
  suggestedWeeklyMeetings?: InputMaybe<Scalars["Int"]>;
  summary?: InputMaybe<Scalars["String"]>;
  summary_for_learners?: InputMaybe<Scalars["String"]>;
  tags?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
  upsell_activity_uid?: InputMaybe<Scalars["ID"]>;
  video_for_learners_uid?: InputMaybe<Scalars["ID"]>;
  video_uid?: InputMaybe<Scalars["ID"]>;
  weekly_meetings?: InputMaybe<Scalars["Int"]>;
  welcome_message?: InputMaybe<WelcomeMessageInput>;
  why_me?: InputMaybe<Scalars["String"]>;
};

export type EntityAttributeInput = {
  key: Scalars["String"];
  value?: InputMaybe<Scalars["String"]>;
};

export type PricingAdjustmentInput = {
  weeklySurchargePercentage?: InputMaybe<Scalars["Float"]>;
};

export type ProficiencyLevelInput = {
  level?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  text?: InputMaybe<Scalars["String"]>;
};

export type WelcomeMessageInput = {
  attachments?: InputMaybe<Array<Scalars["ID"]>>;
  text?: InputMaybe<Scalars["String"]>;
  video?: InputMaybe<Scalars["ID"]>;
};

export type SampleClubPostInput = {
  attachments?: InputMaybe<Array<Scalars["ID"]>>;
  comments?: InputMaybe<Array<SampleClubPostCommentInput>>;
  text?: InputMaybe<Scalars["String"]>;
  video?: InputMaybe<Scalars["ID"]>;
};

export type SampleClubPostCommentInput = {
  text: Scalars["String"];
};

export type SelfPacedMigrationStatusInput = {
  gitCommitSha: Scalars["String"];
  hasConsented: Scalars["Boolean"];
};

export type AgeRangeInput = {
  max: Scalars["Int"];
  min: Scalars["Int"];
};

export type UpdateAssignmentClassPostInput = {
  dueDate?: InputMaybe<Scalars["DateTime"]>;
  lessonUid?: InputMaybe<Scalars["ID"]>;
  messageContentInput?: InputMaybe<MessageContentInput>;
  publishAt?: InputMaybe<Scalars["DateTime"]>;
  sectionUid?: InputMaybe<Scalars["ID"]>;
  submissionRequirement?: InputMaybe<SubmissionRequirementType>;
  title?: InputMaybe<Scalars["String"]>;
};

export type UpdateAssignmentLessonPostInput = {
  lessonUid?: InputMaybe<Scalars["ID"]>;
  messageContent?: InputMaybe<MessageContentInput>;
  orderInLesson?: InputMaybe<Scalars["Int"]>;
  submissionRequirement?: InputMaybe<SubmissionRequirementType>;
  title?: InputMaybe<Scalars["String"]>;
};

export type UpdateBundleInput = {
  description?: InputMaybe<Scalars["String"]>;
  discount?: InputMaybe<Scalars["Float"]>;
  isOrdered?: InputMaybe<Scalars["Boolean"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]>;
  thumbnail?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
  /**
   * Some context for expected fields inside of updatedBundleElements:
   * if a BundleElement existed before this update mutation expects the object to have a uid, a activityUid and orderInBundle
   * if a BundleElement is new: the mutation expects the object to have a activityUid and orderInBundle
   */
  updatedBundleElements: Array<UpdateBundleElementInput>;
};

export type UpdateBundleElementInput = {
  activityUid: Scalars["ID"];
  orderInBundle: Scalars["Int"];
  uid?: InputMaybe<Scalars["ID"]>;
};

export type CouponUpdateInput = {
  deletedAt?: InputMaybe<Scalars["DateTime"]>;
  expiresAt?: InputMaybe<Scalars["DateTime"]>;
  minPurchaseAmountCents?: InputMaybe<Scalars["Int"]>;
};

export type UserDetailsInput = {
  about?: InputMaybe<Scalars["String"]>;
  browserTimeZone?: InputMaybe<Scalars["String"]>;
  countryOfResidence?: InputMaybe<Scalars["String"]>;
  headline?: InputMaybe<Scalars["String"]>;
  howFoundOutschool?: InputMaybe<Scalars["String"]>;
  howFoundOutschoolOther?: InputMaybe<Scalars["String"]>;
  introduction?: InputMaybe<Scalars["String"]>;
  socialOptOut?: InputMaybe<Scalars["Boolean"]>;
  timeRangeAfterSchool?: InputMaybe<Scalars["Boolean"]>;
  timeRangeSchool?: InputMaybe<Scalars["Boolean"]>;
  timeRangeWeekend?: InputMaybe<Scalars["Boolean"]>;
  website?: InputMaybe<Scalars["String"]>;
};

export type LocationInput = {
  address?: InputMaybe<Scalars["String"]>;
  city?: InputMaybe<Scalars["String"]>;
  geo?: InputMaybe<Scalars["String"]>;
  lat?: InputMaybe<Scalars["Float"]>;
  lng?: InputMaybe<Scalars["Float"]>;
  placeDescription?: InputMaybe<Scalars["String"]>;
  placeName?: InputMaybe<Scalars["String"]>;
  state?: InputMaybe<Scalars["String"]>;
  timeZone?: InputMaybe<Scalars["String"]>;
};

export type TeacherAttributesInput = {
  autoSchedulingBufferMinutes?: InputMaybe<Scalars["Int"]>;
  isAutoSchedulingEnabled?: InputMaybe<Scalars["Boolean"]>;
  mainToolbarPinnedTabs?: InputMaybe<Array<MainToolbarPinnedTabs>>;
  showCalendar?: InputMaybe<Scalars["Boolean"]>;
};

export type AdminEnrollmentPaymentRefundInput = {
  action: AdminEnrollmentPaymentRefundActionType;
  adminNoteType?: InputMaybe<AdminEnrollmentPaymentRefundNoteType>;
  notes?: InputMaybe<Scalars["String"]>;
};

export enum AdminEnrollmentPaymentRefundActionType {
  ReducesTeacherPayoutFalse = "REDUCES_TEACHER_PAYOUT_FALSE",
  ReducesTeacherPayoutTrue = "REDUCES_TEACHER_PAYOUT_TRUE",
}

export enum AdminEnrollmentPaymentRefundNoteType {
  RefundUpdateAuditLog = "REFUND_UPDATE_AUDIT_LOG",
}

export type EnrollmentPaymentRefundInput = {
  reason: Scalars["String"];
  reduces_teacher_payout: Scalars["Boolean"];
  refunded_at?: InputMaybe<Scalars["DateTime"]>;
};

/** uid - entity availability schedule uid */
export type UpdateEntityAvailabilityScheduleInput = {
  entityAvailabilities: Array<EntityAvailabilityInput>;
  uid: Scalars["ID"];
};

export type GiftCardUpdateInput = {
  deliverAfter?: InputMaybe<Scalars["DateTime"]>;
  note?: InputMaybe<Scalars["String"]>;
  recipientEmail?: InputMaybe<Scalars["String"]>;
  recipientName?: InputMaybe<Scalars["String"]>;
  senderEmail?: InputMaybe<Scalars["String"]>;
  senderName?: InputMaybe<Scalars["String"]>;
};

export type LessonInput = {
  description?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
  unitUid?: InputMaybe<Scalars["ID"]>;
};

export type UpdateLessonPostInput = {
  isPostedForEnrollments?: InputMaybe<Scalars["Boolean"]>;
  isPostedForSubscriptions?: InputMaybe<Scalars["Boolean"]>;
  lessonUid?: InputMaybe<Scalars["ID"]>;
  messageContent?: InputMaybe<MessageContentInput>;
  orderInLesson?: InputMaybe<Scalars["Int"]>;
  title?: InputMaybe<Scalars["String"]>;
};

export type MeetingAiSummaryPatchInput = {
  deleted_at?: InputMaybe<Scalars["DateTime"]>;
  edited_summary_text?: InputMaybe<Scalars["String"]>;
  is_skipped?: InputMaybe<Scalars["Boolean"]>;
};

export type UpdateMeetingInput = {
  endTime?: InputMaybe<Scalars["DateTime"]>;
  lessonUid?: InputMaybe<Scalars["ID"]>;
  meetingUid: Scalars["ID"];
  startTime?: InputMaybe<Scalars["DateTime"]>;
};

export type UpdateMeetingLessonInput = {
  lessonUid?: InputMaybe<Scalars["ID"]>;
  meetingUid: Scalars["ID"];
};

export type UpdateOrganizationInput = {
  address?: InputMaybe<Scalars["String"]>;
  invoiceEmail?: InputMaybe<Scalars["String"]>;
  logoUrl?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  notes?: InputMaybe<Scalars["String"]>;
  state?: InputMaybe<Scalars["String"]>;
  techSupportEmail?: InputMaybe<Scalars["String"]>;
  totalCapCents?: InputMaybe<Scalars["Int"]>;
  trustSafetyEmail?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<OrganizationType>;
  urlName?: InputMaybe<Scalars["String"]>;
};

export type UpdatePricingOfferInput = {
  buyerOrgName?: InputMaybe<Scalars["String"]>;
  discount?: InputMaybe<Scalars["Float"]>;
  eligibleEmailDomains?: InputMaybe<Array<Scalars["String"]>>;
  endBy?: InputMaybe<Scalars["DateTime"]>;
  excludedEmailAddresses?: InputMaybe<Array<Scalars["String"]>>;
  includedEmailAddresses?: InputMaybe<Array<Scalars["String"]>>;
  isFinancialAid?: InputMaybe<Scalars["Boolean"]>;
  organizationUid?: InputMaybe<Scalars["ID"]>;
  requirePaymentMethod?: InputMaybe<Scalars["Boolean"]>;
  startAfter?: InputMaybe<Scalars["DateTime"]>;
  totalCapCents?: InputMaybe<Scalars["Int"]>;
  userLimitCents?: InputMaybe<Scalars["Int"]>;
};

export type ActivityScheduleSettingsInput = {
  maximumNotice?: InputMaybe<Scalars["String"]>;
  mininumNotice?: InputMaybe<Scalars["String"]>;
  suggestedWeeklyMeetings?: InputMaybe<Scalars["Int"]>;
};

export type LeaderSectionDetailInput = {
  date?: InputMaybe<Scalars["String"]>;
  originalEndTime?: InputMaybe<Scalars["DateTime"]>;
};

export type OnlineClassroomInput = {
  type?: InputMaybe<Scalars["String"]>;
  url?: InputMaybe<Scalars["String"]>;
};

export type LeaderSectionInput = {
  end_time?: InputMaybe<Scalars["DateTime"]>;
  isCanceled?: InputMaybe<Scalars["Boolean"]>;
  isDeleted?: InputMaybe<Scalars["Boolean"]>;
  isPublished?: InputMaybe<Scalars["Boolean"]>;
  price_cents?: InputMaybe<Scalars["Int"]>;
  size_max?: InputMaybe<Scalars["Int"]>;
  start_time?: InputMaybe<Scalars["DateTime"]>;
  user_uid?: InputMaybe<Scalars["ID"]>;
};

export type SectionAdminInput = {
  notes?: InputMaybe<Scalars["String"]>;
};

export type UpdateSellerOrgAgreementInput = {
  agreementTemplateUid?: InputMaybe<Scalars["ID"]>;
  contractSignedBy?: InputMaybe<Scalars["String"]>;
  contractSignerTitle?: InputMaybe<Scalars["String"]>;
  legalAddress?: InputMaybe<Scalars["String"]>;
  legalEntityType?: InputMaybe<Scalars["String"]>;
  legalName?: InputMaybe<Scalars["String"]>;
  legalState?: InputMaybe<Scalars["String"]>;
  ownsBackgroundChecks?: InputMaybe<Scalars["Boolean"]>;
};

export type SellerOrgApplicationInput = {
  additionalInfo?: InputMaybe<Scalars["String"]>;
  alreadyApprovedTeacher?: InputMaybe<Scalars["Boolean"]>;
  alreadyApprovedTeacherEmail?: InputMaybe<Scalars["String"]>;
  classesInfo?: InputMaybe<Scalars["String"]>;
  countries?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  firstClassDescription?: InputMaybe<Scalars["String"]>;
  learnersInfo?: InputMaybe<Scalars["String"]>;
  learnerTypes?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  numberOfClassesPerWeek?: InputMaybe<Scalars["String"]>;
  numberOfStudentsOnAverage?: InputMaybe<Scalars["String"]>;
  numberOfTeachers?: InputMaybe<Scalars["String"]>;
  organizationPhone?: InputMaybe<Scalars["String"]>;
  primaryContactName?: InputMaybe<Scalars["String"]>;
  primaryContactTitle?: InputMaybe<Scalars["String"]>;
  publicReviews?: InputMaybe<Scalars["String"]>;
  sourcedFrom?: InputMaybe<Scalars["String"]>;
  teachersInfo?: InputMaybe<Scalars["String"]>;
  timeInBusiness?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<Scalars["String"]>;
  typeDetail?: InputMaybe<Scalars["String"]>;
  website?: InputMaybe<Scalars["String"]>;
  whenFounded?: InputMaybe<Scalars["String"]>;
};

export type SubscriptionInformation = {
  __typename?: "SubscriptionInformation";
  numCredits: Maybe<Scalars["Int"]>;
  packageName: Maybe<Scalars["String"]>;
  subscriptionId: Maybe<Scalars["String"]>;
};

export type TeacherApplicationInput = {
  age_ranges_taught?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  class_subjects?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  class_types?: InputMaybe<Scalars["String"]>;
  experience_types?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  how_did_you_hear_about_us?: InputMaybe<Scalars["String"]>;
  legal_name?: InputMaybe<Scalars["String"]>;
  online_profiles?: InputMaybe<Scalars["String"]>;
  sample_classes?: InputMaybe<Scalars["String"]>;
  teaching_credentials?: InputMaybe<Scalars["String"]>;
  teaching_experience?: InputMaybe<Scalars["String"]>;
  teaching_motivation?: InputMaybe<Scalars["String"]>;
  video_uid?: InputMaybe<Scalars["ID"]>;
  years_of_experience?: InputMaybe<Scalars["String"]>;
};

export type AvailabilityInput = {
  hourlyTimetable: HourlyTimetableInput;
};

export type HourlyTimetableInput = {
  fri: Array<Scalars["Int"]>;
  mon: Array<Scalars["Int"]>;
  sat: Array<Scalars["Int"]>;
  sun: Array<Scalars["Int"]>;
  thu: Array<Scalars["Int"]>;
  tue: Array<Scalars["Int"]>;
  wed: Array<Scalars["Int"]>;
};

export type ScheduleInput = {
  bufferTime?: InputMaybe<Scalars["String"]>;
  meetingsStartTimeIncrementsInMinutes?: InputMaybe<Scalars["Int"]>;
};

export type AvailableTimesInput = {
  endTime: Scalars["DateTime"];
  isRecurring: Scalars["Boolean"];
  isUnavailable: Scalars["Boolean"];
  startTime: Scalars["DateTime"];
};

export type UnitInput = {
  title?: InputMaybe<Scalars["String"]>;
};

export type UserAdminInput = {
  is_crosslister?: InputMaybe<Scalars["Boolean"]>;
  leader_link?: InputMaybe<Scalars["String"]>;
};

export type ValidateMeetingRequestsForPurchaseResponse = {
  __typename?: "ValidateMeetingRequestsForPurchaseResponse";
  error: Maybe<ValidateMeetingRequestsForPurchaseError>;
  isValid: Scalars["Boolean"];
};

export enum ValidateMeetingRequestsForPurchaseError {
  /** Cannot accept reschedule requests via Mutation.settleOrderEnrollmentMeetingPurchase.  Use Mutation.acceptMeetingReschedule */
  CannotAcceptRescheduleRequestsForPurchase = "CannotAcceptRescheduleRequestsForPurchase",
  /** Enrollment meeting purchase record not found */
  EnrollmentMeetingPurchaseRecordNotFound = "EnrollmentMeetingPurchaseRecordNotFound",
  /** One or more meeting requests have been deleted */
  MeetingRequestDeleted = "MeetingRequestDeleted",
  /** Meeting requests do not belong to purchased activity */
  MeetingRequestDoesNotBelongToActivity = "MeetingRequestDoesNotBelongToActivity",
  /** Meeting requests do not belong to purchased section */
  MeetingRequestDoesNotBelongToSection = "MeetingRequestDoesNotBelongToSection",
  /** Meeting requests are not pending */
  MeetingRequestIsNotPending = "MeetingRequestIsNotPending",
  /** No meeting tied to enrollment meeting purchase */
  NoMeetingFoundForEnrollmentMeetingPurchase = "NoMeetingFoundForEnrollmentMeetingPurchase",
  /** Only some meeting requests in batch are recurring */
  OnlySomeMeetingRequestsAreRecurring = "OnlySomeMeetingRequestsAreRecurring",
  /** Not supported needs fixing: See https://outschool.atlassian.net/browse/ESP-2819 */
  RecurringMeetingRequestsNotSupported = "RecurringMeetingRequestsNotSupported",
  /** Purchased time slot was not the first request chronologically */
  TimeSlotChronologyMismatch = "TimeSlotChronologyMismatch",
  /** Did not purchase a time slot that is the same as one of the meeting requests */
  TimeSlotMeetingRequestMismatch = "TimeSlotMeetingRequestMismatch",
}

export type VerifyPinResult = {
  __typename?: "VerifyPinResult";
  success: Scalars["Boolean"];
};

export type AdminUserActionInput = {
  action: Scalars["String"];
  customValue?: InputMaybe<Scalars["String"]>;
  notes?: InputMaybe<Scalars["String"]>;
  user_uid: Scalars["ID"];
};

export type AdminUserIncidentInput = {
  category?: InputMaybe<Scalars["String"]>;
  notes?: InputMaybe<Scalars["String"]>;
};

export enum CacheControlScope {
  Private = "PRIVATE",
  Public = "PUBLIC",
}

export type ClassRecording = Recording & {
  __typename?: "ClassRecording";
  duration: Scalars["Int"];
  endTime: Scalars["DateTime"];
  isExpired: Scalars["Boolean"];
  meeting: Maybe<Meeting>;
  playUrl: Maybe<Scalars["String"]>;
  readyToPlay: Maybe<Scalars["Boolean"]>;
  recordingId: Scalars["ID"];
  startTime: Scalars["DateTime"];
  uid: Scalars["ID"];
};

export type ClassWalletTransactionListing = TransactionListing & {
  __typename?: "ClassWalletTransactionListing";
  activity: Maybe<Activity>;
  date: Scalars["DateTime"];
  enrollmentPayments: Maybe<Array<EnrollmentPayment>>;
  enrollments: Maybe<Array<BaseEnrollment>>;
  id: Scalars["ID"];
  pendingOrderPayments: Maybe<Array<PendingOrderPayment>>;
  type: TransactionType;
};

export type PendingOrderPayment = {
  __typename?: "PendingOrderPayment";
  amount_cents: Maybe<Scalars["Int"]>;
  coupon_code_uid: Maybe<Scalars["String"]>;
  couponCode: Maybe<CouponCode>;
  currency_code: Maybe<Scalars["String"]>;
  exchange_rate: Maybe<Scalars["Float"]>;
  uid: Scalars["ID"];
};

export type CreditPurchase = {
  __typename?: "CreditPurchase";
  amount_cents: Scalars["Int"];
  amount_granted_cents: Scalars["Int"];
  amount_granted_credits: Maybe<Scalars["Int"]>;
  creditAccount: Maybe<CreditAccount>;
  date: Scalars["DateTime"];
  id: Scalars["ID"];
  order_slug_id: Scalars["ID"];
};

export type CreditTransactionListing = TransactionListing & {
  __typename?: "CreditTransactionListing";
  creditTransaction: CreditTransaction;
  date: Scalars["DateTime"];
  id: Scalars["ID"];
  subscriptionInvoice: Maybe<SubscriptionInvoice>;
  type: TransactionType;
};

export type SubscriptionInvoice = {
  __typename?: "SubscriptionInvoice";
  amount_paid: Scalars["Int"];
  currency_code: Scalars["String"];
  invoice_url: Maybe<Scalars["String"]>;
  period_end: Scalars["DateTime"];
  period_start: Scalars["DateTime"];
  uid: Scalars["String"];
  wasRefunded: Maybe<Scalars["Boolean"]>;
};

export type EnrollingOrganizationLearner = {
  __typename?: "EnrollingOrganizationLearner";
  age: Maybe<Scalars["Int"]>;
  name: Maybe<Scalars["String"]>;
  uid: Scalars["ID"];
};

export type EnrollingOrganizationUser = {
  __typename?: "EnrollingOrganizationUser";
  learners: Array<Maybe<EnrollingOrganizationLearner>>;
  name: Scalars["String"];
  uid: Scalars["ID"];
};

export enum EnrollmentPauseWindowReason {
  PaymentFailure = "payment_failure",
  UserAction = "user_action",
}

export type GiftEnrollments = {
  __typename?: "GiftEnrollments";
  learner: Maybe<Learner>;
  parent: Maybe<Parent>;
  uid: Scalars["ID"];
};

export type InterfacePreferencesInput = {
  activityFormatDescriptionFlexHidden?: InputMaybe<Scalars["Boolean"]>;
  activityFormatDescriptionMultiDayHidden?: InputMaybe<Scalars["Boolean"]>;
  activityFormatDescriptionOneTimeHidden?: InputMaybe<Scalars["Boolean"]>;
  activityFormatDescriptionOngoingHidden?: InputMaybe<Scalars["Boolean"]>;
  defaultScheduleFilters?: InputMaybe<UserScheduleFiltersInput>;
};

export type UserScheduleFiltersInput = {
  dow?: InputMaybe<Scalars["String"]>;
  endBy?: InputMaybe<Scalars["String"]>;
  endByTime?: InputMaybe<Scalars["Float"]>;
  startAfter?: InputMaybe<Scalars["String"]>;
  startAfterTime?: InputMaybe<Scalars["Float"]>;
  startBefore?: InputMaybe<Scalars["String"]>;
};

export type LearningStatementsLearner = LearningStatementsActor & {
  __typename?: "LearningStatementsLearner";
  learner: Learner;
  uid: Scalars["ID"];
};

export type NextClassUpsell = {
  __typename?: "NextClassUpsell";
  enrolledActivity: Activity;
  nextListing: Listing;
};

export type OrderTransactionListing = TransactionListing & {
  __typename?: "OrderTransactionListing";
  creditPurchase: Maybe<CreditPurchase>;
  date: Scalars["DateTime"];
  donation: Maybe<Donation>;
  enrollmentPayments: Maybe<Array<EnrollmentPayment>>;
  enrollments: Maybe<Array<BaseEnrollment>>;
  giftCard: Maybe<GiftCard>;
  id: Scalars["ID"];
  learnerAllowancePayments: Maybe<Array<LearnerAllowancePayment>>;
  payments: Maybe<Array<OrderPayment>>;
  type: TransactionType;
};

export type OrganizationDirectory = {
  __typename?: "OrganizationDirectory";
  learners: Maybe<Array<Maybe<Child>>>;
};

export type PhotoDimensions = {
  height?: InputMaybe<Scalars["Int"]>;
  width?: InputMaybe<Scalars["Int"]>;
};

export type PopularTermSuggestions = {
  __typename?: "PopularTermSuggestions";
  popularTermSuggestions: Array<PopularTerms>;
};

export type PricingOfferWeekAttendances = {
  __typename?: "PricingOfferWeekAttendances";
  missedMeetings: Scalars["Int"];
  totalMeetings: Scalars["Int"];
  week: Scalars["DateTime"];
};

export type PrivacyStatusInput = {
  __typename?: "PrivacyStatusInput";
  listUid: Scalars["ID"];
  privacyStatus: SavedClassListStatus;
};

export type SchoolClosureInputV2 = {
  affected: Scalars["Boolean"];
  application_status?: InputMaybe<FfapApplicationStatus>;
  hispanic_latino: Scalars["Boolean"];
  household_size: Scalars["Int"];
  household_size_3_to_18: Scalars["Int"];
  how_did_you_hear: Scalars["String"];
  income: Scalars["String"];
  interested_in_research: Scalars["Boolean"];
  interested_in_sharing: Scalars["Boolean"];
  learning_environments: Array<Scalars["String"]>;
  primary_language: Scalars["String"];
  race_ethnicity: Array<Scalars["String"]>;
  reliable_internet: Scalars["Boolean"];
  zip_code: Scalars["String"];
};

export type SellerOrgAction = {
  __typename?: "SellerOrgAction";
  action: Scalars["String"];
  actor: User;
  created_at: Scalars["DateTime"];
  messageToSellerOrg: Maybe<Scalars["String"]>;
  notes: Maybe<Scalars["String"]>;
  sellerOrgUser: User;
  uid: Scalars["ID"];
};

export type SellerOrgActionInput = {
  __typename?: "SellerOrgActionInput";
  action: Scalars["String"];
  actorUid: Scalars["ID"];
  messageToSellerOrg: Maybe<Scalars["String"]>;
  notes: Maybe<Scalars["String"]>;
  sellerOrgUserUid: Scalars["ID"];
};

export enum SignupInitiator {
  User = "user",
}

export type SuggestedMeetingStats = {
  __typename?: "SuggestedMeetingStats";
  allTimes: Scalars["Int"];
  userPreferredTimes: Scalars["Int"];
};

export enum SuspendedReason {
  AccountTermination = "AccountTermination",
  EducatorRemovalRequest = "EducatorRemovalRequest",
  FamilyTermsofServiceViolation = "FamilyTermsofServiceViolation",
  InactiveAccount = "InactiveAccount",
  PolicyViolation = "PolicyViolation",
  SuspiciousActivity = "SuspiciousActivity",
  TemporarySuspension = "TemporarySuspension",
  TooManyFailedLoginAttempts = "TooManyFailedLoginAttempts",
  TrustAndSafetySuspension = "TrustAndSafetySuspension",
  UserRequestFullDeletion = "UserRequestFullDeletion",
}

export type TagInput = {
  key: Scalars["String"];
  value: Scalars["String"];
};

export type ZoomRecording = Recording & {
  __typename?: "ZoomRecording";
  endTime: Scalars["DateTime"];
  meeting: Maybe<Meeting>;
  playUrl: Scalars["String"];
  recordingId: Scalars["ID"];
  startTime: Scalars["DateTime"];
  uid: Scalars["ID"];
  /** @deprecated Replaced with recordingId */
  zoomRecordingId: Scalars["ID"];
};
